import { CustomTime } from "../custom-classes/CustomTime";

/** Custom time to hours (1:30 --> 1.5 hours) */
export function getQuantityFromTime(ct: CustomTime | undefined): number {
    if (ct == undefined) { return 0 }
    let h = ct.getHours();
    let m = ct.getMinutes();
    return h + (m ? (m / 60).castDecimals(2) : 0);
}

/** Hours to time format (1.5 hours --> 1:30) */
export function hourToTime(hours: number): string {
    let h = hours - (hours % 1);
    let m = Math.round((hours % 1) * 60);
    let ct = new CustomTime(h.toString(), m.toString());
    return ct.getText();
}
