<div class="container" *ngIf="workloadData != undefined">
    <div class="top">
        <!-- Top filters -->
        <div class="df fdc jcc aic header">
            <!-- LINE 1-->
            <div class="subheader-1">
                <div class="bigdayRef">{{bigRefByDay(matSelect.value)}}</div>
                <div class="daySwitcher df aic">
                    <div class="today">
                        <button style="background-color: white;" mat-stroked-button (click)="goToday()">
                            {{selectedPeriod == pe.DAY ? 'HOY' : selectedPeriod == pe.WEEK ? 'ESTA SEMANA' : 'ESTE MES'}}
                        </button>
                    </div>
                    <button mat-icon-button (click)="arrowNextByFilter(matSelect.value, false)"
                        [matTooltip]="getTooltipByFilter(matSelect.value, false)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <button mat-icon-button (click)="arrowNextByFilter(matSelect.value, true)"
                        [matTooltip]="getTooltipByFilter(matSelect.value, true)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
            <!-- LINE 2-->
            <div class="df fdr fww">
                <div class="mr10">
                    <mat-form-field appearance="outline" class="periodFilter onboarding-workload-change-view">
                        <mat-select #matSelect [value]="selectedPeriod" (valueChange)="onSelectedPeriodChange($event)">
                            <mat-option [value]="pe.DAY">Día</mat-option>
                            <mat-option [value]="pe.WEEK">Semana</mat-option>
                            <mat-option [value]="pe.MONTH">Mes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="searFilterContainer">
                    <mat-form-field appearance="outline" class="searchFilter">
                        <mat-label>Buscar</mat-label>
                        <input #searchInput matInput placeholder="Buscar"
                            (keyup)="applySearchFilter(searchInput.value)">
                        <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
                        <app-autocomplete (click)="applySearchFilter(searchInput.value)">
                        </app-autocomplete>
                    </mat-form-field>
                </div>
                <div class="filters-phone onboarding-workload-phone-filters">
                    <app-filters [filters]="currentFilters"></app-filters>
                </div>
            </div>
            <div *ngIf="workloadData.data.switch">
                <button mat-button class="switch-button mr10 onboarding-workload-or-view" [ngClass]="{'active' : workloadData.isOrView}" *ngIf="workloadData.ORpermisison"
                    (click)="changeView(true);" onclick="this.blur()">
                    <mat-icon class="vam">build</mat-icon>
                    Vista OR
                </button>

                <button mat-button class="switch-button onboarding-workload-appontment-view" [ngClass]="{'active' : !workloadData.isOrView}" *ngIf="workloadData.AppoPermisison"
                    (click)="changeView(false);" onclick="this.blur()">
                    <mat-icon class="vam ">event</mat-icon>
                    Vista citas
                </button>
                
            </div>
        </div>
    </div>

    <div class="left">
        <div class="df fdc leftcontents" [ngClass]="{'week' : isWeek}">
            <div>
                <app-card>
                    <mat-progress-bar *ngIf="loadingCalendar" [mode]="'indeterminate'"></mat-progress-bar>
                    <mat-calendar [dateClass]="dateClass"></mat-calendar>
                    <div class="legend" *ngIf="showLegend   ">
                        <p *ngIf="workloadData.isOrView" class="tac">Plazas taller :  {{company?.getPlaces()}}</p>
                        <p *ngIf="!workloadData.isOrView" class="tac">Máx. citas día : {{company?.getMaxAppointments()}}</p>
                        <p><mat-icon class="c_g less_50">fiber_manual_record</mat-icon> Menos del 50%</p>
                        <p><mat-icon class="c_o more_50">fiber_manual_record</mat-icon> Más del 50%</p>
                        <p><mat-icon class="c_r full_or_more">fiber_manual_record</mat-icon> 100% o más</p>
                    </div>
                </app-card>
            </div>
            <div class="mt5 onboarding-workload-filters">
                <app-card [contentLoaded]="userFilter.loaded" *ngIf="workloadData.isOrView">
                    <app-card-title [noMarginBottom]="true">Filtros</app-card-title>
                    <!-- FILTERS-->
                    <app-user-filter [filter]="userFilter"></app-user-filter>
                    <app-tagfilter [filter]="statusFilter"></app-tagfilter>
                    <app-tagfilter [filter]="typeFilter"></app-tagfilter>
                </app-card>
                <app-card [contentLoaded]="userFilter.loaded" *ngIf="!workloadData.isOrView">
                    <app-card-title [noMarginBottom]="true">Filtros</app-card-title>
                    <app-tagfilter [filter]="appointemtStatus"></app-tagfilter>
                    <app-enum-filter [filter]="alreadyClientFilter"></app-enum-filter>
                    <!-- FILTERS-->
                </app-card>
            </div>
        </div>
    </div>
    <div class="calendar">
        <app-drag-and-drop-grid 
            (onModifyAppointment)="onModifyAppointment.emit($event)"
            (deliveryWarn)="deliveryWarn.emit($event)"
            (onDragFinished)="onDragFinished($event)" [periodEnum]="matSelect.value"
            [refDay]="refDay"></app-drag-and-drop-grid>
    </div>

</div>

<mat-menu #create="matMenu">
    <button *ngIf="workloadData && workloadData.data.or.views.createOr" mat-menu-item
        (click)="routerS.goTo(workloadData.data.or.views.createOr)">OR</button>
</mat-menu>