import { IClassSearcher } from "../interfaces/IClassSearcher";
import { endpoints } from "../constants/Enpoints";
import { M_Action } from "./M_Action";
import { M_Invoice } from "./M_Invoice";
import { M_Company } from "./M_Company";
import { RolesEnum } from "../enums/RolesEnum";
import { M_LastActivity } from "./M_Dashboard";
import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { M_BaseUser } from "./M_BaseUser";
import { match } from "../services/search.service";
import { parseOBJ } from "../custom-classes/ModelParser";

export class M_User extends M_BaseUser implements ISearchFiltrable, IClassSearcher<M_User> {
   confirm_sent: boolean = false;
   company_code: string | undefined;
   actions: M_Action[] = [];
   invoices: M_Invoice[] = [];
   company: M_Company | undefined;
   role: RolesEnum;
   logs : M_LastActivity[] =[];
   last_login_at : Date | undefined;
   showed_workload : boolean = false;

   constructor(d: any) {
      super(d);
      this.confirm_sent = d.confirm_sent ? d.confirm_sent : false;
      this.company_code = d.company_code;
      this.company = d.company ? new M_Company(d.company) : undefined;
      this.role = d.role ? d.role : RolesEnum.NOONE;
      this.last_login_at = d.last_login_at? new Date(d.last_login_at) : undefined;
      this.showed_workload = this.last_login_at ? true : false;

      /** To review on implement appointments */
      if (d.holidays) {
         for (let i = 0; i < d.holidays.length; i++) {
            var currentObj = d.holidays[i];
            if (currentObj.day_holiday) {
               this.holidays.push(new Date(currentObj.day_holiday))
            }
         }
      }
      if (d.actions) {
         for (let i = 0; i < d.actions.length; i++) {
            this.actions.push(new M_Action(d.actions[i]));
         }
      }

      if (d.invoices) {
         for (let i = 0; i < d.invoices.length; i++) {
            this.invoices.push(new M_Invoice(d.invoices[i]));
         }
      }
      if (d.logs) {
         for (let i = 0; i < d.logs.length; i++) {
            this.logs.push(new M_LastActivity(d.logs[i]));
         }
      }
   }

   defaultSearchFilter(text: string): boolean {
      return match(text, this.name, this.email);
   }
   parse(): any {
      let obj: any = parseOBJ(this);
      let newDates: string[] = [];
      this.holidays.forEach(dat => {
         newDates.push(dat.dataBaseFormat())
      });
      obj['day_holiday'] = newDates;

      return obj;
   }

   get admin(){return this.role == RolesEnum.ADMIN}
   get adviser(){return this.role == RolesEnum.ADVISER}
   get comercial(){return this.role == RolesEnum.COMERCIAL}
   get recambista(){return this.role == RolesEnum.RECAMBISTA}
   get mechanic(){return this.role == RolesEnum.MECHANIC}

   get roleName() {
      if (this.admin) {
         return "Administrador";
      }
      else if (this.adviser) {
         return "Asesor";
      }
      else if (this.mechanic) {
         return "Mecánico";
      }
      else if (this.comercial) {
         return "Comercial";
      }
      else if (this.recambista) {
         return "Recambista";
      }
      return "Sin rol";
   }
   /** CLASS SEARCHER INTERFACE */
   get endpoint() { return endpoints.users }
   get cs_id() { return this.id; }
   get icon() { return "build"; }
   get typeof() { return M_User }
   createNew(d: any) { return new M_User(d) }
   getInputText(): string { return this.name; }
   getOptionText(): [string, string] { return ([this.name, this.email]) }
}