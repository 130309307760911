import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[plural]'
})
export class PluralDirective {

  /** Quantity, singular, plural */
  @Input({ required: true }) val!: [number | undefined, string, string]

  constructor(private el: ElementRef<HTMLElement>) { }

  ngOnChanges() {
    this.changeElementText();
  }

  changeElementText() {
    let value = this.val[0]
    let singular = this.val[1];
    let plural = this.val[2];
    let finalTxt = singular;
    if (value != undefined && value != 1) { finalTxt = plural; }
    this.el.nativeElement.innerHTML = finalTxt;
  }
}
