import { COUNTRIES_DB_ES, Country } from "@angular-material-extensions/select-country";
import { COUNTRY_ESP } from "../constants/constants";

/** Generte holiday array
 * @param obj : Array containing the holidays object
 * @param of : Get the company or the user holidays
*/
export function generateHoliday(obj: any, of: "company" | "user"): Date[] {
    var returnArray: Date[] = [];
    if (obj) {
        for (let i = 0; i < obj.length; i++) {

            var currentObj = obj[i];

            if (currentObj.day_holiday) {
                if (currentObj.company_id && of == "company") {
                    returnArray.push(new Date(currentObj.day_holiday))
                }
                else if (currentObj.user_id && of == "user") {
                    returnArray.push(new Date(currentObj.day_holiday))
                }
            }
        }
    }
    return returnArray;
}


export function getCountry(country: any): Country {

    if (!country) { return COUNTRY_ESP; }

    /** Gettign coutntry from backend */
    if (country && typeof country == "string") {
        let filtered = COUNTRIES_DB_ES.find(c => c.alpha2Code == country);
        if (filtered) { return filtered; }
    }
    /** Creating client object form frontend */
    else if ('alpha2Code' in country) {
        return country;
    }

    /** By default spain */
    return COUNTRY_ESP;
}