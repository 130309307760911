<div class="layout-send-email">
    
    <!-- Normal style-->
    <button *ngIf="butontype == 'normal'" #btn mat-flat-button color="primary" (click)="sendEmail(); btn.disabled = true" [disabled]="disabled" style="margin-top: 10px">
        <div [ngTemplateOutlet]="buttonContent"></div>
    </button>

    <!-- Mat menu item style -->
    <button *ngIf="butontype == 'mat-menu-item'" #btn mat-menu-item (click)="sendEmail(); btn.disabled = true" [disabled]="disabled" style="margin-top: 10px">
        <div [ngTemplateOutlet]="buttonContent"></div>
    </button>
    
</div>


<ng-template #buttonContent>
    <div [matTooltip]="tooltipIfSended" class="df jcc aic">
        <mat-icon class="mr5" *ngIf="showIcon">mail</mat-icon> 
        <span>{{est?.btnText}}</span>
    </div>
</ng-template>