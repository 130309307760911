import { AbstractControl, ValidatorFn } from '@angular/forms';

/** Strict plate validator 
  * 
  * Accepts : Combination like (9090LNK) or (B9090LN)
*/
export function plateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (/^[0-9]{4}[A-Z]{3}$/.test(control.value) || (/^[A-Z]{1}\d{4}[A-Z]{2}$/.test(control.value))) {
      return null;
    }
    else {
      if (/[a-z]/.test(control.value)) {
        return { lowercase: true };
      }
      else {
        return { plate: true };
      }

    }
  }
}

/** Strict plate validator. Allow more combinations 
  * 
  * Accepts : A string with at least one number and one character. The string must be at least 4 in length.
*/
export function softPlateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    /** At least one number and charater */                                                        /** At least 4 charater */
    if (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(control.value) && control.value.length && control.value.length >= 4) {
      return null;
    }
    else {
      if (/[a-z]/.test(control.value)) {
        return { lowercase: true };
      }
      else {
        return { plate: true };
      }
    }
  }
}