<div [ngClass]="event.isUserComment ? 'you' : 'support' ">
    <div class="comment-content">
        <div class="first-line" *ngIf="userToShow != undefined">
            <app-circular-letter [user]="userToShow" [showFullName]="false" [showTooltip]="false"></app-circular-letter>
            <div class="df fdc">
                <div class="name-date">
                    <p class="fw500">{{userToShow.name}}</p>
                    <p class="c_t2 fss">{{event.created_at.isToday()? event.created_at.hourFormat() :
                        event.created_at.shortFormat()}}</p>
                </div>
                <div>
                    <p class="comment" *ngIf="!event.isActionComment; else actionComment">
                        <span>{{event.value}}</span>
                    </p>
                    <div class="attached-img" *ngIf="event.file">
                        <span class="underline_hover" (click)="previewS.openPreview(event.file.src)">Imagen adjunta</span>
                    </div>
                </div>

            </div>
        </div>

        <ng-template #actionComment>
            <p class="comment link" (click)="doAction(event.action_id)">
                <span>Ver sección {{event.action_id}}</span>
            </p>
        </ng-template>

        <!--<div class="bottom-content" *ngIf="comment.created_at">

            <mat-icon class="loading">{{comment.uploading ? 'schedule' : 'done'}}</mat-icon>
        </div> !-->
    </div>
</div>