<app-page-structure #ps [pageTitle]="'Compras'" (onclickAddNew)="openDialog()"
    [masterClass]="undefined" [autocompleteID]="'users'" searchLabel="Buscar compra"
    listTitleText="Listado de compras"
    createButtonClass="onboarding-company-users-create" [addNewView]="undefined" [data]="[]"
    [displayedHeader]=" ['Número', 'Fecha facturación','Proveedor cliente','Total','Tipo']"
    [displayedColumns]="['comment', 'date','contact','total','typeName  ']"
    [specialText]="[    undefined,         undefined,            contact,           'money',      typeName]"
    [filter]="f" [basicUserSubscription]="subS.isPro"
    (onSubscriptionAddFail)="subS.openTryProDialog()" (onChangeSelected)="onChange()"
    [createButton]="{text : 'Registrar compra' , icon : 'shopping_basket'}"
    (onclickRow)="params.go(v.purchaseDetails, $event.id)">
</app-page-structure>