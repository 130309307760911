import { Component, Input} from '@angular/core';
import { M_Contact } from '../../models/M_Contact';

@Component({
  selector: 'app-client-consents',
  templateUrl: './client-consents.component.html'
})
export class ClientConsentsComponent {
  @Input() client! : M_Contact | undefined;
  constructor() { }

}
