import { Directive, ElementRef, OnInit } from '@angular/core';
import { CompanyService } from '../services/EinaMainData/company.service';
import { ModulesEnum } from '../enums/ModulesEnum';

/**
 * Check that the module (the current selector) is active.
 * If it is not, remove the HTMl element from the DOM.
 */
@Directive({
  selector: '[recambios], [vnvo]'
})
export class ModuleDirective implements OnInit {

  private m : ModulesEnum;

  constructor(private el: ElementRef, private companyS: CompanyService) { 
    const element = this.el.nativeElement;
    if (element.hasAttribute('recambios')) {
      this.m = ModulesEnum.RECAMBIOS;
    } else if (element.hasAttribute('vnvo')) {
      this.m = ModulesEnum.VNVO;
    }
    else {
      throw Error("Not implemented")
    }
  }

  ngOnInit(): void {
    if (!this.m) { return; }
    if (!this.companyS.company.hasModule(this.m)) {
      this.removeElement(this.el);
    }
  }

  removeElement(el: ElementRef) {
    el.nativeElement.classList.add('dn_i');
    el.nativeElement.remove();
  }

}
