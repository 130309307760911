import { Injectable } from '@angular/core';
import { CustomLog } from '../custom-classes/CustomLog';
import { CustomErrorHandler } from '../custom-classes/CustomErrorHandler';
import { StorageService } from './storage.service';
import { projectConfiguration } from '../app.module';


/**
 *  Reloads the browser cache if the user has a old frontend version 
 *  (window.location.reload()).
 * 
 *  @description
 *  Checks the version of the app by calling and endpoint.
 *  The endpoint to call is defeined in CoreConfiguration
 * 
 * @example
 * By default, the 'entry-point-service' calls the checkvrsion function.
 * 
 * Example implementation:
 * ```ts
 * constructor(private checkV : CheckVersionService) {
 *  this.checkV.checkVersion();
 * }
 * ```
 */

@Injectable({
  providedIn: 'root'
})
export class CheckVersionService {

  constructor(private storageS: StorageService, private errorS: CustomErrorHandler) { }

  checkVersion() {
    if (projectConfiguration.checkVersionEndpoint) {
      fetch(projectConfiguration.checkVersionEndpoint.url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then(res => {
        this.processResponse(res);
      })
    }
  }

  private getVersionFormResponse(res: any): number | undefined {
    if (res && Array.isArray(res) && res[1]) {
      if (typeof res[1] == "number") {
        return res[1];
      }
    }
    return undefined;
  }

  private processResponse(res: Response) {
    res.json().then(res => {
      var serverVersion: number | undefined = this.getVersionFormResponse(res);
      if (serverVersion) {
        var currentVersion = this.storageS.get("version");
        if (currentVersion == null) {
          this.storageS.save("version", serverVersion);
        }
        else {
          var v = currentVersion.getNumber()!;
          if (v < serverVersion) {
            this.storageS.save("version", serverVersion);
            //this.errorS.report(new CustomLog("info", "Client frontent auto-update successfully!"))
            window.location.reload();
          }
        }
      }
      else {
        this.errorS.report(new CustomLog("warning", "The endpoint of check version don't return a number"))
      }

    })

  }

}
