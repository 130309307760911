<p *ngIf="!details && !edit" class="card-title"
    [ngClass]="{'card-title' : isTitle, 'card-subtitle' : !isTitle,'cp' : cursorPointer, 'nmb' : noMarginBottom, 'card-basetitle-first' : first, 'c_p' : primaryColor}"
    (click)="emitClick()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <mat-icon class="info-tooltip" *ngIf="info" #tooltip="matTooltip"
        (click)="tooltip.toggle()" [matTooltip]="info">info</mat-icon>
    <mat-icon class="ml5" style="vertical-align: sub; color: inherit;" *ngIf="rightIcon">{{rightIcon}}</mat-icon>
    
</p>

<button *ngIf="details || edit" mat-button
    [ngClass]="{'card-title' : isTitle, 'card-subtitle' : !isTitle,'title-details' : details, 'title-edit' : edit}"
    (click)="emitClick()" [matTooltip]="getIconTooltip" [matTooltipPosition]="'above'">
    <mat-icon class="mr5" style="vertical-align: text-top;" *ngIf="edit">edit</mat-icon>
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <mat-icon iconPositionEnd  class="ml10" style="vertical-align: text-top;" *ngIf="details">arrow_forward</mat-icon>
</button>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>