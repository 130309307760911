import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { match } from "../services/search.service";

export class M_Product_Invoice implements ISearchFiltrable {
    id: number;
    product_id: number;
    invoice_id: number;
    invoice_id_company: string;
    quantity: number;
    details: string;
    discount: number | null;
    custom: number;
    created_at: Date;
    token: string;
    control_stock: boolean | undefined;
    type: "invoice" | "abono" | "action";

    constructor(d: any) {
        this.id = d.id;
        this.type = d.type;
        this.product_id = d.product_id;
        this.invoice_id = d.invoice_id;
        this.quantity = d.quantity;
        this.control_stock = d.control_stock;
        this.details = d.details;
        this.discount = d.discount;
        this.custom = d.custom;
        this.created_at = new Date(d.created_at);
        this.token = d.token;
        this.invoice_id_company = d.invoice_id_company;
    }

    get isAbono() {
        return this.type == "abono";
    }

    isAdd() {
        return this.details == 'add';
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.invoice_id_company, this.quantity.toString());
    }
}