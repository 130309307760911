<!-- DAY !-->
<div class="day-card-parent"
  [ngClass]="{'draggable-item' : canDrag, 'day-or' : true,'gray-out' : !onFilters(), 'opacity-drag-filters' : disabledUserDragging()}"
  (click)="goEdit(item.id)" cdkDrag [cdkDragDisabled]="!canDrag" [cdkDragData]="item" (cdkDragStarted)="onDrag($event)"
  *ngIf="isDay" (mouseenter)="onHover()" (touchend)="onHover()">

  <div class="placeholder" [ngClass]="{'week-or' : isWeek, 'month-or': isMonth, 'day-or' : isDay}" *cdkDragPlaceholder>
  </div>

  <!-- OR DAY-->
  <lib-or-day-card *ngIf="isOrItem(item)" [item]="item" [allUsers]="allUsers" [day]="day"
    [canAssign]="workloadData?.data?.or?.assignAction != undefined && workloadData?.canUserAssign != false"
    (onAssignUser)="assignNewUser($event)" (onGoOR)="goEdit(item.id)"
    [canContact]="workloadData?.canContact"></lib-or-day-card>

  <!-- APPONTIMENT DAY !-->
  <lib-appointment-day-card *ngIf="!isOrItem(item)" [item]="item" (click)="goEdit(item.id)"
    (onModifyAppointment)="onModifyAppointment.emit($event)"></lib-appointment-day-card>

</div>



<!-- WEEK AND MONTH -->
<div class="dragable-box" *ngIf="!isDay"
  [ngClass]="{'draggable-item' : canDrag, 'week-or' : isWeek, 'needsHighlight' : needH(), 'month-or': isMonth, 'day-or' : isDay, 'gray-out' : !onFilters(), 'opacity-drag-filters' : disabledUserDragging()}"
  cdkDrag [cdkDragDisabled]="!canDrag" [cdkDragData]="item" (cdkDragStarted)="onDrag($event)" (mouseenter)="onHover()"
  (touchend)="onHover()">

  <div class="placeholder" [ngClass]="{'week-or' : isWeek, 'month-or': isMonth, 'day-or' : isDay}" *cdkDragPlaceholder>
  </div>


  <div *ngIf="isWeek" class="w100">
    <!-- WEEK OR -->
    <lib-or-week-card *ngIf="isOrItem(item)" [item]="item" [allUsers]="allUsers" [day]="day"
      [canAssign]="workloadData?.data?.or?.assignAction != undefined && workloadData?.canUserAssign != false"
      (onAssignUser)="assignNewUser($event)" (onGoOR)="goEdit(item.id)" [onFilters]="onFilters()"
      [canContact]="workloadData?.canContact"></lib-or-week-card>

    <!-- WEEK APOINTMENT -->
    <lib-appointment-week-card *ngIf="!isOrItem(item)" [item]="item" (click)="goEdit(item.id)"
      (onModifyAppointment)="onModifyAppointment.emit($event)"></lib-appointment-week-card>
  </div>


  <!-- MONTH OR CARD SAME HTML FOR OR AND APPOINTMENTS -->
  <div *ngIf="isMonth" class="cp" style="width: 100%;" (click)="goEdit(item.id)">
    <div class="df jcc aic">
      <!--<app-or-type-label [showText]="false" [tipo]="or.type" [verticalLines]="true"></app-or-type-label> !-->
      <div class="df aic">
        <mat-icon class="or_icon_month">{{icon}}</mat-icon>
        <p class="month-text workload-overflow-text c_p"> <span class="action-number">{{isOrItem(item) ? item.id_to_show
            : item.id}}</span></p>
      </div>
      <!--<span class="month-max-width c_t2 fss"> {{' ' + item.vehicleLicense()}}</span>!-->
      <app-or-status-label *ngIf="isOrItem(item)" class="circular-status-month" [circularStatus]="true"
        [status]="item.status" [showText]="isOnMatMenu"></app-or-status-label>
      <lib-appointment-status-label class="appoinment-status-month" *ngIf="!isOrItem(item)" [circularStatus]="true"
        [status]="item.status"></lib-appointment-status-label>
    </div>
    <p *ngIf="isOnMatMenu && isOrItem(item)" class="sub-month-text workload-overflow-text">{{item.vehicleLicense()!}}
    </p>
  </div>
</div>