<div [formGroup]="form" class="eina-form-section">

    <!-- Brand -->
    <mat-form-field class="brand-form-field" appearance="outline">
        <mat-label>Marca</mat-label>
        <input #brand matInput placeholder="Marca" formControlName="brand" [matAutocomplete]="brandAuto"
            (focusout)="refreshModelsBasedOnBrand(form.get('brand')!.value, true)" (focus)="filterBrands(brand.value)">
        <mat-autocomplete #brandAuto="matAutocomplete" (optionSelected)="refreshModelsBasedOnBrand($event, true)"
            [displayWith]="displayBrand">
            <mat-option *ngFor="let b of filteredBrands | slice:0:MAX_RESULTS" [value]="b">{{b.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <!-- Model -->
    <mat-form-field class="model-form-field" appearance="outline">
        <mat-label>Modelo</mat-label>

        <div matSuffix [ngClass]="{'vh' : !loadingModels}">
            <mat-spinner [strokeWidth]="6" [diameter]="25" mode="indeterminate"
                style="margin-right: 5px;"></mat-spinner>
        </div>

        <input #model matInput placeholder="Modelo" formControlName="model" [matAutocomplete]="modelAuto"
            (focus)="filterModels(model.value)">
        <mat-autocomplete #modelAuto="matAutocomplete" [displayWith]="displayModel">
            <mat-option *ngFor="let m of filteredModels | slice:0:MAX_RESULTS" [value]="m" (onSelectionChange)="onSelectModel.emit(m)">
                {{m.name}}
                <span class="c_t2" *ngIf="showPrice && m.details" money [val]="m.details.price"></span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>