<div class="onboarding-or-tasks">
    <div *ngFor="let g of groups" class="ra-container">
        <app-or-type-label [menuIconOpen]="true"
            [mat-menu-trigger-for]="groupType" [showText]="false" [tipo]="[g.or_type]" [menuIconOpen]="true">
        </app-or-type-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Descripción de las intervenciones</mat-label>
            <input #input matInput [(ngModel)]="g.title" [maxlength]="150">
            <button matSuffix mat-icon-button (click)="removeGroup(g)" [disabled]="groups.length == 1"><mat-icon>delete</mat-icon></button>
            <mat-hint [align]="'end'">{{input.value.length + "/" + 150}}</mat-hint>
          </mat-form-field>
        <mat-menu #groupType="matMenu" [yPosition]="'above'">
            <app-or-type-selector (onClickOption)="onTypeChange(g, $event)"></app-or-type-selector>
        </mat-menu>
    </div>
    <div class="df jcc">
        <button color="accent" style="margin-top: 10px;" mat-flat-button (click)="addGroup()">
            <mat-icon>account_tree</mat-icon>
            Nueva intervención
        </button>
    </div>
</div>