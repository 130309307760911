import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { BrandModelInputComponent } from 'src/app/components/brand-model-input/brand-model-input.component';
import { ConceptsLineTableComponent } from 'src/app/components/concepts-line-table/concepts-line-table.component';
import { VehicleType } from 'src/app/enums/VehicleType';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { M_Concept } from 'src/app/models/M_Concept';
import { Location } from '@angular/common';
import { MASTER_CLIENT } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { MatDialog } from '@angular/material/dialog';
import { CreateVNVObyBudetg, CreateVNVOcomponent } from '../create-vn-vo/create-vn-vo.component';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { SellVehicleComponent, SellVehicleDialogData } from '../sell-vehicle/sell-vehicle.component';
import { ParamsService } from 'src/app/services/params.service';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { IConceptLineTableComponent } from 'src/app/interfaces/IConceptLineTableComponent';
import { M_Model } from 'src/app/models/M_Model';
import { CreateClientInDialogService } from 'src/app/services/create-client-in-dialog.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { or_status_close } from 'src/app/custom-classes/or_states';

@Component({
  selector: 'app-create-comercial-budget',
  templateUrl: './create-comercial-budget.component.html',
  styleUrls: ['./create-comercial-budget.component.css']
})

export class CreateComercialBudgetComponent extends ParameterStateComponent implements IProductLineTableComponent, IConceptLineTableComponent {

  v = ViewPath;
  private loadedBudget = true;
  form: UntypedFormGroup;
  formHasChanges = false;
  cBudget: M_ComercialBudget | undefined;
  master_client = MASTER_CLIENT;

  provisionalConcepts: M_Concept[] = [];
  provisionalProducts: (M_Product | M_CustomProduct)[] = [];

  get blocksLine(): boolean { return true; }

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  @ViewChild('clientSearcher', {static : true}) clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  @ViewChild(BrandModelInputComponent) brandModelComponent?: BrandModelInputComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;

  constructor(routerS: RouterService, route: ActivatedRoute, fb: FormBuilder, private apiS: ApiService,
    private confirmD: ConfirmDialogService, private snackS: SnackService, private location: Location, private d: MatDialog,
    private paramsS: ParamsService, private chdRef: ChangeDetectorRef, public ccDialog: CreateClientInDialogService) {
    super(routerS, route, ["budget", "client"]);
    this.form = fb.group({
      id: [],
      client_id: ['', Validators.required],
      type: [VehicleType.bike],
      brand: ['', Validators.required],
      model: ['', Validators.required],
      price: ['', Validators.required]
    })

    this.form.valueChanges.subscribe(v => {
      if (this.loaded && !this.form.disabled) {
        this.formHasChanges = true;
      }
    })
  }


  override onParam(param: string, value: string): void {
    if (param == "budget") {
      this.apiS.vnvo.getComercialBudget(value.getNumber()).then(res => {
        this.cBudget = res;
        this.setComercialBudgetToForm(this.cBudget);
      })
    }
    if (param == "client") {
      console.log("Oke 22")
      this.clientSearcherComponent?.setMasterById(value.getNumber())
    }
  }

  addConcept(c: M_Concept): void {
    this.getConcepts().push(c);
  }

  getConcepts() {
    return this.cBudget ? this.cBudget.concepts : this.provisionalConcepts;
  }

  getClient() {
    return this.clientSearcherComponent?.selected;
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    let disc = this.clientSearcherComponent?.selected?.getClientDiscount(p);
    return disc ? disc : null;
  }

  get selectedClient() {
    if (this.clientSearcherComponent?.selected) {
      return false;
    } else {
      return true;
    }
  }

  patchPrice(m: M_Model) {
    if (m.details?.price) {
      this.form.patchValue({ price: m.details.price })
    }
  }

  setComercialBudgetToForm(cBudget: M_ComercialBudget) {
    this.form.patchValue(cBudget);
    this.clientSearcherComponent?.setMasterById(cBudget.client_id);
    this.formHasChanges = false;
    this.updateDisabled();
  }

  updateDisabled() {
    /** This is bad. Refactor needed */
    if (this.cBudget?.status.pending) {
      this.form.get('brand')?.disable({ emitEvent: false });
      this.form.get('model')?.disable({ emitEvent: false });
      this.form.get('price')?.disable({ emitEvent: false });
    }
  }

  goBudgets() {
    this.routerS.goTo(ViewPath.comercialBudget);
  }

  createEdit(): Promise<boolean> {

    return new Promise(resolve => {
      if (this.isCreateEditEnabled) {
        resolve(true);
      }

      let budget = new M_ComercialBudget(this.form.value);
      budget.concepts = this.getConcepts();
      budget.products = this.getProducts();

      return this.apiS.vnvo.createEditComercialBudget(budget).then(res => {
        this.cBudget = budget;
        this.cBudget.id = res.id;
        this.cBudget.token = res.token;
        this.form.patchValue({ id: this.cBudget.id })
        this.conceptLineTable.clearUnsaved();
        this.productLineTable.clearUnsaved();
        this.formHasChanges = false;
        this.location.replaceState("/" + this.v.createComercialBudget.path + "?budget=" + this.cBudget.id);
        resolve(true);
      })
    })
  }

  getProducts() {
    return this.cBudget ? this.cBudget.products : this.provisionalProducts;
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    this.getProducts().push(p);
  }

  addTime(time: M_CustomProduct) {
    this.addProduct(time)
    this.chdRef.detectChanges();
  }

  addComment(comment: M_CustomProduct): void {
    this.addProduct(comment)
    this.chdRef.detectChanges();
  }

  removeProduct(p: M_CustomProduct | M_Product): void {
    this.getProducts().removeElement(p);
  }

  deleteComercialB() {
    if (!this.cBudget) { return; }
    this.confirmD.show({
      title: "Eliminar presupuesto",
      body: "¿Está seguro de que desea eliminar este presupuesto?",
      confirmTxt: "Sí",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.vnvo.deleteConmercialBudget(this.cBudget!.id).then(res => {
          this.routerS.goWithQueryParams(ViewPath.budget, { tab: 1 });
          this.snackS.show("Presupuesto eliminado")
        })
      }
    })
  }

  closeBudget() {
    if (!this.cBudget) { return; }
    this.createEdit().then(res => {
      this.apiS.vnvo.closeComercialBudget(this.cBudget!.id).then(res => {
        this.cBudget!.status = or_status_close;
        this.updateDisabled();
      })
    })
  }

  get isCreateEditEnabled() {
    return this.isEdit ? this.isEditEnabled : this.isCreateEnabled;
  }

  private get isEditEnabled() { return this.form.valid && (this.conceptLineTable?.hasChanges || this.productLineTable?.hasChanges || this.formHasChanges) }
  private get isCreateEnabled() { return this.form.valid; }


  get isEdit() {
    return this.cBudget != undefined;
  }

  get loaded() {
    return this.loadedBudget && this.brandModelComponent?.loaded;
  }

  get isClosed() {
    return this.cBudget && this.cBudget.status.pending;
  }

  get showDeleteMenu() {
    return this.isEdit && !this.isClosed
  }

  get isOpenOrUdefined() {
    return !this.cBudget || this.cBudget.status.open;
  }

  toStock(invoiceAfterCreate: boolean = false) {
    console.log(this.cBudget);
    if (!this.cBudget) { return; }
    this.d.open<CreateVNVOcomponent, CreateVNVObyBudetg>
      (CreateVNVOcomponent, { data: { budget: this.cBudget }, autoFocus: false }).afterClosed().subscribe(res => {
        if (res instanceof M_Vehicle) {
          this.cBudget!.vehicle = res;
          if (invoiceAfterCreate) {
            this.invoice(this.cBudget!.vehicle);
          }
        }
      })
  }

  goToVehicle(v: M_Vehicle) {
    this.paramsS.goVehicleDetails(v)
  }

  invoice(v: M_Vehicle) {
    if (!this.cBudget) { return; }
    if (!this.cBudget.vehicle) {
      console.log(this.cBudget.vehicle);
      this.toStock(true);
    }
    else {
      this.d.open<SellVehicleComponent, SellVehicleDialogData>(SellVehicleComponent, { data: { budget: this.cBudget }, autoFocus: false }).afterClosed().subscribe(res => {
        if (res instanceof M_Invoice) {
          this.cBudget!.invoice = res;
        }
      })
    }
  }

  goToInvoice(i: M_Invoice) {
    this.routerS.goToWithState(ViewPath.invoices, i.id);
  }

}
