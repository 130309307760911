import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { templateTypedoc } from '../../models/M_TemplateField';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PreviewService } from '../../services/preview.service';
import { PdfDocumentsUploadComponent } from '../pdf-documents-upload/pdf-documents-upload.component';
import { DocumentationConfigComponent } from 'src/app/views/settings/company-tab/documentation-config/documentation-config.component';

@Component({
  selector: 'app-documentation-compra-venta',
  templateUrl: './documentation-compra-venta.component.html',
  styleUrls: ['./documentation-compra-venta.component.css']
})
export class DocumentationCompraVentaComponent {
  @Input() pdf_type!: templateTypedoc;
  public form: UntypedFormGroup;
  public pending = false;
  @Output() pendingChange = new EventEmitter<boolean>();
  formData = new FormData();
  public file_out: File | undefined;
  @Output() fileChanged = new EventEmitter<File | null>();
  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent; // Referencia al componente hijo
  @ViewChild("compraventa") compraventaConfig!: DocumentationCompraVentaComponent;
  constructor(private formBuilder: UntypedFormBuilder, public parent: DocumentationConfigComponent, public previewS: PreviewService) {
    this.form = this.formBuilder.group({
      file: [''],
    });

  }
  onFileSelected(file: File | null) {
    if (file) {
      this.pending = true;
      if (this.pdfUploadComponent.selectedFile) {
        this.formData.append('pdf_file', this.pdfUploadComponent.selectedFile);
        this.file_out = this.pdfUploadComponent.selectedFile;
      }
    } else {
      this.pending = false;
    }
  }
  get file() {
    return this.file_out;
  }

  showPreview() { }
}
