import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { feature } from 'src/app/utils/FeaturesController';
import { M_Action } from 'src/app/models/M_Action';
import { ParamsService } from 'src/app/services/params.service';
import { PreviewService } from 'src/app/services/preview.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { action_type_or } from 'src/app/custom-classes/action_types';
import { Filter, DayFilter, TagFilter, FilterOption } from 'src/app/custom-classes/Filter';
import { or_status } from 'src/app/custom-classes/or_states';
import { or_types } from 'src/app/custom-classes/or_types';
import { getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RolesEnum } from 'src/app/enums/RolesEnum';

export enum ORPageFiltesrEnum {
  OR_DELIVEY = 0,
  OR_STATUS = 1,
  OR_GROUP_STATUS = 2,
  OR_TYPE = 3
}

@Component({
  selector: 'app-or',
  templateUrl: './or.component.html',
  styleUrls: ['./or.component.css']
})
export class OrComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;
  v = ViewPath;
  f = filter
  R = RolesEnum;
  filters: Filter[] = [
    new DayFilter("Fecha entrega").setId(ORPageFiltesrEnum.OR_DELIVEY),
    new TagFilter("Estado general OR", or_status, new FilterOption("Abierta"), new FilterOption("Cerrada"), new FilterOption("Facturada")).setId(ORPageFiltesrEnum.OR_STATUS),
    new TagFilter("Estado intervenciones", or_status, new FilterOption("Abierta"), new FilterOption("Cerrada"), new FilterOption("Facturada")).setId(ORPageFiltesrEnum.OR_GROUP_STATUS),
    new TagFilter("Tipo", or_types, new FilterOption("Normal"), new FilterOption("Interno", undefined), new FilterOption("Garantia", undefined, !feature.garantia), new FilterOption("Siniestro"), new FilterOption("Sin tipo")).setId(ORPageFiltesrEnum.OR_TYPE)
  ]

  constructor(public params: ParamsService, public apiS: ApiService, public subS: SubscriptionService, private previewS: PreviewService,public companyS:CompanyService) {
    apiS.actions(action_type_or).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == ORPageFiltesrEnum.OR_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  previsualize(or: M_Action) {
    this.previewS.showPreview("RA", or.token, undefined, or.id);
  }


  ngOnInit(): void { }
  license(action: M_Action) { return action.vehicle?.license; }
  brand(action: M_Action) { return action.vehicle ? action.vehicle.getName(false) : ''; }
  state(action: M_Action) { return action.status.name; }
  //type(action: M_Action) { return action.getStrTypes(); }
  //actionType(action: M_Action) { return action.getTypeColor() }
  delivery(action: M_Action) { return action.delivery ? action.delivery.dayMonthYearFormat() : "" }
  stateColor(action: M_Action) { return action.getStateColor() }

  async pickUpMail() {
    if (this.ps.selected && this.ps.selected.client) {
      const { id, client: { name, email }, company_id, vehicle } = this.ps.selected;
      if (id && name && email && company_id && vehicle) {
        this.apiS.pickUpMail(id, name, email, company_id, vehicle);
      }
    }
  }
  reSendOR = () => {
    if (this.ps.selected && this.ps.selected.client) {
      let client = this.ps.selected.client;
      let group_ids = this.ps.selected.groups.map(group => group.id);
      let comments = '';
      let date = this.ps.selected.created_at;
      let last_movement = this.ps.selected.client.last_movement

      return this.apiS.invoiceOR(group_ids, client, comments, last_movement, date);
    }
    return Promise.resolve(null);
  }
  
  preicon(action : M_Action){
    return isSomethingMissing(action.faults)
  }
}
