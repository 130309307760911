import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { VehicleformComponent } from 'src/app/components/vehicleform/vehicleform.component';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { SnackService } from 'src/app/services/snack.service';
import { RouterService } from 'src/app/services/router.service';

export type CreateVNVObyBudetg = {
  budget: M_ComercialBudget
}

@Component({
  selector: 'app-create-vn-vo',
  templateUrl: './create-vn-vo.component.html',
  styleUrls: ['./create-vn-vo.component.css']
})

export class CreateVNVOcomponent extends ParameterStateComponent implements OnInit {

  @ViewChild(VehicleformComponent, { static: true }) vehicleForm!: VehicleformComponent;

  loaded = false;
  isEdit = false;
  IsDialog = false;
  ve: M_Vehicle | undefined;
  v = ViewPath;
  constructor(private apiS: ApiService, private snackS: SnackService,
    routerS: RouterService, route: ActivatedRoute, private params: ParamsService,
    @Optional() public dialogRef: MatDialogRef<CreateVNVOcomponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CreateVNVObyBudetg | null) {
    super(routerS, route, ["vnvo"]);
  }

  ngOnInit(): void {
    if (this.data?.budget) {
      this.IsDialog = true;
      this.patchFormWithComercaialBudget(this.data.budget);
    }
  }

  patchFormWithComercaialBudget(cBudget: M_ComercialBudget) {
    this.vehicleForm.provisionalConcepts = cBudget.concepts;
    this.vehicleForm.form.patchValue(
      {
        brand: cBudget.brand,
        model: cBudget.model,
        price: cBudget.price,
        budget_id: cBudget.id,
      }
    )
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  get title() {
    return this.isEdit ? 'Editar VN/VO' : 'Crear VN/VO'
  }

  goBackVehicle() {
    if (!this.ve) { return; }
    this.params.go(ViewPath.vnvoDetails, this.ve.vehicle_id);
  }

  override onParam(_k: string, v: any) {
    this.apiS.vnvo.getVehicleById(v).then(res => {
      if (res) {
        this.ve = res;
        this.vehicleForm.patchFormWithVehicle(this.ve);
      }
      this.isEdit = true;
      this.loaded = true;
    })
  }

  override noParams(): void {
    this.loaded = true;
  }

  getConceptsFromForm(): M_Concept[] {
    return this.vehicleForm.getConcepts();
  }

  create() {
    let v = new M_Vehicle(this.vehicleForm.form.value);
    v.concepts = this.getConceptsFromForm();
    this.apiS.vnvo.createVNVO(v).then(res => {
      if (this.isEdit) {
        this.snackS.show("Vehículo editado con éxito");
        this.params.go(ViewPath.vnvoDetails, v.vehicle_id);
      }
      else {
        this.snackS.show("Vehículo creado con éxito");
        if (this.isOnDialog) {
          res.budget_id = this.vehicleForm.form.get('budget_id')?.value;
          this.dialogRef.close(res);
        }
        else {
          this.routerS.goTo(ViewPath.garage);
        }
      }
    })
  }

}