<ng-container [ngSwitch]="category">
    <!-- Degault-->
    <lord-icon *ngSwitchCase="pc.GENERAL" src="https://cdn.lordicon.com/ezosizhp.json" trigger="in"
        colors="primary:#121331,secondary:#b26836 ,tertiary:#e3c0ac" class="lord-big">
    </lord-icon>

    <lord-icon *ngSwitchCase="pc.WRENCH" src="https://cdn.lordicon.com/putylcra.json" trigger="in"
        class="lord-big"> </lord-icon>

    <lord-icon *ngSwitchCase="pc.WHEEL" src="https://cdn.lordicon.com/gizjnixp.json" trigger="in"
        class="lord-big">
    </lord-icon>

    <lord-icon *ngSwitchCase="pc.LIGHT" src="https://cdn.lordicon.com/xhihqryb.json" trigger="in"
        colors="primary:#000000,secondary:#646e78,tertiary:#3a3347,quaternary:{{accent()}},quinary:{{warn()}},senary:#ffc738"
        class="lord-big">
    </lord-icon>

    <lord-icon *ngSwitchCase="pc.LIQUID" src="https://cdn.lordicon.com/vofhrzot.json" trigger="in"
        colors="primary:#121331,secondary:{{warn()}},tertiary:#3a3347,quaternary:#ebe6ef,quinary:#ffc738"
        class="lord-big">
    </lord-icon>

    <lord-icon *ngSwitchCase="pc.BOUTIQUE" src="https://cdn.lordicon.com/mjgbpbfx.json" trigger="in"
        colors="primary:#121331,secondary:#3a3347,tertiary:{{accent()}},quaternary:#646e78"
        class="lord-big">
    </lord-icon>

</ng-container>