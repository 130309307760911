import { Country } from "@angular-material-extensions/select-country";
import { getCountry } from "src/app/utils/functions";

export interface AddressToString {
    toText(): string;
}

export class M_Address implements AddressToString {

    address: string;
    address2: string;
    zip: string;
    city: string;
    province: string;

    constructor(d: any) {
        this.address = d.address;
        this.address2 = d.address2;
        this.zip = d.zip;
        this.city = d.city;
        this.province = d.province;
    }

    toText(): string {
        return getTextAddress(this);
    }

}

export class M_ShippingAddress extends M_Address {

    id: number | undefined;
    name: string;
    phone: number;
    country: Country

    constructor(d: any) {
        super(d);
        this.id = d.id;
        this.country = getCountry(d.country);
        this.name = d.name;
        this.phone = d.phone;
    }

    override toText(): string {
        return getTextAddress(this);
    }
}

export function getTextAddress(param: M_Address | M_ShippingAddress): string {
    const addressParts: string[] = [];
    if (param instanceof M_ShippingAddress && param.name) addressParts.push(param.name);
    if (param.address) addressParts.push(param.address);
    if (param.address2) addressParts.push(param.address2);
    if (param.city) addressParts.push(param.city);
    if (param.province) addressParts.push(param.province);
    if (param.zip) addressParts.push(param.zip);
    if (param instanceof M_ShippingAddress && param.country.name) addressParts.push(param.country.name);
    if (param instanceof M_ShippingAddress && param.phone) addressParts.push(param.phone.toString());
    return addressParts.join(', ');
}