import { QueryList } from "@angular/core";
import { CommentTypes } from "../enums/CommentTypes";

interface ComentInterface {
  text: string,
  icon: string,
  section: CommentTypes[],
  transaltion: string;
}

export class Comentario implements ComentInterface {
  text: string;
  icon: string;
  section: CommentTypes[] = [];
  transaltion: string;
  disabled?: boolean;
  selected: boolean = false;
  filledFromDatabase = false;
  constructor(text: string, section: CommentTypes[], icon?: string, translation?: string, disabled?: boolean) {
    this.text = text;
    this.icon = icon ? icon : "";
    this.section = section;
    this.transaltion = translation ? translation : "";
    this.disabled = disabled ? disabled : false;
  }

}

export const ALL_COMMENTS: Comentario[] =
  [

    /** Revisión periódica */
    new Comentario("Revisión anual", [CommentTypes.revisionperiodica], "revisionanual", ""),
    new Comentario("Revisión completa", [CommentTypes.revisionperiodica], "revisioncompleta", ""),
    new Comentario("Revisión intermedia", [CommentTypes.revisionperiodica], "revisionintemedia", ""),
    new Comentario("Revisión para ITV", [CommentTypes.revisionperiodica, CommentTypes.pedirCita], "itv", ""),
    new Comentario("Revisión por kilometraje", [CommentTypes.revisionperiodica], "revisionkm", ""),
    new Comentario("Revisión 1.000km", [CommentTypes.revisionperiodica], "revisionkm", ""),
    new Comentario("Revisión 5.000km", [CommentTypes.revisionperiodica], "revisionkm", ""),
    new Comentario("Revisión 10.000km", [CommentTypes.revisionperiodica], "revisionkm", ""),
    new Comentario("Revisión 30.000km", [CommentTypes.revisionperiodica], "revisionkm", ""),

    /** Revisiones */
    new Comentario("Ruido y/o vibración", [CommentTypes.revision], "ruido", ""),
    new Comentario("Pérdida de aceite", [CommentTypes.revision], "aceite", ""),
    new Comentario("Revisión batería", [CommentTypes.revision], "bateria", ""),
    new Comentario("Revisión filtros", [CommentTypes.revision, CommentTypes.pedirCita], "filtros", ""),
    new Comentario("Revisión kit transmisión", [CommentTypes.revision], "transmision", ""),
    new Comentario("Revisión de luces", [CommentTypes.revision, CommentTypes.pedirCita], "luces", ""),
    new Comentario("Revisión frenos", [CommentTypes.revision, CommentTypes.pedirCita], "frenos", ""),
    new Comentario("Revisión electrónica", [CommentTypes.revision], "electronica", ""),

    /** Cambio */
    new Comentario("Cambio de aceite", [CommentTypes.cambio, CommentTypes.pedirCita], "aceite", ""),
    new Comentario("Cambio de neumático", [CommentTypes.cambio, CommentTypes.pedirCita], "neumaticos", ""),
    new Comentario("Cambio rueda delantera", [CommentTypes.cambio], "neumaticos", ""),
    new Comentario("Cambio rueda trasera", [CommentTypes.cambio], "neumaticos", ""),
    new Comentario("Montaje accesorios", [CommentTypes.cambio], "montaje", ""),

    /** Reglaje */
    new Comentario("Reglaje de suspensiones", [CommentTypes.reglaje], "suspensiones", ""),
    new Comentario("Reglaje motor", [CommentTypes.reglaje], "motor", ""),

    /** Pedir cita */
    new Comentario("Otros", [CommentTypes.pedirCita], "requadres", ""),

    /**GRUPO CITA EINA */
    new Comentario("Mantenimiento",             [CommentTypes.eina], "revisioncompleta", ""),
    new Comentario("Cambio de aceite",          [CommentTypes.eina], "aceite", ""),
    new Comentario("Pastillas de freno",        [CommentTypes.eina], "frenos", ""),
    new Comentario("Neumáticos",                [CommentTypes.eina], "neumaticos", ""),
    new Comentario("Revisión para ITV",         [CommentTypes.eina], "itv", ""),
    new Comentario("Revisión por kilometraje",  [CommentTypes.eina], "revisionkm", ""),
    new Comentario("Otros",                     [CommentTypes.eina], "requadres", ""),

  ]

export abstract class CommentManager {
  public static comments = ALL_COMMENTS;

  public static getPeriodicReivisonSection(): Comentario[] { return this.getComentsOfSection(CommentTypes.revisionperiodica); }
  public static getRevisionSection(): Comentario[] { return this.getComentsOfSection(CommentTypes.revision); }
  public static getChangeSection(): Comentario[] { return this.getComentsOfSection(CommentTypes.cambio); }
  public static getAdjustmentSection(): Comentario[] { return this.getComentsOfSection(CommentTypes.reglaje); }
  public static getOthersSection(): Comentario[] { return this.getComentsOfSection(CommentTypes.others); }
  public static getPedirCitaSection(): Comentario[] { return this.getComentsOfSection(CommentTypes.pedirCita); }
  public static getTaskEina():  Comentario[] {return this.getComentsOfSection(CommentTypes.eina);}
  private static getComentsOfSection(section: CommentTypes): Comentario[] {
    let array: Comentario[] = []
    this.comments.forEach(comment => {
      comment.section.forEach(s => {
        if (s == section) {
          /** Dont clone new ref, keep the same ref */
          if (section == CommentTypes.pedirCita) {
            array.push(comment);
          }
          else {
            const copy = { ...comment };
            array.push(copy);
          }
        }
      })
    })

    return array;
  }

  /** Para mostrar los comentarios con espacios
   * La ',' ya viene por defecto de la base de datos
  */
  public static commentsWithSpace(tareas: string): string {
    let str = "";
    if (tareas) {
      let comm = tareas.split("#");
      for (let i = 0; i < comm.length; i++) {
        if (comm[i] && comm[i] != "#") {
          str += comm[i] + " ";
        }
      }
    }
    return str;
  }

  /**Añade al input de comentarios los comentarios que provienen de la base de datos */
  public static addCommentsFromDatabase(comments: string): Comentario[] {
    let array = []
    let comm = comments.split("#");
    for (let i = 0; i < comm.length; i++) {
      if (comm[i] && comm[i] != "#" && comm[i] != ",") {
        array.push(this.generatePredefinedCommentByString(comm[i]));
      }
    }
    return array;
  }


  /** Genera un comentario (con su icono), en caso de que sea posible
  * En caso contrario, genera un comentario normal, con solo texto
  */
  public static generatePredefinedCommentByString(str: string): Comentario {
    var str = str.toLocaleLowerCase().replace(' ', '');
    for (let i = 0; i < ALL_COMMENTS.length; i++) {
      var comm1 = ALL_COMMENTS[i].text.toLowerCase().replace(' ', '');
      if (comm1 === str) {
        return ALL_COMMENTS[i];
      }
    }
    return new Comentario(str, []);
  }

  public static getAllCommentsFormatted(comments: Comentario[]) {
    let formattedComments = '';
    for (let i = 0; i < comments.length; i++) {
      if (i != comments.length - 1) {
        formattedComments += '#' + comments[i].text + '#,';
      }
      else {
        formattedComments += '#' + comments[i].text + '#';
      }

    }
    return formattedComments;
  }

  public static getCommentsAsArray(comments: Comentario[]): string[] {
    let comm: string[] = [];
    for (let i = 0; i < comments.length; i++) {
      comm.push(comments[i].text);
    }
    return comm;
  }

  public static getCommentsCitaCliente(comentarios: QueryList<any>) {
    let formattedComments = '';
    for (let i = 0; i < comentarios!.length; i++) {
      if (comentarios.get(i)?.checked) {
        if (i != comentarios.length - 1) {
          formattedComments += '#' + comentarios.get(i)!.text + '#,';
        }
        else {
          formattedComments += '#' + comentarios.get(i)!.text + '#';
        }
      }


    }
    return formattedComments;
  }

  public static setCommentsPedirCita(comments: string, comantarios: QueryList<any>) {
    let comm = comments.split("#");
    for (let i = 0; i < comm.length; i++) {
      if (comm[i] && comm[i] != "#" && comm[i] != ",") {
        for (let j = 0; j < comantarios!.length; j++) {
          if (comantarios.get(j)!.text === comm[i]) {
            comantarios.get(j)!.check_(true);
          }
        }
      }
    }
  }
}

