<div class="df aic">
    <app-class-searcher class="w100" #searcher [masterClass]="productTable.MASTER_PRODUCT" [required]="false"
        [showLeftNumber]="productTable.showProductsStock"
        [specialRow]="'Crear producto'" [width100]="true"
        [searchPlaceHolder]="'Buscar producto'" (onSelect)="onSelectProduct.emit($event);"
        [disableIf]="disabledIf.bind(this)" (onSpecialRow)="goCreateProduct()">
    </app-class-searcher>

    <button (click)="onDestroy.emit()" mat-icon-button>
        <mat-icon>delete</mat-icon>
    </button>

</div>