import { ChangeDetectorRef, Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { allColors, AutocompleteColor, getAutoCompleteColorByName } from '../../custom-classes/Colors';
import { M_Vehicle } from '../../models/M_Vehicle';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { VehicleType } from '../../enums/VehicleType';
import { ImageToggleComponent } from '../image-toggle/image-toggle.component';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { DialogConfirmPlateComponent } from './dialog-confirm-plate/dialog-confirm-plate.component';
import { MatDialog } from '@angular/material/dialog';
import { BrandModelInputComponent } from '../brand-model-input/brand-model-input.component';
import { asyncPlate } from '../../validators/plateAsyncValidator';
import { RegistrationTaxEnum, registrationText } from '../../enums/RegistrationTaxEnum';
import { ComercialVehicleType } from '../../enums/ComercialVehicleType';
import { M_Concept } from '../../models/M_Concept';
import { M_Model } from '../../models/M_Model';
import { CreateVNVOcomponent } from 'src/app/views/create-vn-vo/create-vn-vo.component';
import { IConceptLineTableComponent } from '../../interfaces/IConceptLineTableComponent';
import { ConceptsLineTableComponent } from '../concepts-line-table/concepts-line-table.component';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-vehicleform',
  templateUrl: './vehicleform.component.html',
  styleUrls: ['./vehicleform.component.css']
})
export class VehicleformComponent implements OnInit, IConceptLineTableComponent {

  client = MASTER_CLIENT_MINIFIY;
  RTE = RegistrationTaxEnum;
  CVT = ComercialVehicleType;

  registrationText = registrationText;

  @Input() onlyRequired = false;
  @Input() isOnDialog = false;
  @Input() isVoVn = false;
  @Input() requiredFieldsTitle?: string
  provisionalConcepts: M_Concept[] = [];

  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  @ViewChild(BrandModelInputComponent, { static: true }) brandModelInputComponent!: BrandModelInputComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;


  v = ViewPath;
  validators = Validators;
  public form: UntypedFormGroup;
  lastb1 = "";
  lastb2 = "";
  vehicleTypeApi: number = 1;
  options: AutocompleteColor[] = allColors;
  initialChasisValue: string | undefined;
  filteredOptions!: Observable<AutocompleteColor[]>;
  VT = VehicleType;
  minDate: Date;
  vehicle: M_Vehicle | undefined
  freePlateEedit = false;

  constructor(private formBuilder: UntypedFormBuilder, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS: RouterService, private d: MatDialog,
    @Optional() public createVnVoComponent: CreateVNVOcomponent) {

    this.form = this.formBuilder.group({

      /** Normal vehicle */
      vehicle_id : [],
      type: [this.VT.bike, Validators.required],
      license: ['', {
        validators: [Validators.required],
        asyncValidators: [asyncPlate(this.apiS, this.existsS, () => this.getCurrentVehicle())],
      }],
      brand: ['', []],
      model: ['', []],
      chassis: [''],
      serie: [],
      color: ['', []],
      insurance: ['', []],
      cc: ['', []],
      engine_type: ['', []],
      license_date: ['', []],
      fabrication_date: ['', []],
      hire_date: ['', []],
      km: ['', []],
      last_itv: ['', []],
      next_itv: ['', []],
      electric: ['', []],
      battery_1_no: ['', []],
      battery_2_no: ['', []],
      clientInvoice_id: ['', []],

      /** Comercial */
      comercialType: [],
      registration_tax: [''],
      price: [''],
      cost: [''],
      budget_id: [],
      serie_vehicle: [],
      num_motor: [''],
      workshop_model: [''],
      seller: [''],
      deposit: [''],
      location: [''],
      exp_documentation: [''],
      num_prov: [''],

    });

    this.minDate = new Date();
    this.form.get("battery_1_no")?.disable();
    this.form.get("battery_2_no")?.disable();


    /** When the engine_type 'select' changes, diable or enable some fields */
    this.form.get('engine_type')?.valueChanges.subscribe(val => {
      let b1 = this.form.get("battery_1_no")!;
      let b2 = this.form.get("battery_2_no")!;
      /** If the motor type is gas, disable the electric vehicle fields */
      if (val == 0) {

        this.lastb1 = b1.value;
        b1.setValue('');
        b1.disable();
        this.lastb2 = b2.value;
        b2.disable();
        b2.setValue('');
      }
      else {
        b1.enable();
        b1.patchValue(this.lastb1)
        b2.enable();
        b2.patchValue(this.lastb2)
      }
    })

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.form.patchValue({ serie: null });
    })

    this.form.get('model')?.valueChanges.subscribe(val => {
      if (val instanceof M_Model && val.details) {
        this.form.patchValue({ price: val.details.price })
        this.form.patchValue({ cost: val.details.cost })
        this.form.patchValue({ registration_tax: val.details.tax_registration })
      }
    })

    /** Set up comercial vehicle form */
    this.form.get('comercialType')?.valueChanges.subscribe(v => {
      this.requiredIf(this.form.get('registration_tax'), "vn");
      this.requiredIf(this.form.get('license'), "v", 'vo');
      this.requiredIf(this.form.get('brand'), "vn", 'vo');
      this.requiredIf(this.form.get('model'), "vn", 'vo');
      this.requiredIf(this.form.get('chassis'), "vn", 'vo');
    })


    if (this.createVnVoComponent) {
      this.form.get('comercialType')?.setValue(this.CVT.VN);
    }

    this.requiredIf(this.form.get('price'), "vn", "vo");
    this.requiredIf(this.form.get('cost'), "vn", "vo");
    this.requiredIf(this.form.get('registration_tax'), "vn", "vo");

  }

  addConcept(c: M_Concept): void {
    console.log("Pushing concept")
    this.getConcepts().push(c);
  }

  getConcepts() {
    if (this.vehicle) { return this.vehicle.concepts };
    return this.provisionalConcepts;
  }

  getCurrentVehicle(): M_Vehicle | undefined {
    return this.vehicle;
  }

  enablePlateEdition() {
    this.d.open(DialogConfirmPlateComponent, {
      data: {
        title: "Edición de matrícula",
        message: "¿Estas seguro que quieres editar la matrícula?",
      }
    }).afterClosed().subscribe(res => {
      if (res != true) {
        this.form.get('license')?.disable();

      } else {
        this.freePlateEedit = true;
        this.form.get('license')?.enable();
      }
    }
    );
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  ngOnInit(): void {
    this.filteredOptions = this.form.get('color')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }

  /** Patch the current form with a vehicle. */
  patchFormWithVehicle(vehicle: M_Vehicle) {
    this.vehicle = vehicle;
    this.chdRef.detectChanges();
    if (this.vehicle != undefined) {
      this.form.patchValue(this.vehicle)
    }

    let licenseControl = this.form.get('license');
    if (licenseControl?.value) {
      this.form.get('license')?.disable();
    }
  }

  get licenseBlocked() {
    return this.vehicle != undefined && this.form.get('license')?.value && !this.freePlateEedit;
  }

  private _filter(value: string): AutocompleteColor[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  get loaded() {
    return this.brandModelInputComponent.loaded;
  }

  get isNormalVehicle() {
    return !this.createVnVoComponent;
  }

  get isVn() {
    if (!this.form || !this.createVnVoComponent) { return false; }
    return this.form.get('comercialType')?.value == this.CVT.VN;
  }

  get isVo() {
    if (!this.form || !this.createVnVoComponent) { return false; }
    return this.form.get('comercialType')?.value == this.CVT.VO;
  }

  get selectedColor() {
    let c = this.form.get('color')?.value;
    if (typeof c == "string") {
      return getAutoCompleteColorByName(c);
    }
    return undefined;
  }

  requiredIf(control: AbstractControl<any, any> | null, ...is: ("v" | "vn" | "vo")[]) {
    if (!control) { return; }

    if (this.vehicleIs(...is)) {
      control.addValidators(Validators.required);
      console.log("Setting ", control, " as required field")
    }
    else {
      control.removeValidators(Validators.required);
      console.log("Setting ", control, " as NO required field")
    }
    control.updateValueAndValidity();
  }

  vehicleIs(...is: ("v" | "vn" | "vo")[]) {
    let ok = true;
    if (is.includes("v")) { ok = this.isNormalVehicle; if (ok) { return true; } }
    if (is.includes("vn")) { ok = this.isVn; if (ok) { return true; } }
    if (is.includes("vo")) { ok = this.isVo; if (ok) { return true; } }
    return ok;
  }
}
