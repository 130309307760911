import { trigger, transition, style, animate, state } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { Views } from '../../custom-classes/View';
import { Filter, TagFilter } from '../../custom-classes/Filter';
import { RouterService } from '../../services/router.service';
import { ResponsiveService } from '../../services/responsive.service';
import { or_status } from '../../custom-classes/or_states';
import { or_types } from '../../custom-classes/or_types';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { albaran_status } from '../../custom-classes/albaran_status';
import { ISearchFiltrable } from 'src/app/interfaces/ISearchFiltrable';
import { getAccentColor, sortTable } from 'src/app/utils/FunctionUtils';
import { invoice_states } from 'src/app/custom-classes/invoice_states';
import { IOr } from 'src/app/interfaces/IOr';



/**
 * Componente que se utiliza en las pantallas de la applicación que tienen una estrucutra muy similar
 * Estas pantallas se componen de :
 * 1 - Buscardor
 * 2 - Creación del 'item' de la pantalla
 * 3 - Tabla con todos los 'items'
 * 4 - Card con los detalles avanzados del item
 * 
 * @param searchLabel El texto del buscador
 * @param circleIcon El icono del botón circular de añadir 'item'
 * @param data Array de los items de la pantalla
 * @param displayedHeader El título de las columnas
 * @param displayedColumns Las columnas que se deben mostrar
 * @param specialRow Si una de las filas de la pantalla, necesita un tratamiento especial, se le pasa una función que devuelve un valor
 * Esta función se debe colocar en la posición de la array que coincida con la posición de 'disaplyedColumn' correspondiente
 * @param addNewView View a la que redirije el botón de añadir 'item'
 * 
 * Para mostrar los detalles sobre el item seleccionado, se debe 
 * 
 */
@Component({
  selector: 'app-page-structure',
  templateUrl: './page-structure.component.html',
  styleUrls: ['./page-structure.component.css', './tags.css'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
        }),
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('1s')]),
    ]),
    trigger('openCloseSide', [state(
      'open', style({ opacity: 1, }),),
    state(
      'closed', style({ opacity: 0, marginLeft: -30 }),
    ),
    transition('open => closed', [animate('0s')]),
    transition('closed => open', [animate('0.2s')]),
    ]),
    trigger('bottomOpen', [
      state(
        'open',
        style({
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
        }),
      ),
      transition('open => closed', [animate('0s')]),
      transition('closed => open', [animate('0.4s')]),
    ])
  ]
})
export class PageStructureComponent<T extends ISearchFiltrable> extends ParameterStateComponent implements OnInit {

  @Input({ required: true }) pageTitle!: string;
  @Input({ required: true }) createButton!: { icon: string, text: string } | undefined;
  @Input({ required: true }) listTitleText!: string;

  @Input() sideDetails?: boolean = true;
  @Input() cardStyle?: boolean = true;
  @Input() searchLabel?: string;
  @Input() searchOnboardingClass?: string;
  @Input() autocompleteID?: string;
  @Input() createButtonClass?: string;
  @Input() createButtonLoading?: boolean = false;
  @Input() disableCircle: boolean = false;
  @Input() circleTooltip?: string;
  @Input() addNewView?: Views;
  @Input() masterClass!: "client" | "vehicle" | "product" | "invoice" | "or" | "budget" | "user" | "issues" | "appointment" | "albaran" | undefined;
  @Input() sideAnimation?: boolean = true;
  @Input() data: any[] = [];
  @Input() allDataOnInit: any[] = [];
  @Input() displayedHeader: string[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() cellZize: ("small" | "big" | undefined)[] = [];
  private allHeaders: string[] = [];
  private allColumns: string[] = [];

  @Input() tableClass: string | undefined;
  @Input() specialText: (((data: any) => any) | "decimal" | "money" | undefined)[] = []
  @Input() specialClass: (((data: any) => any) | undefined)[] = []
  @Input() dateFormat: (undefined | "show-hour")[] = []
  @Input() preIcon: (((data: any) => string | undefined) | undefined)[] = []
  @Input() preIconClass: string | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('itemDetails') itemDetails!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('auto') autocomplete!: AutocompleteComponent;
  @Input() card!: TemplateRef<any>;
  /** The elements on the right menu must be 'mat-menu-item' */
  @Input() rightMenu?: TemplateRef<any>;
  @Input() customPageContent?: TemplateRef<any>;
  @Input() circularLetter: boolean = false;; // Show circular letter on users
  
  @Input() filters?: Filter[];
  /** The quickFilter filter must also be inside the filters array */
  @Input() quickFilter?: TagFilter;

  /** Only for eina clients empty page structure */
  @Input() dataUpload?: Views | undefined;

  /** Show or not show the circle add button */
  @Input() canAddNew: boolean = true;

  /** Eina user subscribed */
  @Input() basicUserSubscription: boolean | undefined = undefined;

  @Input() showPagination: boolean = true;
  @Input() filter?: (object: any, ...filters: Filter[]) => boolean;
  @Output() onChangeSelected: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<T> = new EventEmitter();
  @Output() afterSelect: EventEmitter<T> = new EventEmitter();
  @Output() onclickAddNew: EventEmitter<any> = new EventEmitter();
  @Output() onclickRow: EventEmitter<T> = new EventEmitter();
  @Output() onNoDataAddNew: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  @Output() onSubscriptionAddFail: EventEmitter<string> = new EventEmitter();
  @Output() onFiltersDone: EventEmitter<Filter[]> = new EventEmitter();

  /**Text that appears when there is no data**/
  @Input() noDataCreateNew = "";

  scroll = 0;
  selectedPos = 0;
  loaded_: boolean = false;
  /**Item seleccionado de la pantalla */
  item: T | undefined;
  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  /** If there is applied filers  */
  aplyedFilters: boolean = false;
  /** Filters to aplly when the page is loaded */
  applyFilterOnLoad: Filter | undefined;
  /** The object id to highlight. Normally after creating an object and come to this view */
  stateId: number | undefined;
  circleDisabledBySubscription: boolean = false;

  accent = getAccentColor;

  @HostListener('window:scroll', ['$event'])
  setScroll(_event: any) {
    this.scroll = window.pageYOffset;
  }

  constructor(private chdRef: ChangeDetectorRef, routerS: RouterService, route: ActivatedRoute, public responsive: ResponsiveService) {
    super(routerS, route, ["filter", "filtervalue", "filter1", "filtervalue1", "filter2", "filtervalue2"])

    const minColumns = 3;

    this.responsive.onResize.subscribe(val => {
      if (responsive.isPhone()) { //622px
        this.displayedColumns = this.allColumns.slice(0, minColumns)
        this.displayedHeader = this.allHeaders.slice(0, minColumns)
      }
      else if (responsive.w < 750) {
        let columns = Math.max(this.allColumns.length - 2, 3);
        this.displayedColumns = this.allColumns.slice(0, columns)
        this.displayedHeader = this.allHeaders.slice(0, columns)
      }
      else if (responsive.w < 950) {
        let columns = Math.max(this.allColumns.length - 1, 3);
        this.displayedColumns = this.allColumns.slice(0, columns)
        this.displayedHeader = this.allHeaders.slice(0, columns)
      }
      else {
        this.displayedColumns = this.allColumns;
        this.displayedHeader = this.allHeaders;
      }
    })
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
    /** If the circle is not disabled, gonna be disabled if the user hasn't the pro plan */
    if (this.disableCircle == false) {
      if (this.basicUserSubscription != undefined && this.basicUserSubscription) {
        this.circleDisabledBySubscription = true;
        this.circleTooltip = this.circleTooltip != undefined ? this.circleTooltip : "Se requiere el plan PRO";
      }
    }
  }

  override onState(v: any) {
    if (typeof v == "number") {
      this.stateId = v;
    }
  }

  override onParams(params: { param: string, value: string }[]) {

    for (let i = 0; i < 3; i++) {

      let filterName = "filter" + (i == 0 ? '' : i.toString());
      let filterValue = "filtervalue" + (i == 0 ? '' : i.toString());

      var f = params.filter(v => v.param == filterName)[0];
      var fv = params.filter(v => v.param == filterValue)[0];

      if (f && fv && this.filters) {
        if (this.filters[Number(f.value)] != undefined) {
          this.filters[Number(f.value)].initByParam(Number(fv.value));
          if (this.loaded_) {
            this.applyScreenFilter(this.filters[Number(f.value)]);
          }
          else {
            this.applyFilterOnLoad = this.filters[Number(f.value)]
          }
        }
      }
    }

  }

  ngAfterViewInit() {

    /** Init the all colums and headers array */
    this.allColumns = [...this.displayedColumns];
    this.allHeaders = [...this.displayedHeader];

    if (this.responsive.isPhone()) {
      this.displayedColumns = this.displayedColumns.slice(0, 3)
      this.displayedHeader = this.displayedHeader.slice(0, 3)
    }
  }


  initTable(d: any) {
    this.checkAndHighlightStateObject(d);
    this.allDataOnInit = d;
    this.data = d;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaded_ = true;
    this.chdRef.detectChanges();
    this.dataSource.data = this.data;
    this.dataSource._updateChangeSubscription();
    this.dataSource._updatePaginator(this.data.length);
    this.dataSource.filterPredicate = this.defaultSearchFilter;
    this.chdRef.detectChanges();
    if (this.applyFilterOnLoad) {
      this.applyScreenFilter(this.applyFilterOnLoad);
      this.applyFilterOnLoad = undefined;
    }
  }

  defaultSearchFilter(object: any, searchFilter: string) {
    return searchFilter ? object.defaultSearchFilter(searchFilter) : true;
  }

  /** If a state is present, puts the object that match the state id on the top of the table an highlight it*/
  checkAndHighlightStateObject(d: any) {
    if (Array.isArray(d) && d.length != 0 && d[0].id) {
      var objIndex = d.findIndex((obj) => obj.id == this.stateId);
      var obj = d.filter((obj) => obj.id == this.stateId);
      d.removeIndex(objIndex) // Delete obj from array
      d.unshift(...obj); // Place obj on the first array position
    }
  }

  /** If filter is unefined, removes page filters */
  applyScreenFilter(...f: Filter[]) {
    let filteredTable: any[] = [];
    if (f) {
      /** TODO */
      this.allDataOnInit.forEach(obj => {
        if (this.filter) {
          if (this.filter(obj, ...f)) {
            filteredTable.push(obj);
          }
        }
      })
    }
    this.dataSource = new MatTableDataSource(f ? filteredTable : this.allDataOnInit);
    this.dataSource.filterPredicate = this.defaultSearchFilter;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaded_ = true;
    this.aplyedFilters = f != undefined;
    this.dataSource.filter = this.searchInput.nativeElement.value;
    this.chdRef.detectChanges();
  }

  /** Unselect the current item  */
  unselct() {
    this.item = undefined;
  }

  /** Unselect the selected item only if is not on the table or is not on the current page */
  smartUnselect() {
    if (!this.selected) { return; }
    if (!this.dataSource.data.includes(this.selected as any) || !this.isSelectedOnCurrentPage) {
      this.unselct();
    }
  }

  get isSelectedOnCurrentPage(): boolean {
    if (!this.selected) { return false; }
    const selectedIndex = this.dataSource.data.indexOf(this.selected as any);
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    return selectedIndex >= startIndex && selectedIndex < endIndex;
  }

  /** Seleccionar un item */
  select(data: T) {

    this.onclickRow.emit(data);


    /** TO DO : De aqui en adelate se tendra que borrar mas al acabar el refactor */

    /** If the table has no 'sidDetails' */
    if (!this.sideDetails) { return };

    this.onChangeSelected.emit();
    this.autocomplete.setSuggestion(data);

    let itemAux = this.item;

    /** Set the item to undefined and  chdRef.detectChanges() to update the table details
    this.item == undefined;
    this.chdRef.detectChanges();*/

    /** Set the real selected item */
    this.item = itemAux == data ? undefined : data;

    /** itemDetails es una referencia HTML en 'Desktop' */
    if (this.responsive.tableDetails()) {
      this.positionOnitem();
    }

    if (this.item) {
      this.onSelect.emit(this.item);
    }

    this.chdRef.detectChanges();

    if (this.item) {
      this.afterSelect.emit(this.item);
    }
  }

  positionOnitem() {
    this.chdRef.detectChanges();
    let element = document.getElementsByClassName('cell-selected')[0]
    let element2 = document.getElementsByTagName('table')[0]
    let element3 = document.getElementsByClassName('desktopdetails')[0]?.getElementsByTagName("app-card")[0]
    //let element4 = document.getElementsByClassName('tableSection')[0];
    let headerSection = document.getElementsByClassName('headerSection')[0];

    if (element && element2 && element3) {
      var box = element.getBoundingClientRect();
      var body = document.body;
      var docEl = document.documentElement;
      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var clientTop = docEl.clientTop || body.clientTop || 0;
      var top = box.top + scrollTop - clientTop;

      let table = element2.getBoundingClientRect();
      let details = element3.getBoundingClientRect();
      let diff = table.top;
      let maxPosition = diff + (table.height + scrollTop - clientTop) - details.height;
      let detailCard = element3.getBoundingClientRect();

      /** Si la card de los detalles es mas alta que el total de la tabla... */
      if (detailCard.height > table.height) {
        this.selectedPos = top - diff - scrollTop;
      }
      /** En el caso de que la card sobrepase la parte inferior de la tabla */
      else if (box.top + detailCard.height > table.bottom) {
        this.selectedPos = maxPosition - diff - scrollTop;
      }
      /** La posicion de la card es la posición donde se ha dado click */
      else {
        this.selectedPos = top - diff - scrollTop;
      }


      this.selectedPos += headerSection.clientHeight;
    }
  }

  /** Apply search filters to the table */
  applySearchFilter(val: string) {
    //if (this.lazyLoading == undefined || (this.lazyLoading != undefined && this.lazyFinished)) {
    this.dataSource.filter = val;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.onSearch.emit(val);
    //}
  }

  /** Al hacer click al botón circular */
  onClickAdd() {
    this.onclickAddNew.emit();
    if (this.circleDisabledBySubscription) {
      /** TODO */
      this.onSubscriptionAddFail.emit();
    }
    else if (this.addNewView != undefined) {
      this.routerS.goTo(this.addNewView)
    }
  }

  removeElement(e: T) {
    this.unselct();
    this.allDataOnInit.removeElement(e);
    this.initTable(this.allDataOnInit);
    this.chdRef.detectChanges();
  }

  onClickNoDataAdd() {
    if (this.addNewView != undefined) {
      this.routerS.goTo(this.addNewView)
    }
    this.onNoDataAddNew.emit();
  }

  needDirective(i: number) {
    var specialText = this.specialText[i];
    return specialText != undefined && specialText == "decimal" || specialText == "money";
  }

  getSyleBySize(i: number) {
    let currentCellSize = this.cellZize[i];
    if (currentCellSize != undefined) {
      if (currentCellSize == "big") {
        let width = 100 / this.displayedHeader.length;
        return "width : " + (width + 5) + "%;"
      }
      else {
        let width = 100 / this.displayedHeader.length;
        return "width : " + (width - 5) + "%;"
      }
    }

    return "";
  }

  isMoney(i: number) {
    var specialText = this.specialText[i];
    return specialText == "money";
  }

  isDecimal(i: number) {
    var specialText = this.specialText[i];
    return specialText == "decimal";
  }

  getInvoiceTagLabel(row: any) {
    if ("tagLabel" in row) {
      return row.tagLabel;
    }
    else return "";
  }

  isInvoice(row: any, dc: string) {
    return row[dc] instanceof invoice_states;
  }

  isAppointment(row: any) {
    return "state" in row && "cita" in row;
  }

  /** Devuelve el texto de la row solicitada
   *  Si es una row 'especial', se llama a la función pertinente
   */
  getRow(row: any, dc: string, i: number) {
    if (row[dc] instanceof or_status) {
      return row;
    }
    if (row[dc] instanceof or_types) {
      return row[dc];
    }
    if (row[dc] instanceof invoice_states) {
      return row[dc];
    }
    if (row[dc] instanceof albaran_status) {
      return row[dc];
    }

    var specialText = this.specialText[i];

    if (this.specialText != undefined && specialText != undefined && specialText != "decimal" && specialText != "money") {
      return specialText(row);
    }

    if (row[dc] instanceof Date) {
      if (this.dateFormat[i] && this.dateFormat[i] == "show-hour") {
        return row[dc].shortFormat();
      }
      else {
        return row[dc].dayMonthYearFormat();
      }
    }
    return row[dc];
  }

  haveRowData(row: any, dc: string, i: number) {
    /** Check if the row has data (included special text funcion) */
    let val = this.getRow(row, dc, i);
    let valexists = val != undefined && val != null;

    if (typeof val == "string" && val == "") { return false }

    /** Check if the class contains the 'dc' entry */
    let entry = row[dc];
    let entryExists = (entry != undefined && entry != null);

    return valexists || entryExists;
  }

  getClass(row: any, _dc: string, i: number) {
    if (this.specialClass != undefined && this.specialClass[i] != undefined) {
      return this.specialClass[i]!(row);
    }
    return '';
  }

  getPreIcon(row: any, dc: string, i: number) {
    if (this.preIcon != undefined && this.preIcon[i] != undefined) {
      return this.preIcon[i]!(row);
    }
    return undefined;
  }

  showItem() {
    return this.item != undefined;
  }

  getScrollOnClick() {
    return 'margin-top: ' + (this.selectedPos) + 'px; max-width: 400px;'
  }

  loadedNoData() {
    return this.loaded_ && this.data.length == 0;
  }


  onPaginateChange() {
    this.item = undefined;
  }

  onSortChanged(sort: Sort) {
    this.item = undefined;
    sortTable(this.data, sort);
  }

  showGroupsCircles(row: any) {
    if (this.isOr(row)) {
      return row.groups.length && !row.isBudget();
    }
    return false;
  }

  isOr(row: any): row is IOr {
    return "groups" in row && "status" in row;
  }

  get dataOnScreen() { return this.dataSource.filteredData }
  get selected() { return this.item }
}
