import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { StorageService } from '../../services/storage.service';
import { Observable, map } from 'rxjs';
import { startWith } from 'rxjs';
import { ISuggestion } from 'src/app/interfaces/ISuggestion';

/**
 * Component used on every 'app-page-structure' implementation.
 */
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html'
})

export class AutocompleteComponent implements OnInit {

  @ViewChild('auto') autocomplete!: MatAutocomplete;

  /** 
   * ID for saving or retrieving the suggestions. 
   * Don't set the same autocompleteID on different `app-page-structure` objects.
  */
  @Input() autocompleteID?: string;

  /** Emitted when a suggestion is clicked */
  @Output() click: EventEmitter<any> = new EventEmitter();

  stateCtrl = new UntypedFormControl('');
  allSuggestions: Observable<ISuggestion[]>;
  suggestions: ISuggestion[] = [];

  constructor(private chdRef: ChangeDetectorRef, private storageS: StorageService) {
    this.allSuggestions = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map(sugg => (sugg ? this._filterSuggestion(sugg) : this.suggestions.slice())),
    );
  }

  ngOnInit(): void {
    this.getSuggestions();
  }

  private _filterSuggestion(value: string): ISuggestion[] {
    const filterValue = value.toLowerCase();
    return this.suggestions.filter(state => state.name.toLowerCase().includes(filterValue));
  }

  getSuggestionById(id: string): ISuggestion | undefined {
    let suggestion = this.storageS.get(id);
    if (suggestion != null) {
      return {
        id: id,
        name: suggestion.split("_date_")[0],
        date: new Date(suggestion.split("_date_")[1])
      }
    }
    return undefined;
  }

  /** Returns the suggestions chronologically sorted */
  private getSuggestions() {
    let suggestions: ISuggestion[] = []
    for (let i = 0; i < 3; i++) {
      if (this.autocompleteID) {
        let sug = this.getSuggestionById(this.autocompleteID + i);
        if (sug != undefined) {
          suggestions.push(sug);
        }
      }
    }
    this.suggestions = this.orderSuggestions(suggestions);
    return this.suggestions;
  }

  /** Sort the suggestion */
  orderSuggestions(sugg: ISuggestion[]) {
    if (sugg) {
      return sugg.sort((a, b) => { return a.date.getTime() > b.date.getTime() ? -1 : 1 })
    }
    else {
      return [];
    }
  }

  /** Set a suggestion */
  setSuggestion(data: any) {
    let searched = ""
    if (data.title != undefined) {
      searched = data.title;
    }
    else {
      searched = data.name ? data.name : typeof data.getName == 'function' ? data.getName() : undefined
    }
    if (this.autocompleteID) {
      if (searched && searched != "") {
        if (!this.sameSuggestion(searched)) { // --> Check if the text is not already saved
          this.storageS.save(this.getLastSuggestion(), searched + "_date_" + new Date().toString())
          this.suggestions = [];
          this.getSuggestions();
          this.chdRef.detectChanges();
        }

      }
    }
  }

  /** Check if a saved suggestion is the same */
  sameSuggestion(searched: string) {
    let s = this.getSuggestions();
    for (let i = 0; i < s.length; i++) {
      if (s[i].name == searched) {
        return true;
      }
    }
    return false;
  }

  /** Returns the id of the oldest saved suggestion */
  getLastSuggestion(): string {
    let lastSuggestionId = this.autocompleteID + "0"; //--> Por defecto, el ID 0
    let allSuggestions: ISuggestion[] = this.getSuggestions();
    if (allSuggestions.length != 3) {
      if (this.autocompleteID) {
        lastSuggestionId = this.autocompleteID + allSuggestions.length;
      }
    }
    else {
      lastSuggestionId = allSuggestions[allSuggestions.length - 1].id;
    }

    return lastSuggestionId;
  }
}
