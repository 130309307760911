<app-page-structure #ps [pageTitle]="'Equipo'" (onclickAddNew)="openDialog()" class="users-table"
    [masterClass]="'user'" [autocompleteID]="'users'" searchLabel="Buscar usuario"
    listTitleText="Listado de usuarios"
    createButtonClass="onboarding-company-users-create" [addNewView]="undefined" [data]="[]"
    [displayedHeader]=" ['Nombre', 'Email', 'Rol']" [displayedColumns]="['name', 'email', 'role']"
    [circularLetter]="true" [specialText]="[undefined, undefined, roleName]" [specialClass]="[undefined, undefined]"
    [createButtonLoading]="!subS.status" [disableCircle]="disableCircle" [circleTooltip]="circleTooltip"
    [createButtonLoading]="loading" [showPagination]="false" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()" (onChangeSelected)="onChange()"
    [createButton]="{text : 'Crear usuario' , icon : 'groups'}"
    (onclickRow)="params.go(v.userdetails, $event.id)">
</app-page-structure>