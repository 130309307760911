<ng-container *ngIf="isOnDialog">
    <app-close-dialog-button></app-close-dialog-button>
    <p mat-dialog-title>{{title}}</p>
</ng-container>


<div class='create-item' mat-dialog-content [ngClass]="!isOnDialog ? 'conditional-mat-dialog-content' : ''">
    <app-go-back *ngIf=" c != undefined && !isOnDialog" [show]="isEdit" [text]="c!.getName()" (click)="goBackClient()"></app-go-back>
    <app-card [contentLoaded]="loaded" class="section" [noStyle]="isOnDialog">
        <app-card-title *ngIf="!isOnDialog">{{title}}</app-card-title>
        <app-clientform [isOnDialog]="isOnDialog" [clientType]="clientType" [forceType]="getForceType" [edit]="isEdit"></app-clientform>
        <div *ngIf="!isOnDialog" [ngTemplateOutlet]="submitButton"></div>
    </app-card>
</div>

<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="submitButton"></div>
</div>

<ng-template #submitButton>
        <div class="df jcc">
            <button [disabled]="!clientForm.form.valid" appTrialbutton mat-flat-button color="warn" class="onboarding-client-finish" (click)="create()" style="margin-top: 20px;">
                <span>{{isEdit? 'Guardar' : 'Crear contacto'}}</span>
            </button>
        </div>
</ng-template>