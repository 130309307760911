import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Route, } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { PreviousRouteService } from 'src/app/services/previous-route.service';
import { TryProDialogComponent } from '../components/try-pro-dialog/try-pro-dialog.component';
import { SubscriptionService } from '../services/EinaMainData/subscription.service';
import { UserService } from '../services/EinaMainData/user.service';
import { EinaDataService } from '../services/EinaMainData/eina-data.service';
import { RouterService } from '../services/router.service';

@Injectable({
  providedIn: 'root'
})
export class SubscribedUserGuard {
  constructor(private subS: SubscriptionService, private d: MatDialog,
    private routerS: RouterService, private einaDataS: EinaDataService, private userS: UserService, private prevRouteS: PreviousRouteService) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {

      /** Why call the endpoint if the guards are are executed consecutively?
       * Well, because it is not like that, the EinaData guard does not wait... 
       * and the SubscriptionGuard guard is executed before it finishes
       */
      this.einaDataS.getEinaData().then(res => {
        var hasProPermisisons = this.subS.hasProPermissions;
        if (!hasProPermisisons) {
          var v: Route | undefined = this.routerS.getViewFromRoute("/" + route.url.toString());

          /** Mechaninc's dashboard is the workload, they need to load the page */
          if (v && v.path == ViewPath.cargataller.path && this.userS.userIsMechanic) {
            resolve(true);
          }
          else {
            this.d.open(TryProDialogComponent, { autoFocus: false, data: v ? route.title : undefined })
            if (this.prevRouteS.getPreviousUrl() == undefined) {
              this.routerS.goTo(ViewPath.dashboard);
            }
          }
        }
        resolve(hasProPermisisons);
      })
    })
  }

}
