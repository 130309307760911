<div class="image-preview-container">
    <div #imgContainer class="img-container" title="Next">
        <img (load)="saveInitImageSize()" class="no-blue" #img [ngClass]="{'cg' : isOverflown()}" [src]="getImage()"
            [style]="initSacle ? 'height : ' + getYScale() + 'width : ' + initSacle.x * this.scale +'px;' : ''"
            (mousedown)="onGabStart($event)" (mousemove)="onGabScrolling($event)" (mouseleave)="onGrabEnd($event)"
            (mouseup)="onGrabEnd($event)">
    </div>
    <div class="phone-buttons">
        <button mat-icon-button (click)="previous($event)" [disabled]="data.all.length <= 1">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="number-indicator">
            {{index +1}} / {{data.all.length}}
        </div>
        <button mat-icon-button (click)="next($event)" [disabled]="data.all.length <= 1">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>

<div class="top-bar-buttons">
    <div class="zoom-in-button" (click)="zoomIn($event)">
        <button [disabled]="isMaxZoom" mat-icon-button>
            <mat-icon>zoom_in</mat-icon>
        </button>
    </div>
    <div class="zoom-out-button" (click)="zoomOut($event)">
        <button [disabled]="isMinZoom" mat-icon-button>
            <mat-icon>zoom_out</mat-icon>
        </button>
    </div>
    <div class="close-button" (click)="close($event)">
        <button mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>