import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { SubscriptionService } from '../services/EinaMainData/subscription.service';

@Directive({
  selector: '[appTrialbutton]'
})
export class TrialbuttonDirective {

  constructor(private el: ElementRef, private renderer: Renderer2, private subS: SubscriptionService) {
    if (this.subS.isBasic) {
      this.renderer.addClass(this.el.nativeElement, "trial-disabled");
      const child = document.createElement('p');
      child.innerText = "Se requiere el plan PRO"
      this.renderer.addClass(child, "c_r");
      this.renderer.appendChild(this.el.nativeElement, child);
      // Check if the element is a button before setting flex-direction
      if (this.el.nativeElement.tagName.toLowerCase() === 'button') {
        this.renderer.setStyle(this.el.nativeElement, "flex-direction", "column");
      }
    }

    if (this.el.nativeElement.parentNode) {
      this.el.nativeElement.parentNode.onclick = () => {
        if (this.subS.isBasic) {
          subS.openTryProDialog();
        }
      }
    }
  }
}
