<app-page-structure [masterClass]="'client'" pageTitle="Contactos" [filters]="filters" [filter]="f"
    [autocompleteID]="'clients'" searchLabel="Buscar contacto" searchOnboardingClass="onboarding-search-client"
    createButtonClass="onboarding-create-client" [addNewView]="v.createContact"
    listTitleText="Listado de contactos"
    noDataCreateNew="Crear un nuevo cliente" [data]="[]"
    [displayedHeader]=" ['Nombre',     'Identificador',     'Email',      'Teléfono', 'Ventas']"
    [displayedColumns]="['name',       'nif',     'email',      'phone',    'c_client']"
    [specialText]="     [completeName, undefined,  undefined,   undefined,     emptySells]"
    [specialClass]="    [undefined,    undefined,   undefined,   undefined,     sellsClass]"
    [cellZize]="        ['big',    undefined,   'big',   undefined,     'small']" [card]="card"
    [preIcon]="[preIconName,    undefined,   undefined,   undefined,     preIconSells]"
    [dataUpload]="features.dataUpload ? v.dataupload : undefined" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    (onclickRow)="params.go(v.contactDetails, $event.client_id)"
    [createButton]="{text : 'Crear contacto' , icon : 'contacts'}"
    [quickFilter]="quickFilter">
    
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <div class="df aic">
                <button class="fw1" [matTooltip]="'Ver contacto'" mat-flat-button color="warn" [go]="v.contactDetails"
                    [param]="ps.selected!.client_id">
                    <mat-icon>{{ps.selected!.icon}}</mat-icon>
                    {{ps.selected!.getName()}}
                    <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                </button>
                <button mat-icon-button [go]="v.createContact" [param]="ps.selected!.client_id">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>

            <p class="sells-line">
                <mat-icon class="vam">{{ps.selected!.isClient? 'sell' : 'highlight_off'}}</mat-icon>
                {{ps.selected!.isClient ? 'Ventas activas' : '¡Ventas no activas!'}}
            </p>

            <app-card-subtitle [first]="true">Vehículos</app-card-subtitle>

            <app-vehicle-row-container *ngIf="ps.selected!.hasVehicles(); else noData"
                [vehicles]="ps.selected!.vehicles"></app-vehicle-row-container>

            <button mat-stroked-button *ngIf="!ps.selected?.hasVehicles()" [go]="v.createvehicle"
                [param]="{'client' : ps.selected!.client_id}">
                <mat-icon>add</mat-icon>
                Dar de alta vehículo
            </button>

            <ng-template #noData>
                <p class="c_t2">Sin vehículo asignado</p>
            </ng-template>

            <!-- CLIENT (PERSON OR COMPANY) -->
            <ng-container *ngIf="ps.selected!.isClient;">
                <app-card-subtitle>Última reparación</app-card-subtitle>
                <div>
                    <p *ngIf="ps.selected!.last_reception">
                        <mat-icon class="vam">calendar_month</mat-icon>
                        Día <span>{{ps.selected!.last_reception.shortFormat()}}</span>
                    </p>

                    <p *ngIf="!ps.selected!.last_reception" class="c_t2">
                        Todavía no se han efectuado recepciones.
                    </p>
                </div>

                <div *ngIf="ps.selected && ps.selected.hasVehicles()">
                    <div *ngIf="ps.selected.vehicles.length == 1" class="w100">
                        <button class="w100" style="margin-bottom: 20px;" mat-stroked-button [go]="v.createOr"
                            [param]="ps.selected!.vehicles[0]!.vehicle_id">
                            <mat-icon>add</mat-icon>
                            Abrir OR
                        </button>
                    </div>
                    <div *ngIf="ps.selected.vehicles.length > 1" class="w100">
                        <button class="w100" style="margin-bottom: 20px;" mat-stroked-button
                            [mat-menu-trigger-for]="openOr">
                            <mat-icon>add</mat-icon>
                            Abrir OR
                        </button>
                        <mat-menu #openOr="matMenu">
                            <button mat-menu-item *ngFor="let veh of ps.selected?.vehicles!"
                                (click)="params.go(v.createOr, veh.vehicle_id, 'vehicle')">
                                {{veh.getName()}}
                            </button>
                        </mat-menu>
                    </div>
                </div>

                <!-- <div recambios>
                    <app-card-subtitle>Productos</app-card-subtitle>
                    <button class="w100" mat-stroked-button (click)="openManualReservation(ps.selected!)">
                        <mat-icon>bookmark_add</mat-icon>
                        Reservar producto
                    </button>
                </div> -->

            </ng-container>

            <app-contact-buttons>
                <app-whatsapp [phone]="ps.selected!.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.phone"></app-phone-button>
            </app-contact-buttons>
        </app-card>
    </ng-template>
</app-page-structure>