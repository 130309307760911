<app-page-structure [masterClass]="'or'" pageTitle="Órdenes de reparación" [autocompleteID]="'or'"
    searchLabel="Buscar or" [addNewView]="v.createOr" createButtonClass="onboarding-create-or"
    noDataCreateNew="Crear una nueva or" [data]="[]"
    listTitleText="Listado de or"
    [displayedHeader]=" ['Número',     'Matrícula',                'Marca y modelo',    'Estado',       'Tipo',     'Fecha de entrega']"
    [displayedColumns]="['title_id',   'vehicle.license',          'vehicle.brand' ,    'status',       'type',     'delivery']"
    [specialText]="     [undefined,     license,                   brand,                undefined,     undefined,   delivery]"
    [specialClass]="    [undefined,     undefined,                 undefined,            undefined,     undefined,   undefined]"
    [cellZize]="        [undefined,     undefined,                 undefined,             'big',      undefined,   undefined]"
    [preIcon]=" !this.companyS.recambiosModule ?   
    [undefined,     undefined,      undefined,            undefined,       undefined,    undefined]:
    [preicon,     undefined,      undefined,            undefined,       undefined ,     undefined,      undefined]"
    [card]="card" [filters]="filters" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear or' , icon : 'build'}"
    [quickFilter]="quickFilter">

    <ng-template #card>
        
        <app-card *ngIf="ps.showItem()">
            <button [matTooltip]="'Ir a la OR'" mat-flat-button color="warn" [go]="v.editOr"
                [param]="ps.selected!.id" class="mb10">
                <mat-icon>build</mat-icon>
                OR Nº {{ps.selected!.title_id}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <button mat-stroked-button (click)="previsualize(ps.selected!)">
                <mat-icon>find_in_page</mat-icon>
                Apertura OR
            </button>

            <app-card-subtitle>Operario</app-card-subtitle>
            <div class="cp" *ngIf="ps.selected && ps.selected.assigned; else noData">
                <app-circular-letter [showTooltip]="false" [user]="ps.selected.assigned"></app-circular-letter>
            </div>
            <ng-template #noData>
                <p class="c_t2">Sin operario asignado</p>
            </ng-template>

            <app-card-subtitle>Cliente y vehículo</app-card-subtitle>
            <app-vehicle-row [item]="ps.selected!.vehicle!"></app-vehicle-row>
            <app-client-row [item]="ps.selected!.client!"></app-client-row>

            <app-contact-buttons>
                <app-whatsapp [phone]="ps.selected!.client?.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.client?.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.client?.phone"></app-phone-button>
            </app-contact-buttons>
            
            <div class="envioMails" [roles]="[R.ADMIN, R.ADVISER]">
                <div class="w100" style="display: contents;">
                    <app-layout-send-email *ngIf="ps.selected?.status !== undefined && ps.selected?.status?.num == 1"
                        [temaplte]="'recogida'" [endpoint]="pickUpMail.bind(this)"
                        style="justify-content: start;"></app-layout-send-email>
                </div>
                <div class="w100">
                    <app-layout-send-email *ngIf="ps.selected && ps.selected.client && !ps.selected.client.deleted"
                        [temaplte]="'resend-or'" [endpoint]="reSendOR.bind(this)"
                        [disabled]="ps.selected.client.email == undefined"></app-layout-send-email>
                </div>
            </div>
        </app-card>
    </ng-template>
</app-page-structure>