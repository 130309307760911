import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { M_BaseUser } from '../../models/M_BaseUser';
import { IOr } from 'src/app/interfaces/IOr';

@Component({
  selector: 'lib-or-day-card',
  templateUrl: './or-day-card.component.html',
  styleUrls: ['./or-day-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-day.css']
})
export class OrDayCardComponent implements OnInit {

  @Input() item!: IOr;
  @Input() allUsers!: M_BaseUser[];
  @Input() canAssign!: boolean;
  @Input() canContact: boolean | undefined;
  @Input() day!: Date;
  @Output() onAssignUser : EventEmitter<M_BaseUser> = new EventEmitter();
  @Output() onGoOR : EventEmitter<any> = new EventEmitter();

  constructor() { }
  ngOnInit(): void {}
  
}
