import { Component, EventEmitter, Inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_Invoice } from '../../models/M_Invoice';
import { MatSelectChange } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ShowAdvanceClientComponent } from '../show-advance-client/show-advance-client.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_TypePayment } from '../../models/M_TypePayment';
import { TypePaymentService } from '../../services/type-payment-service';
import { M_LastMovements } from '../../models/M_LastMovement';
import { PreviewService } from '../../services/preview.service';
import { DialogCashTotalReturnComponent } from 'src/app/views/invoices/dialog-cash-total-return/dialog-cash-total-return.component';
import { FormService } from 'src/app/services/form.service';
import { invoice_states_payed } from 'src/app/custom-classes/invoice_states';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { T } from '@angular/cdk/keycodes';

/**
 * Invoices side detail and dialog form of payment movements.
 */
@Component({
  selector: 'app-invoice-payement-form',
  templateUrl: './invoice-payement-form.component.html',
  styleUrls: ['./invoice-payement-form.component.css']
})

export class InvoicePayementFormComponent {
  @Input() invoice!: M_Invoice;
  @Input() purchase!: M_Purchase;
  @Input() isDialogForm: boolean = false;
  @Input() resetImport: boolean = false;
  @Output() onDeleteTypePayment: EventEmitter<M_TypePayment> = new EventEmitter();
  @Output() onPayAll: EventEmitter<M_LastMovements> = new EventEmitter();
  @ViewChildren(ShowAdvanceClientComponent) lsmv?: QueryList<ShowAdvanceClientComponent>;
  selectedPayment: any;
  form: UntypedFormGroup;
  dOpened = false;
  cash = false;
  cashTotal: number | undefined;
  importAdvance: number | undefined;
  selectedPaymentId: number | undefined;
  constructor(private fb: UntypedFormBuilder, private d: MatDialog, private apiS: ApiService, private fs: FormService, public typePaymentS: TypePaymentService, @Inject(MAT_DIALOG_DATA) public data: { invoice: M_Invoice, isDialog: boolean,purchase:M_Purchase }, public previewS: PreviewService, public responsiveS: ResponsiveService) {
    if (data) {
      if(data.invoice){
        this.invoice = data.invoice;
      }
      if(data.purchase){
        this.purchase = data.purchase;
      }
      
      this.isDialogForm = data.isDialog;

    }
    this.form = this.fb.group({
      payment_id: [1, Validators.required],
      import: ['', Validators.required],
      invoice_id: [''],
      name: [''],
      dynamicDate: [new Date().datePickerFormat()],
      pending_import_total: ['']
    });
    this.selectedPaymentId = 1;
    this.importControl?.valueChanges.subscribe(res => {
      if (this.form.get('payment_id')?.value != 1) {
        this.checkDisableOkButton();
      }
    });
    this.typePaymentS.getTypePayments().then(res => { });
  }

  ngOnInit(): void {
    if (this.isDialogForm) {
      if(this.invoice){
        this.importControl?.patchValue(this.invoice.pending_import);
        const pendingImportControl = this.form.get('pending_import_total');
        pendingImportControl?.patchValue(this.invoice.pending_import);
        if (this.invoice.isAbono) {
          this.importControl?.disable();
        }
      }
      if(this.purchase){
        this.importControl?.patchValue(this.purchase.total);
        const pendingImportControl = this.form.get('pending_import_total');
      pendingImportControl?.patchValue(this.purchase.total);
      }

    }
  }
  /** Error of 'The amount exceeds the import. */
  checkDisableOkButton() {
    if (this.importControl == undefined) { return; }
    let isOk = this.importControl.value <= this.invoice.pending_import ? this.invoice.pending_import : this.purchase.total;
    if (isOk) { this.importControl.setErrors(null); }
    else {
      this.importControl.setErrors({ error_import: true });
      this.importControl.markAsTouched();
      this.importControl.markAsDirty();
    }
  }

  resetForm() {
    this.nameControl?.removeValidators(Validators.required);
    this.form.reset({}, { onlySelf: true, emitEvent: false });
    this.form.patchValue({ dynamicDate: new Date().datePickerFormat() });
    this.importControl?.markAsPending();
  }
  selectPayment(paymentId: number) {
    this.selectedPaymentId = paymentId;
    this.form.patchValue({ payment_id: this.selectedPaymentId });
    this.checkDisableOkButton();
  }
  onPaymentTypeChanges(event: MatSelectChange) {
    const selectedValue = event.value;
    this.selectedPayment = this.typePaymentS.typePaymentArray.find(payment => payment.id === selectedValue);
    if (event.value == 'personalizado') {
      this.nameControl?.addValidators(Validators.required);
    } else {
      this.nameControl?.removeValidators(Validators.required);
    }
    this.nameControl?.updateValueAndValidity();
  }

  checkImport(importHtml: HTMLInputElement) {
    let valueIimport = 0;
    if(this.invoice.pending_import){
      valueIimport = this.invoice.pending_import;
    }
    if(this.purchase.total){
      valueIimport = this.purchase.total;
    }

    if (this.importControl && this.importControl.value > valueIimport) {
      const changeAmount = (this.importControl.value - valueIimport).toFixed(2);
      const dynamicDateControl = this.form.get('pending_import_total');
      dynamicDateControl?.patchValue(this.invoice.pending_import);
      const selected = this.form.get('payment_id');
      if (selected?.value == 1) {
        //this.openChangeDialog(changeAmount);
        this.cash = true;
        this.cashTotal = parseFloat(changeAmount);
      }
      importHtml.blur();
    } else {
      this.cash = false;
      this.cashTotal = 0;
    }
  }

  openChangeDialog(changeAmount: string) {
    if (!this.dOpened) {
      this.dOpened = true;
      this.d.open(DialogCashTotalReturnComponent, { data: { changeAmount }, }).afterClosed().subscribe(v => {
        this.dOpened = false;
      });
    }
  }

  updateForm() {
    this.form.patchValue({ invoice_id: this.invoice.id });
    this.createPayment();
  }

  createPayment() {
    let pendingImport = this.invoice.pending_import;
    const paymentIdControl = this.form.get('payment_id');
    const importControl = this.form.get('import');
    const paymentId = paymentIdControl?.value;
    const importValue = importControl?.value;

    if (paymentId === 1) {
      const newPendingImport = pendingImport - importValue;
      if (newPendingImport < 0) {
        // Si el nuevo importe pendiente es negativo y no hay movimientos previos, establece el importe al total de la factura
        // Si hay movimientos previos, establece el importe al importe pendiente restante
        const importToSet = this.invoice.last_movement.length === 0 ? this.invoice.total : pendingImport;
        this.form.patchValue({ import: importToSet });
        pendingImport = 0; // El importe pendiente se convierte en 0 porque se está pagando la totalidad pendiente
      } else {
        // Si el nuevo importe pendiente no es negativo, sigue con la lógica normal
        this.form.patchValue({ import: importValue }); // Mantiene el valor actual del importe en el formulario
      }
    } else {
      // Si el payment_id no es 1, sigue con la lógica normal
      this.form.patchValue({ import: importValue }); // Mantiene el valor actual del importe en el formulario
    }

    if (this.selected || this.fs.isOk(this.form)) {
      this.typePaymentS.createPayment(this.form.getRawValue(), this.invoice, this.selected?.dataMostrada).then(res => {
        if (res) {
          this.resetForm();
          this.form.patchValue({ payment_id: 1 });
          if (this.invoice.pending_import == 0 || pendingImport <= 0) {
            this.onPayAll.emit(this.selected?.dataMostrada);
            this.apiS
              .changeStateInvoice(this.invoice.id!, invoice_states_payed)
              .then(() => {
                this.invoice!.state = invoice_states_payed;
              });

          }
        }
      });
    }
  }

  disableOthers(advacne: ShowAdvanceClientComponent) {
    this.lsmv?.forEach(lsvm_ => {
      lsvm_.checkbox!.checked = false;

    })
    advacne.checkbox!.checked = true;
    this.cash = false;
    this.cashTotal = 0;
  }
  disableAdvance(advance: ShowAdvanceClientComponent) {
    this.importControl?.enable();
    advance.checkbox!.checked = false;
    this.form.patchValue({ import: this.invoice.pending_import });
  }
  get selected() {
    return this.lsmv?.filter((sac) => sac.selected)[0];
  }
  get pendingTotalInv() {
    let valuepending = 0
      if(this.invoice){
        valuepending = this.invoice.pending_import
      }
      if(this.purchase){
        valuepending = this.purchase.total!;
      }
    if (this.selected) {
      this.importControl?.disable();
      

      let sumtotal = valuepending - this.selected.dataMostrada!.import
      if (sumtotal == 0) {
        this.form.patchValue({ import: parseFloat(sumtotal.toFixed(2)) });

      } else {

        this.form.patchValue({ import: parseFloat(sumtotal.toFixed(2)) });
      }
      if (sumtotal) {
        this.form.patchValue({ import: parseFloat(sumtotal.toFixed(2)) });
      }

      return valuepending - this.selected.dataMostrada!.import
    } else {
      return valuepending
    }

  }
  get nameControl() {
    return this.form.get('name');
  }

  get importControl() {
    return this.form.get('import');
  }

  /** Only show advances if the import of the advance is less than the total of the invoice */
  get showAdvances() {
    if(this.invoice){
      return this.isDialogForm && this.invoice.client?.last_movement.some(m => m.import <= this.invoice.pending_import);
    }else{
      return this.isDialogForm && this.purchase.contact?.last_movement.some(m => m.import <= this.purchase.total!);
    }
  }

  get advances() {
    if(this.invoice){
    return this.invoice.client?.last_movement.filter(lm => lm.import <= this.invoice.pending_import && !lm.deleted)
  }
    else{
      return this.purchase.contact?.last_movement.filter(lm => lm.import <= this.purchase.total! && !lm.deleted)}
  }
get isTotalPay(){
  if(this.invoice){
    return !this.invoice.isTotalPay;
  }
  if(this.purchase.last_movements){
    return true;
  }
  return false
  }
  get total(){
    let valueTotal = 0;
    if(this.invoice){
      valueTotal =  this.pendingTotalInv;
    }
    if(this.purchase.total){
      valueTotal = this.purchase.total;
    }
    return valueTotal;
  }
  get typePayName(){
    if(this.invoice){
      return this.invoice.type == 5 ? 'Pagar' : 'Cobrar' 
    }
    else{
      return 'Pagar';
    }
  }
  get PayName(){
    if(this.invoice){
      return this.invoice.type == 5 ? 'Factura' : 'Abono' 
    }
    else{
      return 'Factura Compra';
    }
  }
  get TitleObject(){
    if(this.invoice){
      return this.invoice.company_scope_id 
    }
    else{
      return this.purchase.comment
    }
  }
}
