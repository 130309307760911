<app-page-structure [pageTitle]="'Albaranes'"
    class="users-table" [masterClass]="'albaran'" [autocompleteID]="'albaran'" searchLabel="Buscar albarán"
    listTitleText="Listado de albaranes"
    createButtonClass="onboarding-albaran-create" [addNewView]="v.createEditAlbaran" [data]="[]"
    [displayedHeader]=" ['Número',      'Cliente',      'Estado',     'Tipo',       'Realización']" 
    [displayedColumns]="['title_id',    'client',       'status',     'type',       'date_user']" 
    [specialText]="     [undefined,     getClient,      undefined,    undefined,    undefined]" 
    [specialClass]="    [undefined,     undefined,      undefined,    undefined,    undefined]"
    [preIcon]="         [preicon,       undefined,      undefined,    undefined,    undefined]"
    [card]="card" [createButtonLoading]="!subS.status" [filters]="filters" [filter]="f"
    [basicUserSubscription]="subS.isBasic" (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear albarán' , icon : 'assignment'}"
    [quickFilter]="quickFilter">

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <button [matTooltip]="'Ir al albarán'" mat-flat-button color="warn" class="mb10" [go]="v.createEditAlbaran" [param]="ps.selected!.id">
                <mat-icon>assignment</mat-icon>
                Albarán Nº {{ps.selected!.title_id}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>
            <button mat-stroked-button (click)="previsualize(ps.selected!.albaranPdf)" *ngIf="ps.selected!.albaranPdf" class="mb10">
                <mat-icon>find_in_page</mat-icon>
                Albarán
            </button>
            <button mat-stroked-button (click)="previsualize(ps.selected!.invoicePdf)" *ngIf="ps.selected!.invoicePdf">
                <mat-icon>find_in_page</mat-icon>
                Factura
            </button>
            <app-card-subtitle>Cliente</app-card-subtitle>
            <app-client-row [item]="ps.selected!.client!"></app-client-row>
            <app-contact-buttons>
                <app-whatsapp [phone]="ps.selected!.client.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.client.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.client.phone"></app-phone-button>
            </app-contact-buttons>
        </app-card>
    </ng-template>
</app-page-structure>