<h1 mat-dialog-title>RECORTAR IMAGEN</h1>
<div mat-dialog-content>
  <image-cropper *ngIf="data.f != undefined; else load"
    [imageBase64]="data.f"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="dni ? 1.5863: 1"
    (imageCropped)="cropImg($event)">
  </image-cropper>
  <ng-template #load>
    <!--mat-progress-bar mode="buffer"></mat-progress-bar-->
  </ng-template>
</div>
<div mat-dialog-actions align="center">
  <button [mat-dialog-close]="false" mat-flat-button>Cerrar</button>
  <button [mat-dialog-close]="getCroped()" mat-flat-button color="primary">Aceptar</button>
</div>
