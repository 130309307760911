declare global {
    interface String {
        isNumber(): boolean;
        haveSeparator(): boolean;
        removeSeparator() : String;
        getNumber(): number;
        getESNumber(): number;
        castDeciamls(decimals: number): number;
        hasNumbers(): boolean;
    }
}

String.prototype.isNumber = function (): boolean {
    if ((this != null) && (this != undefined) && (this !== "") && (this !== " ") && !isNaN(Number(this))) {
        return true;
    }
    return false;
};

String.prototype.haveSeparator = function (): boolean {
    if ((this != null) && (this != undefined) && (typeof this == "string") && this.includes('.')) {
        return true;
    }
    return false;
};

/**TODO */
String.prototype.removeSeparator = function () : String {
    if (this.haveSeparator()) {
        this.replace(/\./g, '');
    }
    return this;
};

String.prototype.hasNumbers = function (): boolean {
    return /\d/.test(this.toString())
}

/** Get a number by sring. If the string contains ',' replaces by . If the string is not a number, returns 0  */
String.prototype.getESNumber = function (): number {
    var v_replaced = this.replace(",", ".");
    var v = Number(v_replaced)
    return Number.isNaN(v) ? 0 : v;
}   


/** Get a number by sring. If the string is not a number, returns 0  */
String.prototype.getNumber = function (): number {
    var v = Number(this)
    return Number.isNaN(v) ? 0 : v;
}   

/** Cast string decimals. If the string is not a number, returns 0 */
String.prototype.castDeciamls = function (decimals : number): number {
    var val = this.getNumber();
    return Number(val!.toFixed(decimals));
}

export { }