import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ClientformComponent } from 'src/app/components/clientform/clientform.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { ContactEnum } from 'src/app/enums/ContactEnum';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent extends ParameterStateComponent {
  clientType: any
  loaded: boolean = false;
  v = ViewPath;
  public isEdit: boolean = false;
  isClient: boolean = false;
  public c: M_Contact | undefined;
  @ViewChild('toggle') toggle?: MatSlideToggle;
  @ViewChild(ClientformComponent, { static: true }) clientForm!: ClientformComponent;

  constructor(private apiS: ApiService, routerS: RouterService, route: ActivatedRoute, private paramS: ParamsService,
    private params: ParamsService, private snackS: SnackService,
    @Optional() @Inject(MAT_DIALOG_DATA) public forceType: ContactEnum | undefined,
    @Optional() public dialogRef: MatDialogRef<CreateClientComponent>) {
    super(routerS, route, ["client"]);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.clientType = params['client_type'];
    });
  }

  get getForceType() {
    if (typeof this.forceType == "number") { return this.forceType }
    return undefined;
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  override noParams() {
    this.loaded = true;
  }

  override onParam(_k: string, v: any) {
    if (_k == "client") {
      this.isEdit = true;
      this.apiS.getClientById(v).then(res => {
        if (res) {
          this.fillDataWithClient(res);
        }
      })
    }
  }

  fillDataWithClient(client: M_Contact) {
    this.loaded = true;
    this.clientForm.currentContact = client;
    this.clientForm.form.patchValue(client);
    this.clientForm.form.patchValue({ "dob": client.dob ? client.dob.datePickerFormat() : null });
    if (client.billing_address) { this.clientForm.form.patchValue(client.billing_address) }

    client.clients_admincenters.forEach(ac => {
      this.clientForm.addAdminCenter(ac);
    })
    client.extra_adress.forEach(ea => {
      this.clientForm.addExtraAdress(ea);
    })
    this.clientForm.refreshAdminCentersValidators();
    this.c = client;
  }

  create() {
    let client = new M_Contact(this.clientForm.form.getRawValue())
    if (this.isEdit) {
      this.clientForm.form.updateValueAndValidity();
      client.client_id = this.c!.client_id;
      client.vehicles = this.c!.vehicles;
      this.apiS.client.updateClient(client).then(_resp => {
        this.params.go(this.v.contactDetails, client.client_id);
        this.snackS.show("¡Cliente editado con éxito!")
      })

    }
    else {
      let c = new M_Contact(this.clientForm.form.value);
      this.apiS.client.createClient(c).then(resp => {
        if (!this.isOnDialog) {
          this.paramS.go(this.v.contactDetails, resp.client_id)
          this.snackS.show("¡Cliente creado con éxito!")
        }
        else {
          c.client_id = resp.client_id;
          this.dialogRef.close(c);
        }
      })
    }
  }

  goBackClient() {
    if (!this.c) { return; }
    this.paramS.go(ViewPath.contactDetails, this.c.client_id);
  }

  get title() {
    return this.isEdit ? "Editar contacto" : "Crear contacto";
  }

}
