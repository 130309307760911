import { Injectable } from '@angular/core';
import { Views } from '../custom-classes/View';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class BurgerService {

  constructor(private routerS: RouterService) { }

  havePermisisons(data: BurgerOption): boolean {
    return this.routerS.userCanGo(data.view);
  }

  isSelected(data: BurgerOption) {
    if (data.viewsSelected != undefined) {
      return this.routerS.is(...data.viewsSelected) || this.routerS.is(data.view);
    }
    else {
      return this.routerS.is(data.view);
    }
  }

  /** MUTIPLE */


  /** Mira si alguno de los hijos esta seleccionado */
  isSelectedMultiple(children: BurgerOption[]) {
    if (children) {
      for (let i = 0; i < children.length; i++) {
        if (this.isSelected(children[i])) {
          return true;
        }
      }
    }
    return false;
  }

  /** Mira si alguno de los hijos tiene permisos 
   * Si ninnguno de los hijos tiene permisos, ocultamos el componente
  */
  childsHasPermission(children: BurgerOption[]) {
    let allblocked = false;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        if (this.havePermisisons(children[i])) {
          return true;
        }
      }
    }
    return allblocked;
  }
}


export interface BurgerItemInterface {
  name: string;
  selected?: boolean;
}

/**Datos para generar el componente de dropDown
* @param name : Título de la sección
* @param child : Array de dropDownChilds (subsecciones)
* @param isSelected : Boolean que defini si esta seleccionado o no
*/
export class BurgerMultipleOption implements BurgerItemInterface {
  name: string;
  child: BurgerOption[];
  selected = false;
  constructor(name: string, icon: string, child: BurgerOption[]) {
    this.name = name;
    this.child = child;
  }
}


/** ------------------------------------------------------------- */

/**
* Hijos del componente de BurgerMultipleOption
* @param name : Título de la sección
* @param icon : Icono de la sección
* @param view : Vista a la que redirige la sección
* @param isSelected : Array de views que determinana si la sección esta seleccionada. Por defecto, la view a a la que redirige (@param - view) se incluye en esta array.
* @param buttonAction : Acción al hacer click en el botón
*/
export class BurgerOption implements BurgerItemInterface {
  name: string;
  icon: string;
  view: Views;
  viewsSelected?: Views[];
  selected: boolean = false;
  class_?: string;
  constructor(name: string, icon: string, view: Views, viewsSelected?: Views[], action?: () => void, class_?: string) {
    this.name = name;
    this.icon = icon;
    this.view = view;
    this.viewsSelected = viewsSelected;
    this.class_ = class_;
  }
}


/** ------------------------------------------------------------- */
export interface BurgerMenuInterface {
  afterGoTo: () => void
  open(): void;
  toggle(): void;
  toggleTo(opened: boolean): void;
}
