import {AbstractControl, ValidatorFn} from '@angular/forms';

/** CIF validator
 * 
 * Accepts : CIFS with the 1 leading letter followed by 8 numbers (Ex: A12345678).
 */
export function cifValidator(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: boolean } | null =>  { 
        if (/^[A-Za-z]{1}[0-9]{8}$/.test(control.value)){
            return null;
        }
        else{
           return {cif: true} ;
        }
    }
}