type type_names = "Sin tipo" | "Normal" | "Interno" | "Garantia" | "Siniestro" | "Mantenimiento";

const types: [number, type_names][] = [
    [4, "Sin tipo"],
    [0, "Normal"],
    [1, "Interno"],
    [2, "Garantia"],
    [3, "Siniestro"],
    /** Only sinicloud */
    [5, "Mantenimiento"],
]

export class or_types {
    num: number = -1;
    name: type_names = "Sin tipo";
    constructor(tipo: type_names | number) {
        types.map(t => {
            if (typeof tipo == "number") {
                if (t[0] == tipo) {
                    this.num = t[0];
                    this.name = t[1];
                }
            }
            else {
                if (t[1] == tipo) {
                    this.num = t[0];
                    this.name = t[1];
                }
            }
        })
    }

    get todefine() {return this.num == or_types_todefine.num }
    get normal() { return this.num == or_types_normal.num }
    get cargo() {return this.num == or_types_cargo_interno.num }
    get garantia() { return this.num == or_types_garantia.num}
    get siniestro() {return this.num == or_types_siniestro.num }

    /** Only sinicloud */
    get mantenimiento() {return this.num == or_types_mantenimiento.num }
}

export const or_types_todefine = new or_types("Sin tipo");
export const or_types_normal = new or_types("Normal");
export const or_types_cargo_interno = new or_types("Interno");
export const or_types_garantia = new or_types("Garantia");
export const or_types_siniestro = new or_types("Siniestro");

/** Only for Sinicloud */
export const or_types_mantenimiento = new or_types("Mantenimiento");