import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_TemplateField } from '../../models/M_TemplateField';
import { ApiService } from 'src/app/services/Api/api.service';
import { TemplateFieldsFormComponent } from '../template-fields-form/template-fields-form.component';
import { forkJoin } from 'rxjs';
import { PDF_DATA } from '../pdf/pdf.component';
import { TemplateSteps } from '../../enums/TemplateSteps';
import { VehicleType } from '../../enums/VehicleType';
import { M_BuyTransac } from '../../models/M_BuyTransac';

export interface TEMPLATE_DIALOG_DATA {
  steps: TemplateSteps[];
  vehicleType?: VehicleType;
}
export interface TEMPLATE_PDF_PARAMS {
  client_id:    number | undefined,
  vehicle_id:   number | undefined,
  price:        number |  undefined,
  tax:          number |   undefined,
  comment :     string | undefined;
  type :        number | undefined;
  docum_prov_num :     string | undefined;
  mode : string | undefined;
  buy_transac_id:number | undefined;
  date: Date | undefined;
  token: string | undefined;
} 
@Component({
  selector: 'app-template-fields-dialog',
  templateUrl: './template-fields-dialog.component.html',
  styleUrls: ['./template-fields-dialog.component.css']
})
export class TemplateFieldsDialog {
  loaded = false;
  index = 0;
  fields: M_TemplateField[] | undefined;
  TS = TemplateSteps;
  generateById: M_BuyTransac | undefined;
  @ViewChild("clientFieldsComponent") clientFieldsComponent?: TemplateFieldsFormComponent
  @ViewChild("workShopFieldsComponent") workShopFieldsComponent?: TemplateFieldsFormComponent

  constructor(private dialogRef: MatDialogRef<TemplateFieldsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { pdf: PDF_DATA, dialog: TEMPLATE_DIALOG_DATA, params?: TEMPLATE_PDF_PARAMS },
    private apiS: ApiService, private chdRef: ChangeDetectorRef) {
    dialogRef.disableClose = true;
    if(this.data.params){
    console.log(this.data.params);
    }
    if (this.containFieldsClientStep) {
      this.apiS.getFieldsFromDoc(data.pdf.preview_type).then(res => {
        this.fields = res;
        this.removeFieldsStep(this.fields);
        this.loaded = true;
      })
    }
    else {
      this.loaded = true;
    }
  }

  removeFieldsStep(f: M_TemplateField[] | undefined) {
    if (!f || !f.length) {
      this.data.dialog.steps.removeElement(TemplateSteps.FIELDSCLIENT);
      this.data.dialog.steps.removeElement(TemplateSteps.FIELDSWORKSHOP);
    }
    else {
      if (!this.clientFields.length) {
        this.data.dialog.steps.removeElement(TemplateSteps.FIELDSCLIENT);
      }
      if (!this.workShopFields.length) {
        this.data.dialog.steps.removeElement(TemplateSteps.FIELDSWORKSHOP);
      }
    }
  }

  closeDialog() {
    this.chdRef.detectChanges();

    let finalModels: M_TemplateField[] = [];
    let finalFileModels: M_TemplateField[] = [];
    
    console.log(this.generateById);
    if (this.clientFieldsComponent) {
      finalModels.push(...this.clientFieldsComponent.getModels())
      finalFileModels.push(...this.clientFieldsComponent.getFilesModels())
    }

    if (this.workShopFieldsComponent) {
      finalModels.push(...this.workShopFieldsComponent.getModels())
      finalFileModels.push(...this.workShopFieldsComponent.getFilesModels())
    }
    // Generar ID de transacción
     // Verifica si this.data.params está definido
    if (this.data.params != undefined) {
      // Generar ID de transacción
      this.apiS.generateIdTransac(this.data.params).then(res => {
        if (res) {
          this.generateById = res;
        } else {
          this.generateById = undefined;
        }

        // Llamar a la función auxiliar que maneja las operaciones
        this.handleTemplateSave(finalModels, finalFileModels,true);
      }).catch(error => {
        console.error('Error generating transaction ID:', error);
        this.dialogRef.close(false); // O cualquier manejo de error que necesites
      });
    } else {
      // Si this.data.params es undefined, llama directamente a handleTemplateSave
      this.handleTemplateSave(finalModels, finalFileModels,false);
    }
  }
handleTemplateSave(finalModels: M_TemplateField[], finalFileModels: M_TemplateField[],isParams:boolean) {
  if (this.containFieldsClientStep || this.containFieldsWorkshopStep) {

    if ((this.clientFieldsComponent || this.workShopFieldsComponent) && this.data.pdf.id && !isParams) {

      // Lógica específica para cuando this.data.params está definido
          const saveTemplateFieldsObs = this.apiS.saveTemplateFields(this.data.pdf.id, finalModels);
          const saveTemplateFilesObs = this.apiS.saveTemplateFiles(this.data.pdf.id, finalFileModels);
          forkJoin([saveTemplateFieldsObs, saveTemplateFilesObs]).subscribe(res => {
            this.dialogRef.close(true);
          });
      }
      else if(isParams){
         // Si generateById está definido, utiliza el nuevo ID
          // Si generateById no está definido, usa el ID original
        if (this.generateById != undefined) {
          const saveTemplateFieldsObs = this.apiS.saveTemplateFields(this.generateById.buy_num, finalModels);
          const saveTemplateFilesObs = this.apiS.saveTemplateFiles(this.generateById.buy_num, finalFileModels);
          forkJoin([saveTemplateFieldsObs, saveTemplateFilesObs]).subscribe(res => {
            this.dialogRef.close(this.generateById!.buy_num);

          });
        }
        else {
          // El objeto no existe todavía. Cierra el diálogo con los valores de la plantilla introducidos
          this.dialogRef.close({
            models: finalModels,
            files: finalFileModels
          });
        }
      }
      else {
        // El objeto no existe todavía. Cierra el diálogo con los valores de la plantilla introducidos
        this.dialogRef.close({
          models: finalModels,
          files: finalFileModels
        });
      }
    }
    else {
      this.dialogRef.close(true);
  }
}
  get dialogTitle() {
    switch (this.data.pdf.preview_type) {
      case 'B':
        return "Generación de presupuesto"
      case 'RA':
        return "Generación de OR"
      case 'OR':
        return "??"
      case 'I':
        return "Generación de factura"
      case 'FL':
        return "??"
      case 'A':
        return "Generación de abono"
      case 'AN':
        return "Generación de anticipo"
      case 'AC':
        return "??"
      case 'EA':
        return "Entrada de almacén"
      case 'CB':
        return "Presupuesto de comercial"
      case 'CV':
        return "Generación de compra venta" 
      case 'FC':
        return "Generación de factura compra"
    }
  }

  get clientFields() {
    if (this.fields == undefined) { return [] }
    return this.fields.filter(f => !f.workshopFill)
  }

  get workShopFields() {
    if (this.fields == undefined) { return [] }
    return this.fields.filter(f => f.workshopFill)
  }

  get stepShowing(): TemplateSteps {
    try {
      return this.data.dialog.steps[this.index];
    }
    catch {
      return TemplateSteps.PREVIEW
    }
  }

  previousStep() {
    this.index -= 1;
  }

  nextStep() {
    this.index += 1;
  }

  get isEndStep() {
    return this.index == this.totalSteps - 1;
  }

  get containPreviewStep() {
    return this.data.dialog.steps.some(s => { return s == TemplateSteps.PREVIEW });
  }

  get containFieldsClientStep() {
    return this.data.dialog.steps.some(s => { return s == TemplateSteps.FIELDSCLIENT });
  }

  get containFieldsWorkshopStep() {
    return this.data.dialog.steps.some(s => { return s == TemplateSteps.FIELDSWORKSHOP });
  }

  get totalSteps() {
    return this.data.dialog.steps.length;
  }

}
