import { Component, Input } from '@angular/core';
import { M_Contact } from '../../models/M_Contact';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';

@Component({
  selector: 'app-client-searcher-header',
  templateUrl: './client-searcher-header.component.html',
  styleUrls: ['./client-searcher-header.component.css']
})
export class ClientSearcherHeaderComponent {
  @Input() classSearcher!: ClassSearcherComponent<M_Contact>;
  @Input() displayDiscount:boolean= true;
}
