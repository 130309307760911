import { Component, Input } from '@angular/core';
import { ProductCategory } from '../../enums/ProductCategory';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-product-lord-icon',
  templateUrl: './product-lord-icon.component.html',
  styleUrls: ['./product-lord-icon.component.css']
})
export class ProductLordIconComponent {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  pc = ProductCategory;
  @Input({ required: true }) category!: ProductCategory;
}
