<div class="advanced-details-container" *ngIf="ve != undefined || (ve == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado vehículos'" [v]="v.garage" [show]="loaded"></app-go-back>
    <div class="vehicle">
        <app-card [contentLoaded]="loaded">
            <ng-container *ngIf="ve">
                <div class="df fdc aic jcc">
                    <app-vn-vo-label *ngIf="ve.comercialType" [type]="ve.comercialType"></app-vn-vo-label>
                    <p class="mt10 fsm fw300" style="letter-spacing: 2px">{{ve.getName()}}</p>
                    <app-vehicle-lord-icon [vehicle]="ve" [small]="false"></app-vehicle-lord-icon>
                    <button mat-flat-button color="warn" (click)="params.go(v.createVnVo, ve!.vehicle_id!)"
                        class="one-line">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                </div>
            </ng-container>
        </app-card>
    </div>
    <div class="Details">
        <app-card [contentLoaded]="loaded">
            <ng-container *ngIf="ve">
                <app-card-row-content *ngIf="ve != undefined" [data]="
                [
                    ['Chasis', ve.chassis],
                    ['Precio', ve.price, {money : true}],
                    ['Coste',  ve.cost, {money : true} ],
                ]">
                </app-card-row-content>
            </ng-container>
            <mat-divider></mat-divider>
            <div class="documentos">
                <div>
                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Documentación
                        <button mat-icon-button (click)="documentation.clickInputTrigger()" color="p3">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </app-card-subtitle>
                    <p *ngIf="!hasDocuImages()" class="c_t2">Aún no has subido imágenes</p>
                    <lib-file-upload  #documentation class="hidde_add" [uploadtype]="'multiple'" [maxImagesLength]="5"
                        [maxSize]="6" (stateChanged)="uploadImages($event, 'docu_img')"
                        (onRemoveImage)="removeImage($event)">
                    </lib-file-upload>
                </div>
                <div>
                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Imágenes vehículo
                        <button mat-icon-button (click)="vehicleImages.clickInputTrigger()" color="p3">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </app-card-subtitle>
                    <p *ngIf="!hasVehicleImages()" class="c_t2">Aún no has subido imágenes</p>
                    <lib-file-upload  #vehicleImages class="hidde_add" [phoneMultipleUpload]="true"
                        [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6"
                        (stateChanged)="uploadImages($event, 'vehicle_img')" (onRemoveImage)="removeImage($event)">
                    </lib-file-upload>
                </div>
            </div>
        </app-card>
    </div>
    <div class="BottomCard">
        <app-card [contentLoaded]="loaded" [noData]="ve && !ve.concepts.length">
            <app-card-title>Conceptos</app-card-title>
            <ng-container *ngIf="ve && ve.concepts">
                <app-concepts-line-table [concepts]="ve.concepts" [vehicle]="ve" [isInfo]="true" [canModifyTable]="false"></app-concepts-line-table>
            </ng-container>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el vehículo'" [goText]="'Ir al listado de vehículos'"
        [view]="v.vehicles">
    </app-model-not-found>
</ng-template>