import { Component, Inject, Input, OnInit } from '@angular/core';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ProductLineTableComponent } from '../product-line-table.component';
import { AddRemoveComponent } from 'src/app/views/products/add-remove/add-remove.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { ThemePalette } from '@angular/material/core';

/** Line actions types */
export type lineActions = "manual-fault" | "remove-reservation" | "quantity-by-stock-min";

/** Reperesents a action on extended info */
type lineActionMenuItem = {
  /** Icon of the action */
  icon: string,
  /** Text on the menu item button */
  menuText: string,
  /** This action need recambios module? */
  needRecambios: boolean,
  /** Button tooltip */
  tooltip: (product: M_Product | M_CustomProduct) => string
  /** When does this menu item have to be shown? */
  showIf: (product: M_Product | M_CustomProduct) => boolean,
  /** Function to perform when clicking on the item */
  function: (product: M_Product | M_CustomProduct, ar: AddRemoveComponent) => void;
}

@Component({
  selector: 'app-product-line-actions',
  templateUrl: './product-line-actions.component.html',
  styleUrls: ['./product-line-actions.component.css']
})
export class ProductLineActionsComponent implements OnInit {

  @Input({ required: true }) product!: M_Product | M_CustomProduct;
  @Input({ required: true }) addRemove!: AddRemoveComponent;
  loaded = false;

  constructor(@Inject(ProductLineTableComponent) public productTable: ProductLineTableComponent, private apiS: ApiService) { }

  ngOnInit(): void {
    /** Filter line actions according to recambios module */
    if (this.productTable.extendedInfoConfig) {
      if (this.productTable.extendedInfoConfig.actions) {
        this.productTable.extendedInfoConfig.actions = this.productTable.extendedInfoConfig.actions.filter(action => {
          let extendedAction = this.lineActionsMenuItem[action];
          if (extendedAction.needRecambios) { return this.productTable.companyS.recambiosModule; }
          else { return true; }
        })
      }
    }
  }

  /** Product line table actions definition */
  lineActionsMenuItem: Record<lineActions, lineActionMenuItem> = {

    /** Generate a manual fault */
    "manual-fault": {
      icon: "production_quantity_limits",
      menuText: "Añadir falta manual",
      needRecambios: true,
      tooltip: (p: M_Product | M_CustomProduct) => { return "" },
      showIf: (p: M_Product | M_CustomProduct) => {
        return p.instanceofProduct();
      },
      function: (p: M_Product | M_CustomProduct, ar: AddRemoveComponent) => this.productTable.manualFault(p)
    },

    /** Remove other client reservation */
    "remove-reservation": {
      icon: "bookmark_remove",
      menuText: "Quitar reserva",
      needRecambios: true,
      tooltip: (p: M_Product | M_CustomProduct) => { return "" },
      showIf: (p: M_Product | M_CustomProduct) => {
        if (!this.productTable.isInvoice && !this.productTable.isOr && !this.productTable.isAlbaran) { return false }
        return this.productTable.isReservedWithFaultRequestedNotRecived(p);
      },
      function: (p: M_Product | M_CustomProduct, ar: AddRemoveComponent) => this.productTable.removeReservation(p)
    },

    /** Button to add the quantity based on the stock min and stock max */
    "quantity-by-stock-min": {
      icon: "balance",
      menuText: "Cantidad por stock minimo",
      needRecambios: true,
      tooltip: (p: M_Product | M_CustomProduct) => { if (p.instanceofProduct()) { return "Estblecerá " + p.quantityByStockMin + " unidad/es"; } return "" },
      showIf: (p: M_Product | M_CustomProduct) => { return p.instanceofProduct() && p.stock_min != undefined },
      function: (p: M_Product | M_CustomProduct, ar: AddRemoveComponent) => { if (p.instanceofProduct()) { ar.setValue(p.quantityByStockMin); } }
    }
  }

  loadProductQuants() {
    let moveType = this.productTable.movTypeByParent;

    if (this.product instanceof M_CustomProduct || moveType == undefined) {
      this.loaded = true;
      return;
    }

    this.loaded = false;
    let lineId = this.product.task_id ? this.product.task_id : this.product.line_id;
    if (moveType && lineId) {
      this.apiS.productItemQuants(moveType, lineId).then(res => {
        if (this.product.instanceofProduct()) {
          this.product.productsQuants = res;
        }
        this.loaded = true;
      })
    }
    else {
      this.loaded = true;
    }

  }

  showButton(p: M_Product | M_CustomProduct) {
    if (p.instanceofProduct() && this.productTable.isBudget && !p.reserve){return false;}
    return true;
  }

  showButtons(p: M_Product | M_CustomProduct) {
    if (p instanceof M_CustomProduct) { return false; }
    return this.productTable.extendedInfoConfig &&
      this.productTable.extendedInfoConfig.actions &&
      this.productTable.extendedInfoConfig.actions.some(a => this.lineActionsMenuItem[a].showIf(p));
  }

  isActionShowing(p: M_Product | M_CustomProduct, action: lineActions) {
    return this.productTable.extendedInfoConfig?.actions?.includes(action) && this.lineActionsMenuItem[action].showIf(p);
  }

  getIconClass(product: M_Product | M_CustomProduct) {
    if (!this.showExlamation(product)) { return "" }
    if (this.productTable.isBudgetReservedRequestedAndRecived(product)) { return "c_b_i" }
    return "c_r_i";
  }

  getBadgeColor(product: M_Product | M_CustomProduct): ThemePalette {
    if (this.productTable.isBudgetReservedRequestedAndRecived(product)) { return "accent" }
    return "warn";
  }

  /** Show a exlamation on line actions ? */
  showExlamation(p: M_Product | M_CustomProduct) {
    return this.productTable.isLineBlocked(p);
  }
}
