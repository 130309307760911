<div class="subscription-option-card">
    <div class="header-contents">
        <div class="label-info">
            <ng-content></ng-content>
            <p>{{label}}</p>
        </div>
    </div>

    <div class="main-content">
        <div>
            <p class="price-text">{{price}}€ <span>/mes</span>             <span class="iva_span">(más IVA)</span>
            </p>
            <p *ngIf="discount" class="nmb">Ahorra <span class="fwb c_p">{{totalSavings}}€</span> con este plan</p>
        </div>
        <div class="df fdc">
            <mat-form-field appearance="outline" class="small-form-field" [floatLabel]="'always'" [ngClass]="{'dn_i' : isLandingpage}">
                <mat-label>Código promocional</mat-label>
                <input #input matInput [formControl]="code">
            </mat-form-field>
            <button style="height: 40px;" mat-flat-button color="primary" (click)="subscribe(code.value)">{{isLandingpage ? 'Registrarse' : 'Obtener'}}</button>
            <mat-progress-bar class="obtain-loading" [mode]="'indeterminate'" *ngIf="loadingLink"></mat-progress-bar>
        </div>
    </div>
    <div style="height: 100%; margin-top: 20px;" *ngIf="!isLandingpage">
        <p class="nmb fss fsi">
            Al continuar aceptas nuestra <a (click)="openCancelationDialog()" class="link">política de cancelación y devolución</a>
        </p>
    </div>
</div>