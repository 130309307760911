<app-page-structure [masterClass]="'vehicle'" [autocompleteID]="'vehicle'" pageTitle="Vehículos"
    searchLabel="Buscar vehículo"
    createButtonClass="onboarding-create-vehicle" [addNewView]="v.createvehicle"
    listTitleText="Listado de vehículos"
    noDataCreateNew="Crear un nuevo vehículo" [data]="vehicles"
    [displayedHeader]=" ['Matrícula',   'Marca',    'Modelo', 'Propietario', 'Última reparación']"
    [displayedColumns]="['license',     'brand',    'model',  'client', 'last_reception' ]"
    [specialText]="     [undefined,      undefined,  undefined,  getOwner,  undefined]"
    [specialClass]="    [undefined,      undefined,  undefined,  undefined,  undefined]" [preIcon]="[preIcon]"
    [card]="card" [filters]="filters" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear vehículo' , icon : 'wheel_outline'}"
    (onclickRow)="params.go(v.vehicleDetails, $event.vehicle_id)"
    [quickFilter]="quickFilter">

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <div class="df aic">
                <button class="fw1" [matTooltip]="'Ir al vehículo'" mat-flat-button color="warn" [go]="v.vehicleDetails"
                    [param]="ps.selected!.vehicle_id">
                    <mat-icon>{{ps.selected!.icon}}</mat-icon>
                    {{ps.selected!.getName()}}
                    <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                </button>
                <button mat-icon-button [go]="v.createvehicle" [param]="ps.selected!.vehicle_id">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>


            <app-card-subtitle>Propietario</app-card-subtitle>
            <div *ngIf="ps.selected!.client; else nodata">
                <app-client-row [item]="ps!.selected!.client"></app-client-row>
            </div>
            <ng-template #nodata>
                <p class="c_t2">Sin propietario</p>
            </ng-template>
            <app-card-subtitle>Última reparación</app-card-subtitle>
            <div>
                <p *ngIf="ps.selected!.last_reception">
                    <mat-icon class="vam">calendar_month</mat-icon>
                    Día
                    <span>{{ps.selected!.last_reception.shortFormat()}}</span>
                </p>

                <p *ngIf="!ps.selected!.last_reception" class="c_t2">
                    Todavía no se han efectuado recepciones.
                </p>
            </div>
            <button style="margin-bottom: 20px;" mat-stroked-button [go]="v.createOr" [param]="ps.selected!.vehicle_id">
                <mat-icon>add</mat-icon>
                Abrir OR
            </button>
        </app-card>
    </ng-template>
</app-page-structure>