import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Order } from 'src/app/models/M_Order';
import { M_StoreHouseEntry } from 'src/app/models/M_StoreHouseEntry';
import { M_Product } from 'src/app/models/Products/M_Product';

@Component({
  selector: 'app-storehouse-entry-add-product',
  templateUrl: './storehouse-entry-add-product.component.html',
  styleUrls: ['./storehouse-entry-add-product.component.css']
})
export class StoreHouseEntryAddProductComponent implements OnInit {

  @Input({ required: true }) entry!: M_StoreHouseEntry;
  @Input({ required: true }) provider!: M_Contact | undefined;
  @Output() onLoad: EventEmitter<M_Product[]> = new EventEmitter()

  MAX_RESULTS = 5;
  allProducts: M_Product[] = []
  products: M_Product[] = [];
  filtered: M_Product[] = [];
  fc: FormControl<string | null> = new FormControl('');
  loaded = false;
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;

  constructor(private apiS: ApiService) {
    this.apiS.products().then(res => {
      this.allProducts = res;
      this.onLoad.emit(this.allProducts);
      this.setUpPvpTAndCost(this.allProducts);
      this.filterProductsByProvider(res);
      this.loaded = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['provider']) {
      this.filterProductsByProvider(this.allProducts);
    }
  }

  /** Match the pvp_t and the cost with the original product values */
  setUpPvpTAndCost(products: M_Product[]) {
    products.forEach(p => {
      p.orders.forEach(o => {
        o.products.forEach(order_product => {
          if (p.product_id == order_product.product_id) {
            order_product.pvp_t = p.pvp_t;
            order_product.buy_price = p.buy_price;
          }
        })
      })
    })
  }

  ngOnInit(): void {
    this.fc.valueChanges.subscribe(v => {
      if (v == null) { this.filtered = this.products; }
      else {
        this.filtered = [];
        this.products.forEach(p => {
          if (p.defaultSearchFilter(v)) {
            this.filtered.push(p);
          }
        })
      }
    })
  }

  filterProductsByProvider(products: M_Product[]) {
    if (this.provider == undefined) { return; }
    let finalProds: M_Product[] = [];
    products.forEach(original => {
      let p = new M_Product(original);
      p.orders.forEach(o => {
        if (o.provider.client_id != this.provider!.client_id) {
          p.orders.removeElement(o);
        }
      })
      if (p.providers.some(p => p.client_id == this.provider!.client_id)) {
        finalProds.push(p);
      }
    })
    this.fc.setValue('');
    this.products = finalProds;
    this.filtered = this.products;
  }


  onClickOrder(o: M_Order) {
    /** We create a new M_Order to lose the reference to the original object. 
     * This way, if we delete a product from the order, we prevent it from also being deleted in the original object. */
    let order = new M_Order(o);
    this.entry.addOrder(order);
  }

  onClickProduct(p: M_Product) {
    this.entry.addProduct(p, "products");
  }
}
