import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from 'src/app/models/M_Action';
import { M_Contact } from 'src/app/models/M_Contact';
import { ParamsService } from 'src/app/services/params.service';
import { PreviewService } from 'src/app/services/preview.service';
import { or_status_invoiced } from 'src/app/custom-classes/or_states';

@Component({
  selector: 'app-invoice-flota-or-dialog',
  templateUrl: './invoice-flota-or-dialog.component.html',
  styleUrls: ['./invoice-flota-or-dialog.component.css']
})
export class InvoiceFlotaOrDialogComponent {

  loaded = false;
  ors: M_Action[] = [];

  constructor(public dialogRef: MatDialogRef<InvoiceFlotaOrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: M_Contact, private apiS: ApiService, private previewS: PreviewService, private paramS: ParamsService) { 
    this.apiS.getClientClosedGroups(this.data).then(res => {
      this.ors = res;
      this.loaded = true;
    })
  }

  invoiceAll() {
    if (this.data.orPendingArray.length) {
      this.apiS.invoiceFlotas(this.data).then(res => {
        this.previewS.showPreview("FL", res.token);
        this.dialogRef.close();
        this.data.orPendingArray.forEach(i => {
          i.status = or_status_invoiced;
        })
      })
    }
    else {
      this.dialogRef.close();
    }
  }

  goOr(action: M_Action) {
    this.paramS.go(ViewPath.editOr, action.id);
    this.dialogRef.close();
  }

  pendingGroupsTotal(or : M_Action){
    return or.closedTotal - or.totalInternosClosed
  }

}
