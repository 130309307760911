import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { DayFilter, EnumFilter, Filter, FilterOption, SliderFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Company } from 'src/app/models/M_Company';
import { M_PayCometInvoice } from 'src/app/models/M_PayCometInvoice';

@Component({
  selector: 'app-pay-comet-invoices',
  templateUrl: './pay-comet-invoices.component.html',
  styleUrls: ['./pay-comet-invoices.component.css']
})
export class PayCometInvoicesComponent {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_PayCometInvoice>;
  f = filter;
  company_id: any;

  priceFilter = new SliderFilter("Precio", 200);
  filters: Filter[] = [
    new DayFilter("Fecha de creación"),
    new EnumFilter("Estado", new FilterOption("Pendiente"), new FilterOption("Pagada")),
    this.priceFilter
  ]

  constructor(private apiS: ApiService) {
    this.initComponent();
  }

  async initComponent() {
    try {
      const res = await this.apiS.company();
      this.company_id = res.id;
      const paymentRes = await this.apiS.paymentByCompany(this.company_id);
      this.ps.initTable(paymentRes);
      this.priceFilter.changeMax(Math.max(...paymentRes.map(i => i.amount || 0)));
    } catch (error) {
      console.error("Error al inicializar el componente:", error);
    }
  }

  async requestPDF(reference: string) {
    let company: M_Company = await this.apiS.company()
    if (company !== undefined && reference !== undefined) {
        this.apiS.requestPDF(company, reference);
    } else {
        console.error('company_id or reference is undefined');
    }  
  }
  

  previewPDF(cf: CustomFile){
    window.open(cf.src, '_blank')

  }
  havePDF(invoice: M_PayCometInvoice) {
    return invoice.file ? "Descargar" : "Pendiente"
  }

  payedName(invoice: M_PayCometInvoice) {
    return invoice.payed ? "Pagada" : "Pendiente"
  }

  payedClass(invoice: M_PayCometInvoice) {
    return invoice.payed ? "payed-paycomet-invoice" : "pending-paycomet-invoice"
  }


}
