<div class="transition-appear maxw" *ngIf="vehicle; else noData">
    <div class="df fdc aic jcc">

        <div [ngClass]="{'cp' : !vehicle.deleted && userS.userIsAdminOrAdviser}" (click)="goVehicle()"
            [matTooltip]="vehicle.deleted || userS.userIsMechanic ? '' : 'Ir a detalles avanzados del vehículo'">
            <div class="df">
                <app-vehicle-lord-icon [small]="small" [vehicle]="vehicle"></app-vehicle-lord-icon>
            </div>
        </div>

        <p *ngIf="vehicle.deleted" class="c_r fw600">¡Vehículo eliminado!</p>

        <app-card-subtitle class="title tac" [first]="true" [noMarginBottom]="true">
            {{vehicle.getName()}}</app-card-subtitle>
        <p class="fsm nmb fw300" style="letter-spacing: 2px" *ngIf="vehicle.brandAndModel">
            {{vehicle.license?.toUpperCase()}}</p>

        <button color="blue" mat-button [matMenuTriggerFor]="menu">
            <span>Más información</span></button>

        <mat-menu #menu="matMenu" class="card-row-menu">
            <app-card-row-content [data]="[ 
                ['Kms', vehicle.km],
                ['Serie', vehicle.serie ? vehicle.serie : undefined],
                ['Chasis', vehicle.chassis],
                ['CC', vehicle.cc],
                ['Tipo', vehicle.engineType],
            ]">
            </app-card-row-content>
        </mat-menu>
    </div>
</div>

<ng-template #noData>
    <div class="df fww fdr jcc aic">
        <lord-icon class="f-gray" src="https://cdn.lordicon.com/nrmtiuty.json" trigger="hover"
            [attr.colors]="'primary:#121331,secondary:#3a3347,tertiary:#f55142,quaternary:#ebe6ef'"
            [ngClass]="small ? 'lord-medium' : 'lord-big'">
        </lord-icon>
        <p class="c_t2 tac nmb" *ngIf="!classSearcherHasSomethingSelected; else noClient">
            Selecciona un vehículo para ver más detalles
        </p>
        <ng-template #noClient>
            <p class="c_t2 tac nmb">Sin vehículo</p>
        </ng-template>
    </div>
</ng-template>