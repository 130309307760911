<app-page-structure [masterClass]="'invoice'" pageTitle="Ventas" [autocompleteID]="'bills'"
    [createButtonClass]="'onboarding-create-invoice ' + (isDraft ? 'draft' : '')" searchLabel="Buscar facturas"
    [addNewView]="v.createInvoice" listTitleText="Listado de facturas" noDataCreateNew="Crear una nueva factura"
    [data]="[]" [createButtonLoading]="isCircleLoading"
    [displayedHeader]=" ['Número',              'Fecha facturación',   'Cliente',     'Total',          'Tipo factura',   'Estado' ]"
    [displayedColumns]="[ 'company_scope_id',   'created_at',    'client',      'total',          'invoice_type_or_type',   'state']"
    [preIcon]="[invoiceIcon, undefined]" [circleTooltip]="isDraft ? 'Borrador en curso' : ''"
    [specialText]="     [ undefined,            undefined,    getClientName,  getTotal,         undefined,          undefined,   undefined]"
    [specialClass]="    [ undefined,            undefined,      undefined,      undefined,       undefined,          undefined,   undefined]"
    [cellZize]="        ['big',    undefined,   undefined,   'small',     undefined, undefined]" [card]="card"
    [rightMenu]="rightMenu" [filters]="filtersToShow" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()" (onSelect)="this.resetPaymentForm()"
    [createButton]="{text : 'Crear factura' , icon : 'receipt_long'}"
    [quickFilter]="quickFilter">

    <ng-template #rightMenu>
        <div *ngIf="ps">
            <button *ngIf="ps.loaded_" mat-menu-item class="onboarding-checkout-control"
                (click)="openDialog()"><mat-icon>point_of_sale</mat-icon>
                Control de caja
            </button>
            <app-skeleton *ngIf="!ps.loaded_" [width]="176" [height]="36"></app-skeleton>
            <div style="margin-left: auto;"
                [matTooltip]="disableMax(ps.dataOnScreen) ? 'Máximo ' + MAX_DOWNLOAD_NUMBER + ' facturas' : disableMin(ps.dataOnScreen) && ps.loaded_ ? 'Mínimo una factura' : ''">
                <button [disabled]="disabledDownload(ps)" *ngIf="ps?.loaded_" mat-menu-item color="secondary"
                    (click)="exportInvoices(ps.dataOnScreen)">
                    <mat-icon>library_books</mat-icon>
                    Exportar a Excel
                </button>
                <button *ngIf="true && ps?.loaded_" mat-menu-item [disabled]="disabledDownload(ps)"
                    (click)="donwloadInvoices(ps.dataOnScreen)">
                    <mat-icon>download</mat-icon>
                    Descargar PDF ({{ps.dataOnScreen.length}})
                </button>
                <app-skeleton *ngIf="!ps.loaded_" [width]="176" [height]="36"></app-skeleton>
            </div>
        </div>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <app-card-title *ngIf="!ps.selected!.isAbono">
                {{ps.selected!.isInternoInvoice ? 'Documento' :'Factura'}} Nº {{ps.selected!.company_scope_id}}
            </app-card-title>

            <app-card-title *ngIf="ps.selected!.isAbono">
                {{ps.selected!.isInternoInvoice ? 'Documento' :'Abono'}} Nº{{ps.selected!.company_scope_id}}
            </app-card-title>

            <div class="df" *ngIf="ps.selected!.aboned">
                <button mat-stroked-button [disabled]="true" class="w100" style="margin-bottom: 10px;">
                    <mat-icon class="c_p">check</mat-icon>
                    Abono generado
                </button>
            </div>
            <button mat-stroked-button (click)="previsualize(ps.selected!)">
                <mat-icon>find_in_page</mat-icon>
                Vista previa
            </button>
            <div *ngIf="!ps.selected!.aboned && !ps.selected!.isAbono" class="w100">
                <button mat-stroked-button [go]="v.abono" [param]="{invoice : ps.selected!.id}" class="w100"
                    [disabled]=" ps.selected!.total ==0" style="margin-top: 10px;">
                    <mat-icon>currency_exchange</mat-icon>
                    Generar abono
                </button>
            </div>
            <p *ngIf="ps.selected!.abono_of">
                Abono generado a partir de la factura
                <span (click)="previsualize(ps.selected!.abono_of)" class="c_p fw500 underline_hover">
                    Nº{{ps.selected!.id}}
                </span>
            </p>
            <!-- Payed invoicde-->
            <ng-template #payedInvoice>
                <button mat-stroked-button [disabled]="true">
                    <mat-icon class="c_p">check</mat-icon>
                    {{ps.selected!.isInternoInvoice? 'Documento tramitado' : 'Factura cobrada'}}
                </button>
            </ng-template>

            <p class="mb10"></p>

            <app-card-subtitle *ngIf="!ps.selected!.state.isPayed" class="tac" [first]="true">
                Pendiente <span class="liquidation" money [val]="ps.selected!.pending_import"></span>
            </app-card-subtitle>


            <!-- Not payed invoicde-->
            <ng-container *ngIf="!ps.selected!.state.isPayed; else payedInvoice">
                <button mat-flat-button (click)="openPayCheckControl()" color="warn" style="margin-bottom: 10px;">
                    <mat-icon>price_check</mat-icon>
                    <div>
                        {{ ps.selected!.type == 5 ? 'Pagar' : 'Cobrar' }}
                    </div>
                </button>
            </ng-container>



            <!-- Cobros section-->
            <app-card-subtitle *ngIf="ps.selected!.last_movement?.length">Cobros</app-card-subtitle>
            <app-invoice-movements [movements]="ps.selected!.last_movement" [isTotalPay]="ps.selected!.isTotalPay"
                (onDeleteMovement)="deleteLastPayment($event)" [showLastCircle]="false">
            </app-invoice-movements>
            <app-invoice-payement-form *ngIf="ps.selected!.type !=5" [invoice]="ps.selected!"
                [resetImport]="importFormReset"
                (onDeleteTypePayment)="deleteTypePayment($event)"></app-invoice-payement-form>


            <div class="button-type-payment">
            </div>

            <p *ngIf="ps.selected && ps.selected.client  && !ps.selected.isInternoInvoice">
                Cliente :
                <app-client-row [item]="ps.selected.client"></app-client-row>
            </p>

            <app-layout-send-email
                *ngIf="ps.selected && ps.selected.client && !ps.selected.client.deleted && ps.selected.type != 5 && !ps.selected.isInternoInvoice"
                [temaplte]="'invoice'" [disabled]="ps.selected.client.email == undefined"
                [endpoint]="reSendInvoice.bind(this)"></app-layout-send-email>

            <app-layout-send-email
                *ngIf="ps.selected && ps.selected.client && !ps.selected.client.deleted && ps.selected.type == 5 && !ps.selected.isInternoInvoice"
                [temaplte]="'abono'" [disabled]="ps.selected.client.email == undefined"
                [endpoint]="reSendInvoice.bind(this)"></app-layout-send-email>

        </app-card>
    </ng-template>
</app-page-structure>