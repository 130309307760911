type status_names = "Pendiente" | "Finalizado"

const types : [number , status_names][] = [
    [0, "Pendiente"],
    [1, "Finalizado"], 
]

export class order_status{
    num : number = -1;
    name : status_names = "Pendiente";
    constructor(tipo : status_names | number) {
        types.map(t => {
            if (typeof tipo == "number") {
                if (tipo != 0 && tipo != 1 && tipo != 2){
                    this.num = 0;
                    this.name = "Pendiente";
                }
                else if (t[0] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
            else{
                if (t[1] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
        })
    }

    get pending(){
        return this.num == 0;
    }

    get finished() {
        return this.num == 1;
    }
}

export const order_status_pending = new order_status("Pendiente");
export const order_status_finished = new order_status("Finalizado");
