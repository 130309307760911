export enum MovTypeEnum {
    /** Salida (OR) */
    OR = 1,
    /** Salida (Fac.Rec) */
    FR = 2,
    /** Salida (Albaran Vta.) */
    AC = 3,
    /** Salida Manual */
    SM = 4,
    /** Entrada (OR) */
    EOR = 5,
    /** Entrada Almacen  */
    EA = 6,
    /** Entrada Manual */
    EM = 7,
    /** Entrada (Abono) */
    AB = 8,
    /** Salida (Dev. Albaran Prov) */
    DA = 9,
    /** Reserva Manual */
    RM = 10,
    /** Pedido a Proveedor */
    PP = 11,
    /** Falta Pedida */
    FP = 12,
    /** Reserva Presupuesto */
    RP = 13,
    /** Entrada Factura Recambio */
    EFC = 14,
    /** Factura de Venta */
    FV = 15,
    /** Reserva Albaran Venta */
    RAV = 16,
    /** Entrada Albaran Venta */
    EAV = 17,
    /** Falta Manual */
    FM = 18,
    /** Movimiento Interno */
    TM = 19
}












