/*
type hour = 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|16|19|20|21|22|23
type minute = 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|
               21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40|
               41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59
export class Test {
    constructor (private h : hour, private m : minute){}
}*/

export class CustomTime {
    private hours: string;
    private minutes: string;
    private seconds: string;

    constructor(fullTime: string); //10:05
    constructor(hours?: string, minutes?: string) //10, 5
    constructor(hours?: string, minutes?: string, seconds?: string) //10, 5, 00
    constructor(hours?: string, minutes?: string, seconds?: string) {
        var d = new Date();
        /** Full time */
        if (hours && !minutes && !seconds) {
            try {
                this.hours = hours.split(":")[0];
                this.minutes = hours.split(":")[1];
            }
            catch (e: any) {
                this.hours = d.getHours().toString();
                this.minutes = d.getMinutes().toString();
                this.seconds = d.getSeconds().toString();
            }
        }
        else {
            this.hours = hours ? hours : d.getHours().toString();
            this.minutes = minutes ? minutes : d.getMinutes().toString();;
        }

        this.seconds = seconds ? seconds : d.getSeconds().toString();

        //Keep a leading 0
        this.hours = ('0' + this.hours).slice(-2);
        this.minutes = ('0' + this.minutes).slice(-2);
        this.seconds = ('0' + this.seconds).slice(-2);
    }

    getHours() {
        return Number(this.hours);
    }

    getMinutes() {
        return Number(this.minutes);
    }

    getSeconds() {
        return Number(this.seconds);
    }

    getText(): string {
        return this.hours + ":" + this.minutes;
    }

    getValue(): number {
        return this.getMinutes() + (this.getHours() * 60);
    }
}