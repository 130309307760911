import { Component, Input, TemplateRef } from '@angular/core';

/** The core card component */

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  /** Card padding ? */
  @Input() nopadding: boolean = false;
  /** If undefined or true the card is loaded, else card shows loading skeleton */
  @Input() contentLoaded?: boolean;
  /** Show a padlock on top of the card. If locked, the user canno't interact with the card. See also the 'lockedText' property*/
  @Input() locked = false;
  /** Text to show on hover the card if the card is locked */
  @Input() lockedText = ""
  /** Show a lord-icon with a title of 'Sin datos' */
  @Input() noData?: boolean;
  /** Remove all the card styles and classes */
  @Input() noStyle = false;
  /** Set the component on the top right of the card. Typically used the "more_vert" icon */
  @Input() cornerMatMenu?: TemplateRef<any>;
  /** Not implemented yet */
  @Input() darkmode = false;
  /** Custom Skeleton loader  */
  @Input() customSkeleton?: TemplateRef<any>;

  constructor() { }

  showNoData() {
    return (this.contentLoaded == undefined || this.contentLoaded == true) && this.noData
  }
}
