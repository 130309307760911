import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { M_BaseUser } from '../../models/M_BaseUser';
import { MasterStorageService } from './master-storage-service';
import { IClassSearcher } from 'src/app/interfaces/IClassSearcher';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { projectConfiguration } from 'src/app/app.module';

export interface ClassSearcherForm {
  value: FormControl<IClassSearcher<any> | undefined | null>;
  hidden: FormControl<IClassSearcher<any> | undefined | null>;
}

@Component({
  selector: 'app-class-searcher',
  templateUrl: './class-searcher.component.html',
  styleUrls: ['./class-searcher.component.css']
})
export class ClassSearcherComponent<T extends IClassSearcher<T>> implements OnInit {

  @Input() masterClass!: T;
  @Input() width100 = true;
  @Input() selectedText: string | undefined;
  /** Search bar placeholder */
  @Input() searchPlaceHolder: string = "Buscar";
  /** Placeholder of nothing selected */
  @Input() noSelectionPlaceHolder: string = "Ningún item selecionado";
  @Input() required: boolean = true;
  /**Placeholder, icon | placeholder */
  @Input() specialRow?: string | undefined;
  /** The option is disabled if the conditions on this funcion returns true */
  @Input() disableIf?: (args: any) => boolean;
  @Input() selectedOnLoad: number | undefined;
  @Input() canRemove = true;
  @Input() hint: string | { hint: string, class: string } | undefined;
  @Input() showLeftNumber = true;
  @Input() separator: "-" | "|" | undefined;
  @Input() form_?: UntypedFormGroup;
  @Input() formCname?: string;

  @Input() formFieldTagClass?: string;

  /**Event emitters*/
  @Output() onSpecialRow: EventEmitter<string> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onInit: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() onLoad: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  core = projectConfiguration;
  allData: T[] = []
  options: T[] = [];
  selected: T | undefined;
  loaded = false;
  triggerInit = false;
  form: FormGroup<ClassSearcherForm>;

  constructor(private ms: MasterStorageService, public companyS: CompanyService) {

    this.form = new FormGroup<ClassSearcherForm>({
      value: new FormControl<IClassSearcher<T> | undefined | null>(undefined, this.customValidator()),
      hidden: new FormControl<IClassSearcher<T> | undefined | null>(undefined), // No need for validators if not provided
    })

    /** Form Test */
    this.form.get('value')?.valueChanges.subscribe(v => {
      if (this.form_ && this.formCname) {
        this.form_.get(this.formCname)!.patchValue(v?.cs_id)
      }
    });

  }

  /** Disable form */
  setDisabled() {
    this.form.get('value')!.disable()
  }

  ngOnInit(): void {
    if (this.required) {
      this.form.addValidators(Validators.required);
    }

    this.getData().then(res => {
      this.initDataAndOptions(res);
      this.onLoad.emit();
    })
  }

  emitSpecialRow(v: string) {
    this.onSpecialRow.emit(v);
  }

  initDataAndOptions(data: T[]) {
    this.allData = data;
    this.options = data;
    this.loaded = true;
    let initValue = this.getInitialValue();
    if (initValue) {
      this.setMasterById(initValue, this.triggerInit);
      this.selectedOnLoad = undefined;
    }
    else if (this.input && this.input.nativeElement.value) {
      this.refresh(this.input.nativeElement.value)
    }
    this.sortDisableds();
  }

  getInitialValue() {
    let realFormValue: number | undefined = undefined
    if (this.form_ && this.formCname) {
      let aux = this.form_.get(this.formCname)?.value
      if (typeof aux == "number") {
        realFormValue = aux;
      }
    }
    return realFormValue || this.selectedOnLoad;
  }

  /** Petición al endpoint que se ha pasado por parámetro */
  getData() {
    return new Promise<any[]>(resolve => {
      this.ms.getMaster(this.masterClass).then(res => {
        resolve(res);
      })
    })
  }

  /** Set a selected object. Refresh the text */
  setMasterObject(obj: T) {
    //console.log("Class Searcher --> ", "Setting Master Object:", obj)
    this.selected = obj;
    this.form.patchValue({
      "value": obj
    })
    this.setDisabled();
    if (this.triggerInit) {
      this.onInit.emit(obj);
    }
  }

  /** Set a master object by ID */
  setMasterById(id: number, initvalue: boolean = false) {
    //console.log("Class Searcher --> ", "Master by id [", id, "]")
    this.triggerInit = initvalue;
    if (this.loaded) {
      this.allData.forEach(option => {
        if (option.cs_id == id) {
          this.setMasterObject(option);
        }
      })
    }
    else {
      this.selectedOnLoad = id;
    }
  }

  /** Disable a option according to 'disableIf' parameter funcion */
  getDisableIF(obj: IClassSearcher<T>) {
    if (this.disableIf) {
      return this.disableIf(obj)
    }
    return false;
  }

  focus() {
    this.input.nativeElement.focus();
  }

  onClickOption(input: HTMLInputElement, option: any) {
    input.blur();
    if (!this.getDisableIF(option)) { /** Emit event if the option is not disabled */
      if (this.masterClass.onlyCopies) {
        /** If not this way, problems with quantity */
        var emitCopyOf = this.masterClass.createNew(option)
        this.onSelect.emit(emitCopyOf)
      }
      else {
        this.onSelect.emit(option)
      }
    }
    this.sortDisableds();
  }

  sortDisableds() {
    this.options.sort((a, b) => {
      let adisabled = this.getDisableIF(a);
      let bdisabled = this.getDisableIF(b);
      return (adisabled === bdisabled) ? 0 : adisabled ? 1 : -1;
    })
  }

  onFocusOut(inputval: string) {
    /*if (!inputval && this.selected) {
      this.removeSelectedValue();
    }*/
    //else if (this.selected) {

    if (this.selected) {
      this.setMasterObject(this.selected)
    }
  }

  /** The displayed text on the input value */
  getInputText(val: T) {
    this.selected = val ? val : undefined;
    return val == undefined ? "" : val.getInputText();
  }


  refresh(val?: string) {
    this.options = []
    if (val) {
      for (let i = 0; i < this.allData.length; i++) {
        let currentObj = this.allData[i];
        if (currentObj.defaultSearchFilter(val)) {
          this.options.push(currentObj);
        }
      }
    }
    else {
      this.options = this.allData;
    }
  }

  /** Remove the selected value */
  removeSelectedValue() {
    this.onRemove.emit(undefined);
    this.selected = undefined;
    this.form.patchValue({
      'value': undefined
    })
    this.refresh();
    this.form.enable();
  }

  /** Input validator */
  customValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasError = this.required ? !this.selected : (control.value != undefined && control.value != "" && !this.selected);
      return hasError ? { required: { value: control.value } } : null;
    };
  }

  /** Special row placeholder */
  getSpecialRowPlaceHolder() {
    if (this.specialRow != undefined) {
      if (typeof this.specialRow == "string") {
        return this.specialRow;
      }
      else {
        return this.specialRow[0];
      }
    }
    return "";
  }

  isUser(v: any): v is M_BaseUser {
    return v instanceof M_BaseUser;
  }

  addAndSelectNewOption(v: T) {
    this.allData.push(v);
    this.setMasterObject(v);
    this.onSelect.emit(v);
  }

  get getHint() {
    return this.hint ? typeof this.hint == "string" ? this.hint : this.hint.hint : "";
  }

  get getHintClass() {
    if (this.hint && typeof this.hint != "string") { return this.hint.class };
    return "";
  }

}