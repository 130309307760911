<div class="df aic" *ngIf="!circularStatus; else circular">
  <app-card-subtitle *ngIf="showText" class="subtitleNoBottom" [first]="true">Estado</app-card-subtitle>
  <div>
    <mat-chip-set>
      <mat-chip [class]="'order_status order_status' + status.num">
        <span>{{taglabel}}</span>
      </mat-chip>
    </mat-chip-set>
  </div>
</div>

<ng-template #circular>
  <div [class]=" 'df jcfs aic fss ' + ' order_circular' + status.num">
    <mat-icon matChipAvatar>circle</mat-icon> <span *ngIf="showText">{{taglabel}}</span>
  </div>
</ng-template>