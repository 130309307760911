<div class="fault-div" [ngClass]="{'slash' : slash, 'opacity' : opacity, 'large' : large, 'clickable' : goFault}" (click)="goByFault()">
    <p class="nmb">
        <mat-icon class="vam" style="margin-right: 0px;"  smartIcon>{{fault.product?.icon}}</mat-icon>
        <span class="ml5">{{fault.product?.name}}</span>
        <span class="fault-units c_r ml5"> ({{fault.quantity}} <span plural [val]="[fault.quantity, 'unidad', 'unidades']"></span>)</span>
    </p>
    <p class="c_t2 fss nmb">
        <span class="fault-document">{{fault.document}}</span>
        <span class="fault-separator ml5 mr5">|</span>
        <span class="fault-date">{{fault.created_at.numMonthFormat() + ' - ' + fault.created_at.hourFormat()}}</span>
    </p>
</div>