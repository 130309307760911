import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { environment } from 'src/environments/environment';
import { projectConfiguration } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private project_name: string;
  private key_prefix: string
  env: { local: boolean; production: boolean; preproduction: boolean; dev: boolean; apiUrl: string; analyticsId: string; };

  constructor(private logger: LoggerService) {
    this.env = environment;
    this.project_name = projectConfiguration.projectName;
    this.key_prefix = this.formatPrefix();
  }

  /** Format a prefix for the keys. Ex : pre_weberp_ */
  private formatPrefix(): string {
    var envname = this.env.local ? "local" : this.env.dev ? "dev" : this.env.preproduction ? "pre" : this.env.production ? "pro" : ""
    return envname + "_" + this.project_name;
  }

  /** Format the key to store. Ex : pre_weberp_username */
  private formatKey(key: string) {
    return this.key_prefix + "_" + key;
  }

  /** Format a value depending of his type */
  private formatValue(val: string | number | boolean) {
    return typeof val == "number" ? val.toString() : typeof val == "boolean" ? JSON.stringify(val) : val;
  }

  /** Save a key-value on localstorage */
  save(key: string, value: string | number | boolean) {
    window.localStorage.setItem(this.formatKey(key), this.formatValue(value));
    this.logger.log("Save on localStorage --> '" + this.formatKey(key) + "' : " + this.formatValue(value));
  }

  /** Get a value from localStorage. If the value is a boolean, use 'getBoolean()'.  */
  get(key: string): string | null {
    var val = window.localStorage.getItem(this.formatKey(key));
    this.logger.log("Get from localStorage --> '" + this.formatKey(key) + "' : " + val);
    return val;
  }

  /** Remove a value from localstorage */
  remove(...key: string[]) {
    key.forEach(k => {
      this.logger.log("Removed from localStorage --> '" + this.formatKey(k));
      window.localStorage.removeItem(this.formatKey(k));
    })
  }

  /** Get a localStorage variable and returns it as a boolean or null */
  getBoolean(key: string): boolean | null {
    var storedValue = this.get(key);
    if (storedValue == null) { return null }
    var value = JSON.parse(storedValue);
    this.logger.log("Get from localStorage --> '" + this.formatKey(key) + "' : " + value);
    if (typeof value == "boolean") { return value; }
    return value == "true" || value == "1" ? true : value == "false" || value == "0" ? false : null;
  }

  /** Clear all the storage */
  clear() {
    this.logger.log("Cleared local localStorage");
    if (this.env.local) {
      this.removeProjectSotrage();
    }
    else {
      window.localStorage.clear();
    }
  }

  /** Remove only the current project storage.
   * Util with localhost to avoid remove other projects storage */
  private removeProjectSotrage() {
    var kv = this.getAllStorage();
    kv.forEach(keyvalue => {
      if (keyvalue[0]?.includes(this.project_name)){
        window.localStorage.removeItem(keyvalue[0])
      }
    })
  }

  /** Get all the storage with key-value */
  getAllStorage() {
    var archive = [],
      keys = Object.keys(localStorage),
      i = 0, key;

    for (; key = keys[i]; i++) {
      archive.push([key, localStorage.getItem(key)]);
    }

    return archive;
  }

}