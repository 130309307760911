type status_names = "Abierta" | "Cerrada" | "Facturada" | "Finalizada";

const types : [number , status_names][] = [
    [0, "Abierta"],
    [1, "Cerrada"], 
    [2, "Facturada"], 
    [3, "Finalizada"]
]

export class or_status{
    num : number = -1;
    name : status_names = "Abierta";
    constructor(tipo : status_names | number) {
        types.map(t => {
            if (typeof tipo == "number") {
                if (tipo != 0 && tipo != 1 && tipo != 2){
                    this.num = 0;
                    this.name = "Abierta";
                }
                else if (t[0] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
            else{
                if (t[1] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
        })
    }

    isBigger(os : or_status) {
        return this.num > os.num;
    }

    getBudgetName(){
        return this.num == 0 ? "Abierto" : this.num == 1? "Cerrado" : "Facturado"
    }

    get open(){
        return this.num == 0;
    }

    get pending() {
        return this.num == 1;
    }

    get invoiced(){
        return this.num == 2;
    }

    get sinicloudFinished(){
        return this.num == 3;
    }
}

export const or_status_open = new or_status("Abierta");
export const or_status_close = new or_status("Cerrada");
export const or_status_invoiced = new or_status("Facturada");
export const or_status_SinicloudFinished = new or_status("Finalizada");
