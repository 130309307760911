import { ChangeDetectorRef, Component, Inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { PreviewService } from 'src/app/services/preview.service';
import { GroupCardInvoiceGroupComponent } from './group-card-invoice-group/group-card-invoice-group.component';
import { MASTER_CLIENT_MINIFIY } from 'src/app/constants/masters';
import { ShowAdvanceClientComponent } from 'src/app/components/show-advance-client/show-advance-client.component';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { InvoicedSiniestroDialogComponent } from './invoiced-siniestro-dialog/invoiced-siniestro-dialog.component';
import { M_Action } from 'src/app/models/M_Action';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { CanInvoiceService } from 'src/app/services/can-invoice.service';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { or_types } from 'src/app/custom-classes/or_types';
import { RouterService } from 'src/app/services/router.service';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { endpoints } from 'src/app/constants/Enpoints';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.css']
})
export class InvoiceDialogComponent {
  @ViewChildren(GroupCardInvoiceGroupComponent) groupCards?: QueryList<GroupCardInvoiceGroupComponent>;
  @ViewChildren(ShowAdvanceClientComponent) lsmv?: QueryList<ShowAdvanceClientComponent>;
  @ViewChild(ClassSearcherComponent) clientCompoenent!: ClassSearcherComponent<M_Contact>;
  v = ViewPath;
  e = endpoints;
  client = MASTER_CLIENT_MINIFIY;
  comments: UntypedFormControl;
  date: FormControl<Date | null>;
  franchise: FormControl<number | null> = new FormControl();
  expedient: FormControl<string | null>
  groups: M_GroupTask[] | M_GroupTask | undefined;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<InvoiceDialogComponent>, private companyS: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: { groups: M_GroupTask[], preSelected: M_GroupTask | undefined, action: M_Action, comp : IProductLineTableComponent}, public routerS: RouterService,
    private apiS: ApiService, public previewS: PreviewService, private chdRef: ChangeDetectorRef, public canInvoiceS : CanInvoiceService) {
    this.comments = new UntypedFormControl('');
    this.date = new FormControl<Date | null>(new Date());
    this.expedient = new FormControl(null, this.requiredExpedient ? Validators.required : []);
    this.date.addValidators(Validators.required);
    this.franchise.addValidators([this.ValidatorFranchise()]);
  }

  get requiredExpedient() {
    return this.data && this.data.action.defaultInvoiceClient?.cli_is_invoice_e;
  }

  ValidatorFranchise(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!this.isSomeOrAllSiniestro) {
        return null;
      }
      else if (this.franchiseIsInvalid) {
        return { franchise: true }
      }
      return null
    }
  }

  get screenCurrentClient() {
    if (this.isSomeOrAllInterno) { return undefined; }
    return this.clientCompoenent?.selected ? this.clientCompoenent.selected : this.data.action.defaultInvoiceClient;
  }

  selectAll() {
    this.groupCards?.forEach(c => {
      c.switchSelected
    })
  }

  get isDataOk() {

    if (this.someInternoSelected) {
      return true;
    }

    if (this.clientCompoenent != undefined) {
      if (this.isSomeOrAllSiniestro) {
        return this.clientCompoenent.selected != undefined && this.franchise.valid && this.date.valid;
      }
      return this.selectedGroups != 0 && this.clientCompoenent.selected != undefined && this.date.valid;
    }
    else return false;
  }
  get selectLastMovement() {

    let count = 0;
    this.lsmv?.map(c => { if (c.selected) { count++ } })

    return count;
  }
  get selectedGroups() {

    let count = 0;
    this.groupCards?.map(c => { if (c.selected) { count++ } })
    return count;
  }

  isPreSelectedGroup(g: M_GroupTask) {

    if (this.data.preSelected != undefined) {
      return this.data.preSelected.id == g.id;
    }
    else {
      return false;
    }
  }

  get getSelectedGroups(): M_GroupTask[] | undefined {
    return this.groupCards?.filter(c => { return c.selected == true }).map(group => group.g);
  }
  get getLastMovement(): M_LastMovements[] | undefined {

    return this.lsmv?.filter(c => { return c.selected == true }).map(last_mv => last_mv.dataMostrada!);
  }

  /** Some selected group is 'Siniestro' or all groups on the dialog are 'Siniestro' */
  get isSomeOrAllSiniestro() {
    return this.getSelectedGroups?.some(g => g.type.siniestro) ||
      this.groupCards?.toArray().every(g => g.g.type.siniestro);
  }

  /** Some selected group is 'Cargo Interno' or all groups on the dialog are 'Cargo interno' */
  get isSomeOrAllInterno() {
    return this.someInternoSelected ||
      this.groupCards?.toArray().every(g => g.g.type.cargo);
  }

  /** The selected/s groups are interno */
  get someInternoSelected() {
    return this.getSelectedGroups?.some(g => g.type.cargo);
  }

  get franchiseIsInvalid() {
    /* To do : Refactor totals */
    let siniestros = this.getSelectedGroups?.filter(g => g.type.siniestro);
    if (siniestros && this.franchise.value) {
      let client = this.clientCompoenent && this.clientCompoenent.selected ? this.clientCompoenent.selected : this.data.action.defaultInvoiceClient;
      let total = siniestros.reduce((sum, current) => sum + current.getTotalBreakdown(client).total, 0);
      return this.franchise.value > total;

    } else {
      return false
    }

  }

  /** Array of non repeated or_types */
  getTypes() {
    var all: or_types[] = [];
    if (this.groupCards) {
      this.groupCards.forEach(c => {
        if (all.filter(g => g.num == c.g.type.num).length == 0) {
          all.push(c.g.type)
        }
      })
    }
    return all;
  }
  refreshFranchise() {
    this.franchise.updateValueAndValidity();
  }
  selectOnlyTypeOf(t: or_types) {
    this.groupCards?.forEach(c => {
      c.unSelect();
    })

    this.chdRef.detectChanges();

    this.groupCards?.forEach(c => {
      if (c.g.type.num == t.num) {
        c.select();
      }
    })

  }

  isDisabled(groupComponent: GroupCardInvoiceGroupComponent) {
    if (this.groupCards && this.groupCards.length > 1) {
      let filtered: GroupCardInvoiceGroupComponent[] | undefined = this.groupCards?.filter(c => { return c.selected == true });
      if (filtered && filtered.length >= 1) {
        return filtered[0]!.g.type.num != groupComponent.g.type.num;
      }
      else {
        return false;
      }
    }
    return false;
  }


  invoiceGroups() {
    let last_movement = this.getLastMovement;
    let groups = this.getSelectedGroups;
    let expedient = this.expedient
    if (groups) {
      this.closeDialogWithGroups(groups, this.clientCompoenent.selected!, last_movement, expedient.value);
    }
  }

  goCreateClient() {
    this.dialogRef.close(undefined);
    this.routerS.goTo(this.v.createContact);
  }

  closeDialogWithGroups(groups: M_GroupTask[], user: M_Contact | undefined, last_movement?: M_LastMovements[], expedient?: string | null) {
    let ids = groups.map(g => g.id);
    let lastMovementToSend = last_movement || [];
    this.isSomeOrAllSiniestro ? this.franchise.value : undefined
    let franchiseToSend = undefined;
    expedient = expedient

    if (this.isSomeOrAllSiniestro && this.franchise.value) {
      franchiseToSend = this.franchise.value;
    }

    if (!this.companyS.companyMissingInfo) {

      let date: Date = new Date();
      if (this.isSomeOrAllInterno) { user = undefined; }
      else { date = this.date.value ? new Date(this.date.value) : new Date(); }

      this.apiS.invoiceOR(ids, user, this.comments.value, lastMovementToSend, date, franchiseToSend, expedient).then(res => {
        if (res && res.length) {
          if (res.length == 1) {
            groups.map(g => { g.token = res[0].token; g.invoice_name = res[0].invoice_name })
            groups.map(g => g.client = this.screenCurrentClient)
            this.dialogRef.close(groups);
            this.previewS.showPreview("OR", res[0].token, undefined, res[0].invoice_id, undefined, undefined, undefined, !this.isSomeOrAllInterno);
          }
          //If more than one invoice arrives, it means that a 'siniestro' group with franchise was invoiced.
          else {
            this.dialog.open<InvoicedSiniestroDialogComponent, { "invoice_id": number, "token": string, "invoice_name": string }[]>(
              InvoicedSiniestroDialogComponent,
              {
                data: res
              }
            ).afterClosed().subscribe(res => {
              this.routerS.refresh();
            });

            this.dialogRef.close(groups);
          }
        }
        else {
          this.dialogRef.close([])
        }
      })
    }
    else {
      this.companyS.showMissingCompanyDialog();
    }

  }
  
}


