<!-- <app-view-title-lord-icon class="df jcc" [pageTitle]="'Subscripción'" [lordIcon]="subscriptionIcon"></app-view-title-lord-icon>
<ng-template #subscriptionIcon>
    <lord-icon src="https://cdn.lordicon.com/iriatmos.json" trigger="in" state="in-reveal"
        colors="primary:#121331,secondary:#545454,tertiary:#ffc738,quaternary:{{warn()}}" class="lord-small">
    </lord-icon>
</ng-template> -->


<!-- NO SUBSCRIPTION AND NEVER SUBSCIBED -->
<div *ngIf="(subS.isBasic || subS.isTrial) && !subS.someTimeHasPro">
    <app-pricing></app-pricing>
    <app-trusted-users></app-trusted-users>
</div>


<!-- WHEN USER IS SUBSCRIBED OR HAS BEEN SUBSCRIBED -->
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="subS.isPro || subS.someTimeHasPro" class="subscription-tabs">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">card_membership</mat-icon>
            Tu subscripción
        </ng-template>
        <div class="subscribed-tab-content">
            <app-subscription-status></app-subscription-status>
        </div>

    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">receipt_long</mat-icon>
            Pagos
        </ng-template>
        <div class="subscribed-tab-content">
            <app-pay-comet-invoices></app-pay-comet-invoices>
        </div>
    </mat-tab>
</mat-tab-group>