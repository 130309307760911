import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, ValidationErrors} from '@angular/forms';

/**
 * [COMPONENT]
 * Classe padre de todos los inputs 'custom'
 */


@Component({
    template: ''
})
export class BaseInput implements OnInit {

    /** HostBinding selector  */
    classSelector = "_customInput"

    /**Formulario al que pertenece el input */
    @Input() form! : UntypedFormGroup;
    /** Nombre del control del formulario */
    @Input() formCName! : string;
    /**Classe que obtiene el 'padre' del input */
    @Input() class_? : string |undefined;
    /** Si el input es requerido */
    @Input() required : boolean = true;
    /** Si el input esta deshabilitado */
    @Input() disabled : boolean = false;
    /**Texto del input al hacer foucs */
    @Input() placeHolder? : string | undefined;
    /**Autocomplete */
    @Input() disableAutocomplete? : boolean = false;
    /**Texto del input sin hacer focus */
    @Input() label = "";
    /**Input solo mayúsuclas */
    @Input() forceUppercase = false;
    /** Input foucs out */
    @Output() onFocusOut : EventEmitter<any> = new EventEmitter();

    constructor(private changeDetector: ChangeDetectorRef) {}
    
    ngOnInit(){}

    ngAfterViewInit(){
        //this.control!.updateValueAndValidity();
        //this.control?.markAllAsTouched();
        this.changeDetector.detectChanges();
    }

    get value(){
        return this.form.get(this.formCName)?.value;
    }

    get control(){
        return this.form.get(this.formCName)
    }

    get touched(){
        return this.control!.touched && this.control!.dirty
    }

    setErrors(errors: ValidationErrors | null, opts?: {emitEvent?: boolean;}): void{
        this.control?.setErrors(errors, opts)
    }

    onValueChange(action : ()=> void){
        this.control?.valueChanges.subscribe(val =>{
            action();
        })
    }

    changeToUppercase() {
        if (this.forceUppercase){
            this.control?.setValue(this.control.value.toUpperCase())
        }
    }

    /** Mira si en el formulario hay el error en el teléfono */
    hasErrors() {
        return this.control?.errors != undefined && this.touched;
    }
}