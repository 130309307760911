import { Component, Input} from '@angular/core';
import { M_Contact } from '../../models/M_Contact';
import { getAccentColor, getWarnColor } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-user-email-always',
  templateUrl: './user-email-always.component.html'
})
export class UserEmailAlwaysComponent {
  @Input() client! : M_Contact | undefined;
  accent = getAccentColor;
  warn = getWarnColor;
  ngAfterContentInit(){}
}
