<div class="advanced-details-container">
    <div class="title">
        <app-view-title>Abonar factura</app-view-title>
    </div>

    <!-- Vehicle on OR, general info on invoice-->
    <div class="left">
        <app-card [contentLoaded]="hasOrOrInvoice">
            <div *ngIf="invoice">
                <app-card-title>Información general</app-card-title>

                <app-card-subtitle>Fecha facturación</app-card-subtitle>
                <p>{{invoice.created_at!.humanFormat()}}</p>

                <app-card-subtitle>Total</app-card-subtitle>
                <p money [val]="invoice.total"></p>

                <app-card-subtitle>Comentarios</app-card-subtitle>
                <p *ngIf="invoice.comment">{{invoice.comment}}</p>
                <p *ngIf="!invoice.comment" class="c_t2">Sin comentarios</p>

            </div>
        </app-card>
    </div>

    <!-- Client -->
    <div class="right">
        <app-card [contentLoaded]="hasOrOrInvoice">
            <app-card-title>Cliente</app-card-title>
            <div class="ribbon" *ngIf="client?.discount">
                <span>{{client?.discount}}% DTO</span>
            </div>
            <app-client-info *ngIf="client; else noClient" [client]="client"> </app-client-info>
            <ng-template #noClient>
                <p class="c_t2">Sin cliente</p>
            </ng-template>
        </app-card>
    </div>

    <!-- Products / Products and time-->
    <div class="products">
        <app-card [contentLoaded]="hasOrOrInvoice">

            <app-card-title>Productos / intervenciones</app-card-title>
            <p>Se abonarán todos los productos o intervenciones de la factura.</p>

            <!-- If Invoice-->
            <div *ngIf="invoice && invoice.breakdown">
                <app-product-line-table [products]="invoice.breakdown.all" [canModifyTable]="false"
                    [showLocation]="false"
                    [showProductsStock]="false"
                    [lineTypes]="[]">
                </app-product-line-table>
                <div class="df jcc">
                    <button mat-flat-button color="warn" (click)="abonar()">
                        Abonar
                    </button>
                </div>
            </div>

            <table class="breakdown no-pointer" *ngIf="invoice && invoice.getTotalBreakdown() as totals">
                <tr>
                    <td class="fw400 total">
                        Subtotal
                        <p class="fss fsi nmb" *ngIf="totals.client_excent_iva">Cliente exento de IVA</p>
                    </td>
                    <td><span class="c_p fw600" money [val]="totals.subtotal"></span></td>
                </tr>
                <tr *ngFor="let row of totals.iva_rows">
                    <td *ngIf="row.iva != 0" class="fw400 total">IVA ({{row.iva}}%)</td>
                    <td *ngIf="row.iva == 0" class="fw400 total">EXENTO</td>
                    <td><span class="c_p fw600" money [val]="row.iva == 0? row.subtotal : row.total"></span></td>
                </tr>
                <!-- change iva code-->
                <!--
                    <tr>
                        <td class="fsm fw500 total">
                        <span class="c_p fsxs mr5 cp" (click)="openChangeIva()">Modificar</span> IVA ({{iva.value}}%) :
                        </td>
                        <td><span class="c_p fw400">{{bc.iva}} €</span></td>
                    </tr>
                -->
                <tr>
                    <td class="fsm fw500 total">Total</td>
                    <td><span class="c_p fw600" money [val]="totals.total"></span></td>
                </tr>
            </table>
        </app-card>
    </div>
   
</div>