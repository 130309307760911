import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TryProDialogComponent } from '../../components/try-pro-dialog/try-pro-dialog.component';
import { EnumSubscription } from '../../enums/EnumSubscription';
import { M_Subscription } from '../../models/M_Subscription';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { ViewPath } from 'src/app/app-routing.module';
import { EinaDataService } from './eina-data.service';
import { ConfirmDialogService } from '../confirm-dialog.service';
import { CardComponent } from 'src/app/components/card/card.component';
import { CreateTicketDialog } from 'src/app/components/create-ticket-dialog/create-ticket-dialog';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {


  /** Subect for notify when the user subscribe / unsubscribe */
  onChangeSubscription: Subject<M_Subscription | undefined> = new Subject();
  constructor(private einaDataS: EinaDataService, private d: MatDialog, private userS: UserService, private confirmD: ConfirmDialogService) { }

  /** User's subscription state */
  get status(): M_Subscription { return this.einaDataS.subscription; }

  /**Change the user's subscription status. Also emitts changes */
  setSubscriptionStatus(status: M_Subscription) {
    if (environment.local) { this.startProDev(); }
    else {
      this.setNewSubscription(status);
      this.onChangeSubscription.next(this.status);
    }
  }

  /** Update the company on the service. Emits 'onEinaDataChange' event */
  setNewSubscription(d: M_Subscription) {
    this.einaDataS.setNewSubscription(d);
  }

  /** Open dialog to pay */
  openTryProDialog(text?: string, card?: CardComponent) {
    if (!card || card.locked == true) {
      this.d.open(TryProDialogComponent, { data: text, autoFocus: false })
    }
  }

  get isTrial() {
    return this.status?.type == EnumSubscription.TRIAL;
  }

  get isBasic() {
    return this.status?.type == EnumSubscription.BASIC;
  }

  get isPro() {
    return this.status != undefined &&
      this.status.type == EnumSubscription.PRO;
  }

  get hasProPermissions() {
    return this.status != undefined &&
      (this.status.type == EnumSubscription.PRO ||
        this.status.type == EnumSubscription.TRIAL);
  }

  /** Show the top banner ? 
   *  - When the user has the free trial (green)
   *  - When the user finished the free trial (red)
   *  - When the user canceled the subscription and already have remaining days of pro (blue)
   */
  get showBanner() {
    if (environment.local) { return false; } // Remove this if u want to check the banner.
    if (this.status == undefined || !this.userS.userIsAdmin) { return false; }
    return this.isTrial || this.isBasic || (this.isPro && this.status.will_cancel);
  }

  get someTimeHasPro() {
    return this.status != undefined && (this.isPro || (this.isBasic && !this.status.is_demo))
  }

  /** ==================== ONLY FOR DEV =============================== */
  startFreeTrialDev() {
    let s: M_Subscription = {
      type: EnumSubscription.TRIAL,
      active: false,
      will_cancel: false,
      users: 0,
      remaining_days: 2,
      amount: 0,
      is_demo: true,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.setNewSubscription(s);
    this.onChangeSubscription.next(this.status);
  }
  endFreeTrialDev() {
    let s: M_Subscription = {
      type: EnumSubscription.BASIC,
      active: false,
      will_cancel: false,
      users: 0,
      is_demo: true,
      remaining_days: 0,
      amount: 0,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.setNewSubscription(s);
    this.onChangeSubscription.next(this.status);
  }

  startProDev() {
    let s: M_Subscription = {
      type: EnumSubscription.PRO,
      active: true,
      will_cancel: false,
      users: 3,
      remaining_days: 0,
      amount: 100,
      is_demo: false,
      next_payment: new Date().plusDays(2),
      typedetails: {
        first_user: 30,
      }
    }
    this.setNewSubscription(s);
    this.onChangeSubscription.next(this.status);
  }

  cancelProDev() {
    let s: M_Subscription = {
      type: EnumSubscription.PRO,
      active: false,
      will_cancel: true,
      users: 3,
      remaining_days: 2,
      amount: 0,
      is_demo: false,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.setNewSubscription(s);
    this.onChangeSubscription.next(this.status);
  }

  canceledProDev() {
    let s: M_Subscription = {
      type: EnumSubscription.BASIC,
      active: false,
      will_cancel: true,
      users: 3,
      remaining_days: 2,
      amount: 0,
      is_demo: false,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.setNewSubscription(s);
    this.onChangeSubscription.next(this.status);
  }

  genericSubscriptionError(title: string, body: string) {
    this.confirmD.show({
      title: title,
      body: body,
      type: "danger",
      confirmTxt: "Contactar",
      showCancel: true
    }).afterClosed().subscribe(res => {
      if (res) {
        this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues: true }, autoFocus: false });
      }
    });
  }
}
