import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentStatus } from '../../enums/AppointmentStatus';
import { IAppointment } from 'src/app/interfaces/IAppointment';

/**
 * Workload WEEK card for appointemnts
 */
@Component({
  selector: 'lib-appointment-week-card',
  templateUrl: './appointment-week-card.component.html',
  styleUrls: ['./appointment-week-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-day.css']
})
export class AppointmentWeekCardComponent implements OnInit {

  app_status = AppointmentStatus;

  /** The apoointment object itself */
  @Input() item!: IAppointment;
  /** Emitted when user click on the card */
  @Output() onGoAppointment : EventEmitter<any> = new EventEmitter();
  /** Emitted when the appointment is modified */
  @Output() onModifyAppointment: EventEmitter<[IAppointment, ("ra" | "eliminar" | "done" | "pending" | "hour")]> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  preventCardClick(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

}
