<!-- Person -->
<lord-icon *ngIf="contact.isPerson; else companyIcon" src="https://cdn.lordicon.com/fmasbomy.json" trigger="in"
    colors="primary:#121331,secondary:{{warn()}},tertiary:#fae6d1" state="hover-looking-around" [ngClass]="small ? 'lord-medium' : 'lord-big'">
</lord-icon>

<!-- Company -->
<ng-template #companyIcon>
    <lord-icon *ngIf="contact.isCompany; else providerIcon" src="https://cdn.lordicon.com/waxtfsst.json" trigger="in"
        colors="primary:#121331,secondary:{{accent()}},tertiary:#3a3347,quaternary:#ebe6ef,quinary:#f24c00"
        [ngClass]="small ? 'lord-medium' : 'lord-big'">
    </lord-icon>
</ng-template>

<!-- Provider -->
<ng-template #providerIcon>
    <lord-icon *ngIf="contact.isProvider; else agentIcon" src="https://cdn.lordicon.com/amfpjnmb.json" [ngClass]="small ? 'lord-medium' : 'lord-big'"
        trigger="in"
        colors="primary:#121331,secondary:{{accent()}},tertiary:#3a3347,quaternary:{{warn()}},quinary:{{primary()}}">
    </lord-icon>
</ng-template>

<!-- Agent -->
<ng-template #agentIcon>
    <!-- To do : https://siniwin.atlassian.net/browse/EINA-949-->
    <lord-icon src="https://cdn.lordicon.com/mebvgwrs.json" trigger="in" [ngClass]="small ? 'lord-medium' : 'lord-big'">
    </lord-icon>
</ng-template>