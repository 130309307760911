
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { IClassSearcher } from "src/app/interfaces/IClassSearcher";

/** --------------------- **/
@Injectable({
    providedIn: 'root'
  })
  export class MasterStorageService {
    public masters: any[] = []
    public solicitedMasters: any[] = []
  
    constructor(public router: Router, private http: HttpClient) {
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.masters = [];
        }
      });
    }
  
    getMaster(instance: IClassSearcher<any>) {
      let md = this.getMasterData(instance);
      return new Promise<any[]>(resolve => {
        if (md != undefined) {
          resolve(md);
        }
        else if (instance.endpoint) {
          this.solicitedMasters.push(instance);
          this.http.get<any>(instance.endpoint.url).subscribe(
            data => {
              let data_: any[] = [];
              for (let i = 0; i < data.length; i++) {
                data_.push(instance.createNew(data[i]))
              }
              this.masters.push(data_)
              resolve(data_);
            }
          );
        }
      })
    }
  
    alreadyRequested(master: IClassSearcher<any>) {
      return this.solicitedMasters.includes(master);
    }
  
    getMasterData(masterClass: IClassSearcher<any>) {
      for (let i = 0; i < this.masters.length; i++) {
        let m = this.masters[i];
        if (Array.isArray(m) && m.length != 0 && m[0] instanceof masterClass.typeof) {
          return m;
        }
      }
      return undefined;
    }
  
  }
  