<app-card-subtitle [first]="true" [noMarginBottom]="true" [locked]="true">
    <div class="product-title">
        <div class="df jcs" [ngClass]="{'underline_red_hover' :parent.canModifyTable && clickable}"
            (click)="editProductDialog()">
            <!-- Product Icon-->
            <mat-icon smartIcon>{{product.icon}}</mat-icon>
            <div class="df aifs fdc product-and-ref">
                <!-- Product name -->
                <span class="one-line">{{product.isComment ? 'Comentario' : product.isTime? 'Tiempo trabajado' : product.name}}</span>
                <span class="c_t2 fw300" [ngClass]="{'comment-ref' : product.isComment}">{{ getRef()}}</span>
            </div>
        </div>
    </div>
</app-card-subtitle>