<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>{{data.client ? 'Reasignar cliente' : 'Asignar cliente'}}</p>

<div mat-dialog-content>
    <app-card [contentLoaded]="csearcher.loaded" [noStyle]="true">
        <app-class-searcher [showLeftNumber]="false" #csearcher [masterClass]="client" [allData]="" [width100]="true"
            searchPlaceHolder="Buscar cliente" [specialRow]="'Nuevo cliente / empresa'"
            (onSpecialRow)="goCreateClient()" [extendedInfo]="false" [form_]="form" [formCname]="'client_id'"
            [required]="true" (onRemove)="csas.fc.reset()"
            (onLoad)="loaded = true">
        </app-class-searcher>
        <app-contact-sending-address-selector [contentLoaded]="true" #csas [ngClass]="{'dn' : !csearcher.selected}"
            [contact]="this.data.client ? this.data.client : csearcher.selected!" [fc]="form.get('extra_address')!"
            [canChange]="true" (onChangedAddress)="csearcher.selected!.selected_extra_adress = $event ? $event : undefined">
        </app-contact-sending-address-selector>
    </app-card>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" mat-flat-button [disabled]="!form.valid" [mat-dialog-close]="csearcher.selected"
        cdkFocusInitial>Asignar</button>
</div>
