import { Component, Input } from '@angular/core';
import { M_Product } from '../../models/Products/M_Product';

@Component({
  selector: 'app-provider-selector',
  templateUrl: './provider-selector.component.html',
  styleUrls: ['./provider-selector.component.css']
})
export class ProviderSelectorComponent {
  @Input({ required: true }) p!: M_Product;
  constructor() { }
}
