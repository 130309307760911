<table mat-table [dataSource]="dataSource" *ngIf="client.products_providers.length > 0; else noData">

    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Referencia </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.product.reference; else noRow">{{element.product.reference}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element">
            <span class="vam">{{element.product.name}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="buy_price">
        <th mat-header-cell *matHeaderCellDef> Coste </th>
        <td mat-cell *matCellDef="let element">
            <span class="vam">{{element.product.buy_price}}</span>
        </td>
    </ng-container>


    <ng-container matColumnDef="stock" *ngIf="responsiveS.w > 500">
        <th mat-header-cell *matHeaderCellDef> Stock </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.product.stock; else noRow">{{element.product.stock}}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsByScreenSize"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsByScreenSize;" (click)="goProduct(row)"></tr>
</table>
<mat-paginator [ngClass]="{'dn_i' : dataSource.data.length == 0}" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

<div style="margin-top: 10px;">
    <button class="w100" mat-stroked-button (click)="goCreateProduct()">
        <mat-icon>add</mat-icon>
        Dar de alta producto
    </button>
</div>
<ng-template #noRow>
    <span class="c_t2">Sin datos</span>
</ng-template>

<ng-template #noData>
    <p *ngIf="client.products_providers">Este proveedor no tiene asociado ningún producto. Puedes asociarle un
        nuevo producto haciendo clic en el siguiente botón:</p>
</ng-template>