<div class="header appointment">
    <div class="header-contents">
        <mat-icon class="mr5">event</mat-icon>
        <p class="week-text workload-overflow-text fsm mr10">{{item.title_appointment}}</p>
        <lib-appointment-status-label class="big-screen-satus" style="transform: scale(0.8);"
            [status]="item.status"></lib-appointment-status-label>
        <button prevent mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</div>

<div class="day-card-body elevation">
    <p class="fsl fw500 c_p ttu">{{item.vehicleName()}}</p>
    <p class="fsl fw500 ttu">{{item.clientName()}}</p>
</div>

<div class="day-card-body">
    <p class="day-title c_p">TAREAS</p>
    <div>
        <p *ngFor="let r of item.reparations" class="df aic nmb">
            <mat-icon style="transform: scale(0.4);" class="c_a">circle</mat-icon>
            {{r}}
        </p>
        <p *ngIf="item.reparations.length == 0" class="c_t2">Sin tareas</p>
    </div>
    <div class="separator"></div>
    <div class="bottom-contents" [ngTemplateOutlet]="hourActionsAppointment"></div>
</div>


<ng-template #hourActionsAppointment>
    <div class="bottom-section-content">
        <lib-appointment-status-label class="small-screen-satus" style="transform: scale(0.8);"
            [status]="item.status"></lib-appointment-status-label>
        <p class="nmb fw500 wml20" style="font-size: 18px;" prevent>
            {{item.appointment_date.hourFormat()}} <span *ngIf="item?.status == 0" class="cp fss nmb blue_text_action"
                (click)="onModifyAppointment.emit([item,'hour'])">Modificar</span></p>
    </div>
</ng-template>

<!--Appointments-->
<!-- Sinicloud deprecated (menu) !-->
<mat-menu #menu="matMenu">
    <div matTooltipPosition="above" [matTooltip]="!item.canRA ? 'La cita esta cancelada' :''">
        <button [disabled]="!item.canRA" mat-menu-item (click)="onModifyAppointment.emit([item,'ra'])">
            <mat-icon>build</mat-icon>
            Pasar a OR
        </button>
    </div>
    <button *ngIf="item?.status != app_status.canceled" mat-menu-item
        (click)="onModifyAppointment.emit([item,'eliminar'])">
        <lib-appointment-status-label [pointer]="true" [status]="app_status.canceled"></lib-appointment-status-label>
    </button>
    <button *ngIf="item?.status != app_status.pending" mat-menu-item
        (click)="onModifyAppointment.emit([item,'pending'])">
        <lib-appointment-status-label [pointer]="true" [status]="app_status.pending"></lib-appointment-status-label>
    </button>
    <button *ngIf="item?.status != app_status.done" mat-menu-item (click)="onModifyAppointment.emit([item,'done'])">
        <lib-appointment-status-label [pointer]="true" [status]="app_status.done"></lib-appointment-status-label>
    </button>
    <div class="contact-section df jcsa aic" [ngClass]="{'unique-option' : item.status != 0}">
        <app-whatsapp [phone]="item.clientphone"></app-whatsapp>
        <app-phone-button [phone]="item.clientphone"></app-phone-button>
        <app-email-button [email]="item.clientemail"></app-email-button>
    </div>
</mat-menu>