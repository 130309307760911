import { ChangeDetectorRef, Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { MenuComponent } from '../menu.component';
import { ViewPath } from 'src/app/app-routing.module';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { DropdownDirective } from 'src/app/directives/DropDown/dropdown.directive';
import { BurgerMultipleOption, BurgerOption, BurgerService } from 'src/app/services/burger.service';
import { StorageService } from 'src/app/services/storage.service';


export enum MultipleMenuSection {
  MANAGEMENT = 1,
  WORKSHOP = 2,
  COMERCIAL = 3,
  STOREHOUSE = 4,
  FINANCE = 5,
  CONFIGURATION = 6
}

@Component({
  selector: 'app-multiple-menu-item',
  templateUrl: './multiple-menu-item.component.html',
  styleUrls: ['./multiple-menu-item.component.css']
})
export class MultipleMenuItemComponent {

  @ViewChildren(MenuItemComponent) children?: QueryList<MenuItemComponent>;
  @ViewChild(DropdownDirective) dropdownDirective?: DropdownDirective;

  @Input({ required: true }) id!: MultipleMenuSection;
  @Input({ required: true }) data!: BurgerMultipleOption;
  @Input({ required: true }) parent!: MenuComponent;
  @Input() class_?: string;


  constructor(private chdRef: ChangeDetectorRef, public bS: BurgerService, private companyS: CompanyService, private storageS: StorageService) { }

  ngAfterViewInit() {
    if (!this.children) { return; }
    this.initToggleFromStorage();
    this.chdRef.detectChanges();
    this.parent.onStartOnboardingSection.subscribe(v => {
      if (this.dropdownDirective){
        this.dropdownDirective.openIfClosed();
      }
    })
  }

  initToggleFromStorage() {
    if (this.dropdownDirective && !this.isStorageOpen()) {
      this.dropdownDirective.toggle();
      this.chdRef.detectChanges();
    }
  }

  getClass() {
    return this.someChildSelected ? 'burgerSelected' : '';
  }

  getBadge(bo: BurgerOption) {
    if (bo.view == ViewPath.settings) {
      return this.companyS.companyMissingInfo ? '!' : undefined;
    }
    return undefined;
  }

  getSvgIcon(bo: BurgerOption) {
    return bo.icon == "wheel" || bo.icon == "barcode";
  }

  saveToggle(opened: boolean) {
    this.storageS.save("menu-item-" + this.id.toString(), opened);
  }

  isStorageOpen() {
    let toggled = this.storageS.getBoolean("menu-item-" + this.id.toString());
    return toggled == null ? true : toggled;
  }

  /** Return array of BurgerOption from the children array */
  get childsToBurger(): BurgerOption[] {
    if (!this.children) { return []; }
    return this.children.map(item => item.data);
  }

  /** Is some child showing ? */
  get someChildShowing() {
    if (!this.children) { return false; }
    return this.bS.childsHasPermission(this.childsToBurger);
  }

  /** Is some child selected ? */
  get someChildSelected() {
    if (!this.children) { return false; }
    return this.bS.isSelectedMultiple(this.childsToBurger) ? 'burgerSelected' : '';
  }

  get totalChildsShowing(): number {
    if (!this.children) { return 0; }
    return this.children.reduce((total, ch) => ch.isShowing ? total + 1 : total, 0);
  }
}
