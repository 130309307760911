import { Component, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Action } from 'src/app/models/M_Action';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { M_User } from 'src/app/models/M_User';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.css']
})
export class PurchasesComponent  {
  v = ViewPath;
  f = filter;
  loading = false;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_User>;
  constructor(public subS: SubscriptionService,public sessionS: SessionService,public params: ParamsService,public apiS:ApiService){
    this.initTable();
  }
  initTable(){
    this.apiS.getPurchases().then(res=>{
      this.loading = true;
      this.ps.initTable(res);
      console.log(res);
    });
  }
  onChange(){}
  openDialog(){}
  contact(purchase: M_Purchase) { return purchase.contact?.name }
  typeName(purchase: M_Purchase) { return purchase.typeName }
}
