import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Fault } from 'src/app/models/M_Fault';
import { M_Product } from 'src/app/models/Products/M_Product';
import { CreateClientInDialogService } from 'src/app/services/create-client-in-dialog.service';
import { ContactEnum } from 'src/app/enums/ContactEnum';

@Component({
  selector: 'app-add-provider-to-product-or-fault',
  templateUrl: './add-provider-to-product-or-fault.component.html',
  styleUrls: ['./add-provider-to-product-or-fault.component.css']
})
export class AddProviderToProductOrFaultComponent {

  provider = MASTER_PROVIDER;
  @ViewChild("providerSearch") providerSearch!: ClassSearcherComponent<M_Contact>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Fault | M_Product,
    public dialogRef: MatDialogRef<AddProviderToProductOrFaultComponent>,
    private apiS: ApiService, private createclientInDialogS: CreateClientInDialogService) { }

  get prodId(): number {
    return this.data instanceof M_Product ? this.data.product_id! : this.data.product!.product_id!;
  }

  disabledIf(provider: M_Contact) {
    if (this.data instanceof M_Fault) { return false; }
    else {
      return this.data.providers.find(prov => prov.client_id == provider.client_id) != undefined
    }
  }

  closeWithProvider() {
    let selectedProvider = this.providerSearch.selected;
    if (selectedProvider) {
      this.apiS.linkProductToProvider({ action: "create", product_id: this.prodId, client_id: selectedProvider.client_id }).then(res => {
        this.dialogRef.close(selectedProvider);
      })
    }
    else {
      this.dialogRef.close(undefined);
    }
  }

  createClientInDialog(cs: ClassSearcherComponent<M_Contact>) {
    this.createclientInDialogS.create(cs, ContactEnum.PROVIDER);
  }
}
