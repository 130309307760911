import { Component, EventEmitter, Input, Output } from '@angular/core';
import { feature } from '../../utils/FeaturesController';
import { or_types_cargo_interno, or_types_garantia, or_types_normal, or_types_siniestro } from 'src/app/custom-classes/or_types';

@Component({
  selector: 'app-or-type-selector',
  templateUrl: './or-type-selector.component.html',
  styleUrls: ['./or-type-selector.component.css']
})
export class OrTypeSelectorComponent{

  @Input() showInterno : boolean = true; 
  @Input() isAlbaran : boolean = false; 
  feature = feature;

  @Output() onClickOption: EventEmitter<number> = new EventEmitter();

  t_n = or_types_normal;
  t_ci = or_types_cargo_interno;
  t_g = or_types_garantia;
  t_s = or_types_siniestro;

}
