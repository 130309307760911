import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() { }
  match(searched: string, ...value: string[]) {
    return match(searched, ...value);
  }
}

export function match(searched: string | undefined, ...value: (string | undefined)[]) {
  if (searched == undefined) {
    return false;
  }
  if (value) {
    for (let i = 0; i < value.length; i++) {
      let val = value[i];
      if (val != undefined) {
        val = val.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
        searched = searched.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
        if (val.includes(searched)) {
          return true;
        }

        if (val.length > searched.length) {
          val = val.substring(0, searched.length)
        }

        if (val == searched) {
          return true;
        }
      }

    }
  }
  return false;
}