<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Fichaje manual</p>

<div mat-dialog-content>
    <form [formGroup]="form" class="add-time-dialog p10 form">
        <div [roles]="[R.ADMIN, R.ADVISER]">
            <app-class-searcher [form_]="form" [formCname]="'user'" #workerSearcher class="w100" [masterClass]="m_user"
                searchPlaceHolder="Buscar operario" [me] [noSelectionPlaceHolder]="'Ningún operario seleccionado'">
            </app-class-searcher>
        </div>
        <div class="df jcsb">
            <mat-form-field class="pricehour w50" color='primary'>
                <mat-label>Horas</mat-label>
                <input max="99" onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" matInput
                    placeholder="Horas" formControlName="hours">
                <mat-error *ngIf="this.form.get('hours')?.hasError('max')">Máx. 99 horas</mat-error>
            </mat-form-field>

            <mat-form-field class="pricehour w50" color='primary'>
                <mat-label>Minutos</mat-label>
                <input max="59" onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" matInput
                    placeholder="Minutos" formControlName="minutes">
                <mat-error *ngIf="this.form.get('minutes')?.hasError('max')">Máx. 59 minutos</mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="w100">
                <mat-label>Elige un día</mat-label>
                <input [required]="true" #pickerInput matInput [matDatepicker]="picker" (click)="picker.open()"
                    formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions>
    <button color="warn" mat-flat-button [disabled]="!form.valid" (click)="closeWithTime()">
        Añadir tiempo
    </button>
</div>