import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from '../../models/M_Action';
import { M_GroupTask } from '../../models/M_GroupTask';
import { M_Product } from '../../models/Products/M_Product';
import { DragGroupComponent } from './drag-group/drag-group.component';
import { OrTimerService } from '../../services/or-time.service';
import { MatDialog } from '@angular/material/dialog';
import { ReserveDialogComponent } from './reserve-dialog/reserve-dialog.component';
import { M_Reservation } from '../../models/M_Reservation';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { SessionService } from 'src/app/services/session.service';
import { or_types, or_types_cargo_interno } from 'src/app/custom-classes/or_types';

@Component({
  selector: 'app-drag',
  templateUrl: './drag.component.html',
  styleUrls: ['./drag.component.css']
})
export class DragComponent implements OnInit {
  @Input() action?: M_Action;
  @Input() canModify?: boolean;
  @Input() canDelete?: boolean;
  @Input() abono: boolean = false;
  @Input() isBudgetPage: boolean = false;
  @Input() locked: boolean = false;
  isOrder: boolean = false;
  @Output() onInvoiceChildTask: EventEmitter<M_GroupTask> = new EventEmitter();
  @Output() onSomeGroupChanges: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveChildGroupTask: EventEmitter<any> = new EventEmitter();

  @ViewChildren(DragGroupComponent) groupsComponents?: QueryList<DragGroupComponent>;
  product_list: M_Product[] = [];
  dragLoaded = false;

  constructor(private apiS: ApiService, private sessionS: SessionService, private orTimeS: OrTimerService, private d: MatDialog, public companyS: CompanyService) {
    this.apiS.products().then(res => {

      this.product_list = res;
      this.dragLoaded = true;
    })
  }

  ngOnInit(): void { }


  finalSave(): Promise<boolean> {

    return new Promise(resolve => {
      if (!this.groupsComponents == undefined) { resolve(true); }
      this.groupsComponents?.forEach((g, index) => {
        /** On the last item, await the response and resolve the promise */
        if (index == this.groupsComponents!.length - 1) {
          resolve(g.saveChanges(undefined, "by-general"))
        }
        else {
          //Avoid showing 'Cambios guardados' snack multiple times
          g.saveChanges(undefined, "by-general");
        }
      })
    })
  }

  /** Save all the group components. Returns a promise with a boolean */
  saveAll(isClosing: boolean): Promise<boolean> {
    let reserves: M_Product[] = []
    if (this.product_list.length) {
      this.groupsComponents?.forEach(g => {
        let action_id = g.group.action_id;
        let client_id = this.action?.client_id;
        g.group.products.forEach(p => {
          if (p instanceof M_Product && p.reserve && p.line_hasChanges) {
            let task_id = p.task_id;
            var reservation = new M_Reservation({});
            reservation.product_id = p.product_id;
            reservation.client_id = client_id;
            reservation.mov_id = action_id;
            reservation.mov_type = 13;
            reservation.item_id = task_id;
            reservation.location_id = p.selected_location
            reservation.quantity = p.quantity;
            p.reservations.push(reservation);
            reserves.push(p);
          }
        })
      })
    } else {
      // dayly CAMBIAR IMPORTANTE TASK POR PRODUCT TANTO BACK COMO FRONT CAMBIO IMPORTANTE TASK PASA A SER PRODUCT DIRECTO.
      this.product_list.forEach(p => {

      });
    }
    if (this.companyS.recambiosModule) {
      if (reserves.length) {
        return this.d.open(ReserveDialogComponent, { maxWidth: 500, data: { reserves: reserves, isClosing: isClosing } }).afterClosed().toPromise().then(res => {
          if (res == true) {
            return this.finalSave();
          }
          else {
            return Promise.resolve(false);
          }
        });
      }
      else {
        return this.finalSave();
      }
    }
    else {
      return this.finalSave();
    }
  }

  addNew() {
    if (this.action) {
      /** OR */
      if (this.action.action_id) {
        this.apiS.addEditGroup(this.action.id, undefined, "Título de la intervención", new or_types("Normal")).then(res => {
          let g = new M_GroupTask({id: res})
          if (this.action?.isVnVoOr){
            g.type = or_types_cargo_interno;
          }
          this.action!.addGroupTask(g)
        })
      }
      else {
        //this.apiS.createOR
        alert("no implemented")
      }

    }
  }

  /** ????  */
  onDrop(_e: any) {
    this.action?.groups.forEach(_g => {
      //g.onDragAndDropReorder();
    })
  }

  onRemoveGroup(g: M_GroupTask) {
    this.onRemoveChildGroupTask.emit(g);
    this.action?.refreshType();
  }

  onStatusChange(_e: any) {
    if (this.action) {
      this.action.refreshStatus();
      /** End current worker timer */
      if (this.action.status.pending || this.action.status.invoiced) {
        const isworking = this.action?.isOperatorWorking(this.sessionS.getId());
        if (isworking) { this.action?.closeTime(isworking!.id); this.orTimeS.end(this.action); }
      }
    }
  }

  onTypeChange(_e: any) {
    this.action?.refreshType();
  }

  onInvoiceTask(tipo: M_GroupTask) {
    this.onInvoiceChildTask.emit(tipo);
  }

  canModifyAction() {
    if (this.action?.isBudget()) {
      return !this.action.isClosed();
    }
    else if (this.action?.isOr()) {
      return !this.action.allInvoiced()
    }
    return false;
  }

  hasChanges() {
    if (!this.groupsComponents) { return false }
    return this.groupsComponents.toArray().some(g => {
      return g.hasChanges()
    })
  }
}
