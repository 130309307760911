type status_names = "Abierto" | "Cerrado" | "Facturado"

const types : [number , status_names][] = [
    [0, "Abierto"],
    [1, "Cerrado"],
    [2, "Facturado"], 
]

export class albaran_status{
    num : number = -1;
    name : status_names = "Abierto";
    constructor(tipo : status_names | number) {
        types.map(t => {
            if (typeof tipo == "number") {
                if (tipo != 0 && tipo != 1 && tipo != 2){
                    this.num = 0;
                    this.name = "Abierto";
                }
                else if (t[0] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
            else{
                if (t[1] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
        })
    }

    get open(){
        return this.num == 0;
    }

    get closed() {
        return this.num == 1;
    }

    get invoiced() {
        return this.num == 2;
    }
}

export const albaran_status_open = new albaran_status("Abierto");
export const albaran_status_closed = new albaran_status("Cerrado");
export const albaran_status_invoiced = new albaran_status("Facturado");
