import { ErrorHandler, Injectable } from '@angular/core';
import { DeveloperService } from '../services/developer.service';
import { LoggerService } from '../services/logger.service';
import { SessionService } from '../services/session.service';
import { CustomLog } from './CustomLog';
import { projectConfiguration } from '../app.module';

const ignoreErrorsContaining = [
    "ExpressionChangedAfterItHasBeenCheckedError",
]

/**
 * Custom Error Handler.
 * Required "errorHandlerEndpoint" varaible on the core configuration !
 * Send the error data on the provided endpoint
 * 
 * Send the following data : {
 *  code : number
 *  message : string
 *  stak : string
 * } 
 */

@Injectable({
    providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {

    /** The last error reported */
    lastError: CustomLog | undefined = undefined;
    core = projectConfiguration;
    
    constructor(private sessionS: SessionService, private developerS: DeveloperService, private logger: LoggerService) { }

    /** Default eror handler of angular porject */
    handleError(error: any) {
        console.log(error); // Always console log error
        var endpoint = this.core.errorHandlerEndpoint;
        var customError = new CustomLog("error", error.message, error.stack);
        this.developerS.onError.next(customError);
        if (this.reportNedeed(customError) && endpoint != undefined) {
            this.lastError = customError;
            this.logger.log("Error handler Endpoint :" + endpoint.url)
            this.report(customError, true);
        }
    }

    /** Report log to backend. By default , the stack is 'Error from core-library' */
    report(customLog: CustomLog, byHandler = false) {
        if (this.core.errorHandlerEndpoint) {

            if (!byHandler) {
                customLog.stack = "Reported manually from core-library";
            }
            
            fetch(this.core.errorHandlerEndpoint.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.sessionS.getToken()
                },
                body: JSON.stringify(customLog)
            }).then(res => {
                this.logger.log("Error endpoint response : ", res)
            });
        }
    }

    /** Prevent sending multiple times the same error
     * @returns If the error needs to be reported
     */
    checkLastError(error: CustomLog): boolean {
        if (this.lastError == undefined) { return true }
        else { return !error.isTheSame(this.lastError) }
    }

    /** Determinates if is necessary to ignore the error
     *  @returns If the error needs to be reported
     */
    checkIgnoreErrors(error: CustomLog): boolean {
        var needReport = true;
        if (typeof error.message == "string") {
            for (let i = 0; i < ignoreErrorsContaining.length; i++) {
                if (error.message.includes(ignoreErrorsContaining[i])) {
                    needReport = false;
                }
            }
        }
        return needReport;
    }

    /** Check if the error needs to be reported / saved on the databse */
    reportNedeed(error: CustomLog) {
        return this.checkIgnoreErrors(error) && this.checkLastError(error);
    }
}
