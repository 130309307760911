import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  today = new Date();

  /**Deshabilita los domingos en el calendario */
  sundayFilter: (date: Date | null) => boolean =
  (date: Date | null) => {
    if (!date) {
      return false;
    }
    const day = date.getDay();
    return day != 0; //Domingo
  };

    /**Deshabilita los domingos en el calendario */
    weekendFilter: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) {
        return false;
      }
      const day = date.getDay();
      return day != 0 && day != 6; //Domingo
    };

  /**
   * Deshabilita los días anteriores a 'hoy'
   * Deshabilita los domingos en el calendario
   */
  maxTodayAndSunday: (date: Date | null) => boolean =
  (date: Date | null) => {
    if (!date) {
      return false;
    }
    const day = date.getDay();
    const cd : Date = new Date();
    cd.minusDays(1);
    return date > cd && day != 0;
  };

  /** Day color on OR calendar 
   * @param total : Total OR on the specific day
   * @param maxOnDay : Max OR that can be done in one day (company_places)
  */
  getDayColorByOrLength(total: number, maxOnDay: number | undefined) {
    if (maxOnDay != undefined) {
      var half = maxOnDay / 2;
      if ((total >= maxOnDay) || (maxOnDay == 0 && total)) {
        return 'high-work';
      } else if (total >= half) {
        return 'mid-work'
      }
      else if (total) {
        return 'low-work'
      }
    }
    else if (total) {
      return 'low-work';
    }
    return ""
  }

}