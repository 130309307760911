import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 *  Put this lines on app-entry.point :
 * 
 *  @HostListener('window:resize', ['$event'])
 *  onResize(event: any) {
 *    this.responsive.width = event.target.innerWidth;
 *  }
 * 
 *  In the constructor :
 *  constructor(public responsiveS: ResponsiveService) {
 *    this.responsiveS.width = window.innerWidth;
 *  }
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  onResize = new Subject<number>()
  w!: number;
  constructor() { }

  set width(w: number) {
    this.w = w;
    this.onResize.next(this.w);
  }

  isPhone() {
    return this.w <= 622;
  }

  tableDetails() {
    return this.w >= 1216;
  }
}
