<div class="container">

    <div class="title">
        <app-go-back class="title" *ngIf="true" [text]="'Listado presupuestos'" (click)="goBudgets()"
            [show]="true"></app-go-back>
        <app-view-title *ngIf="true">{{isEdit ?
            'Editar presupuesto de comercial' :
            'Crear presupuesto de comercial'}}
        </app-view-title>
    </div>

    <div class="cbudget">
        <app-card [contentLoaded]="loaded">

            <div class="df fww aib">
                <app-card-title>
                    Información general
                </app-card-title>
                <app-documentation-pdf-menu class="mla" [cBudget]="cBudget"></app-documentation-pdf-menu>
            </div>

            <app-card-subtitle [first]="true" *ngIf="cBudget">
                <div class="df aic fww" style="line-height: 40px;">
                    <span class="mr5">Id : {{cBudget.id}}</span>
                    <app-or-status-label [status]="cBudget.status"></app-or-status-label>
                </div>
            </app-card-subtitle>

            <form [formGroup]="form" class="eina-form">

                <div class="eina-form-section">
                    <app-class-searcher #clientSearcher [form_]="form" [formCname]="'client_id'" [width100]="true"
                        [masterClass]="master_client" [canRemove]="isOpenOrUdefined" searchPlaceHolder="Buscar cliente"
                        [extendedInfo]="false" [specialRow]="'Nuevo cliente'" (onSpecialRow)="ccDialog.create(clientSearcher)">
                    </app-class-searcher>
                </div>

                <app-brand-model-input [form]="form" [showPrice]="true" (onSelectModel)="patchPrice($event)"
                    [customBrandModel]="false"></app-brand-model-input>

                <div class="eina-form-section">
                    <mat-form-field appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input type="number" min="0" matInput formControlName="price">
                    </mat-form-field>
                </div>

            </form>

            <div class="client-in-dialog df jcc">
                <app-client-vehicle-in-dialog [t]="'Detalles del cliente'" [c]="clientSearcher.selected"
                    [v]="undefined"></app-client-vehicle-in-dialog>
            </div>

        </app-card>
    </div>

    <div class="client">
        <app-card [contentLoaded]="loaded">
            <app-client-info [client]="clientSearcher.selected" [small]="false"></app-client-info>
        </app-card>
    </div>

</div>

<!-- ACTIONS -->
<div class="actions-and-concepts">
    <div class="actions floating-action-bar no-border">
        <app-card class="normal-padding-top" [locked]="false" [contentLoaded]="loaded"
            [cornerMatMenu]=" showDeleteMenu ? deleteButton : undefined">
            <div class="df jcc" style="gap: 10px;">

                <!-- Not created or opened-->
                <ng-container *ngIf="isOpenOrUdefined">
                    <button mat-flat-button color="warn" [disabled]="!isCreateEditEnabled" (click)="createEdit()">
                        {{isEdit ? 'Guardar' : 'Crear'}}
                    </button>
                    <button appTrialbutton mat-raised-button [disabled]="!cBudget || !form.valid" color="orange"
                        *ngIf="cBudget" (click)="closeBudget()">
                        <mat-icon>lock</mat-icon>
                        Cerrar
                    </button>
                </ng-container>

                <!-- Closed -->
                <ng-container *ngIf="cBudget && isClosed">

                    <!-- To stock -->
                    <button appTrialbutton mat-raised-button [color]="'primary'" (click)="toStock()"
                        *ngIf="cBudget.vehicle == undefined">
                        Pasar a stock
                    </button>

                    <!-- Already gone to stock-->
                    <button [matTooltip]="'Ir al vehículo'" mat-flat-button color="warn" *ngIf="cBudget.vehicle"
                        (click)="goToVehicle(cBudget.vehicle)">
                        <mat-icon>{{cBudget.vehicle.icon}}</mat-icon>
                        {{cBudget.vehicle.getName()}}
                    </button>

                    <!-- Invoice -->
                    <button appTrialbutton mat-raised-button (click)="invoice(cBudget.vehicle)"
                        *ngIf="!cBudget.invoice && cBudget.vehicle">
                        Facturar
                    </button>

                    <!-- Already invoiced -->
                    <button appTrialbutton mat-button (click)="goToInvoice(cBudget.invoice)" *ngIf="cBudget.invoice">
                        Ver factura
                    </button>

                </ng-container>
            </div>
        </app-card>
    </div>
    <app-card [contentLoaded]="loaded" [locked]="selectedClient || cBudget == undefined">

        <!-- CONCEPTOS -->
        <app-card-title>Conceptos</app-card-title>
        <app-concepts-line-table [concepts]="getConcepts()" [showSave]="true" [isInfo]="false"
            [canModifyTable]="!isClosed && cBudget != undefined"></app-concepts-line-table>

        <div class="mt20"></div>

        <!-- PRODUCTOS -->
        <app-card-title>Productos</app-card-title>
        <app-product-line-table *ngIf="this.clientSearcherComponent && this.clientSearcherComponent.selected"
            [products]="getProducts()" [lineTypes]="['product', 'custom','time','comment']"
            [extendedInfoConfig]="{showColumn:true, actions:['manual-fault','remove-reservation']}"
            [canModifyTable]="!isClosed && cBudget != undefined">
        </app-product-line-table>
    </app-card>
</div>

<ng-template #deleteButton>
    <button mat-icon-button class="mt10" [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #deleteMenu="matMenu">
        <button mat-menu-item (click)="deleteComercialB()">
            <mat-icon>delete</mat-icon> Eliminar
        </button>
    </mat-menu>
</ng-template>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el presupuesto'" [goText]="'Ir al listado de presupuestos'"
        [view]="v.budget">
    </app-model-not-found>
</ng-template>