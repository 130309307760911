import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { M_Product } from '../../models/Products/M_Product';
import { ModulesEnum } from '../../enums/ModulesEnum';
import { M_Location } from '../../models/M_Location';
import { M_CustomProduct } from '../../models/Products/M_CustomProduct';
import { locationAvailable, moveFromLocation } from '../../services/stock-calculator.service';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { ProductLineTableComponent } from '../product-line-table/product-line-table/product-line-table.component';

@Component({
  selector: 'app-storage-selector',
  templateUrl: './storage-selector.component.html',
  styleUrls: ['./storage-selector.component.css']
})
export class StorageSelectorComponent {

  stockModule = ModulesEnum.RECAMBIOS;
  @Output() onChangeStorage: EventEmitter<any> = new EventEmitter();

  @Input({ required: true }) p!: M_Product | M_CustomProduct | undefined;
  @Input({ required: true }) disabled!: boolean;
  /** Show only the product selected location on the mat-menu */
  @Input() locationOnlyInformative: boolean = false;
  @Input() showStock: boolean = true;

  product: M_Product | undefined;

  constructor(@Optional() @Inject(ProductLineTableComponent) public productLineTable: ProductLineTableComponent | null, public companyS: CompanyService) { }

  ngOnInit() {
    if (this.p && this.p instanceof M_Product) {
      this.product = this.p;
    }
  }

  selectedStore(location: M_Location) {
    if (this.product && this.product instanceof M_Product && this.product.selected_location != location.id) {

      if (this.productLineTable) {
        moveFromLocation(this.product, location, this.productLineTable.getAllProducts());
        this.product.line_hasChanges = true
        this.onChangeStorage.emit();
      }
      else {
        this.product.selected_location = location.id;
      }
      this.product.line_hasChanges = true
    }
  }

  private getScreenTotal(location: M_Location) {
    if (this.productLineTable) {
      let products = this.productLineTable.getAllProducts();
      if (this.p instanceof M_Product) {
        return locationAvailable(this.p, products, location, this.productLineTable.parent.getClient());
      }
    }
    return 0;
  }

  stockText(location: M_Location) {
    return this.product?.stockText(this.getScreenTotal(location), this.companyS.recambiosModule)
  }

  stockClass(location: M_Location) {
    if (!this.p) { return "" }
    let totalStock = this.getScreenTotal(location);
    /** Avoid orange above red... */
    if (totalStock == 0) { return "c_t2" }
    return this.p.getStockClassByStock(totalStock, this.companyS.recambiosModule);
  }

  /** */
  get getProductLocations(): M_Location[] {
    if (!this.product) { return []; }
    if (this.locationOnlyInformative) {
      if (this.product.selectedLocation) { return [this.product.selectedLocation] }
      else { return [] }
    }
    else {
      return this.product?.locations;
    }
  }
}
