import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { M_CustomProduct } from '../../models/Products/M_CustomProduct';
import { CustomProductType } from '../../enums/CustomProductType';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.css']
})
export class AddCommentComponent implements AfterViewInit {

  @ViewChild('comment') commentInput: ElementRef | undefined;
  custom: M_CustomProduct | undefined;
  form: UntypedFormGroup;
  v = ViewPath;

  constructor(public routerS: RouterService, private fs: FormService, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddCommentComponent>, @Inject(MAT_DIALOG_DATA) public data: M_CustomProduct | undefined) {
    this.form = this.fb.group({
      comment: ['', Validators.required],
      is_comment: [true]
    })
    if (this.data && this.data.comment) {
      this.form.patchValue({ comment: this.data.comment });
    }
  }

  create() {
    if (this.fs.isOk(this.form)) {
      let c = new M_CustomProduct(this.form.value);
      c.setQuantity(0);
      c.name = "Comentario";
      c.type = CustomProductType.COMMENT;
      this.dialogRef.close(c);
    }
  }

  ngAfterViewInit() {
    this.commentInput?.nativeElement.focus();
  }

  get disabled() {
    return !this.input && !this.form.valid;
  }

  get input(): number {
    let val = this.form.get('comment')!.value;
    return val ? val : '';
  }
}
