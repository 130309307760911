<form *ngIf="isTotalPay && isDialogForm" [formGroup]="form" class="df fdc line_form form_pay">
    <div class="header_form">
        <app-card-subtitle *ngIf="isDialogForm" [first]="true">{{ typePayName
            }}</app-card-subtitle>
        <app-documentation-pdf-menu [invoice]="invoice"></app-documentation-pdf-menu> <!--falta poner la factura compra en el documentation-->
    </div>
    <app-card-subtitle *ngIf="isDialogForm" [first]="true">{{ PayName }} Nº
        {{TitleObject}}</app-card-subtitle>
    <mat-divider></mat-divider>
    <div>
        <div class="header_info">
            <app-invoice-totals [pending_total_invoice]="pendingTotalInv"
                [total_invoice]="total"></app-invoice-totals>
        </div>

        <!-- CHIPS SELECTOR ON PHONE -->
        <div class="paymentChips fww" *ngIf="responsiveS.w >= 715">
            <div *ngFor="let payment of typePaymentS.typePaymentArray" class="chip-container">
                <mat-chip class="chip-spacing" [value]="payment.id"
                    [ngClass]="{'selected-chip': payment.id === selectedPaymentId}" (click)="selectPayment(payment.id)">
                    <mat-icon matChipAvatar smartIcon class="mr5">{{payment.icon}}</mat-icon>
                    {{payment.payment}}
                </mat-chip>
            </div>
        </div>
    </div>


    <div class="form">
        <!-- MAT SELECT ON DESKTOP -->
        <div class="df" *ngIf="responsiveS.w < 715">
            <mat-form-field [appearance]="'outline'" class="w100">
                <mat-label>Tipo de cobro</mat-label>
                <mat-select #selectedValue (selectionChange)="selectPayment($event.value)" [value]="selectedPaymentId">
                    <mat-option *ngFor="let payment of typePaymentS.typePaymentArray" [value]="payment.id">
                        <mat-icon matChipAvatar smartIcon class="mr5">{{payment.icon}}</mat-icon>
                        <p class="nmb df aic">{{payment.payment}}</p>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="df">
            <div class="eina-form-wrapper">
                <div class="eina-form-section df">
                    <mat-form-field appearance="outline" class="w50 mr10">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dynamicDate">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="example-full-width w50 mr10" appearance="outline">
                        <mat-label>Importe</mat-label>
                        <input formControlName="import" #importHtml matInput type="number" DecimalInput
                            (input)="checkImport(importHtml)">
                        <mat-error *ngIf="form.get('import')?.hasError('error_import') && form.get('payment_id')?.value !=1">El
                            importe supera el saldo pendiente.</mat-error>
                        <mat-hint *ngIf="cash && cashTotal" class="msg_hint">
                            Debes devolver : <span class=" ml5 fw600" money [val]="cashTotal"></span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <span *ngIf="showAdvances && !invoice.isAbono">
        <div *ngFor="let last_movement of advances">
            <app-show-advance-client #lsmv [dataMostrada]="last_movement" (onSelect)="disableOthers($event)"
                (ondisSelect)="disableAdvance($event)"></app-show-advance-client>
        </div>
    </span>

    <p *ngIf="isDialogForm && selected" class="c_tw fsi fss">
        Se aplicará un anticipo por valor de <span class="fw600" money [val]="selected.dataMostrada?.import"></span>
    </p>
    <div class="action mt10">
        <button [mat-dialog-close]="false" mat-button class="mr10 w50">Cancelar</button>
        <button color="primary" class="w50 onboarding-invoice-payment-finish" [disabled]="!form.valid || form.get('import')?.value <=0" mat-flat-button
            (click)="updateForm()">{{typePayName}}</button>
    </div>
</form>