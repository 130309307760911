import { CustomFile } from "../custom-classes/CustomFile";
import { TICKET_STATUS } from "../enums/TicketStatus";
import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { match } from "../services/search.service";
import { M_BaseUser } from "./M_BaseUser";
import { M_CloudTicketEvent as M_CloudTicketEvent } from "./M_CloudTicketEvent";
import { M_Jira_Issue } from "./M_Jira_Issue";

export class M_CloudTicket implements ISearchFiltrable {

    id: number;
    client_id: number | string | undefined;
    company_id: number | string;
    client_name : string | undefined;
    company_name: string | undefined;
    user_id: number;
    in_charge: number | undefined;
    title: string;
    description: string;
    status: TICKET_STATUS;
    events: M_CloudTicketEvent[] = [];
    created_at: Date;
    updated_at: Date;
    you: M_BaseUser | undefined;
    reporter: M_BaseUser | undefined;
    responsible: M_BaseUser | undefined;
    project: "sinicloud" | "weberp" | undefined;
    images: CustomFile[] = [];
    technicalname : string;
    jira_id : number | undefined;
    jira_issue : M_Jira_Issue | undefined;
    showingJiraIssue = false;

    /** Frontend only field */
    needResponsibleAtention: boolean = false;

    constructor(d: any) {
        this.id = d.id;
        this.technicalname = this.id.toString();
        this.user_id = d.user_id;
        this.in_charge = d.in_charge;
        this.title = d.title;
        this.description = d.description;
        this.status = d.status;
        this.project = d.project;

        /** Company and client info */
        this.client_id = d.client_id;
        this.company_id = d.company_id;
        this.client_name = d.client_name;
        this.company_name = d.company_name;

        if (d.events && d.events.length) {
            for (let i = 0; i < d.events.length; i++) {
                this.events.push(new M_CloudTicketEvent(d.events[i]));
            }
            this.events.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
            this.needResponsibleAtention = this.events.some(e => e.user_id != this.in_charge && !e.readed);
        }

        if (d.images && d.images.length) {
            for (let i = 0; i < d.images.length; i++) {
                this.images.push(new CustomFile(d.images[i].img, undefined, undefined, d.images[i].id));
            }
        }

        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);

        /** Implicated cloud ticket users 
         *  The users can be null if we are consturcting a notification
        */
        this.you = d.you ? new M_BaseUser(d.you) : undefined;
        this.reporter = d.reporter ? new M_BaseUser(d.reporter) : undefined;
        this.responsible = d.responsible ? new M_BaseUser(d.responsible) : undefined;

        /** Jira issue id */
        this.jira_id = d.jira_id? d.jira_id : undefined;

        this.generateTechnicalName(d.technicalname);
    }

    get pending() { return this.status == TICKET_STATUS.pending }
    get revision() { return this.status == TICKET_STATUS.revision }
    get solved() { return this.status == TICKET_STATUS.solved }

    get comments() { return this.events.filter(e => e.event == "comment") }
    get statuschanges() { return this.events.filter(e => e.event == "status") }

    get userCanEdit() {
        if (this.you && this.reporter && this.responsible) {
            return this.you.id == this.reporter.id || this.you.id == this.responsible.id;
        }
        return false;
    }

    generateTechnicalName(technicalname: any) {
        if (technicalname != undefined){
            this.technicalname = technicalname;
        }
        else {
            if (this.project){
                this.technicalname = 
                this.project == "sinicloud" ? 
                    ("SCLD-" + this.id) : 
                    ("EINA-" + this.id)
            }
        }
    }

    addUserComment(user: M_BaseUser, comment: string, cf? : CustomFile) {
        var newComment = new M_CloudTicketEvent(
            {
                value: comment,
                created_at: new Date(),
                updated_at: new Date(),
                user: user,
                file : cf,
                event: "comment",
            }
        )
        this.events.unshift(newComment)
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text, this.id.toString(), this.technicalname, this.title,  this.description, this.company_name, this.client_name);
    }
}
