<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Cambiar contraseña</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <form>
        <app-password-input [label]="'Contraseña'" #pass [form]="form" formCName="pass1"></app-password-input>
        <app-repeat-passowrd-input [label]="'Repite la contraseña'" #pass2 class_="mt-20" [otherPass]="pass"
            [form]="form" formCName="pass2">
        </app-repeat-passowrd-input>
        <div class="df jcc">
        </div>
    </form>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button (click)="updatePass(pass.value)" [disabled]="pass2.getDisabled()">
        Cambiar contraseña
    </button>
</div>