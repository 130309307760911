import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { DayFilter, EnumFilter, Filter, FilterOption, TagFilter, UserFilter } from '../../custom-classes/Filter';
import { or_status, or_status_invoiced } from '../../custom-classes/or_states';
import { or_types } from '../../custom-classes/or_types';
import { RouterService } from '../../services/router.service';
import { WorkloadData } from '../../custom-classes/WorkloadData';
import { WorkloadDataGetterService } from '../../services/workload-data-getter.service';
import { PeriodEnum } from '../../enums/PeriodEnum';
import { SnackService } from '../../services/snack.service';
import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ResponsiveService } from '../../services/responsive.service';
import { M_BaseUser } from '../../models/M_BaseUser';
import { AppointmentStatus } from '../../enums/AppointmentStatus';
import { StorageService } from '../../services/storage.service';
import { ConfirmData, ConfirmDialogService } from '../../services/confirm-dialog.service';
import { MemoryParamsService } from '../../services/memory-params.service';
import { CalendarService } from '../../services/calendar.service';
import { IAppointment } from 'src/app/interfaces/IAppointment';
import { IOr } from 'src/app/interfaces/IOr';
import { ICompany } from 'src/app/interfaces/ICompany';
import { getPrimaryColor } from 'src/app/utils/FunctionUtils';
import { projectConfiguration } from 'src/app/app.module';

/**
 * Query params : 
 * 
 * period --> (0 day, 1 week, 2 month)
 * 
 * section --> ("or" OR "appointment")
 * 
 * id --> id of item to highlight.
 * 
 * date --> go to specific day
 */
@Component({
  selector: 'app-core-carga-taller',
  templateUrl: './core-carga-taller.component.html',
  styleUrls: ['./core-carga-taller.component.css']
})
export class CoreCargaTallerComponent implements OnInit {

  @HostListener('click', ['$event'])
  onClick(event: any) {
    // get the clicked element
    if (event.target.ariaLabel && (event.target.ariaLabel.includes("Previous month") || event.target.ariaLabel.includes("Next month"))) {
      if (this.matCalendar.monthView) {
        this.loadTopLeftCalendar(new Date(this.matCalendar.monthView._weeks[0][0].rawValue));
      }
    }
  }

  @Output() onModifyAppointment: EventEmitter<[IAppointment, ("ra" | "eliminar" | "done" | "pending" | "hour")]> = new EventEmitter();
  @Output() deliveryWarn: EventEmitter<IOr> = new EventEmitter();

  /** Necessary input for initialize the component */
  @Input() workloadData!: WorkloadData;
  @ViewChild(MatSelect) matSelect!: MatSelect;
  @ViewChild(MatCalendar) matCalendar!: MatCalendar<Date>;

  /********** GRID **********/
  loadingCalendar: boolean = false;
  currentDay: WorkloadDay | undefined;
  currentWeek: Week | undefined;
  currentMonth: Month | undefined;

  /********** COMMON **********/
  /** Search filter */
  searchFilter: string = "";
  loaded_ = false;
  pe = PeriodEnum;
  selectedPeriod: PeriodEnum = PeriodEnum.WEEK
  refDay = new Date();
  users: M_BaseUser[] = [];
  currentFilters: Filter[] = [];
  storage_key = "workload_section"

  /********** OR **********/
  /** Filters */
  statusFilter: TagFilter = new TagFilter("Estado", or_status, new FilterOption("Abierta"), new FilterOption("Cerrada"), new FilterOption("Facturada"));
  typeFilter: TagFilter = new TagFilter("Tipo", or_types, new FilterOption("Normal"), new FilterOption("Interno"), new FilterOption("Garantia"), new FilterOption("Siniestro"), new FilterOption("Mantenimiento"));
  userFilter = new UserFilter("Usuarios", this.workloadData);
  or_filters: Filter[] = [this.userFilter, this.statusFilter, this.typeFilter];
  /** Top left calendar OR object */
  calendar_or: IOr[] = [];
  /** Main calendar OR */
  or: IOr[] = [];
  company: ICompany | undefined;

  highlightId: number | undefined;
  sectionParam: "or" | "appointment" | undefined;
  core = projectConfiguration;

  /********** APPOINTMENTS **********/
  /** Filters */
  appointemtStatus: TagFilter = new TagFilter("Estado", AppointmentStatus, new FilterOption("Pendiente"), new FilterOption("Hecha"), new FilterOption("Cancelada"));
  alreadyClientFilter: EnumFilter = new EnumFilter("Cliente", new FilterOption("Registrado", "how_to_reg"), new FilterOption("No registrado", "no_accounts"));
  appo_filters: Filter[] = [this.appointemtStatus, this.alreadyClientFilter];
  /** Main calendar Appointments */
  appointments: IAppointment[] = [];
  /** Top left calendar Appointments object */
  calendar_appointment: IAppointment[] = [];
  constructor(private chdRef: ChangeDetectorRef, private memoryP: MemoryParamsService, public routerS: RouterService, private confirmD: ConfirmDialogService, route: ActivatedRoute, private storageS: StorageService, private claendarS: CalendarService) {

    let isSinicloud = this.core.projectName == "sinicloud";
    this.userFilter.label = isSinicloud ? "Operarios" : "Usuarios";

    /**
     * Hide "Garantia" and "Interno" on Eina 
     * Delete the or type "Mantenimiento on Eina" 
    */
    if (!isSinicloud) {
      //this.typeFilter.options.get(1)!.hidden = true; // Interno
      this.typeFilter.options.get(2)!.hidden = true; // Garantia
      this.typeFilter.options.delete(this.typeFilter.options.size - 1)
    }
    /** Pre-filtered appointment (0 --> pending) */
    this.appointemtStatus.selectedOptions.push(0)
    /** Pre-filtered OR status (0 --> abierta, 1 --> cerrada ) */
    this.statusFilter.selectedOptions.push(0, 1);

    if (isSinicloud) {
      this.statusFilter.options.set(3, new FilterOption("Finalizada"))
      this.statusFilter.selectedOptions.push(0, 3);
    }

    route.queryParams.subscribe(q => {
      if (q['period']) {
        var v = Number(q['period']);
        if (v == PeriodEnum.DAY) {
          this.selectedPeriod = PeriodEnum.DAY;
        }
        else if (v == PeriodEnum.WEEK) {
          this.selectedPeriod = PeriodEnum.WEEK;
        }
        else if (v == PeriodEnum.MONTH) {
          this.selectedPeriod = PeriodEnum.MONTH;
        }
      }
      if (q['section']) {
        let s = q['section'];
        if (s == "or" || s == "appointment") {
          this.sectionParam = s;
        }
      }
      if (q['id']) {
        this.highlightId = (q['id'] as string).getNumber();
      }
      if (q['date']) {
        try {
          this.refDay = new Date(q['date']);
        }
        catch { }
      }

      this.or_filters.forEach((f, index) => {
        if (q['orfilter' + index]) {
          f.initByParam(Number(q['orfilter' + index]))
        }
      })

      this.appo_filters.forEach((f, index) => {
        if (q['apfilter' + index]) {
          f.initByParam(Number(q['apfilter' + index]))
        }
      })


    })

  }


  /** If the user has the value on storage, dont show the alert */
  initView(view: "or" | "appointment", showAlert: boolean = true) {
    if (view == "or") {
      if (this.workloadData.ORpermisison) {
        this.currentFilters = this.or_filters;
        this.workloadData.isOrView = true;
      }
      else if (showAlert) {
        var d: ConfirmData = { title: "Sin permisos", body: "Actualmente no tienes permisos para ver la página de OR" }
        this.confirmD.show(d)
        this.initView("appointment");
      }
    }

    if (view == "appointment") {
      if (this.workloadData.AppoPermisison) {
        this.currentFilters = this.appo_filters;
        this.workloadData.isOrView = false;
      }
      else if (showAlert) {
        var d: ConfirmData = { title: "Sin permisos", body: "Actualmente no tienes permisos para ver la página de citas" }
        this.confirmD.show(d)
        this.initView("or");
      }
    }
  }


  /** By default, the OR view */
  ngAfterViewInit() {

    /** If ther is a param. Is more important than the storage or the default project view */
    if (this.sectionParam != undefined) {
      this.initView(this.sectionParam);
    }

    /** If there is no param... */
    else {
      var onStorage = this.storageS.get(this.storage_key);

      /**No stored value, init with default view */
      if (onStorage == undefined) {
        this.initView(this.workloadData.data.defaultView);
      }

      /** There is some value on storage */
      else {
        this.initView(onStorage == "or" ? "or" : "appointment", false);
      }
    }

    this.workloadData.data.general.users().then(res => {
      this.users = res;
    })
    this.onRefDayChanges();
    this.loadTopLeftCalendar(new Date());
    this.sideCalendarListeners();
    this.userFilter.wd = this.workloadData;
    this.userFilter.reload();
  }

  /** Change the OR / Appointment view */
  changeView(or: boolean) {
    this.storageS.save(this.storage_key, or ? "or" : "appointment");
    this.memoryP.add(["section", or ? "or" : "appointment"])

    // --> The workload data is not always defined (Getting query params of the url)
    if (this.workloadData && or != this.workloadData.isOrView) {
      this.workloadData.changeView(or);
      this.searchFilter = "";
      this.currentFilters = this.workloadData.isOrView ? this.or_filters : this.appo_filters;
      this.loadTopLeftCalendar(this.refDay);
      this.onRefDayChanges();
      this.chdRef.detectChanges();
    }
  }

  syncAppoWithLeftCalendar(item: IAppointment) {
    this.calendar_appointment.map(v => {
      if (v.id == item.id) {
        this.calendar_appointment.removeElement(v);
      }
    })
    this.calendar_appointment.push(item);
    this.matCalendar.updateTodaysDate();
    this.chdRef.detectChanges();
  }

  onDragFinished(item: IOr | IAppointment) {
    // If is or
    if ("images" in item) {
      this.calendar_or.map(v => {
        if (v.id == item.id) {
          this.calendar_or.removeElement(v);
        }
      })
      this.calendar_or.push(item);
    }
    // If is Appointment
    else {
      this.calendar_appointment.map(v => {
        if (v.id == item.id) {
          this.calendar_appointment.removeElement(v);
        }
      })
      this.calendar_appointment.push(item);
    }

    this.matCalendar.updateTodaysDate();
    this.chdRef.detectChanges();
  }

  applySearchFilter(value: string) {
    this.searchFilter = value;
    this.chdRef.detectChanges();
  }

  sideCalendarListeners() {

    this.matCalendar.selectedChange.subscribe(v => {
      this.onCaledarDayChange(v)
    })

    /** TODO */
    //Arrow change

    /** Selecting month on calendar (not with arrows) */
    this.matCalendar.monthSelected.subscribe(v => {
      this.loadTopLeftCalendar(new Date(v));
    })
  }

  /** Top left calendar */
  loadTopLeftCalendar(d: Date) {
    let m = this.getMonthByDay(d)
    var fromDate = m.first.v;
    var toDate = m.last.v;
    this.loadingCalendar = true;
    //Conditionally load the calendar
    this.workloadData.isOrView ? this.loadCalendarOr(fromDate, toDate) : this.loadCalenarAppointments(fromDate, toDate);
    this.chdRef.detectChanges();
  }

  loadCalenarAppointments(fromDate: Date, toDate: Date) {
    if (!this.company) {
      const a = this.workloadData.data.general.company();
      const b = this.workloadData.data.appointments.appointments(fromDate, toDate, false);
      forkJoin([a, b]).subscribe(res => {
        this.company = res[0];
        this.calendar_appointment = res[1];
        this.loadingCalendar = false;
        this.matCalendar.updateTodaysDate()
      })
    }
    else {
      this.workloadData.data.appointments.appointments(fromDate, toDate, false).then(res => {
        this.calendar_appointment = res;
        this.loadingCalendar = false;
        this.matCalendar.updateTodaysDate()
      })
    }
  }

  loadCalendarOr(fromDate: Date, toDate: Date) {
    if (!this.company) {
      const a = this.workloadData.data.general.company();
      const b = this.workloadData.data.or.schedules(fromDate, toDate, false);
      forkJoin([a, b]).subscribe(res => {
        this.company = res[0];
        this.calendar_or = res[1];
        this.loadingCalendar = false;
        this.matCalendar.updateTodaysDate()
      })
    }
    else {
      this.workloadData.data.or.schedules(fromDate, toDate, false).then(res => {
        this.calendar_or = res;
        this.loadingCalendar = false;
        this.matCalendar.updateTodaysDate()
      })
    }
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
  }

  getTooltipByFilter(p: PeriodEnum, next: boolean) {
    let v = "";
    switch (p) {
      case this.pe.DAY:
        v = "Día"; break;
      case this.pe.WEEK:
        v = "Semana"; break;
      case this.pe.MONTH:
        v = "Mes"; break;
    }
    return next ? "Siguiente " + v : v + " anterior"
  }

  arrowNextByFilter(p: PeriodEnum, next: boolean) {
    let v = 0;
    switch (p) {
      case this.pe.DAY:
        v = 1; break;
      case this.pe.WEEK:
        v = 7; break;
      case this.pe.MONTH:
        v = 30; break;
    }

    if (next) {
      this.refDay.plusDays(v);
    }
    else {
      this.refDay.minusDays(v);
    }

    this.selectedPeriod = p;
    this.onRefDayChanges();
  }

  onCaledarDayChange(d: Date | null) {
    if (d) {
      this.refDay = new Date(d);
      this.onRefDayChanges();
    }
  }

  get showLegend() {
    return (this.workloadData.isOrView && this.company?.getPlaces() != undefined) || (!this.workloadData.isOrView && this.company?.getMaxAppointments() != undefined)
  }

  onSelectedPeriodChange(e: number) {
    this.selectedPeriod = e;
    this.memoryP.add(["period", e.toString()])
    this.onRefDayChanges();
  }

  bigRefByDay(p: PeriodEnum) {
    switch (p) {
      case this.pe.DAY:
        return this.refDay.getMonthName(true) + " " + this.refDay.getDate() + ", " + this.refDay.getYear(true);
      case this.pe.WEEK:
      case this.pe.MONTH:
        return this.refDay.getMonthName(true) + " " + this.refDay.getYear(true);
    }
  }

  goToday() {
    this.refDay = new Date();
    this.onRefDayChanges();
  }

  getItemOfDay(d: Date, reverse = false) {
    if (reverse) {
      return !this.workloadData.isOrView ?
        this.or.filter(or => { return or.schedule?.isEquals(d) }) :
        this.appointments.filter(appo => { return appo.appointment_date!.isEquals(d) });
    }
    else {
      return this.workloadData.isOrView ?
        this.or.filter(or => { return or.schedule?.isEquals(d) }) :
        this.appointments.filter(appo => { return appo.appointment_date!.isEquals(d) });
    }
  }

  /** Calendar workload colors */
  getCalendarItemOfDay(d: Date) {
    return this.workloadData.isOrView ?
      this.calendar_or.filter(or => { return or.schedule?.isEquals(d) && !or.status.invoiced }) :
      this.calendar_appointment.filter(appo => { return appo.appointment_date!.isEquals(d) && appo.status == AppointmentStatus.pending })
  }

  onRefDayChanges() {
    this.loaded_ = false;
    let fromDate = new Date();
    let toDate = new Date();

    switch (this.selectedPeriod) {
      case (PeriodEnum.DAY): {
        fromDate = this.refDay;
        toDate = this.refDay;
        break;
      }
      case (PeriodEnum.WEEK): {
        let w = this.getweekDaysByDay(new Date(this.refDay));
        this.chdRef.detectChanges();
        fromDate = w.first.v;
        toDate = w.last.v;
        break;
      }
      case (PeriodEnum.MONTH): {
        let m = this.getMonthByDay(new Date(this.refDay))
        fromDate = m.first.v;
        toDate = m.last.v;
        break;
      }
    }

    this.workloadData.isOrView ? this.onRefDayChangeOr(fromDate, toDate) : this.onRefDayChangeApponimtent(fromDate, toDate);
    this.memoryP.add(['date', this.refDay.toString()]);
  }

  onRefDayChangeOr(fromDate: Date, toDate: Date) {
    const a = this.workloadData.data.or.schedules(fromDate, toDate, true);
    const b = this.workloadData.data.appointments.appointments(fromDate, toDate, true);
    forkJoin([a, b]).subscribe(res => {
      this.or = res[0];
      this.appointments = res[1];
      this.refreshUserOrFilterNumber(this.or);
      this.regenerateCalendar();
      this.loaded_ = true;
    })
  }

  onRefDayChangeApponimtent(fromDate: Date, toDate: Date) {
    const a = this.workloadData.data.appointments.appointments(fromDate, toDate, true);
    const b = this.workloadData.data.or.schedules(fromDate, toDate, true);
    forkJoin([a, b]).subscribe(res => {
      this.appointments = res[0];
      this.or = res[1];
      this.regenerateCalendar();
      this.loaded_ = true;
    })
  }

  sortByHour(wd: WorkloadDay[]) {
    wd.forEach(day => {
      day.data.sort((a, b) => {
        if ("cita" in a && "cita" in b) {
          return a.appointment_date > b.appointment_date ? 1 : -1
        }
        return 0;
      })
    })
  }

  regenerateCalendar() {
    switch (this.selectedPeriod) {
      case (PeriodEnum.DAY): {
        this.currentDay = new WorkloadDay(this.refDay, this.getItemOfDay(this.refDay));
        /** Hour sort if is appointment */
        if (!this.workloadData.isOrView) {
          this.sortByHour([this.currentDay]);
        }
        this.chdRef.detectChanges();
        break;
      }
      case (PeriodEnum.WEEK): {
        this.currentWeek = this.getweekDaysByDay(new Date(this.refDay));
        /** Hour sort if is appointment */
        if (!this.workloadData.isOrView) {
          this.sortByHour(this.currentWeek.days);
        }
        this.chdRef.detectChanges();
        break;
      }
      case (PeriodEnum.MONTH): {
        this.currentMonth = this.getMonthByDay(new Date(this.refDay));
        /** Hour sort if is appointment */
        if (!this.workloadData.isOrView) {
          this.currentMonth.weeks.forEach(week => {
            this.sortByHour(week.days)
          })
        }
        this.chdRef.detectChanges();
        break;
      }
    }
  }

  /** Get the first day of week  */
  getFirstWeekDayByDay(d: Date) {
    for (let i = 0; i <= 7; i++) {
      if (d.getDay() == 1) {
        return d;
      }
      d.minusDays(1);
    }
    throw Error("Cannot get the first day of week")
  }

  getweekDaysByDay(d: Date) {
    let first = this.getFirstWeekDayByDay(d);
    let week: WorkloadDay[] = [new WorkloadDay(new Date(first), this.getItemOfDay(first))];
    for (let i = 0; i <= 5; i++) {
      first.plusDays(1);
      week.push(new WorkloadDay(new Date(first), this.getItemOfDay(first)));
    }
    return new Week(week);
  }

  /** The first day represneted on screen, not always is the same motnh */
  getFirstMonthDayByDay(d: Date) {
    let date = new Date(d.getFullYear(), d.getMonth(), 1);
    return new Date(this.getFirstWeekDayByDay(new Date(date)));
  }

  getLastMonthDayByDay(d: Date) {
    let date = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return new Date(date);
  }

  getMonthByDay(d: Date) {
    const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
    let first = this.getFirstMonthDayByDay(d);
    let month: Month = new Month();
    for (let i = 0; i < 5; i++) {
      month.appendWeek(this.getweekDaysByDay(first));
      if (i != 4) {
        first.plusDays(6);
      }
      // Check if the month needs to display 6 weeks on the screen
      else {
        first.plusDays(1);
        if (firstDay.getMonth() == first.getMonth()) {
          month.appendWeek(this.getweekDaysByDay(first));
        }
      }
    }
    this.refDay!.getDayName()
    return month;
  }

  isOtherMonth(d: Date) {
    return this.refDay?.getMonth() != d.getMonth();
  }

  goToSpecificDay(d: Date) {
    this.selectedPeriod = this.pe.DAY;
    this.refDay = d;
    this.chdRef.detectChanges();
    this.onRefDayChanges();
  }


  goToSpecificWeek(d: Date) {
    this.selectedPeriod = this.pe.WEEK;
    this.refDay = d;
    this.chdRef.detectChanges();
    this.onRefDayChanges();
  }

  refreshUserOrFilterNumber(or: IOr[]) {
    this.userFilter.or = or;
  }

  /** Mat calendar colors */
  dateClass = (d: Date) => {
    return this.workloadData.isOrView ? this.dateClassOr(d) : this.dateClassAppointments(d);
  }

  dateClassOr(d: Date) {
    var date_ = new Date(d);
    var or = this.getCalendarItemOfDay(date_);
    var places = this.company?.getPlaces();
    return this.claendarS.getDayColorByOrLength(or.length, places);
  }

  dateClassAppointments(d: Date) {
    var date_ = new Date(d);
    var items = this.getCalendarItemOfDay(date_);
    var places = this.company?.getMaxAppointments();
    return this.claendarS.getDayColorByOrLength(items.length, places);
  }

  get isWeek() { return this.selectedPeriod == this.pe.WEEK; }

}




/** ----------------------------------- */


export class WorkloadDay {
  constructor(public v: Date, public data: IOr[] | IAppointment[]) { };
  get dataAsAny() {
    return this.data as any;
  }
}


export class Week {
  constructor(public days: WorkloadDay[]) { };
  get first() {
    return this.days[0];
  }
  get last() {
    return this.days[this.days.length - 1];
  }
}

export class Month {
  weeks: Week[] = []
  constructor() { };
  appendWeek(w: Week) {
    this.weeks.push(w);
  }
  get first() {
    return this.weeks[0].first;
  }
  get last() {
    return this.weeks[this.weeks.length - 1].last;
  }
}




/** ----------------------------------- */
@Component({
  selector: 'app-drag-and-drop-grid',
  templateUrl: './drag-and-drop-grid/grid/drag-and-drop-grid.component.html',
  styleUrls: ['./drag-and-drop-grid/grid/drag-and-drop-grid.component.css']
})
export class DragAndDropGridComponent implements OnInit {

  /** Period enum of filter selector */
  @Input() periodEnum!: PeriodEnum;
  pe = PeriodEnum;
  @Output() onDragFinished: EventEmitter<IOr | IAppointment> = new EventEmitter();
  @Output() onModifyAppointment: EventEmitter<[IAppointment, ("ra" | "eliminar" | "done" | "pending" | "hour")]> = new EventEmitter();
  @Output() deliveryWarn: EventEmitter<IOr> = new EventEmitter();
  onFinishDrag: Subject<boolean> = new Subject()
  draggingCard: IOr | IAppointment | undefined;
  core = projectConfiguration;
  
  constructor(@Inject(CoreCargaTallerComponent) public parent: CoreCargaTallerComponent, private snackS: SnackService, private routerS: RouterService, private dataGetter: WorkloadDataGetterService) { }

  ngOnInit(): void { }

  getTemporalLineClass(d: Date) {
    if (this.draggingCard && "images" in this.draggingCard) {
      let or: IOr = this.draggingCard;
      if (or.delivery && or.schedule) {
        // Schedule, deelivery and the calendar day are the same
        if (or.delivery.isEquals(or.schedule) && d.isEquals(or.delivery)) {
          return "temporal-line-mixed"
        }
        // If the calendar day is the same as the or schedule
        else if (d.isEquals(or.schedule)) {
          return "temporal-line-schedule"
        }
        // If the calendar day is the same as the or delivery
        else if (d.isEquals(or.delivery)) {
          return "temporal-line-delivery"
        }
        //If the caledar day is between the delivery and the schedule
        else if (!d.isEquals(or.schedule)) {
          let minDate: Date = or.delivery < or.schedule ? or.delivery : or.schedule;
          let maxDate: Date = or.delivery > or.schedule ? or.delivery : or.schedule;
          if (d > minDate && d < maxDate) {
            return "temporal-line-schedule";
          }
        }
      }
    }
    return undefined;
  }

  /** Yes, yes, getting the user holidays on the user object parent. */
  getOrUserAssignedHolidays(date: Date) {
    if (this.draggingCard && "images" in this.draggingCard && this.draggingCard.assigned) {
      let draggingObj: IOr = this.draggingCard;
      let user = this.parent.users.find(u => u.id == draggingObj?.assigned?.id)
      if (user) {
        return user.holidays.find(d => d.isEquals(date)) != undefined;
      }
    }
    return false;
  }

  isOrDelivery(date: Date) {
    if (this.draggingCard && "images" in this.draggingCard && this.draggingCard.delivery) {
      return this.draggingCard.delivery.isEquals(date);
    }
    return false;
  }

  isOrSchedule(date: Date) {
    if (this.draggingCard && "images" in this.draggingCard && this.draggingCard.schedule) {
      return this.draggingCard.schedule.isEquals(date);
    }
    return false;
  }

  isCompanyHolidays(date: Date) {
    if (!this.parent.company) { return false }
    return this.parent.company.holidays.find(h => h.isEquals(date)) != undefined
  }


  drop(event: CdkDragDrop<IOr[] | IAppointment[]>, to: Date) {

    this.draggingCard = undefined;

    if (to.getDay() == 0) {
      this.snackS.show(this.parent.workloadData.isOrView ?
        "No se puede mover una OR a un Domingo" :
        "No se puede mover una cita a un Domingo"
      )
      return;
    }

    if (!to.todayOrBigger()) {
      this.snackS.show(this.parent.workloadData.isOrView ?
        "No se puede mover una OR a un día anterior al de hoy" :
        "No se puede mover una cita a un día anterior al de hoy"
      )
      return;
    }

    /** Dragged or sorted ITEM */
    let item = event.item.data;

    /** Day change */
    if (event.previousContainer != event.container) {
      if (item) {
        /** OR */
        if ("images" in item) {
          if (item.status.invoiced) {
            this.snackS.show("No puedes mover de día una OR ya facturada")
          }
          /** In Sinicloud, closed ORs cannot be moved by day either*/
          else if (((item.status.pending || item.status.sinicloudFinished) && this.core.projectName == "sinicloud")) {
            if (item.status.pending) { this.snackS.show("No puedes mover de día una OR ya cerrada") }
            if (item.status.sinicloudFinished) { this.snackS.show("No puedes mover de día una OR ya finalizada") }
          }
          else {
            var updateSchedule = this.parent.workloadData.data.or.updateSchedule;
            if (updateSchedule) {
              this.dataGetter.updateSchedule(updateSchedule, item.id, to).then(res => {
                transferArrayItem(
                  event.previousContainer.data as IOr[],
                  event.container.data as IOr[],
                  event.previousContainer.data.indexOf(event.item.data),
                  event.currentIndex,
                );
                if ("images" in item) {
                  item.schedule = to;
                  this.reorderCall(event as CdkDragDrop<IOr[]>, false, false);
                  this.onDragFinished.emit(item);
                }


                let aux = item as IOr;
                if (aux.delivery && aux.schedule && (aux.delivery.isEquals(aux.schedule) || aux.delivery <= aux.schedule)) {
                  this.deliveryWarn.emit(item);
                }
              })
            }
          }
        }

        /** APPOINTMENT */
        else {
          var updateAppoEndpoint = this.parent.workloadData.data.appointments.updateAppointment;
          if (updateAppoEndpoint) {
            /** Avoid the new date be 00:00:00 */
            to.setHours(item.appointment_date.getHours(), item.appointment_date.getMinutes(), item.appointment_date.getSeconds())
            this.dataGetter.updateAppointment(updateAppoEndpoint, item.id, to).then(res => {
              transferArrayItem(
                event.previousContainer.data as IAppointment[],
                event.container.data as IAppointment[],
                event.previousContainer.data.indexOf(event.item.data),
                event.currentIndex,
              );
              if ("images" in item) { } else {
                item.day = to;
                this.onDragFinished.emit(item);
                /** Appointments need to sort by hour */
                this.parent.regenerateCalendar();
              }
            })
          }
        }
      }
      else {
        this.snackS.show("Algo salió mal al mover la OR de día")
      }
    }
    /** Sort */
    else {
      /** Only sort on OR items */
      if (event.previousIndex != event.currentIndex) {
        if (item && "images" in item) {
          this.actionReorder(event as CdkDragDrop<IOr[]>); // Screen reorder
          this.reorderCall(event as CdkDragDrop<IOr[]>, true, true);
        }
        else {
          this.snackS.show("Las citas se ordenan automáticamente según su hora")
        }
      }
    }

  }

  /** ONLY ON OR !! */
  reorderCall(event: CdkDragDrop<IOr[]>, rollBackOnFail: boolean, showLoading: boolean) {
    var reorder = this.parent.workloadData.data.or.reorderAction;
    if (reorder) {
      let action_ids = event.container.data.map(a => a.id);
      this.dataGetter.reorderActions(reorder, action_ids, showLoading).then(res => {
        if (res == -1) {
          if (rollBackOnFail) { this.actionReorder(event, true); } //Rollback the card to its previous position
          this.snackS.show("El orden de la OR no se ha podido guardar");
        }
      })
    }
  }

  actionReorder(event: CdkDragDrop<IOr[]>, reverse = false) {
    moveItemInArray(event.container.data, reverse ? event.currentIndex : event.previousIndex,
      reverse ? event.previousIndex : event.currentIndex);
  }

  onScroll(e: any) {
  }

  goCreateOnDay(d: Date) {
    if (this.parent.workloadData.isOrView) {
      var createOrView = this.parent.workloadData.data.or.views.createOr;
      if (createOrView) {
        this.routerS.goWithQueryParams(createOrView, { "startDate": d.toString() })
      }
    }
    else {
      var createAppoView = this.parent.workloadData.data.appointments.views.createAppointment;
      if (createAppoView) {
        this.routerS.goWithQueryParams(createAppoView, { "appointmentDay": d.toString() })
        /** Sinicloud deprecated */
        //this.routerS.goToWithState(createAppoView, d)
      }
    }

  }

}


@Component({
  selector: 'app-month-grid',
  templateUrl: './drag-and-drop-grid/grid/month-grid/month-grid.component.html',
  styleUrls: ['./drag-and-drop-grid/grid/drag-and-drop-grid.component.css']
})

export class MonthGridComponent {
  constructor(@Inject(DragAndDropGridComponent) public grid: DragAndDropGridComponent, public respoisiveS: ResponsiveService) { }

  /** Something is wrong with this */
  filterOrByWorker(actions: IOr[] | IAppointment[]): any {
    let filteredActions: any = [];
    for (let i = 0; i < actions.length; i++) {

      var filtersOk = filterItem(actions[i], ...this.grid.parent.currentFilters);

      if (filtersOk)
        filteredActions.push(actions[i]);
    }
    return filteredActions;
  }
}




/** -------------- */
@Component({
  selector: 'app-week-grid',
  templateUrl: './drag-and-drop-grid/grid/week-grid/week-grid.component.html',
  styleUrls: ['./drag-and-drop-grid/grid/drag-and-drop-grid.component.css']
})
export class WeekGridComponent {
  constructor(@Inject(DragAndDropGridComponent) public grid: DragAndDropGridComponent, public respoisiveS: ResponsiveService) { }
}


/** -------------- */
@Component({
  selector: 'app-day-grid',
  templateUrl: './drag-and-drop-grid/grid/day-grid/day-grid.component.html',
  styleUrls: ['./drag-and-drop-grid/grid/drag-and-drop-grid.component.css', './drag-and-drop-grid/grid/day-grid/day-grid.component.css']
})
export class DayGridComponent {
  constructor(@Inject(DragAndDropGridComponent) public grid: DragAndDropGridComponent, public respoisiveS: ResponsiveService) { }
}




@Component({
  selector: 'app-grid-card',
  templateUrl: './drag-and-drop-grid/grid-card/grid-card.component.html',
  styleUrls: ['./drag-and-drop-grid/grid-card/grid-card.component.css', './drag-and-drop-grid/grid-card/grid-day.css',
    './drag-and-drop-grid/grid-card/grid-month.css', './drag-and-drop-grid/grid-card/grid-week.css']
})
export class GridCardComponent implements OnInit {
  /**[AppointmentInterface, ("ra" |"eliminar" | "done" | "hour")] */
  @Output() onModifyAppointment: EventEmitter<[IAppointment, ("ra" | "eliminar" | "done" | "pending" | "hour")]> = new EventEmitter();
  @Output() onRemoveHighlight: EventEmitter<any> = new EventEmitter();
  @Output() onStartDragging: EventEmitter<IOr | IAppointment> = new EventEmitter();
  @Output() deliveryWarn: EventEmitter<IOr> = new EventEmitter();
  @Input() filters: Filter[] = [];
  @Input() searchFilter: string = "";
  @Input() allUsers: M_BaseUser[] = []; //Menu that allow  to change the worker assigned to the or
  @Input() period!: PeriodEnum;
  @Input() item!: IOr | IAppointment;
  @Input() draggable: boolean | undefined = undefined;
  @Input() otherCardIsDragging: boolean = false;
  @Input() isOnMatMenu = false;
  @Input() workloadData?: WorkloadData;
  @Input() highlightId: number | undefined;
  @Input() day!: Date;
  pe = PeriodEnum;
  onfilters = false;

  constructor(private dataGetter: WorkloadDataGetterService, private routerS: RouterService) { }
  ngOnInit(): void { }

  /** First we check the input. */
  get canDrag() {
    if (this.isAppointmentItem(this.item)) {
      return this.item.status == AppointmentStatus.pending;
    }
    var draggableByWorkloadData = this.getWDDraggableSettings();
    return this.draggable != undefined ? this.draggable && draggableByWorkloadData : draggableByWorkloadData;
  }

  getWDDraggableSettings() {
    if (this.isOrItem(this.item)) {
      if (this.period == this.pe.DAY) { return this.workloadData?.data.or.draggable.day; }
      else if (this.period == this.pe.WEEK) { return this.workloadData?.data.or.draggable.week; }
      else { return this.workloadData?.data.or.draggable.month; }
    }
    else {
      if (this.period == this.pe.DAY) { return this.workloadData?.data.appointments.draggable.day; }
      else if (this.period == this.pe.WEEK) { return this.workloadData?.data.appointments.draggable.week; }
      else { return this.workloadData?.data.appointments.draggable.month; }
    }
  }

  onFilters() {
    if (this.isOrItem(this.item)) {
      var filtersOk = filterOR(this.item, ...this.filters);
      var searchOk = this.item.defaultSearchFilter(this.searchFilter);
      this.onfilters = filtersOk && searchOk;
      return this.onfilters;
    }
    else {
      var filtersOk = filterAppointment(this.item, ...this.filters);
      var searchOk = this.item.defaultSearchFilter(this.searchFilter);
      this.onfilters = filtersOk && searchOk;
      return this.onfilters;
    }
  }

  goEdit(id: number) {
    if (this.workloadData?.isOrView) {
      var editOr = this.workloadData?.data.or.views.editOr
      if (editOr) {
        this.routerS.goWithQueryParams(editOr, { or: id })
      }
    }
    else {
      var editAppo = this.workloadData?.data.appointments.views.editAppointment;
      if (editAppo) {
        this.routerS.goWithQueryParams(editAppo, { appointment: id })
      }
    }
  }

  disabledUserDragging() {
    return !this.onfilters && this.otherCardIsDragging && this.draggable;
  }

  preventCardClick(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onDrag(e: CdkDragStart<IOr | IAppointment>) {
    this.onStartDragging.emit(this.item);
    if (this.needH()) {
      this.onRemoveHighlight.emit();
    }
  }


  onHover() {
    this.highlightId = undefined;
    if (this.needH()) {
      this.onRemoveHighlight.emit();
    }
  }

  needH() {
    return this.item.id == this.highlightId;
  }


  /** If is OR item */
  isOrItem(v: IOr | IAppointment): v is IOr {
    return "images" in v;
  }

  getVehicleColor(item: IOr | IAppointment) {
    if (this.isOrItem(item)) {
      return item.vehicleColor();
    }
    else return getPrimaryColor();
  }


  /** If is appointment item */
  isAppointmentItem(v: IOr | IAppointment): v is IAppointment {
    return !this.isOrItem(v);
  }

  assignNewUser(user: M_BaseUser) {
    if (this.workloadData?.data.or.assignAction) {
      this.dataGetter.assignAction(this.workloadData?.data.or.assignAction, this.item.id, user.id!).then(res => {
        if (this.isOrItem(this.item)) {
          this.item.assigned = user;
        }
      });
    }
  }

  get icon() { return this.isOrItem(this.item) ? "build" : "event" }
  get isDay() { return this.period == this.pe.DAY; }
  get isWeek() { return this.period == this.pe.WEEK; }
  get isMonth() { return this.period == this.pe.MONTH; }
  get invoiced() {
    if (this.isOrItem(this.item)) {
      return this.item.status.num == or_status_invoiced.num
    }
    return false;
  }

}

function filterItem(item: IOr | IAppointment, ...filters: Filter[]) {
  if ("images" in item) {
    return filterOR(item, ...filters)
  }
  else {
    return filterAppointment(item, ...filters);
  }
}

/** Filter appointment function */
function filterAppointment(object: IAppointment, ...filters: Filter[]) {
  var isStatusOk = true; //Screen filters
  var isAlreadyClientOK = true; //Screen filters
  filters.forEach(filter => {
    if (filter.activated) {
      if (filter instanceof TagFilter) {
        isStatusOk = filter.checkFilter([object.status]);
      }
      if (filter instanceof EnumFilter) {
        isAlreadyClientOK = filter.checkFilter(object.isClient ? 0 : 1);
      }
    }
  })
  return isStatusOk && isAlreadyClientOK;
}


/** Filter OR function */
function filterOR(object: IOr, ...filters: Filter[]) {
  var isDayFilterOk = true; //Screen filters
  var isEnumFilterOk = true; //Screen filters
  var isTypeOk = true; //Screen filters
  var isStatusOk = true; //Screen filters
  var isUserFilterOk = true; //Screen filters
  filters.forEach(filter => {
    if (filter.activated) {
      if (filter instanceof DayFilter && isDayFilterOk) {
        isDayFilterOk = filter.checkFilter(object.schedule)
      }
      else if (filter instanceof EnumFilter && isEnumFilterOk) {
        isEnumFilterOk = filter.checkFilter(object.status.num)
      }
      else if (filter instanceof TagFilter) {
        if (filter.typeOfLabel == or_status) {
          isStatusOk = filter.checkFilter([object.status.num])
        }
        else {
          isTypeOk = filter.checkFilter(object.type.map(obj => obj.num));
        }
      }
      else if (filter instanceof UserFilter && isUserFilterOk) {
        isUserFilterOk = filter.checkFilter(object.assigned?.id)
      }
    }

  })
  return isDayFilterOk && isEnumFilterOk && isStatusOk && isTypeOk && isUserFilterOk;
}



