import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
/**
<div (fileDropped)="uploadS.onFileDropped($event, this)">
    <input type="file" #input_drop
        accept="image/*, video/*"
        capture="environment"
        ...
        ...
        (change)="uploadS.processFile(input_drop, this)">
  </div> 
*/
@Directive({
  selector: '[dragAndDrop]'
})
export class DragAndDropDirective {
  /** Si el ratón esta encima del elemento HTML, éste último passa a tener la clase 'fileover' */
  @HostBinding("class.fileover") fileOver: boolean = false;
  /** Esta 'directive' se debe poner en el elemento HTML donde se desee arrastrar los archivos */
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files)
    }
    this.fileOver = false;
  }
}
