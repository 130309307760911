import { Component, Input } from '@angular/core';
import { M_Vehicle } from '../../models/M_Vehicle';
import { getColorByVehicle } from '../../custom-classes/Colors';

@Component({
  selector: 'app-vehicle-lord-icon',
  templateUrl: './vehicle-lord-icon.component.html',
  styleUrls: ['./vehicle-lord-icon.component.css']
})
export class VehicleLordIconComponent {

  getColorByVehicle = getColorByVehicle;

  @Input({required : true}) vehicle! : M_Vehicle;
  @Input() small : boolean = true;

}
