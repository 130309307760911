import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Order } from 'src/app/models/M_Order';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ParamsService } from 'src/app/services/params.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-incoming-orders',
  templateUrl: './incoming-orders.component.html',
  styleUrls: ['./incoming-orders.component.css']
})
export class IncomingOrdersComponent {
  @Input() product?: M_Product;
  @Input() client?: M_Contact;
  incomingOrders: M_Order[] = []
  displayedColumns = ['prov_name', 'status', 'prov_phone', 'prov_email', 'created_at']
  dataSource: MatTableDataSource<M_Order> = new MatTableDataSource<M_Order>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public paramS: ParamsService, public responsiveS: ResponsiveService, public routerS: RouterService) { }

  ngAfterViewInit() {
    if (this.product?.orders) {
      this.incomingOrders = this.product.unfinishedOrders;
    } else if (this.client?.orders) {
      this.incomingOrders = this.client.orders.filter(order => !order.finished);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.incomingOrders;
    this.updateDisplayedColumns();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateDisplayedColumns();
  }

  updateDisplayedColumns(): void {
    if (this.responsiveS.w < 500) {
      this.displayedColumns = ['prov_name', 'created_at']
    } else if (this.responsiveS.w < 750) {
      this.displayedColumns = ['prov_name', 'status', 'prov_email', 'created_at'];
    } else {
      this.displayedColumns = ['prov_name', 'status', 'prov_phone', 'prov_email', 'created_at'];
    }
  }

  goOrder(element: M_Order) {
    this.routerS.goToWithState(ViewPath.orders, element.id)
  }

  goCreateOrder() {
    this.routerS.goTo(ViewPath.createOrder)
  }
}
