import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Order } from 'src/app/models/M_Order';
import { ParamsService } from 'src/app/services/params.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { ViewPath } from 'src/app/app-routing.module';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { MatDialog } from '@angular/material/dialog';
import { FaultsDialogComponent } from 'src/app/components/faults-dialog/faults-dialog.component';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { Filter, TagFilter, FilterOption, ClassSearcherFilter, ButtonToggleFilter, DayFilter } from 'src/app/custom-classes/Filter';
import { order_status } from 'src/app/custom-classes/order_states';
import { getAccentColor, getWarnColor, getPrimaryColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';

export enum OrderPageFiltesrEnum {
  ORDER_STATUS = 0,
  PRIORITY = 1,
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})

export class OrdersComponent {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Order>;
  v = ViewPath;
  f = filter;
  accent = getAccentColor;
  warn = getWarnColor;
  primary = getPrimaryColor;
  filters: Filter[] = [
    new TagFilter("Estado", order_status, new FilterOption("Pendiente"), new FilterOption("Finalizado")).setId(OrderPageFiltesrEnum.ORDER_STATUS),
    new TagFilter("Prioridad", undefined, new FilterOption("Normal", "shopping_cart_checkout"), new FilterOption("Urgente", "bolt")).setId(OrderPageFiltesrEnum.PRIORITY),
    new ClassSearcherFilter("Proveedor", MASTER_PROVIDER, "Buscar proveedor"),
    new ButtonToggleFilter("Envío", "Enviado", true),
    new DayFilter("Creación")
  ]

  constructor(private routerS: RouterService, public params: ParamsService, public apiS: ApiService, public subS: SubscriptionService, private d: MatDialog) {
    apiS.orders().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == OrderPageFiltesrEnum.ORDER_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  goStoreHouseEntry() {
    this.routerS.goTo(ViewPath.createStoreHouseEntry);
  }

  openFaultsDialog() {
    this.d.open(FaultsDialogComponent);
  }

  changeSend(order: M_Order) {
    this.apiS.changeSendedOrder(order);
  }

  send(order: M_Order) { return order.sendOrder; }
  client_name(order: M_Order) { return order.provider.name; }
  icon(order: M_Order) { return order.icon; }
  preIcon(order: M_Order) { return order.typeIcon; }

}
