export enum RolesEnum {
    ADMIN = 1, 
    ADVISER = 2,
    MECHANIC = 3,
    COMERCIAL = 4,
    RECAMBISTA = 5,
    NOONE = -1,
}

/** Most common array of roles for views */
export const roleGroup = {
    adminAdviser : [RolesEnum.ADMIN, RolesEnum.ADVISER],
    adminComercial : [RolesEnum.ADMIN, RolesEnum.COMERCIAL],
    adminRecambista : [RolesEnum.ADMIN, RolesEnum.RECAMBISTA],
    adminAdviserMechanic : [RolesEnum.ADMIN, RolesEnum.ADVISER, RolesEnum.MECHANIC],
    everyoneExcepMechanic : [RolesEnum.ADMIN, RolesEnum.ADVISER, RolesEnum.COMERCIAL, RolesEnum.RECAMBISTA],
    workloadAsDashboard : [RolesEnum.ADVISER, RolesEnum.MECHANIC],
    bigMamaAsDashboard : [RolesEnum.COMERCIAL, RolesEnum.RECAMBISTA]
}