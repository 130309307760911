import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CODE_PROMOTIONAL, } from 'src/app/constants/constants';
import { M_Company } from 'src/app/models/M_Company';
import { SendMailCodePromotionalComponent } from '../send-mail-code-promotional/send-mail-code-promotional.component';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/Api/api.service';
import { SnackService } from 'src/app/services/snack.service';
export interface DialogDiscountComponentInterface {
  title: string;
  body: string;
  value: number;
  bottom: string;
  extra: string;
  step_1: string | undefined;
  step_2: string | undefined;
  step_3: string | undefined;
  step_4: string | undefined;
  type: boolean
}
@Component({
  selector: 'app-discount-monthly',
  templateUrl: './discount-monthly.component.html',
  styleUrls: ['./discount-monthly.component.css']
})
export class DiscountMonthlyComponent {
  isCopy: boolean = false;
  codeFinish: string = "";
  company: M_Company | undefined;
  loaded: boolean = false;
  currentStepIndex: number = 0;
  interval: any;
  gifRoute = "./assets/video/";
  tipName = "codepromotional";
  tipIndex = 1;
  totalTips = 4;
  tipUrl = ""
  e = environment;
  public form: UntypedFormGroup;
  texts: DialogDiscountComponentInterface[] = [
    {
      title: '¡Que se sepa lo que vale EINA!',
      body: '¡Manda este código de descuento a otro taller para ',
      value: 0,
      bottom: 'que se registre en EINA y ambos ganáis 1 mes gratis!',
      extra: '',
      step_1: '',
      step_2: '',
      step_3: '',
      step_4: '',
      type: false
    },
    {
      title: 'Pasos a seguir:',
      body: '1: Envía el código a un amigo por email o WhatsApp.',
      value: 0,
      bottom: '2: Dile que EINA es genial! Porque en realidad lo es, no porque lo digamos nosotros…',
      extra: '3: Dale algún consejo de cómo funciona y explícale donde va el código promo.',
      step_1: '',
      step_2: '',
      step_3: '',
      step_4: '',
      type: false,
    }, {
      title: '',
      body: '',
      value: 0,
      bottom: '',
      extra: '',
      step_1: '',
      step_2: '',
      step_3: '',
      step_4: '',
      type: true,
    }
  ];
  constructor(public apiS: ApiService, private snackService: SnackService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DiscountMonthlyComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogDiscountComponentInterface) {
    this.form = this.formBuilder.group({
      code: ['', []],
    });

    this.apiS.company().then(res => {
      this.form.patchValue({ code: CODE_PROMOTIONAL + res.id });
      this.loaded = true;
    });
    const d = new Date();
    var firstWeekday = new Date(d.getFullYear(), d.getMonth(), 1).getDay();
    var offsetDate = d.getDate() + firstWeekday - 1;
    let week = Math.floor(offsetDate / 7);
    this.tipIndex = week + 1;
    this.tipUrl = this.gifRoute + this.tipName + ".mp4";

  }
  ngOnInit() {

    if (this.data.value) {
      this.form.get('code')?.disable();
      this.codeFinish = this.form.get('code')?.value;
    }
    this.startCarousel();
  }
  copyCode() {

    if (this.form.get('code')?.value != undefined) {
      navigator.clipboard.writeText(this.form.get('code')?.value);
      this.snackService.show("Enlace copiado en el portapapeles")
    }
  }
  sendMail() {
    this.dialog.open(SendMailCodePromotionalComponent, { data: { codePromotional: this.form.get('code')?.value, type: "email" } });
  }
  sendWhatsapp() {
    this.dialog.open(SendMailCodePromotionalComponent, { data: { codePromotional: this.form.get('code')?.value, type: "whatsapp" } });
  }
  startCarousel(): void {
    this.interval = setInterval(() => {
      this.currentStepIndex = (this.currentStepIndex + 1) % this.texts.length;
    }, 20000000); // Cambia de texto cada 3 segundos
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  previous(): void {
    this.currentStepIndex = (this.currentStepIndex - 1 + this.texts.length) % this.texts.length;
  }

  next(): void {
    this.currentStepIndex++;
    if (this.currentStepIndex === this.texts.length) {
      this.currentStepIndex = 0; // Reinicia al llegar al último elemento
    }
  }
  goToStep(index: number): void {
    this.currentStepIndex = index;
  }
}
