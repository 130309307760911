import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Product } from 'src/app/models/Products/M_Product';

@Component({
  selector: 'app-product-storehouse-controlstock-details',
  templateUrl: './product-storehouse-controlstock-details.component.html',
  styleUrls: ['./product-storehouse-controlstock-details.component.css']
})

export class ProductStorehouseControlstockDetailsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public product: M_Product) { }
}
