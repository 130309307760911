import { TypePurchase } from "../enums/TypePurchase";
import { VehicleType } from "../enums/VehicleType";
import { getArrayOf } from "../utils/FunctionUtils";
import { M_Contact } from "./M_Contact";
import { M_LastMovements } from "./M_LastMovement";
import { M_User } from "./M_User";
import { M_Vehicle } from "./M_Vehicle";

export class M_Purchase {
    id?: number;
    user?: M_User;
    date?:  Date | undefined;
    contact?: M_Contact;
    vehicle?: M_Vehicle;
    pdf_cv?: string;
    pdf_fc?: string;
    tax?: number;
    docum_prov_num: string;
    total?: number;
    type : TypePurchase;
    buy_num : number;
    comment : string;
    client_id: number | undefined;
    vehicle_id: number | undefined;
    last_movements : M_LastMovements[] =[];
    constructor(d: any) {
        this.id = d.id;
        this.user = new M_User(d.user);
        this.date = d.date ? new Date(d.date) : undefined;
        if (d.client) {
            this.contact = new M_Contact(d.client);
          }
        if (d.urlpdf) {
            this.pdf_cv = d.urlpdf;
        }
        if (d.pdffc) {
            this.pdf_fc = d.pdffc;
        }
        if(d.vehicle){
            this.vehicle = d.vehicle;
        }
        if(d.vehicle_id){
            this.vehicle_id = d.vehicle_id;
        }
        if(d.client_id){
            this.client_id = d.client_id;
        }
        if (d.last_movement) {
            this.last_movements = getArrayOf(M_LastMovements, d.last_movement);
        } else {
            this.last_movements = [];
        }
        this.tax = d.tax;
        this.total = d.total;
        this.docum_prov_num = d.docum_prov_num;
        this.type = d.type;
        this.buy_num = d.buy_num;
        this.comment = d.comment;
        
    }
    clientName(): string | undefined {
        return this.contact?.getName();
    }
    get typeName(){
        let name ="" 
        switch (this.type) {
            case 1:
                name = "FACTURA PROVEEDOR"
                break;
            case 2:
                name = "COMPRA VENTA"
                break;
            default:
                name = "FACTURA PROVEEDOR"
                break;
        }
        return name;
    }
    getTaxTotal(total : number , ivaPorcentaje: number){

        const iva = total * (ivaPorcentaje / 100);
        return iva;
    }
    get taxSum(){
        let iva = 0;
        if(this.total && this.tax){
            iva = this.total * (this.tax /100);
        }
        return iva;
    }
}