import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewInerface } from '../../custom-classes/View';
import { FileType } from '../../enums/FileType';
import { FormService } from '../../services/form.service';
import { RouterService } from '../../services/router.service';
import { SnackService } from '../../services/snack.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { ReportedProblem } from './Model/ReportedProblem';


export interface CloudCreateDialogData {
  view : ViewInerface;
  showGoIssues : boolean;
}

/**
 * Cloud ticket creation dialog
 * @afterClosed : undefined | ReportedProblem
 * 
 * Usage example : 
 *    this.d.open(CreateTicketDialog, { data: {view : ViewPath.issues} }).afterClosed().subscribe(res => {
        if (res != undefined) {
          ...
          ...
        }
      });
 */
@Component({
  selector: 'app-create-ticket-dialog',
  templateUrl: './create-ticket-dialog.html',
  styleUrls: ['./create-ticket-dialog.css']
})
export class CreateTicketDialog {

  form: UntypedFormGroup;
  sendDisabled = false;
  ft = FileType.image;
  @ViewChild(FileUploadComponent) fu!: FileUploadComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CloudCreateDialogData,
    private fb: UntypedFormBuilder, private formS: FormService,
    private snackS: SnackService, private routerS: RouterService,
    public dRef: MatDialogRef<CreateTicketDialog>) {

    this.form = this.form = this.fb.group({
      summary: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  sendProblem() {
    if (this.formS.isOk(this.form)) {
      var files = this.fu.getUploadedFiles();
      var reportedProblem: ReportedProblem = new ReportedProblem(this.form.get('summary')?.value, this.form.get('description')?.value, files ? files : [])
      this.sendDisabled = true;
      this.snackS.show("Gracias por tu aportación. La revisaremos lo antes posible", "", 4);
      this.dRef.close(reportedProblem);
    }
  }

  goIssues() {
    this.routerS.goTo(this.data.view, true);
  }

}
