import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

/** Strict NIF validator. 
 * 
 * Accepts : Only valid spanish NIF.
*/
export function nifValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

        if (!control.hasValidator(Validators.required) && !control.value){
            return null;
        }

        if (/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i.test(control.value) ||
            /^[TRWAGMYFPDXBNJZSQVHLCKE][0-9]{8}$/i.test(control.value) ||
            /^[KLMXYZklmxyz]{1}[0-9]{7}[A-Za-z]{1}$/i.test(control.value)) {
            return null;
        }
        else {
            return { dni: true };
        }
    }
}

/** Soft NIF validator. Allow more combinations.
 * 
 * Accepts : A string with at least one number and one character. The string must be at least 5 in length.
*/
export function softNifValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

        if (!control.hasValidator(Validators.required) && !control.value){
            return null;
        }

        /** At least one number and charater */                                                       /** At least 5 charater */
        if (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(control.value) && control.value.length && control.value.length >= 5) {
            return null;
        }
        else {
            return { dni: true };
        }
    }
}

//https://es.stackoverflow.com/questions/67041/validar-pasaporte-y-dni-espa%C3%B1oles