import { Injectable } from '@angular/core';
import { projectConfiguration } from '../app.module';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
  constructor() { }
  log(str?: any, ...optionalParams: any[]) {
    if (projectConfiguration.verbose) {
      console.log(str)
    }
  }
}
