<div *ngIf="show" class="developer-helper" [ngClass]="{'minimized' : status == s.minimized, 'maximized' : status == s.maximized, 'closed' : status == s.closed}">
    <div class="header">
        <div class="cp" style="margin-right: auto;" (click)="status == s.maximized ? status = s.minimized : status = s.maximized">
            <p class="nmb fw500">THE DEVELOPER HELPER</p>
        </div>
        <button mat-icon-button (click)="status == s.maximized ? status = s.minimized : status = s.maximized">
            <mat-icon>{{status == s.maximized ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
        </button>
        <button *ngIf="errors.length > 0" mat-icon-button (click)="status == s.maximized ? status = s.minimized : status = s.maximized">
            <mat-icon [matBadge]="errors.length" aria-hidden="false" aria-label="errores">warning</mat-icon>
        </button>
        
        <button mat-icon-button (click)="status = s.closed">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="developer-contents">
        <ng-content></ng-content>
    </div>
</div>
