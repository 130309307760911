import { Component, Input } from '@angular/core';
import { M_Contact } from '../../models/M_Contact';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-contact-lord-icon',
  templateUrl: './contact-lord-icon.component.html',
  styleUrls: ['./contact-lord-icon.component.css']
})
export class ContactLordIconComponent {
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;

  @Input({ required: true }) contact! : M_Contact;
  @Input() small : boolean = false;
}
