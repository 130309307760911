<div class="container">

    <div class="title" *ngIf="loaded">
        <app-go-back class="back" [text]="'Listado pedidos'" [v]="v.orders" [show]="loaded"></app-go-back>
        <app-view-title class="nmb">Editar pedido</app-view-title>
    </div>

    <app-card [contentLoaded]="loaded" class="order">

        <app-card-title *ngIf="order">
            <div class="df aic">
                <mat-icon class="mr5" smartIcon>{{order.typeIcon}}</mat-icon>
                <div class="mr5">{{order.order}}</div>
                <app-order-status-label [status]="order.order_status"></app-order-status-label>
                <div class="ml5 fss fw400">
                    <p class="nmb c_t2"> |  {{order.created_at?.dayMonthYearFormat()}}</p>
                </div>
            </div>
        </app-card-title>

        <app-card-subtitle *ngIf="order" [first]="true">
            <div class="df aic">
                <mat-icon class="mr5" smartIcon>{{order.provider.icon}}</mat-icon>
                <div class="mr5">{{order.provider.getName()}}</div>
            </div>
        </app-card-subtitle>

        <ng-container *ngIf="!orderFailedToLoad">
            <ng-container *ngIf="order">
                <app-product-line-table [canDeleteLastProduct]="false" [products]="order.products" [lineTypes]="['product']" [showSave]="true"
                    [showDiscount]="false" [showProductsStock]="false"
                    [showLocation]="false"
                    [showCost]="true"
                    [showPVP]="false"
                    [canModifyTable]="order.canEdit"
                    [showDest]="true"
                    [extendedInfoConfig]="{
                        showColumn : true,
                        actions : ['quantity-by-stock-min']
                    }"
                    ></app-product-line-table>
                <button mat-flat-button color="warn" *ngIf="order.canEdit"
                    [disabled]="productLineTable != undefined && !productLineTable.hasChanges"
                    (click)="editOrder()">Guardar</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="orderFailedToLoad">
            <p>Pedido no encontrado</p>
        </ng-container>
    </app-card>

</div>