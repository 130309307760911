import { M_Product } from "./Products/M_Product";
import { M_BaseOrder } from "./M_Order";
import { M_PreOrderBreakdown } from "./M_PreOrderBreakdown";
import { M_Fault } from "./M_Fault";

export class M_PreOrder extends M_BaseOrder {

    constructor(d: any) {
        super(d);
    }

    contains(item: M_Product | M_Fault) {
        /**Get the product */
        if (item instanceof M_Product) {
            return this.products.find(p => item.product_id == p.product_id) != undefined;
        }
        else {
            return this.products.find(p => item.product_id == p.product_id && item.id == p.fault_id) != undefined;
        }

    }

    containsSome(item: (M_Product | M_Fault)[]) {
        return item.some((it: M_Product | M_Fault) => !this.contains(it)) && item.some((it: M_Product | M_Fault) => this.contains(it));
    }

    containsAll(item: (M_Product | M_Fault)[]): boolean {
        return item.every((it: M_Product | M_Fault) => this.contains(it));
    }

    add(items: (M_Product | M_Fault) | (M_Product | M_Fault)[]) {
        let finalItems: (M_Product | M_Fault)[] = Array.isArray(items) ? items : [items];
        finalItems.forEach(item => {

            /**Get the product */
            let p = item instanceof M_Product ? item : item.product;
            if (p == undefined || !p.providers.length) { return; }

            /** Default quantity */
            let quantity = item instanceof M_Fault ? item.quantity : 1;

            if (!this.contains(item)) {
                p.quantity = quantity;
                p.fault_quantity = quantity;
                this.products.push(p);
            }
        });
    }


    remove(p: M_Product) {
        this.products.removeElement(p);
    }

    isOk() {
        return this.products.length != 0;
    }

    get multiple() {
        return this.getBreakdown().length > 1;
    }

    getBreakdown(): M_PreOrderBreakdown[] {
        let orders: M_PreOrderBreakdown[] = []
        this.products.forEach(p => {
            let provider = this.findProvider(orders, p);
            if (provider) {
                provider.products.push(p);
            }
            else {
                orders.push(new M_PreOrderBreakdown(p.selected_provider!, [p], p.typeOrder))
            }
        })
        return orders;
    }

    findProvider(list: M_PreOrderBreakdown[], product: M_Product): M_PreOrderBreakdown | undefined {
        let toReturn = list.find(list => {
            let hasProvider = list.provider.client_id == product.selected_provider?.client_id;
            let hasPriority = list.type == product.typeOrder;
            return hasProvider && hasPriority;
        });
        return toReturn;
    }
}

