import { Component, Input } from '@angular/core';
import { M_Contact } from '../../models/M_Contact';
import { M_Vehicle } from '../../models/M_Vehicle';
import { MatDialog } from '@angular/material/dialog';
import { ClientVehicleData, ClientVechileInfoDialogComponent as ClientVehicleInfoDialogComponent } from './client-vechile-info-dialog/client-vechile-info-dialog.component';

@Component({
  selector: 'app-client-vehicle-in-dialog',
  templateUrl: './client-vehicle-in-dialog.component.html',
  styleUrls: ['./client-vehicle-in-dialog.component.css']
})
export class ClientVehicleInDialogComponent {

  @Input() c: M_Contact | undefined;
  @Input() v: M_Vehicle | undefined;
  @Input() t: string | undefined;

  constructor(private d: MatDialog) { }

  openD() {
    this.d.open<ClientVehicleInfoDialogComponent, ClientVehicleData>(ClientVehicleInfoDialogComponent, {
      data: {
        client: this.c,
        vehicle: this.v
      }, autoFocus: false
    });
  }

  openWithParams(c: M_Contact | undefined) {
    this.d.open<ClientVehicleInfoDialogComponent, ClientVehicleData>(ClientVehicleInfoDialogComponent, {
      data: {
        client: this.c,
        vehicle: this.v
      }, autoFocus: false
    });
  }
}
