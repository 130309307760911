<app-page-structure [masterClass]="'product'" pageTitle="Productos" [autocompleteID]="'products'"
    searchLabel="Buscar producto" [addNewView]="v.createProduct" listTitleText="Listado de productos"
    createButtonClass="onboarding-create-product" noDataCreateNew="Crear un nuevo producto" [data]="[]"
    [displayedHeader]="displayedHeaders" [displayedColumns]="displayedColumns" [specialText]="specialText"
    [specialClass]="specialClass" [cellZize]="cellZize" [card]="card" [preIcon]="[productIcon]" [filters]="filters"
    [rightMenu]="rightMenu" [filter]="filterProducts.bind(this)" [basicUserSubscription]="subS.isBasic"
    (onSelect)="afterSelectProduct($event)" (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear producto' , icon : 'barcode'}" 
    (onclickRow)="params.go(v.productDetails, $event.product_id)"
    [quickFilter]="quickFilter">
    
    <ng-template #rightMenu>
        <button *ngIf="features.dataUpload" mat-menu-item [go]="v.storeHouseEntry">
            <mat-icon> save_alt</mat-icon>
            Entrada de almacén
        </button>
        <button recambios mat-menu-item (click)="routerS.goTo(v.discountGroups)">
            <mat-icon>abc</mat-icon>
            Grupos de descuento
        </button>
        <button recambios mat-menu-item (click)="openDialogReserve()">
            <mat-icon>class</mat-icon>
            Reservas
        </button>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <div class="df aic">
                <button class="infoButton fw1" [matTooltip]="'Ir al producto'" mat-flat-button color="warn"
                    [go]="v.productDetails" [param]="ps.selected!.product_id">
                    <mat-icon smartIcon *ngIf="!refreshReference">{{ps.selected!.icon}}</mat-icon>
                    {{ps.selected!.name.length > 25 ? (ps.selected!.name | slice:0:25) + '...' : ps.selected!.name}}
                    <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                </button>
                <button mat-icon-button [go]="v.createProduct" [param]="ps.selected!.product_id">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>


            <div>
                <div *ngIf="companyS.recambiosModule">
                    <app-card-subtitle class="nmb">Existencias</app-card-subtitle>
                    <mat-form-field appearance="outline">
                        <input matInput
                            [placeholder]="ps.selected?.cs_stock! < 0 ? '0' : (ps.selected?.cs_stock!.toString())"
                            readonly>
                    </mat-form-field>
                </div>
                <div *ngIf="!companyS.recambiosModule">
                    <app-card-subtitle class="nmb">Modificar existencias</app-card-subtitle>

                    <app-add-remove *ngIf="!refreshReference" [minValue]="0" [initValue]="this.ps.selected!.stock"
                        (onSave)="saveNewStock(ps.selected!, $event)"></app-add-remove>
                </div>
            </div>


            <app-card-subtitle>Última actualización</app-card-subtitle>
            <p>
                <mat-icon class="vam">calendar_month</mat-icon>
                {{ps.selected!.updated_at?.shortFormat()}}
            </p>
            <button mat-stroked-button [go]="v.createInvoice"
                [param]="{'newproductid' : ps.selected!.product_id, 'highlight_prod_id' : ps.selected!.product_id}">
                <mat-icon>receipt_long</mat-icon>
                Facturar artículo
            </button>

        </app-card>
    </ng-template>
</app-page-structure>