import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { ParamsService } from 'src/app/services/params.service';
import { ClassSearcherComponent } from '../class-searcher.component';

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.css']
})
export class VehicleInfoComponent {

  v = ViewPath;

  @Input() vehicle: M_Vehicle | undefined;
  @Input() small: boolean = true;
  @Input() classSearcher: ClassSearcherComponent<M_Contact> | undefined;

  constructor(public paramsS: ParamsService, public userS: UserService) { }

  goVehicle() {
    if (!this.vehicle?.deleted && this.userS.userIsAdminOrAdviser) {
      this.paramsS.goVehicleDetails(this.vehicle);
    }
  }

  get classSearcherHasSomethingSelected() {
    return this.classSearcher && this.classSearcher.selected != undefined;
  }
}
