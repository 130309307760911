<div [ngClass]="class_ ? class_ : ''">
    <button [matMenuTriggerFor]="menu" mat-mini-fab color="primary" [matTooltip]="'Notificaciones'">
        <mat-icon *ngIf="notificationNumber == 0" [filled]="false">notifications</mat-icon>
        <mat-icon *ngIf="notificationNumber != 0" matBadge="{{notificationNumber}}">notifications_active</mat-icon>
    </button>
</div>
<mat-menu #menu="matMenu" class="notifications-menu">
    <div class="overflowH">
        <div class="df fdc jcc notificationsheader">
            <div class="df fdr jcsb aic">
                <app-card-subtitle [first]="true" [noMarginBottom]="true">Notificaciones</app-card-subtitle>
                <p (click)="markAllNotificationsAsReded()" class="fss nmb"
                    [ngClass]="markAsReadedActive? 'cp c_b' : 'c_t2'">
                    Marcar como leídas</p>
            </div>
            <p class="c_t2 fss nmb">Mostrando las notificaciones de los últimos 30 días</p>
        </div>
        <mat-divider></mat-divider>


        <div *ngIf="!loaded; else contentLoaded">
            <app-skeleton class="notContent" [fullW]="true" [height]="100"></app-skeleton>
            <app-skeleton class="notContent" [fullW]="true" [height]="100"></app-skeleton>
            <app-skeleton class="notContent" [fullW]="true" [height]="100"></app-skeleton>
        </div>

        <ng-template #contentLoaded>
            <div class="noNotif" *ngIf="notifications?.length == 0">
                <p class="large nnt tac jcc"> Sin notificaciones </p>
                <p class="">¡Parece que estás al día!</p>
            </div>

            <!-- All notifications -->
            <div *ngFor="let not of notifications; last as isLast">
                <div>
                    <div class="notContent" [ngClass]="not.readed? 'readed' : 'toRead'"
                        (click)="clickNotification(not)">
                        <mat-icon>{{not.icon}}</mat-icon>
                        <div>
                            <p class="semi-bold notTitle">
                                {{not.title}}
                            </p>
                            <p class="notText">{{not.text}}</p>
                            <p class="notCompany" *ngIf="notificationCompany(not) != undefined">
                                {{notificationCompany(not)}}
                            </p>
                            <p class="minutesAgo">{{not.time_ago}}</p>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                <mat-divider *ngIf="!isLast"></mat-divider>
            </div>
        </ng-template>
    </div>
</mat-menu>