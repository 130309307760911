<div *ngIf="allActivity.length; else noData"  [ngClass]="isProfile ? 'profilelast':'activity-parent'">
    <div *ngFor="let a of allActivity; let last = last" [class]="'activity activity-'+a.activity_color">
        <div class="df">
            <div class="df fdc aic">
                <div class="circle"></div>
                <div class="circle-line" [ngClass]="{'last': last}"></div>
            </div>
            <div class="text-section">
                <p class="title">{{a.message}} </p>
                <p class="subtitle">
                    <span class="more" *ngIf="a.action" [go]="viewOf(a)" [param]="a.object_id">Ver</span>
                </p>
            </div>
        </div>
    </div>
</div>


<ng-template #noData>
    <p class="c_t2">Sin actividad reciente</p>
</ng-template>