import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { M_BaseUser } from '../../models/M_BaseUser';
import { projectConfiguration } from 'src/app/app.module';
import { IOr } from 'src/app/interfaces/IOr';

@Component({
  selector: 'lib-or-week-card',
  templateUrl: './or-week-card.component.html',
  styleUrls: ['./or-week-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-day.css']
})
export class OrWeekCardComponent implements OnInit {
  @Input() item!: IOr;
  @Input() canAssign!: boolean;
  @Input() allUsers!: M_BaseUser[];
  @Input() day!: Date;
  @Input() onFilters!: boolean;
  @Input() canContact: boolean | undefined;
  @Output() deliveryWarn: EventEmitter<IOr> = new EventEmitter();
  @Output() onAssignUser: EventEmitter<M_BaseUser> = new EventEmitter();
  @Output() onGoOR: EventEmitter<any> = new EventEmitter();
  core = projectConfiguration;

  constructor() { }

  ngOnInit(): void {}

  preventCardClick(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

}
