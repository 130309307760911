import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ViewPath } from 'src/app/app-routing.module';
import { ReferenceService } from '../../services/onboarding.service';
import { UserService } from '../../services/EinaMainData/user.service';
import { MultipleMenuSection } from './multiple-menu-item/multiple-menu-item.component';
import { Subject } from 'rxjs';
import { feature } from 'src/app/utils/FeaturesController';
import { BurgerMenuInterface, BurgerMultipleOption, BurgerOption } from 'src/app/services/burger.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { roleGroup, RolesEnum } from 'src/app/enums/RolesEnum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, BurgerMenuInterface {

  @Input() isDesktop = true;
  @Input() drawer?: MatDrawer;
  @Input() refService?: ReferenceService;
  @ViewChild('menu') menu!: ElementRef<HTMLDivElement>;
  @Output() onclicktrigger: EventEmitter<any> = new EventEmitter<any>();

  features = feature;
  opened = false;
  enter = false;
  exit = false;
  lockmenu = false;
  v = ViewPath;
  mms = MultipleMenuSection;
  onStartOnboardingSection: Subject<any> = new Subject();

  /** DASHBOARD */
  panel = new BurgerOption("Panel de control", "dashboard", this.v.dashboard);

  /** MANAGEMENT */
  contacts = new BurgerOption("Contactos", "contacts", this.v.contacts, [this.v.createContact, this.v.contactDetails], undefined, "onboarding-client");
  vehiculos = new BurgerOption("Vehículos", "wheel", this.v.vehicles, [this.v.createvehicle, this.v.vehicleDetails], undefined, "onboarding-vehicle");
  productos = new BurgerOption("Productos", "barcode", this.v.products, [this.v.createProduct, this.v.productDetails], undefined, "onboarding-product");
  BManagement = new BurgerMultipleOption("Gestión", "", [this.contacts, this.vehiculos, this.productos]);

  /** WORKLOAD */
  cargataller = new BurgerOption("Carga de taller", "equalizer", this.v.cargataller, undefined, undefined, "onboarding-workload");
  appontiments = new BurgerOption("Citas", "event", this.v.appointments, [this.v.appointments], undefined, "onboarding-appointments");
  budget = new BurgerOption("Presupuesto de taller", "calculate", this.v.budget, [this.v.createEditBudget], undefined, "onboarding-budget");
  or = new BurgerOption("Órdenes de reparación", "build", this.v.or, [this.v.or, this.v.createOr, this.v.editOr], undefined, "onboarding-or");
  BWorkshop = new BurgerMultipleOption("Taller", "person", [this.cargataller, this.appontiments, this.budget, this.or]);

  /** COMERCIAL */
  garage = new BurgerOption("Stock de vehículos", "garage", this.v.garage, [this.v.createVnVo, this.v.vnvoDetails, this.v.concepts, this.v.createConcept], undefined, "onboarding-garage");
  comercialbudget = new BurgerOption("Presupuesto comercial", "calculate", this.v.comercialBudget, [this.v.comercialBudget, this.v.createComercialBudget], undefined, "onboarding-comercial-budget");
  BComercial = new BurgerMultipleOption("Comercial", "", [this.garage, this.comercialbudget]);

  /** STOREHOUSE */
  storehouse = new BurgerOption("Gestión de almacén", "storefront", this.v.storehouses, [this.v.storehouses], undefined, "onboarding-storehouse");
  albaran = new BurgerOption("Albaranes", "assignment", this.v.albaranes, [this.v.createEditAlbaran], undefined, "onboarding-albaran");
  pedidos = new BurgerOption("Pedidos", "local_shipping", this.v.orders, [this.v.orders, this.v.createOrder], undefined, "onboarding-orders");
  storehouseEntry = new BurgerOption("Entrada de almacén", "archive", this.v.storeHouseEntry, [this.v.storeHouseEntry, this.v.createStoreHouseEntry], undefined, "onboarding-storehouse-entry");
  BStorehouse = new BurgerMultipleOption("Almacén", "person", [this.storehouse, this.albaran, this.pedidos, this.storehouseEntry]);

  /** FINANCE */
  sells = new BurgerOption("Ventas", "receipt_long", this.v.invoices, [this.v.invoices, this.v.createInvoice], undefined, "onboarding-invoice");
  purchases = new BurgerOption("Compras", "shopping_basket", this.v.purchases, [this.v.purchases], undefined, "onboarding-purchases");
  balance = new BurgerOption("Balance", "balance", this.v.balance, [this.v.balance], undefined, "onboarding-balance");
  BFinance = new BurgerMultipleOption("Finanzas", "person", [this.sells, this.purchases, this.balance]);

  /** CONFIGURATION */
  settings = new BurgerOption("Configuración", "settings", this.v.settings, [this.v.createEditUser, this.v.userdetails], undefined, "onboarding-company");


  initWorkloadName = this.cargataller.name;
  initWorkloadIcon = this.cargataller.icon;

  constructor(public userS: UserService, public responsiveS: ResponsiveService, public companyS: CompanyService) {
    this.changeDashboardToWorkload(this.isWorkloadDashboard);
  }

  get isWorkloadDashboard() {
    let role = this.userS.getRole();
    if (!role) { return false; }
    let is = roleGroup.workloadAsDashboard.includes(role)
    this.changeDashboardToWorkload(is);
    return is;
  }

  changeDashboardToWorkload(isWorkload: boolean) {
    if (isWorkload) {
      this.BWorkshop.child.removeElement(this.cargataller);
      this.cargataller.icon = this.panel.icon;
      this.cargataller.name = this.panel.name;
    }
    else {
      this.cargataller.icon = this.initWorkloadIcon;
      this.cargataller.name = this.initWorkloadName;
      if (!this.BWorkshop.child.includes(this.cargataller)){
        this.BWorkshop.child.push(this.cargataller);
      }
    }
  }

  afterGoTo() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.opened = false;
      }
    }
    else {
      if (this.drawer) {
        this.drawer.close()
        this.opened = false;
      }
    }
  }

  get hiddenToUser() {
    return this.responsiveS.isPhone();
  }

  ngOnInit(): void {
    /** When the onboarding inits, aut oassigns itself to the service input parameter.
     *  Like this, is possible to call this component inside the service of 'refService',
     */
    if (this.refService) {
      this.refService.menuComponent = this;
    }
  }

  onMouseEnterComponent() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.enter = true;
        this.exit = false;
        setTimeout(() => {
          if (!this.exit) {
            if (this.isDesktop) {
              this.open();
            }
          }
        }, 350);
      }
    }
  }

  onMouseLeaveComponent() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.exit = true;
        this.enter = false;
        if (this.isDesktop) {
          this.close();
        }
      }
    }
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
    if (!this.drawer) {
      this.lockmenu = false;
    }
  }

  toggle() {
    if (this.drawer) {
      if (this.drawer.opened) {
        this.drawer.close();
        this.opened = false;
      }
      else {
        this.drawer.open();
        this.opened = true;
      }
    }
    else {
      this.lockmenu = !this.lockmenu;
      this.opened = !this.opened;
    }
  }

  toggleTo(opened: boolean) {
    if (this.drawer) {
      if (!opened) {
        this.drawer.close();
        this.opened = false;
      }
      else {
        this.drawer.open();
        this.opened = true;
      }
    }
    else {
      this.lockmenu = opened;
      this.opened = opened;
    }
  }

  openAllDropdowns() {
    this.onStartOnboardingSection.next(true);
  }

}
