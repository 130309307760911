import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: 'mat-icon[filled]'
})
export class IconFilledDirective implements OnChanges {

  @Input('filled') filled!: boolean;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filled']) {
      if (this.filled) {
        this.elementRef.nativeElement.classList.add("material-symbols-outlined-filled");
      } else {
        this.elementRef.nativeElement.classList.remove("material-symbols-outlined-filled");
      }
    }
  }

}
