<app-card [noStyle]="true">
    <div class="df aic" *ngIf="filter.showLabel">
        <app-card-subtitle>{{filter.label}}</app-card-subtitle>
    </div>
    <div class="df aic">
        <p class="nmb" style="margin-right: 5px;">
            {{filter.checkBoxName ? filter.checkBoxName : filter.label }}
        </p>
        <mat-button-toggle-group class="button-toggle-filter" [(ngModel)]="filter.checked">
            <mat-button-toggle [value]="true">Si</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
        <span (click)="clear()" class="clear-text"
            [ngClass]="filter.checked != null ? 'some-value underline_hover' : 'no-value'">Quitar</span>
    </div>
</app-card>