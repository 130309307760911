/**
 * 
 * Template field. This is the custom fields respect the autonomous community
 * 
 * HTML Example : 
 * 
 * <p>
 *  {{add_content}}
 *  //here the input
 *  {{add_contentnext}}
 * <p/>
 * 
 */

import { SafeResourceUrl } from "@angular/platform-browser";
import { generateImageURL } from "../utils/Browserutils";

/**
     "B"   --> Presupuesto";
     "A    --> Abono";
     "I"   --> Factura (Invoice)";
     "OR": --> Orden de reparación";
     "RA"  --> Apertura OR (Recepción activa);
     "AN"  --> Anticipo
     "AC"  --> Albarán"
     "EA"  --> Entrada de almacén
 */
export type templateTypedoc = "B" | "RA" | "OR" | "I" | "A" | "AN" | "AC" | "FL" | "EA" | "CB" | "CV" | "FC";
export type templateAddType = "boolean" | "decimal" | "number" | "text" | "largetext" | "date" | "dateant" | "datepost" | "signature" | "damages" | "divider" | "title" | "freetext"

export class M_TemplateField {
    /** First text of a <p></p> **/
    add_content: string;
    /** Input value */
    adv_value: any | null;
    /** Input value type, can be 'string', 'number', 'file... */
    add_type: templateAddType = "text";
    /** Last text of <p></p>. Text before input. */
    add_contentnext: string;
    /** Type of document referred to: OR, Budget,... */
    add_typedoc: templateTypedoc;
    /** Disign reference */
    design_id: number;
    /** Some backend reference */
    value_id: number | null;
    /** The template field is defined on the 'company' view? */
    add_general: boolean;
    /** 'design_id' to Disable if the value of this template is not null */
    add_active_from: number | null;
    /** If is more than 100 --> 'A rellenar por el taller' */
    add_order : number;
    /** HTML value of the template image*/
    private parsedFile: string | SafeResourceUrl | undefined;

    constructor(d: any) {
        this.add_content = d.add_content;
        this.add_typedoc = d.add_typedoc;
        this.add_type = d.add_type;
        this.adv_value = d.adv_value;
        this.parseDatabaseImage(d.adv_value);
        this.add_contentnext = d.add_contentnext;
        this.design_id = d.design_id;
        this.value_id = d.value_id;
        this.add_general = d.add_general;
        this.add_active_from = d.add_active_from;
        this.add_order = d.add_order;
    }

    /** If it is a image, the backend only pass the final part of the url. It's necessary to complete the url */
    parseDatabaseImage(adv_value: any) {
        if (typeof adv_value == "string") {
            if (this.add_type == "signature" || this.add_type == "damages") {
                this.adv_value = generateImageURL(adv_value);
                this.refreshFileUrl(this.adv_value);
            }
        }
    }

    refreshFileUrl(url: string | SafeResourceUrl) {
        this.parsedFile = url;
    }

    /** HTML value of the template image */
    get parsedFileURL() {
        return this.parsedFile;
    }

    parseDatabaseDate() {
        if (this.add_type == "date" || this.add_type == "dateant" || this.add_type == "datepost") {
            if (this.adv_value instanceof Date) {
                this.adv_value = new Date(this.adv_value).datePickerFormat();
            }
        }
    }

    get workshopFill() {
        return this.add_order > 100;
    }
}