import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-fourdigits',
  templateUrl: './fourdigits.component.html',
  styleUrls: ['./fourdigits.component.css']
})
export class FourdigitsComponent {

  @ViewChild("v0") v0!: ElementRef;
  @ViewChild("v1") v1!: ElementRef;
  @ViewChild("v2") v2!: ElementRef;
  @ViewChild("v3") v3!: ElementRef;

  /** The user has entered the code correctly and pressed enter */
  @Output() deleteByEnter: EventEmitter<any> = new EventEmitter();

  htmlRefs: ElementRef[] = [];
  form: UntypedFormGroup;
  securityCode: number;

  constructor(private fb: UntypedFormBuilder) {
    this.securityCode = this.getRandom(1000, 9999);
    this.form = this.fb.group({
      v0: ['',],
      v1: ['',],
      v2: ['',],
      v3: ['',],
    })
  }

  getRandom(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  ngAfterViewInit() {
    this.htmlRefs.push(this.v0, this.v1, this.v2, this.v3);
    this.getRef(0).nativeElement.focus();
  }

  /** Focus next input */
  focusNext(current: number) {
    if (current != 3) {
      this.getRef(current + 1).nativeElement.focus();
    }
  }

  /** Focus previous input */
  focusPrevious(current: number) {
    if (current != 0) {
      this.getRef(current - 1).nativeElement.focus();
    }
  }

  /**Check input before dom render */
  beforeInput(e: InputEvent, v: number) {

    /** Delete by 'enter' */
    if (e.inputType == "insertLineBreak" && this.isOk()) {
      this.deleteByEnter.emit();
    }

    /** User pressed remove character key */
    else if (e.inputType == "deleteContentBackward") {
      var value = this.getInput(v).value;
      let hasData: boolean = value != null && value != '';
      
      this.getInput(v).setValue('');
      if (!hasData) {
        this.focusPrevious(v);
        e.stopPropagation();
        e.preventDefault();
      }
    }
    else {
      if (e.data && e.data.isNumber()) {
        this.getInput(v).setValue(e.data);
        this.focusNext(v);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }

  /** GETTERS */
  getRef(input: number) {
    return this.htmlRefs[input];
  }

  getInput(input: number) {
    return this.form.get("v" + input)!;
  }

  getFormValue() {
    return this.getInput(0).value.toString() +
      this.getInput(1).value.toString() +
      this.getInput(2).value.toString() +
      this.getInput(3).value.toString();
  }

  isOk() {
    return this.securityCode == this.getFormValue()
  }

}