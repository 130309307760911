
/** Base user class */
export class M_BaseUser {
    id: number;
    email: string;
    name: string;
    img: string | undefined;
    updated_at: Date;
    created_at: Date;
    holidays: Date[] = [];

    /** id, name, email, image */
    constructor(d: any) {
        this.id = d.id ? d.id : d.user_id;
        this.name = d.name ? d.name : d.user_name ? d.user_name : d.NomOperario;
        this.email = d.email;
        this.created_at = d.created_at ? new Date(d.created_at) : new Date();
        this.updated_at = d.created_at ? new Date(d.updated_at) : new Date();
        this.img = d.image ? d.image : d.pict ? d.pict : d.profile_img ? d.profile_img : undefined;
    }
}