import { ValidatorFn, AbstractControl } from "@angular/forms";
/**
 * Password validator
 * Accepts strnigs with : 
 * - 1 Uppercase letter
 * - 1 Lowercase letter
 * - 1 Number
 * - 8 Or more characters
 */
export function passwordValidator(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: boolean } | null =>  { 
        if (control.value) {

            let may = false;
            let min = false;
            let num = false;
            let len = false;

            if (control.value.length < 8){
                len = true;
            }
            if(!/[A-Z]/.test(control.value)) { //Si no hay al menos una mayúsucla
                may = true;
            }
            if(!/[a-z]/.test(control.value)) { //Si no hay al menos una minúscula
                min = true;
            }
            if(!/[0-9]/.test(control.value)) { //Si no hay al menos un número
                num = true;
            }
            if (!may && !min && !num && !len){
                return null;
            }
            return {passMay : may, passMin : min, passNum : num, passLen : len}
        }
        else{
           return {passRequired: true} ;
        }
    }
}


/** Checks if 2 passwords matches.
 * 
 * Typicall repeat password control.
 */
export function passwordMatch(other : string): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: boolean } | null =>  { 
        if (control.value && other) {
            if (control.value == other){
                return null;
            }
            else{
            }
            return {notMatch : true}
        }
        else{
            return {notMatch : true}
        }
    }
}