import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from '../enums/AppLanguages';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateManagerService {
  availabelLanguages: string[] = [];
  savedLanguageKey: string = "webrp.user.language" // Local storage key
  constructor(private translate: TranslateService, private storageS : StorageService) {
    this.addLanguages();
    this.initLanguage();
  }

  /** Add the languages based on AppLanguages Enum */
  addLanguages() {
    const values = Object.values(AppLanguages);
    values.forEach((value) => {
      this.availabelLanguages.push(value);
    });
    this.translate.addLangs(this.availabelLanguages)
  }

  /** Language priority:
   *  1 - Saved Language
   *  2 - Browser Language
   *  3 - Default Lenguage
   */
  initLanguage() {

    var savedLanguage: string | undefined = this.getUserSavedLanguage(); // User saved language
    const browserLang = this.translate.getBrowserLang(); //Browser language

    // If the user has a saved language
    if (savedLanguage != undefined) {
      console.log("🌎 User has", savedLanguage.toUpperCase(), "as a saved language.")
      if (this.isLanguageAvailable(savedLanguage)) {
        this.translate.use(savedLanguage);
      }
    }
    // Check if user's browser langague is availabe
    else if (this.isLanguageAvailable(browserLang)) {
      console.log("🌎 Langauge", browserLang!.toUpperCase(), "is available.")
      this.translate.use(browserLang!);
    }
    // Setting the default langauge
    else {
      this.translate.use(AppLanguages.castellano)
      console.log("🌎 The", browserLang?.toUpperCase(), "isn't available. Setting the default langauge (ES)")
    }
  }

  /** Returns if language is available on the app based on string */
  isLanguageAvailable(language: string | undefined): boolean {
    return language ? this.availabelLanguages.includes(language) : false;
  }

  /** Get the saved language of browser's localstorage */
  saveUserLanguage(language: string) {
    this.storageS.save(this.savedLanguageKey, language);
  }

  /** Save the language on browser's localstorage */
  getUserSavedLanguage() {
    var saved = this.storageS.get(this.savedLanguageKey);
    return saved ? saved : undefined;
  }

}
