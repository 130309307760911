import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_ShippingAddress } from 'src/app/models/M_Address';
import { M_Contact } from 'src/app/models/M_Contact';


export type contactAddressSelectorData = { contact: M_Contact, address: M_ShippingAddress | undefined }

@Component({
  selector: 'app-contanct-sending-adress-selector-dialog',
  templateUrl: './contanct-sending-adress-selector-dialog.component.html',
  styleUrls: ['./contanct-sending-adress-selector-dialog.component.css']
})
export class ContanctSendingAdressSelectorDialogComponent {

  currentSelected : M_ShippingAddress | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: contactAddressSelectorData) { 
    this.currentSelected = data.address;
  }

}
