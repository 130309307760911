import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { M_Brand } from '../../models/M_Brand';
import { M_Model } from '../../models/M_Model';
import { VehicleType } from '../../enums/VehicleType';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-brand-model-input',
  templateUrl: './brand-model-input.component.html',
  styleUrls: ['./brand-model-input.component.css']
})
export class BrandModelInputComponent implements OnInit {

  loaded = false;
  loadingModels = false;
  MAX_RESULTS = 10;

  @Input({ required: true }) form!: UntypedFormGroup;
  @Input({ required: false }) showPrice!: boolean;
  @Input({ required: false }) required!: boolean;
  @Input({ required: false }) customBrandModel: boolean = true;
  @Output() onSelectModel: EventEmitter<M_Model> = new EventEmitter();

  /** All brands suggested on screen */
  showingBrands: M_Brand[] = [];
  /** Filtered brands depen according to models form input*/
  filteredBrands: M_Brand[] = [];
  /** All models suggested on screen */
  filteredModels: M_Model[] = [];
  /** Filtered models according to models form input*/
  showingModels: M_Model[] = [];
  /** Saved Brands */
  savedBrands: [VehicleType, M_Brand[]][] = [];



  constructor(private apiS: ApiService, private searchS: SearchService, private chdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.brandControl.valueChanges.subscribe(val => {
      this.filterBrands(val)
    })

    this.modelControl.valueChanges.subscribe(val => {
      this.filterModels(val);
    })

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.initByVehicleType(val);
    })

    this.initByVehicleType(this.form.get("type")?.value).then(res => {
      this.loaded = true;
      this.brandControl?.setValue(this.brandControl.value);
    })

    if (this.required) {
      this.brandControl.addValidators(Validators.required);
      this.modelControl.addValidators(Validators.required);
    }

    if (!this.customBrandModel) {
      this.brandControl.addValidators(this.realBrand());
      this.modelControl.addValidators(this.realModel());
    }
  }

  realBrand(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isOk = control.value instanceof M_Brand;
      return isOk ? null : { isOk: false };
    }
  }
  realModel() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isOk = control.value instanceof M_Model;
      return isOk ? null : { isOk: false };
    }
  }

  /** Api call accaording to the vehicle type on screen */
  initByVehicleType(vt: VehicleType) {
    return new Promise<any>(resolve => {
      let isSaved = this.isbrandSaved(vt);
      if (isSaved) {
        this.initShowingBrands(isSaved[1]);
        resolve(true);
      }
      else {
        if (vt == VehicleType.bike) {
          this.apiS.brands(0).then(res => {
            this.initShowingBrands(res);
            this.savedBrands.push([vt, res]);
            resolve(true);
          })
        }
        else if (vt == VehicleType.car) {
          this.apiS.brands(1).then(res => {
            this.initShowingBrands(res);
            this.savedBrands.push([vt, res]);
            resolve(true);
          })

        }
        else {
          console.error("No vehicle type");
          resolve(false);
        }
      }
    });
  }

  isbrandSaved(vt: VehicleType) {
    let sb = this.savedBrands.find(bf => {
      return bf[0] == vt;
    })
    return sb;
  }

  initShowingBrands(v: M_Brand[]) {
    this.showingBrands = v;
    this.refreshModelsBasedOnBrand(this.form.get('brand')?.value);
  }

  onChangeVehicleType(vt: VehicleType) {
    this.initByVehicleType(vt);
  }

  refreshModelsBasedOnBrand(val: MatAutocompleteSelectedEvent | M_Brand | undefined, clearModel = false) {

    // console.log("rrrrefresh")

    let value = typeof val == "string" ? undefined : val instanceof M_Brand ? val : val?.option.value instanceof M_Brand ? val?.option.value : undefined;

    if (value instanceof M_Brand) {
      this.loadingModels = true;
      this.apiS.brandModel(value.id).then(res => {
        this.showingModels = res;
        this.filterModels(undefined);
        this.loadingModels = false;
        this.chdRef.detectChanges();
      })
    }
    else {
      this.showingModels = [];
      this.chdRef.detectChanges();
    }

    if (clearModel) {
      this.clearModel();
    }
  }

  filterBrands(val: string | undefined) {
    if (typeof val != "string") { return; }
    this.filteredBrands = val ? this.showingBrands.filter(option => this.searchS.match(val, option.name)) : this.showingBrands;
    this.selectByName("brand", val);
  }

  filterModels(val: string | undefined) {
    if (typeof val != "string") { return; }
    this.filteredModels = val ? this.showingModels.filter(option => this.searchS.match(val, option.name)) : this.showingModels;
    this.selectByName("model", val);
  }

  selectByName(bm: "brand" | "model", val: string) {
    if (bm == "brand") {
      let b: M_Brand | undefined = this.filteredBrands.find(b => b.name.toLocaleLowerCase() == val.toLocaleLowerCase());
      if (b) { this.brandControl.setValue(b) }
    }
    if (bm == "model") {
      let m: M_Model | undefined = this.filteredModels.find(b => b.name.toLocaleLowerCase() == val.toLocaleLowerCase());
      if (m) { this.modelControl.setValue(m) }
    }
  }

  clearModel() {
    this.form.patchValue({ 'model': undefined });
  }

  displayBrand(brand: M_Brand | string): string {
    return brand && brand instanceof M_Brand ? brand.name : brand;
  }

  displayModel(model: M_Model | string): string {
    return model && model instanceof M_Model ? model.name : model;
  }

  get brandControl() { return this.form.get("brand")! }
  get modelControl() { return this.form.get("model")! }
}
