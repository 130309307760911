<app-page-structure [masterClass]="undefined" pageTitle="Conceptos" [autocompleteID]="'products'"
    searchLabel="Buscar concepto"  [addNewView]="v.createConcept"
    listTitleText="Listado de conceptos"
    createButtonClass="onboarding-create-product" noDataCreateNew="Crear un nuevo concepto" [data]="[]"
    [displayedHeader]="displayedHeaders" [displayedColumns]="displayedColumns" [specialText]="specialText"
    [specialClass]="specialClass" [cellZize]="cellZize" [card]="card" [preIcon]="[icon]" [filters]="filters"
    [filter]="" [basicUserSubscription]="subS.isBasic" (onSelect)="afterSelectConcepts($event)"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear concepto' , icon : 'playlist_add_check_circle'}">
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <button [matTooltip]="'Ir al concepto'" class="infoButton" mat-flat-button color="warn"
                [go]="v.conceptDetails" [param]="{concept : ps.selected!.id!}">
                <mat-icon smartIcon *ngIf="!refreshReference">{{ps.selected!.icon}}</mat-icon>
                {{ps.selected!.name.length > 25 ? (ps.selected!.name | slice:0:25) + '...' : ps.selected!.name}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>
            <app-card-subtitle>Última actualización</app-card-subtitle>
            <p>
                <mat-icon class="vam">calendar_month</mat-icon>
                {{ps.selected!.updated_at?.shortFormat()}}
            </p>
        </app-card>
    </ng-template>

</app-page-structure>