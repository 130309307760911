<div class="advanced-details-container">
    <app-go-back class="back" [text]="'Listado Compras'" [v]="v.purchases" [show]="loaded"></app-go-back>
    <div class="Details">
        <app-card [contentLoaded]="loaded" class="bc_b1">
            <app-advanced-details *ngIf="purchase != undefined">

                <app-advanced-title>
                    <p class="ad-title">{{purchase.comment}}</p>
                    <mat-chip>Pendiente</mat-chip>
                </app-advanced-title>

                <app-advanced-subtitle>
                    Fecha facturación el
                    {{purchase.date?.dayMonthYearFormat()}}
                </app-advanced-subtitle>

                <app-advanced-heading>
                    <app-contact-client-rectangle *ngIf="purchase.contact"
                        [client]="purchase.contact"></app-contact-client-rectangle>
                   
                </app-advanced-heading>
                <app-vehicle-row-container *ngIf="purchase.vehicle"
                        [vehicles]="vehicles"></app-vehicle-row-container >


                <app-advanced-main-actions>
                    <button mat-flat-button color="primary" class="one-line" (click)="gotoPay()">
                        <mat-icon>paid</mat-icon>
                        Pagar
                    </button>
                </app-advanced-main-actions>

                <app-advanced-menu-actions>
                    <button mat-menu-item *ngIf="purchase.pdf_cv" (click)="showPdfcv()">Ver compra venta
                        <mat-icon>picture_as_pdf</mat-icon></button>
                    <button mat-menu-item *ngIf="purchase.pdf_fc" (click)="showPdffc()">Ver factura
                        <mat-icon>picture_as_pdf</mat-icon></button>
                    <button mat-menu-item>
                        <span>
                            Eliminar compra
                        </span>
                    </button>
                </app-advanced-menu-actions>
            </app-advanced-details>
        </app-card>
    </div>
    <div class="Details2">
        <app-card>
            <app-card-title>Resumen</app-card-title>
            <div class="details">
                <div class="item">
                  <span>Subtotal</span>
                  <strong><span class=" ml5 fw500" money [val]="subTotal"></span></strong>
                </div>
                <div class="item">
                  <span>IVA ({{purchase?.tax}}%)</span>
                  <strong><span class=" ml5 fw500" money [val]="this.purchase?.taxSum"></span></strong>
                </div>
              </div>
              <div class="total">
                <strong>Total: <span class=" ml5 fw500" money [val]="purchase?.total"></span></strong>
              </div>
        </app-card>
    </div>
    <div class="Details4">
        <app-card>
            <app-card-title>Movimientos de Pago</app-card-title>
            <app-invoice-movements [movements]="purchase?.last_movements!" [isTotalPay]="true"
            (onDeleteMovement)="deleteLastPayment($event)" [showLastCircle]="false">
        </app-invoice-movements>
        </app-card>
    </div>
    <div class="Details3">
        <app-card>
            <app-card-title>Documentos</app-card-title>
            <mat-card>
                <mat-card-content>
                  <mat-list>
                    <mat-list-item *ngIf="purchase">
                        <div class="title_docu">
                            <div *ngIf="purchase.pdf_cv" class="df content_btt">
                                <p class="fw200"><mat-icon class="vam">description</mat-icon>{{ purchase.pdf_cv}}</p>
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </div>

                            <div *ngIf="purchase.pdf_fc" class="df content_btt">
                                <p class="fw200"><mat-icon class="vam">description</mat-icon>{{ purchase.pdf_fc}}</p>
                                <button mat-icon-button [matMenuTriggerFor]="menu2">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-item>
                  </mat-list>
                </mat-card-content>
              </mat-card>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showPdfcv()">Ver Contrato <mat-icon>picture_as_pdf</mat-icon></button>
              </mat-menu>
              <mat-menu #menu2="matMenu">
                <button mat-menu-item (click)="showPdffc()">Ver factura<mat-icon>picture_as_pdf</mat-icon></button>
              </mat-menu>
        </app-card>
    </div>
<div class="void"></div>