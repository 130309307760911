import { IClassSearcher } from "../interfaces/IClassSearcher";
import { match } from "../services/search.service";
import { M_ModelDetails } from "./M_ModelDetails";

export class M_Model implements IClassSearcher<M_Model> {
    id: number
    id_brand: number;
    name: string;
    created_at?: Date;
    updated_at?: Date;
    details: M_ModelDetails | null;

    constructor(d: any) {
        this.id = d.id;
        this.id_brand = d.id_brand;
        this.name = d.name;
        this.updated_at = d.udpated_at ? new Date(d.udpated_at) : undefined;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.details = d.details ? new M_ModelDetails(d.details) : null;
    }

    createNew(d: any): M_Model {
        return new M_Model(d);
    }
    getInputText(): string {
        return this.name;
    }
    getOptionText(): [string, undefined] {
        return [this.name, undefined]
    }
    defaultSearchFilter(v: string): boolean {
        return match(v, this.name);
    }

    get cs_id() { return this.id; }
    get icon() { return "bike_scooter"; }
    get typeof() { return M_Model }
}