import { M_Contact } from "../models/M_Contact";
import { M_Product } from "../models/Products/M_Product";
import { M_User } from "../models/M_User";
import { M_Vehicle } from "../models/M_Vehicle";
import { M_Concept } from "../models/M_Concept";
import { ContactEnum } from "../enums/ContactEnum";

/** INTANCES FOR THE CLASS SERACHER COMPONENT */

/** CLIENT */
export const MASTER_CLIENT = new M_Contact({})
export const MASTER_CLIENT_MINIFIY = new M_Contact({})
MASTER_CLIENT.minify = true;

/** CLIENT with Active Sales */
export const MASTER_C_CLIENT = new M_Contact({})
MASTER_C_CLIENT.activeSales = true

/** PROVIDER */
export const MASTER_PROVIDER = new M_Contact({})
MASTER_PROVIDER.type = ContactEnum.PROVIDER;

/** VEHICLE */
export const  MASTER_VECHILE = new M_Vehicle({});
export const  MASTER_VECHILE_MINIFY = new M_Vehicle({});
MASTER_VECHILE_MINIFY.minify = true;
export const  MASTER_VECHILE_MINIFY_2 = new M_Vehicle({});
MASTER_VECHILE_MINIFY_2.vnvoCall = true;

/** PRODUCT */
export const MASTER_PRODUCT = new M_Product({})

/** PRODUCTS WITH PROVIDERS */
export const MASTER_PRODUCT_PROVIDERS = new M_Product({})
MASTER_PRODUCT_PROVIDERS.onlyProviders = true;

/** USER */
export const MASTER_USER = new M_User({});

/** CONCEPTS */
export const MASTER_CONCEPT = new M_Concept({});