import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { M_BaseUser } from '../models/M_BaseUser';
import { CustomFile } from '../custom-classes/CustomFile';

@Injectable({
  providedIn: 'root'
})
export class ProfilePictureService {

  profileImageSubject: Subject<[cf : CustomFile, u : M_BaseUser]> = new Subject();
  
  constructor() { }
  onChangeProfilePicture(data : [cf : CustomFile, u : M_BaseUser]) {
    this.profileImageSubject.next(data)
  }
}
