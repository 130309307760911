import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/M_Vehicle';

export type ClientVehicleData = {
  client: M_Contact | undefined,
  vehicle: M_Vehicle | undefined,
}

@Component({
  selector: 'app-client-vechile-info-dialog',
  templateUrl: './client-vechile-info-dialog.component.html',
  styleUrls: ['./client-vechile-info-dialog.component.css']
})
export class ClientVechileInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ClientVehicleData) { }
}
