import { expectedColumns } from "../excel-column-matcher.component";

/** Columns ID */
export enum supportedTxtBrandEnum {
  PIAGGIO = "Piaggio",
  PIAGGIO2 = "Piaggio 2",
}

/** Columns ID */
export enum exelColumId {
  REF,
  NAME,
  QUANTITY,
  COST,
  PVP_T,
  DISCOUNT,
  DISCOUNT_GROUP
}

/** Expected columns on storehouse excel import. */
export const storeHouseExpectedColunmns: expectedColumns[] =
  [
    {
      id: exelColumId.REF,
      name: "Referencia",
      alternative_names: ["Ref"],
      val_type: "string",
      required: true,
      update: true,
      txtFromTo: {
        from: 1,
        to: 2,
      }
    },
    {
      id: exelColumId.NAME,
      name: "Nombre",
      alternative_names: ["Denominación"],
      val_type: "string",
      required: true,
      update: true,
      txtFromTo: {
        from: 3,
        to: 4,
      }
    },
    {
      id: exelColumId.QUANTITY,
      name: "Cantidad",
      alternative_names: ["Cant", "Quant"],
      val_type: "number",
      required: true,
      update: true,
      txtFromTo: {
        from: 5,
        to: 6,
      }
    },
    {
      id: exelColumId.COST,
      name: "Coste",
      alternative_names: ["coste", "cost", "cst"],
      val_type: "number",
      required: true,
      update: true,
      txtFromTo: {
        from: 7,
        to: 8,
      }
    },
    {
      id: exelColumId.PVP_T,
      name: "PVP (tarifa)",
      alternative_names: ["Precio", "Price", "PVP"],
      val_type: "number",
      required: true,
      update: true,
      txtFromTo: {
        from: 9,
        to: 10,
      }
    },
    {
      id: exelColumId.DISCOUNT,
      name: "Descuento",
      alternative_names: ["Disc", "Disc.", "Descuento"],
      val_type: "number",
      required: false,
      update: true,
      txtFromTo: {
        from: 11,
        to: 12,
      }
    },
    {
      id: exelColumId.DISCOUNT_GROUP,
      name: "Grupo descuento",
      alternative_names: ["Grupo descuento", "G. descuento", "Grupo de descuento"],
      val_type: "string",
      required: false,
      update: true,
      txtFromTo: {
        from: 13,
        to: 14,
      }
    }
  ]