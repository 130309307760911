<div *ngIf="!ps.createButtonLoading; else circleLoad" [matTooltip]="ps.circleTooltip ? ps.circleTooltip : ''">
    
    <!-- Desktop flat button -->
    <button [disabled]="ps.disableCircle" mat-flat-button color="primary" [class]="'page-structure-create-button desktop ' + (ps.createButtonClass ? ps.createButtonClass : '')"  (click)="ps.onClickAdd()">
        <mat-icon smartIcon *ngIf="ps.createButton" [ngClass]="{'c_w' : !ps.disableCircle}">{{ps.createButton.icon}}</mat-icon>
        {{ps.createButton?.text}}
    </button>

    <!-- Phone circular button -->
    <button [disabled]="ps.disableCircle" mat-fab color="primary"
        [class]="'page-structure-create-button mobile ' + (ps.createButtonClass ? ps.createButtonClass : '') " (click)="ps.onClickAdd()">
        <mat-icon smartIcon *ngIf="ps.createButton" class="c_w" >{{ps.createButton.icon}}</mat-icon>
        <mat-icon class="add_absolute">add</mat-icon>
    </button>
</div>

<ng-template #circleLoad>

    <!-- Desktop flat skeleton button -->
    <div class="page-structure-create-button skeleton desktop" *ngIf="ps.canAddNew">
        <app-skeleton [width]="150" [height]="36"></app-skeleton>
    </div>

    <!-- Phone circular skeleton button -->
    <div class="page-structure-create-button skeleton mobile" *ngIf="ps.canAddNew">
        <app-skeleton [circle]="true" [width]="56" [height]="56"></app-skeleton>
    </div>

</ng-template>