import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { M_Vehicle } from '../models/M_Vehicle';
import { CreateVehicleComponent } from 'src/app/views/create-vehicle/create-vehicle.component';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';

@Injectable({
  providedIn: 'root'
})
export class CreateVehicleInDialogService {

  constructor(private d: MatDialog) { }

  create(searcher: ClassSearcherComponent<M_Vehicle> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateVehicleComponent, { maxWidth: 800, autoFocus : false})
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Vehicle) {
        searcher.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }
}
