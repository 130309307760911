<div *ngFor="let a of reservations; let last = last" [class]="'activity activity-0'">
    
    <div class="df body-comp"  *ngIf="companyS.recambiosModule">
        <div [ngClass]="a.productsQuants?.quant_pend == 0 ?'circle-completed' : 'circle-fault' "></div>
        <div class="df fdc aic">
            <div class="circle ">
                <mat-icon class="c_w">local_shipping</mat-icon>
            </div>
        </div>
        <div class="circle-line" [ngClass]="{last}"></div>
        <div class="text-section" *ngIf="a">
            <p class="title">
                <span class="fw500"> {{a.fault?.name_product ? a.fault?.name_product : a.product?.name }}</span>
                <span class="c_t2"> | </span>
                <span  class="c_g2 fss">{{a.fault?.ref_product ? a.fault?.ref_product : a.product?.smartRef(companyS.recambiosModule)}}</span>
            </p>
            <p class="subtitle">
                <span class="c_g2 fss">{{a.quant_reserved}} Uds.</span>
                <span class="c_t2"> | </span>
                <span class="c_a" *ngIf=" a.fault && a.fault?.order" (click)="goto(a.fault)">Ir al pedido <mat-icon class="c_a vam txt_icon">open_in_new</mat-icon></span>
            </p>
        </div>
    </div>

    <div class="df" *ngIf="last && showLastCircle">
        <div class="df fdc aic div-last">
            <div class="circle-last"></div>
            <div class="circle-line" [ngClass]="{'last': last}"></div>
        </div>
    </div>
</div>