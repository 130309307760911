<ng-container *ngIf="p.providers.length && p.selected_provider">

    <button mat-button *ngIf="p.providers.length > 1; else oneProvider" [matMenuTriggerFor]="providersMenu">
        <mat-icon>local_shipping</mat-icon>
        {{p.selected_provider.name}}
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>

    <ng-template #oneProvider>
        <div>
            <mat-icon class="vam mr5">local_shipping</mat-icon>
            {{p.selected_provider.name}}
        </div>
    </ng-template>
    

</ng-container>


<!-- MAT MENU -->
<mat-menu #providersMenu="matMenu">
    <div mat-menu-item *ngFor="let prov of p.providers" (click)="p.selected_provider = prov">
        <mat-icon>local_shipping</mat-icon>
        {{prov.name}}
    </div>
</mat-menu>
