<div style="margin-top: 10px;"></div>
<app-page-structure
    class="paycomet-invoices"
    [autocompleteID]="'paycomet-invoices'"
    searchLabel="Buscar pago" 
    [pageTitle]="''"
    listTitleText=""
    [canAddNew]="false"
    [addNewView]="undefined"
    [data]="[]"
    [sideDetails]="true"
    [filters]="filters"
    [filter]="f"
    [card]="card"
    [displayedHeader]=" ['Referencia', 'Estado',    'Fecha',   'Precio', 'PDF']"
    [displayedColumns]="['reference',  'payed',     'created_at', 'amount','file']"
    [specialText]="     [undefined,    payedName,   undefined,    'money', havePDF]"
    [specialClass]="    [undefined,    payedClass,  undefined,   undefined, payedClass]"
    [listTitleText]="'Listado de pagos'"
    [disableCircle]="false"
    [showPagination]="true"
    [createButton]="undefined">

    <ng-template #card>
        <app-card *ngIf="ps.showItem()" class="tac mt10">
            <app-card-title>{{ps.selected!.reference}}</app-card-title>
            <ng-container *ngIf="ps.selected">
                <button *ngIf="ps.selected.file" (click)="previewPDF(ps.selected.file)" mat-flat-button color="primary" style="margin-bottom: 10px;">
                    <mat-icon>save</mat-icon>
                    Descargar PDF
                </button>
                <button *ngIf="!ps.selected.file" (click)="requestPDF(this.ps.selected.reference!)" mat-flat-button color="warn" style="margin-bottom: 10px;">
                    <mat-icon>send</mat-icon>
                    Pedir PDF
                </button>
            </ng-container>
        </app-card>
    </ng-template>
</app-page-structure>