import { Component, Inject, ViewChild } from '@angular/core';
import { calendarEnabled } from 'src/app/constants/constants';
import { SettingsComponent } from '../settings.component';
import { M_Company } from 'src/app/models/M_Company';
import { HolidaysCalendarComponent } from 'src/app/components/holidays-calendar/holidays-calendar.component';

@Component({
  selector: 'app-holidays-company',
  templateUrl: './holidays-company.component.html',
  styleUrls: ['./holidays-company.component.css']
})
export class HolidaysCompanyComponent {
  enabledCalender = calendarEnabled;
  @ViewChild(HolidaysCalendarComponent) holidays!: HolidaysCalendarComponent;

  constructor(@Inject(SettingsComponent) public parent: SettingsComponent) { }
  
  initCompany(company: M_Company) {
    this.holidays.formatAndSetMultipleDates(company);
    this.holidays.initComponent({ companyH: company.holidays, userH: [] });
  }
}
