import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from '../models/M_Action';
import { ViewPath } from 'src/app/app-routing.module';
import { Title } from '@angular/platform-browser';
import { RouterService } from './router.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class OrTimerService {
  workingOr: M_Action[] = [];
  constructor(private apiS: ApiService, private routerS: RouterService, private sessionS: SessionService, private titleService: Title) {
    this.sessionS.onLogOut.subscribe(_v => {
      this.workingOr = [];
    })
  }

  makeCall(): Promise<void> {
    this.workingOr = [];
    if (this.sessionS.hasSession()) {
      return this.apiS.currentlyWorking().then(res => {
        this.workingOr = res ? res : [];
      });
    } else {
      return Promise.resolve();
    }
  }

  /** Is the user working in more than 1 or? */
  get hasMore() {
    return this.workingOr.length > 1;
  }

  /** Number to show */
  get moreNumber() {
    return this.workingOr.length - 1;
  }

  /** Menu or list */
  get moreOr() {
    return this.workingOr.slice(1);
  }

  start(or: M_Action | undefined) {
    if (or) { this.workingOr.unshift(or); }
  }

  end(or: M_Action | undefined) {
    if (or) {
      this.workingOr.forEach((arrayOr, index) => {
        if (arrayOr.id == or.id) {
          this.workingOr.removeIndex(index);
          this.setTitle(undefined);
        }
      })
    }
  }

  goOr(or: M_Action) {
    this.routerS.goWithQueryParams(ViewPath.editOr, { or: or.id })
  }

  get refOr() {
    return this.workingOr[0] != undefined ? this.workingOr[0] : undefined;
  }

  setTitle(elapsed: string | undefined) {
    let title = this.titleService.getTitle();
    if (elapsed) {
      if (title.includes("-")) {
        title = title.split("-")[0];
        title = title + " - " + elapsed;
      }
      else {
        title = title + " - " + elapsed;
      }
    }
    else {
      if (title.includes("-")) {
        title = title.split("-")[0];
      }
    }

    this.titleService.setTitle(title);
  }

  get elapsed() {
    if (!this.refOr) { return undefined; }
    let elapsed = this.orElapsed(this.refOr);
    this.setTitle(elapsed);
    return elapsed;
  }

  orElapsed(or: M_Action) {
    var elapsed = or.isOperatorWorking(this.sessionS.getId())?.elapsed;
    if (elapsed) {
      return or.isOperatorWorking(this.sessionS.getId())?.elapsed
    }
    else {
      this.end(or)
      return undefined;
    }
  }

}
