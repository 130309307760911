import { M_Action } from "../models/M_Action";
import { M_Invoice } from "../models/M_Invoice";
import { M_Product } from "../models/Products/M_Product";
import { M_PayCometInvoice } from "../models/M_PayCometInvoice";
import { ORPageFiltesrEnum } from "src/app/views/or/or.component";
import { M_Appointment } from "../models/M_Appointment";
import { AppoPageFiltesrEnum } from "src/app/views/appointments/appointments.component";
import { M_Contact } from "../models/M_Contact";
import { ClientsPageFiltersEnum } from "src/app/views/clients/clients.component";
import { M_Order } from "../models/M_Order";
import { OrderPageFiltesrEnum } from "src/app/views/orders/orders.component";
import { M_Vehicle } from "../models/M_Vehicle";
import { VehiclesPageFiltersEnum } from "src/app/views/vehicles/vehicles.component";
import { M_Albaran } from "../models/M_Albaran";
import { AlbaranPageFiltesrEnum } from "src/app/views/albaranes/albaranes.component";
import { BillPageFiltesrEnum } from "src/app/views/invoices/invoices.component";
import { M_StoreHouseEntry } from "../models/M_StoreHouseEntry";
import { BudgetPageFiltesrEnum } from "src/app/views/budget/normal-budget/normal-budget.component";
import { M_ComercialBudget } from "../models/M_ComercialBudget";
import { ComercialBudgetPageFiltesrEnum } from "src/app/views/budget/comercial-budget/comercial-budget.component";
import { ButtonToggleFilter, ClassSearcherFilter, DayFilter, EnumFilter, Filter, SliderFilter, TagFilter, TextFilter, UserFilter } from "./Filter";

/** This funcion is the 'filterPredicate' of the page table 
 * Iterates the table and check if the table object match with filtetrs
 * Important : We need the rewtirte the default string filter of the MatTable (the searchbar)
 * On this class, the method rewtited is defaultStrFilter();
*/
export function filter(object: any, ...filters: Filter[]) {
    var fitleredFilters = filters.filter(f => f.activated);  //xd

    if (object instanceof M_Contact) {
        return filterContact(fitleredFilters, object);
    }
    else if (object instanceof M_Vehicle) {
        return filterVehicle(fitleredFilters, object);
    }
    else if (object instanceof M_Invoice) {
        return filterInvoice(fitleredFilters, object)
    }
    else if (object instanceof M_Product) {
        return filterProduct(fitleredFilters, object)
    }
    else if (object instanceof M_Action) {
        return filterAction(fitleredFilters, object)
    }
    else if (object instanceof M_PayCometInvoice) {
        return filterPayCometInvoice(fitleredFilters, object);
    }
    else if (object instanceof M_Appointment) {
        return filteAppointment(fitleredFilters, object);
    }
    else if (object instanceof M_Order) {
        return filterOrder(fitleredFilters, object);
    }
    else if (object instanceof M_Albaran) {
        return filterAlbaran(fitleredFilters, object);
    }
    else if (object instanceof M_StoreHouseEntry) {
        return filterStoreHouseEntry(fitleredFilters, object);
    }
    else if (object instanceof M_ComercialBudget) {
        return filterComercialBudget(fitleredFilters, object);
    }

    return false;
}

/** Contact filter function */
function filterContact(filters: Filter[], object: M_Contact) {
    let isTypeOk = true;
    let isVehicleOk = true;
    let exentIva = true;
    let sells = true;
    let eInvoice = true;
    let clientDiscount = true;

    filters.forEach(f => {
        if (f instanceof TagFilter && f.id == ClientsPageFiltersEnum.CONTACT_TYPE) {
            isTypeOk = f.checkFilter([object.isPerson ? 0 : object.isCompany ? 1 : 2])
        }
        if (f instanceof TagFilter && f.id == ClientsPageFiltersEnum.VEHICLE_TYPE) {
            let hasBike = object.vehicles.some(v => v.isBike);
            let hasCar = object.vehicles.some(v => v.isCar);
            isVehicleOk = f.checkFilter([hasBike ? 0 : hasCar ? 1 : -1])
        }
        if (f instanceof ButtonToggleFilter && f.id == ClientsPageFiltersEnum.SELLS) {
            sells = f.checkFilter(object.c_client);
        }
        if (f instanceof ButtonToggleFilter && f.id == ClientsPageFiltersEnum.EXENT_IVA) {
            exentIva = f.checkFilter(object.tax_free);
        }
        if (f instanceof ButtonToggleFilter && f.id == ClientsPageFiltersEnum.E_INVOICE) {
            eInvoice = f.checkFilter(object.cli_is_invoice_e);
        }
        if (f instanceof SliderFilter) {
            clientDiscount = !object.discount ? false : f.checkFilter(object.discount);
        }
    })
    return isTypeOk && isVehicleOk && sells && exentIva && eInvoice && clientDiscount;
}

/** Vehicle filter function */
function filterVehicle(filters: Filter[], object: M_Vehicle) {
    let isTypeOk = true;
    let isOwnerOk = true;
    filters.forEach(f => {
        if (f instanceof TagFilter && f.id == VehiclesPageFiltersEnum.VEHICLE_TYPE) {
            isTypeOk = f.checkFilter([object.isBike ? 0 : object.isCar ? 1 : 2])
        }
        if (f instanceof TagFilter && f.id == VehiclesPageFiltersEnum.OWNER_TYPE) {
            isOwnerOk = f.checkFilter([object.client ? object.client.isPerson ? 0 : object.client.isCompany ? 1 : 2 : -1])
        }
    })
    return isTypeOk && isOwnerOk;
}

/** Product filter function */
function filterProduct(filters: Filter[], object: M_Product) {
    var slidersOk = true;
    var textFilter = true;
    var tagFilter = true;

    filters.forEach((filter) => {
        if (filter instanceof SliderFilter && slidersOk) {
            slidersOk = filter.checkbyProduct(object);
        }
        if (filter instanceof TextFilter && textFilter) {
            textFilter = filter.checkbyProduct(object);
        }
        if (filter instanceof TagFilter && tagFilter) {
            textFilter = filter.checkFilter([object.category]);
        }
    })

    return slidersOk && textFilter && tagFilter;
}


/** Invoices filter Funcion */
function filterInvoice(filters: Filter[], object: M_Invoice) {
    var isDayFilterOk = true;
    var isDayFilterPayed = true;
    var isSliderFilterOk = true;
    var isEnumFilterOk = true;
    var isTagFilter1Ok = true;
    var isTagFilter2Ok = true;
    var isTagFilter3Ok = true;
    var isTagFilter4Ok = true;

    filters.forEach(filter => {
        if (filter instanceof DayFilter && isDayFilterOk && filter.id == BillPageFiltesrEnum.INVOICE_CREATED_AT) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        else if (filter instanceof DayFilter && isDayFilterPayed && filter.id == BillPageFiltesrEnum.INVOICE_PAYED_AT) {
            isDayFilterPayed = object.last_movement.some(movement => { return filter.checkFilter(movement.created_at) })
        }
        else if (filter instanceof SliderFilter && isSliderFilterOk) {
            isSliderFilterOk = filter.checkFilter(object.total)
        }
        else if (filter instanceof EnumFilter && isEnumFilterOk) {
            isEnumFilterOk = filter.checkFilter(object.state.isPayed ? 0 : object.state.isPartial ? 2 : 1)
        }

        /** Invoice Type */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.INVOICE_TYPE && isTagFilter1Ok) {
            if (object.type_invoice != undefined) {
                isTagFilter1Ok = filter.checkFilter([object.type_invoice.num])
            }
            else {
                isTagFilter1Ok = false;
            }
        }

        /** OR Type */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.OR_TYPE && isTagFilter2Ok) {
            if (object.type_or != undefined) {
                isTagFilter2Ok = filter.checkFilter([object.type_or.num])
            }
            else {
                isTagFilter2Ok = false;
            }
        }
        /** TYPE PAYMENT INVOICE Type */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.INVOICE_TYPE_PAYMENT && isTagFilter3Ok) {
            isTagFilter3Ok = object.last_movement.some(x => {
                return filter.checkFilterByString(x.concept);
            });

        }
        /** TYPE  INVOICE STATE */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.INVOICE_STATE && isTagFilter4Ok) {
            isTagFilter4Ok = filter.checkFilter(object.type_filter());
        }
    })

    return isDayFilterPayed && isDayFilterOk && isSliderFilterOk && isEnumFilterOk && isTagFilter1Ok && isTagFilter2Ok && isTagFilter3Ok && isTagFilter4Ok;
}

/** Filter Action */
function filterAction(filters: Filter[], object: M_Action) {
    return object.isBudget() ? filterBudget(filters, object) : filterOR(filters, object);
}


/** Filter OR function */
function filterOR(filters: Filter[], object: M_Action) {
    var isDayFilterOk = true; //Screen filters
    var isEnumFilterOk = true; //Screen filters
    var isStatusFilterOk = true; //Screen filters
    var isGroupFilterOk = true; //Screen filters
    var isTypeFilterOk = true; //Screen filters
    var isUserFilterOk = true; //Screen filters
    filters.forEach(filter => {
        if (filter instanceof DayFilter && isDayFilterOk) {
            isDayFilterOk = filter.checkFilter(object.delivery)
        }
        else if (filter instanceof EnumFilter && isEnumFilterOk) {
            isEnumFilterOk = filter.checkFilter(object.status.num)
        }
        else if (filter instanceof TagFilter && isStatusFilterOk && filter.id == ORPageFiltesrEnum.OR_STATUS) {
            isStatusFilterOk = filter.checkFilter([object.status.num])
        }
        else if (filter instanceof TagFilter && isGroupFilterOk && filter.id == ORPageFiltesrEnum.OR_GROUP_STATUS) {
            isGroupFilterOk = object.groups.some(g => { return filter.checkFilter([g.state.num]) })
        }
        else if (filter instanceof TagFilter && isTypeFilterOk && filter.id == ORPageFiltesrEnum.OR_TYPE) {
            isTypeFilterOk = filter.checkFilter(object.type.map(obj => obj.num))
        }
        else if (filter instanceof UserFilter && isUserFilterOk) {
            isUserFilterOk = filter.checkFilter(object.assigned?.id)
        }
    })

    return isDayFilterOk && isEnumFilterOk && isStatusFilterOk && isTypeFilterOk && isGroupFilterOk && isUserFilterOk;
}


/** Filter Budget function */
function filterBudget(filters: Filter[], object: M_Action) {
    var isDayFilterOk = true; //Screen filters
    var isSliderFilterOk = true; //Screen filters
    var isEnumFilterOk = true; //Screen filters
    var isStatusFilterOk = true; //Screen filters

    filters.forEach(filter => {
        if (filter instanceof DayFilter) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        else if (filter instanceof SliderFilter) {
            isSliderFilterOk = filter.checkFilter(object.total)
        }
        else if (filter instanceof EnumFilter) {
            isEnumFilterOk = filter.checkFilter(object.status.num)
        }
        else if (filter instanceof TagFilter && isStatusFilterOk && filter.id == BudgetPageFiltesrEnum.BUDGET_STATUS) {
            isStatusFilterOk = filter.checkFilter([object.status.num])
        }
    })
    return isDayFilterOk && isSliderFilterOk && isEnumFilterOk && isStatusFilterOk;
}


function filterPayCometInvoice(filters: Filter[], object: M_PayCometInvoice) {
    var isDayFilterOk = true; //Screen filters
    var isEnumFilterOk = true; //Screen filters
    var sliderFilterOk = true; //Screen filters
    filters.forEach(filter => {
        if (filter instanceof DayFilter) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        else if (filter instanceof EnumFilter) {
            isEnumFilterOk = filter.checkFilter(object.payed)
        }
        else if (filter instanceof SliderFilter) {
            sliderFilterOk = filter.checkFilter(object.amount);
        }
    })
    return isDayFilterOk && isEnumFilterOk && sliderFilterOk;
}

function filteAppointment(filters: Filter[], object: M_Appointment) {
    var isDayFilterOk = true; //Screen filters
    var isStatusFilterOk = true; //Screen filters
    var isClientFilterOk = true; //Screen filters
    var isOriginFilterOk = true; //Screen filters
    filters.forEach(filter => {
        if (filter instanceof DayFilter) {
            isDayFilterOk = filter.checkFilter(object.appointment_date)
        }
        else if (filter instanceof TagFilter && isStatusFilterOk && filter.id == AppoPageFiltesrEnum.APPOINTMENT_STATUS) {
            isStatusFilterOk = filter.checkFilter([object.status])
        }
        else if (filter instanceof EnumFilter && isClientFilterOk && filter.id == AppoPageFiltesrEnum.APPOINTMENT_IS_CLIENT) {
            isClientFilterOk = filter.checkFilter(object.isClient ? 0 : 1);
        }
        else if (filter instanceof TagFilter && isOriginFilterOk && filter.id == AppoPageFiltesrEnum.APPOINTMENT_ORIGIN) {
            isStatusFilterOk = filter.checkFilter([object.origin == "Eina" ? 0 : 1])
        }
    })
    return isDayFilterOk && isStatusFilterOk && isClientFilterOk && isOriginFilterOk;
}

function filterOrder(filters: Filter[], object: M_Order) {
    var isStatusFilterOk = true;
    var isPriorityFilterOk = true;
    var isProviderFilterOk = true;
    var isDayFilterOk = true;
    var isSendFilterOk = true;

    filters.forEach(filter => {
        if (filter instanceof TagFilter && filter.id == OrderPageFiltesrEnum.ORDER_STATUS) {
            isStatusFilterOk = filter.checkFilter([object.order_status.num])
        }
        if (filter instanceof TagFilter && filter.id == OrderPageFiltesrEnum.PRIORITY) {
            isPriorityFilterOk = filter.checkFilter([object.type])
        }
        if (filter instanceof ClassSearcherFilter) {
            isProviderFilterOk = filter.checkFilter(object.provider?.client_id);
        }
        if (filter instanceof DayFilter) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        if (filter instanceof ButtonToggleFilter) {
            isSendFilterOk = filter.checkFilter(object.send)
        }
    })
    return isStatusFilterOk && isPriorityFilterOk && isProviderFilterOk && isDayFilterOk && isSendFilterOk;
}

function filterAlbaran(filters: Filter[], object: M_Albaran) {
    var isStatusOk = true;
    var isTypeOk = true;
    var isDayFilterOk = true;
    filters.forEach(filter => {
        if (filter instanceof TagFilter && filter.id == AlbaranPageFiltesrEnum.STATUS) {
            isStatusOk = filter.checkFilter([object.status.num])
        }
        if (filter instanceof TagFilter && filter.id == AlbaranPageFiltesrEnum.TYPE && isTypeOk) {
            if (object.type != undefined) {
                isTypeOk = filter.checkFilter([object.type.num])
            }
            else {
                isTypeOk = false;
            }
        }
        if (filter instanceof DayFilter && filter.id == AlbaranPageFiltesrEnum.CREATED_AT) {
            isDayFilterOk = filter.checkFilter(object.date_user)
        }
    })
    return isStatusOk && isTypeOk && isDayFilterOk;
}

function filterStoreHouseEntry(filters: Filter[], object: M_StoreHouseEntry) {
    let classSearcherOk = true;
    let entranceTypeOk = true;
    let albaranDateOk = true;

    filters.forEach(filter => {
        if (filter instanceof ClassSearcherFilter) {
            classSearcherOk = filter.checkFilter(object.provider?.cs_id)
        }
        if (filter instanceof TagFilter) {
            entranceTypeOk = filter.checkFilter([object.entranceType])
        }
        if (filter instanceof DayFilter) {
            albaranDateOk = filter.checkFilter(object.dnote_prov_date)
        }
    })

    return classSearcherOk && entranceTypeOk && albaranDateOk;
}

function filterComercialBudget(filters: Filter[], object: M_ComercialBudget) {
    let statusOk = true;
    let priceOk = true;
    let stockEntered = true;
    
    filters.forEach(filter => {
        if (filter instanceof TagFilter && filter.id == ComercialBudgetPageFiltesrEnum.BUDGET_STATUS) {
            statusOk = filter.checkFilter([object.status.num])
        }
        if (filter instanceof SliderFilter && filter.id == ComercialBudgetPageFiltesrEnum.BUDGET_PRICE) {
            priceOk = filter.checkFilter(object.price)
        }
        if (filter instanceof ButtonToggleFilter && filter.id == ComercialBudgetPageFiltesrEnum.STOCK_ENTERED) {
            stockEntered = filter.checkFilter(object.vehicle != undefined)
        }
    })
    return statusOk && priceOk && stockEntered;
}


/** This function chck if the text introduced on the serachBar of Page-Structure-Component
 *  match with the object that we are checking. We need the rewrite this funcion beacouse we
 *  set a custom fitlerPredicate function.
 */
export function defaultSearchFilter(object: any, searchFilter: string) {
    return searchFilter ? object.defaultSearchFilter(searchFilter) : true;
}

