import { M_Fault } from "../models/M_Fault";

export function isSomethingMissing(faults: M_Fault[]) {
    if (isSomeFaultOnTheWay(faults)) {
        return "shopping_cart_checkout"
    }
    else if (isSomeFaultPending(faults)) {
        return "production_quantity_limits"
    }
    return undefined;
}

function isSomeFaultPending(faults: M_Fault[]) {
    return faults.some(f => f.isPending);
}

function isSomeFaultOnTheWay(faults: M_Fault[]) {
    return faults.some(f => f.onTheWay);
}