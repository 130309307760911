import { Directive, ElementRef, Input } from '@angular/core';
import { toMoney } from '../utils/FunctionUtils';


/** Numer Manager 'Directive'. It is practically an interface for other directives */
@Directive({
  selector: '[--------]',
})
export class NumberManager {
  @Input() val: number | string | undefined = undefined;

  ngOnChanges() {
    this.changeNativeElement();
  }

  ngAfterContentInit() {
    this.changeNativeElement();
  }

  changeNativeElement() {
    throw Error("Implmentation on children")
  }

  /** No matter the param [val] value. Always return a number. By default 0 */
  get defaultValue() {
    return typeof this.val == "string" ? this.val.getNumber()! : this.val == undefined ? 0 : this.val;
  }
}


/**
 * Formats the [val] parameter with the current user locale (es-Es...)
 * TO DO : Get the locale from company data ? By default es-ES locale
 */
@Directive({
  selector: '[money]'
})
export class MoneyDirective  extends NumberManager{

  constructor(private el: ElementRef<HTMLElement>) {
    super();
  }

  override changeNativeElement() {
    var formatted = toMoney(this.defaultValue);
    this.el.nativeElement.innerHTML = formatted;
  }
}



/**
 *  By default 2 decimals.
 * 
 *  Required to pass the [val] parameter
 */
@Directive({
  selector: '[decimal]'
})
export class DecimalsDirective extends NumberManager {

  @Input() decimals : number = 2;
  constructor(private el: ElementRef<HTMLElement>) {
    super();
  }

  override changeNativeElement() {
    var formatted = new Intl.NumberFormat('es-ES', {maximumFractionDigits : this.decimals}).format(this.defaultValue);
    this.el.nativeElement.innerHTML = formatted;
  }
}
