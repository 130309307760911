<div class="transition-appear maxw" *ngIf="client; else noData">

    <div class="df fdc aic jcc">

        <div [ngClass]="{'cp' : clickable}" (click)="goClient()"
            [matTooltip]=" clickable? 'Ir a detalles avanzados del contacto' : '' ">
            <div class="df">
                <app-contact-lord-icon [contact]="client" [small]="small"></app-contact-lord-icon>
            </div>
        </div>

        <p *ngIf="client.deleted" class="c_r fw600">¡Contacto eliminado!</p>

        <app-card-subtitle class="title tac discount-subtitle" [first]="true" [noMarginBottom]="true">
            {{client.getName()}}
        </app-card-subtitle>

        <p class="fsm nmb fw300" style="letter-spacing: 2px">{{client.nif.toUpperCase()}}</p>

        <button color="blue" mat-button [matMenuTriggerFor]="menu">
            <span>Más información</span>
        </button>

        <mat-menu #menu="matMenu" class="card-row-menu">
            <app-card-row-content [data]="[
                ['Email', client.email],
                ['Teléfono', client.phone],
                ['Dirección', client.billing_address?.toText()]]">
            </app-card-row-content>
        </mat-menu>
    </div>
</div>

<ng-template #noData>
    <div class="df fww fdc jcc aic">
        <lord-icon class="f-gray" src="https://cdn.lordicon.com/fmasbomy.json" trigger="hover"
            colors="primary:#121331,secondary:{{warn()}},tertiary:#fae6d1" state="hover-looking-around"
            [ngClass]="small ? 'lord-medium' : 'lord-big'">
        </lord-icon>
        <p class="c_t2 tac nmb" *ngIf="!somethingSelected; else noClient">
            Selecciona un cliente para ver más detalles
        </p>
        <ng-template #noClient>
            <p class="c_t2 tac nmb">Sin cliente</p>
        </ng-template>
    </div>
</ng-template>