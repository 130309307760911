<app-close-dialog-button *ngIf="bmi.loaded"></app-close-dialog-button>
<app-card [contentLoaded]="bmi.loaded" [noStyle]="true">

    <p mat-dialog-title>{{onEdit ? editTitle : 'Editar marca y modelo'}}</p>

    <div mat-dialog-content>

        <!-- Search -->
        <div [ngClass]="{'dn' : onEdit}">

            <form class="eina-form">
                <app-brand-model-input #bmi [form]="form" [showPrice]="true"></app-brand-model-input>
            </form>

            <div class="df jcfe mt10">
                <button mat-button color="warn" (click)="goNext()" [disabled]="isNextDisabled">Siguiente</button>
            </div>
        </div>

        <!-- Edit -->
        <div [ngClass]="{'dn' : !onEdit}">
            <div class="df jcfs">
                <button mat-button color="warn" (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                    Atrás
                </button>
            </div>
            <form class="form" [formGroup]="editForm">
                <div>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input type="number" min="0" matInput formControlName="price">
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Coste</mat-label>
                        <input type="number" min="0" matInput formControlName="cost">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="w50">
                        <mat-label>IVA</mat-label>
                        <mat-select formControlName="tax" [value]="21">
                            <mat-option [value]="0">Sin IVA</mat-option>
                            <mat-option [value]="4">4%</mat-option>
                            <mat-option [value]="10">10%</mat-option>
                            <mat-option [value]="21">21%</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Impuesto matriculación</mat-label>
                        <mat-select formControlName="tax_registration">
                            <mat-option [value]="RTE.Low"> {{registrationText(RTE.Low)}}</mat-option>
                            <mat-option [value]="RTE.MediumLow">{{registrationText(RTE.MediumLow)}}</mat-option>
                            <mat-option [value]="RTE.MediumHigh">{{registrationText(RTE.MediumHigh)}}</mat-option>
                            <mat-option [value]="RTE.High">{{registrationText(RTE.High)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="w100" appearance="outline">
                        <mat-label>Grupo contable</mat-label>
                        <input min="0" matInput formControlName="accounting">
                    </mat-form-field>
                </div>

                <div class="df jcc mt10">
                    <button mat-flat-button color="warn" (click)="update()">Actualizar</button>
                </div>

            </form>
        </div>
    </div>
</app-card>