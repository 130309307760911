<div class="footer-fields-container">
    <p>Adjuntar las cláusulas del contrato</p>
    <!-- FOOTER !-->
    <form [formGroup]="form" class="footer-form">
        <app-pdf-documents-upload (fileSelected)="onFileSelected($event)" [images]="true"></app-pdf-documents-upload>
    </form>

    <!-- EXTRA FIELDS -->
    <app-template-fields-form [isCompanyView]="true" (onChange)="pending = true" #templateFieldsForm></app-template-fields-form>

    <!-- PREVIEW BUTTON -->
    <button class="w100"  mat-stroked-button style="margin-top: 10px;"(click)="showPreview()">
            <mat-icon>find_in_page</mat-icon>
            Vista previa
    </button>
</div>