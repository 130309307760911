import { Component } from '@angular/core';
import { or_types } from 'src/app/custom-classes/or_types';
import { M_RAGroup } from 'src/app/models/M_RAGroup';


@Component({
  selector: 'app-ra-groups',
  templateUrl: './ra-groups.component.html',
  styleUrls: ['./ra-groups.component.css']
})
export class RaGroupsComponent {

  groups: M_RAGroup[] = [new M_RAGroup("")];
  index = 1;

  addGroup() {
    let g = new M_RAGroup("");
    this.groups.push(g);
  }

  removeGroup(g: M_RAGroup) {
    this.groups.removeElement(g);
  }

  onTypeChange(tc: M_RAGroup, val: number) {
    tc.or_type = new or_types(val);
  }

}
