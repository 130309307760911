import { Component, Input } from '@angular/core';
import { order_status, order_status_pending } from '../../custom-classes/order_states';

@Component({
  selector: 'app-order-status-label',
  templateUrl: './order-status-label.component.html',
  styleUrls: ['./order-status-label.component.css']
})
export class OrderStatusLabelComponent {
  @Input() showText : boolean = false;
  @Input() circularStatus : boolean = false;
  @Input() status! : order_status;
  constructor() {}

  ngOnInit(): void {
    if (this.status == undefined){
      this.status = order_status_pending;
    }
  }

  get taglabel(){
    return this.status.name;
  }
}
