
<div #parentDiv class="imgContainer" (click)="showPreview()" [ngClass]="{'cp' : parent.showPreviewOnClick, 'imgContainer_p': phoneUser, 'selected' : selected && !principal}" (mouseover)="mouseOver=true" (mouseleave)="mouseOver = false">
    <div *ngIf="parent.canReorder">
        <button [ngClass]="mouseOver? 'arrowIcon' : 'arrowIcon2'" (click)="move('up')" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <mat-spinner class="loading-spinner" *ngIf="!cf.loaded" style="margin-right: 5px;" [strokeWidth]="6" [diameter]="25" mode="indeterminate"></mat-spinner>
    <img  *ngIf="cf.getType() == ft.image" [src]="cf.src ? cf.src : './assets/img/loading.png'" alt="" [ngClass]="{'img-cover' : true, 'imHighlighted': principal, 'loading-img' : !cf.loaded }">
    <video *ngIf="cf.getType() == ft.video" controls alt="" [src]="cf.src"></video>

    <!-- If is not image or vdieo -->
    <div class="df jcc aic uploadedFileLabel" *ngIf="cf.getType() != ft.image && cf.getType() != ft.video" >
        <p style="margin-bottom: 0px; margin-right: 5px;" [ngClass]="cf.file ? '' : 'c_b fw500'">{{cf.getFileName()}}</p>
        <button *ngIf="canDelete && cf.loaded" (click)="remove()" mat-icon-button>
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <!-- If is image or video, floating remove button-->
    <div *ngIf="cf.getType() == ft.image || cf.getType() == ft.video"  [ngClass]="parent.canReorder ?'absolute' : 'absolute_n_a'">
        <button *ngIf="canDelete && cf.loaded" class="deleteIcon" (click)="remove()" mat-icon-button>
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <div class="absolute2" *ngIf="parent.fav">
        <button class="starIcon" (click)="markAsPrincipal($event)" mat-icon-button>
            <mat-icon [ngClass]="principal? 'yellow': ''">star</mat-icon>
        </button>
    </div>
    <div *ngIf="parent.canReorder">
        <button [ngClass]="mouseOver? 'arrowIcon' : 'arrowIcon2'" (click)="move('down')" mat-icon-button>
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
    <div *ngIf="principal" class="principalP">Foto principal</div>
</div>