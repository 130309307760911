

<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Reservas de productos</p>

<!-- Dialog content -->
<div mat-dialog-content *ngIf="!data.isClosing">
    <!-- Fist section -->
    <p mat-dialog-subtitle>Has aplicado reservas en alguno de tus productos</p>
    <p>Resumen:</p>
    <table  mat-table [dataSource]="data.reserves" class="mat-elevation-z8 mb10">
        <ng-container matColumnDef="Ref">
            <th mat-header-cell *matHeaderCellDef>Ref</th>
            <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
        </ng-container>
        <ng-container matColumnDef="Cantidad">
            <th mat-header-cell *matHeaderCellDef> Cantidad </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
          </ng-container>
          <ng-container matColumnDef="Faltas">
            <th mat-header-cell *matHeaderCellDef> Faltas </th>
            <td mat-cell *matCellDef="let element"> {{element.totalFaults}} </td>
          </ng-container>
          <ng-container matColumnDef="Reservar">
            <th mat-header-cell *matHeaderCellDef> Reserva </th>
            <td mat-cell *matCellDef="let element">Sí</td>  
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>

<div mat-dialog-content *ngIf="data.isClosing">
    <!-- Fist section -->
    <p mat-dialog-subtitle>Los productos reservados al cerrar el presupuesto se reservarán automáticamente</p>
    <p>Resumen:</p>
    <table  mat-table [dataSource]="data.reserves" class="mat-elevation-z8 mb10">
        <ng-container matColumnDef="Ref">
            <th mat-header-cell *matHeaderCellDef>Ref</th>
            <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
        </ng-container>
        <ng-container matColumnDef="Cantidad">
            <th mat-header-cell *matHeaderCellDef> Cantidad </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
          </ng-container>
          <ng-container matColumnDef="Faltas">
            <th mat-header-cell *matHeaderCellDef> Faltas </th>
            <td mat-cell *matCellDef="let element"> {{element.totalFaults}} </td>
          </ng-container>
          <ng-container matColumnDef="Reservar">
            <th mat-header-cell *matHeaderCellDef> Reserva </th>
            <td mat-cell *matCellDef="let element">Sí</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions *ngIf="!data.isClosing">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" mat-flat-button  [mat-dialog-close]="true" cdkFocusInitial (click)="saveReserve()">Aplicar reservas</button>
</div>
<div mat-dialog-actions *ngIf="data.isClosing">
    <button mat-button  [mat-dialog-close]="true">Continuar</button>
</div>