<div class="header or">
    <div class="header-contents">
        <mat-icon class="mr5">build</mat-icon>
        <p class="week-text workload-overflow-text fsm mr10 fw500">{{item.title_to_show}}</p>
        <!--<p class="nmb ds fw500" style="margin-left: auto; font-size: 22px;">{{item.vehicleLicense()}}</p>!-->
        <app-or-status-label class="big-screen-satus" [status]="item.status">
        </app-or-status-label>
        <button prevent mat-icon-button [matMenuTriggerFor]="actions">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</div>

<div class="day-card-body elevation">
    <div>
        <div class="df fdc">
            <div *ngIf="false">
                <p class="fsxs c_t2 workload-overflow-text">Entrega del día
                    {{item.delivery?.shortFormat()}}</p>
            </div>
            <app-or-status-label class="small-screen-satus" [status]="item.status">
            </app-or-status-label>
            <p class="fsl fw500 c_p ttu">{{item.vehicleBrand()}} {{item.vehicleName()}}</p>
            <p class="fsl fw500 ttu">{{item.clientName()}}</p>
            <p *ngIf="item.delivery && item.schedule && item.delivery.isEquals(item.schedule) && item.status.open" class="c_o fw500">
                <mat-icon class="vam c_o">running_with_errors</mat-icon> ¡Se entrega hoy!
            </p>
            <p *ngIf="item.delivery && item.schedule && !item.delivery.isEquals(item.schedule) && item.delivery < item.schedule && item.status.open" class="c_r fw500">
                <mat-icon class="vam c_r">error</mat-icon> Fuera de plazo
              </p>
        </div>
    </div>
</div>

<div class="day-card-body">
    <!-- Day card content -->
    <div class="df fdc">
        <!-- Left -->


        <!-- Right -->
        <div class="groups-section">
            <div class="title-wrapper fww">
                <p class="day-title c_p">GRUPOS</p>
            </div>
            <!-- OR GROUP TASKS-->
            <app-group-status-type-text [preferWidth]="true" [groups]="item.groups"></app-group-status-type-text>
        </div>
        <div class="separator"></div>
        <!-- Bottom contents -->
        <div class="bottom-contents pd0">
            <div class="images">
                <div *ngIf="item.hasImages()">
                    <img *ngFor="let i of item.images" [src]="i.src">
                </div>
                <div *ngIf="!item.hasImages()" class="df aic">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <img style="width:40px;height:40px;" src="./assets/img/lord-icon-images/camera.png">
                    <p class="nmb">Sin imágenes</p>
                </div>
            </div>
            <div class="wokrer">

                <app-circular-letter prevent [matMenuTriggerFor]="selsectuser" *ngIf="item.assigned; else noAssinged"
                    [showFullName]="true" [medium]="true" [user]="item.assigned!" [showTooltip]="false">
                </app-circular-letter>

                <ng-template #noAssinged>
                    <button prevent [matMenuTriggerFor]="selsectuser" mat-icon-button
                        *ngIf="item.assigned == undefined && !item.status.invoiced && canAssign">
                        <mat-icon>
                            person_add
                        </mat-icon>
                    </button>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<mat-menu #actions="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="selsectuser">Cambiar operario</button>
    <button mat-menu-item (click)="onGoOR.emit()">Ver OR</button>
    <div class="contact-section df jcsa aic" *ngIf="canContact">
        <app-whatsapp [phone]="item.clientPhone()"></app-whatsapp>
        <app-phone-button [phone]="item.clientPhone()"></app-phone-button>
        <app-email-button [email]="item.clientEmail()"></app-email-button>
    </div>
</mat-menu>


<mat-menu #selsectuser>
    <lib-or-change-worker [day]="day" [item]="item" [canAssign]="canAssign" [allUsers]="allUsers"
        (onAssignUser)="onAssignUser.emit($event)"></lib-or-change-worker>
</mat-menu>