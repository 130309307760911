import { CustomEnum } from "./CustomEnum";

type invoice_states_names = "Pendiente" | "Parcial" | "Cobrada";

const types: [number, invoice_states_names][] = [
    [0, "Pendiente"],
    [1, "Parcial"],
    [2, "Cobrada"],
]

export class invoice_states extends CustomEnum {
    constructor(tipo: invoice_states_names | number) {
        super(types, tipo)
    }

    get isPending() {
        return this.num == invoice_states_pending.num;
    }

    get isPartial() {
        return this.num == invoice_states_partial.num;
    }

    get isPayed() {
        return this.num == invoice_states_payed.num;
    }
}

export const invoice_states_pending = new invoice_states("Pendiente");
export const invoice_states_partial = new invoice_states("Parcial");
export const invoice_states_payed = new invoice_states("Cobrada");