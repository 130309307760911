import { Component } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { allDiscountGroups } from 'src/app/constants/constants';
import { M_DiscountGroupLine } from 'src/app/models/M_DiscountGroupLine';
import { discountGroup } from 'src/app/models/Products/M_BaseProduct';

@Component({
  selector: 'app-discount-groups',
  templateUrl: './discount-groups.component.html',
  styleUrls: ['./discount-groups.component.css']
})
export class DiscountGroupsComponent {

  allDiscountGroups = allDiscountGroups;
  currentEditing: discountGroup | undefined = undefined;
  discoungGroups: M_DiscountGroupLine[] = [];
  loadingGroup = false;
  v = ViewPath;

  constructor(private apiS: ApiService) { }

  onGroupClick(g: discountGroup) {
    this.loadingGroup = true;
    this.currentEditing = g;
    this.apiS.getLinesByDiscountGroup(this.currentEditing).then(res => {
      this.discoungGroups = res;
      this.loadingGroup = false;
    })
  }

  edit() {
    if (!this.currentEditing) { return; }
    this.apiS.addLinesToDiscountGroup(this.currentEditing, this.discoungGroups).then(res => {
      this.currentEditing = undefined;
      this.discoungGroups = [];
    })
  }

  appendLine() {
    this.discoungGroups.push(new M_DiscountGroupLine({}))
  }

  deleteLine(line: M_DiscountGroupLine) {
    this.discoungGroups.removeElement(line)
  }

  get isOkDisabled() {
    return this.discoungGroups.some(dg => dg.code_line == "" || dg.code_line == null || dg.code_line == undefined) ||
      this.discoungGroups.some(dg => dg.p_group == "" || dg.p_group == null || dg.p_group == undefined) ||
      this.discoungGroups.some(dg => dg.p_type_1 > 100 || dg.p_type_2 > 100 || dg.v_cat_1 > 100 || dg.v_cat_2 > 100 || dg.v_cat_3 > 100 || dg.v_cat_4 > 100 || dg.v_cat_5 > 100)
  }

}
