<!-- Mechanic dashboard -->
<div *ngIf="!subS.hasProPermissions && userS.userIsMechanic" class="df jcc fdc aic bottom-entrance-blurred">
    <lord-icon class="lord-big" trigger="in" delay="1000" src="https://cdn.lordicon.com/cniojwli.json">
    </lord-icon>
    <p class="fsi fsl mt10">¡Echa el freno!</p>
    <p>Para poder acceder a la <span class="fw600 underline_r">carga de taller</span> debes tener la subscripción PRO.</p>
    <p>¡No te quedes atascado, comunícate con el administrador del taller para desbloquear el acceso completo a Eina!</p>
</div>

<app-core-carga-taller *ngIf="subS.hasProPermissions" [workloadData]="wS.workloadData"
    (deliveryWarn)="openWarnDialog($event)" (onModifyAppointment)="onModifyAppointment($event)"></app-core-carga-taller>