import { Component } from '@angular/core';
import { M_Module } from 'src/app/models/M_Module';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.css']
})
export class ModuleSelectorComponent {

  modules: M_Module[] = []
  loaded = false;
  constructor(private apiS: ApiService) {
    this.getModules();
  }

  getModules() {
    this.apiS.getModules().then(res => {
      this.modules = res;
      this.loaded = true;
    })
  }

}
