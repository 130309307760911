import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { filter } from '../../custom-classes/MasterFilter';
import { M_Albaran } from '../../models/M_Albaran';
import { ViewPath } from 'src/app/app-routing.module';
import { ParamsService } from 'src/app/services/params.service';
import { ApiService } from 'src/app/services/Api/api.service';
import { feature } from 'src/app/utils/FeaturesController';
import { PreviewService } from 'src/app/services/preview.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { M_PDFTemplate } from 'src/app/models/M_PDFTemplate';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { DayFilter, Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { albaran_status } from 'src/app/custom-classes/albaran_status';
import { or_types } from 'src/app/custom-classes/or_types';

export enum AlbaranPageFiltesrEnum {
  STATUS = 0,
  TYPE = 1,
  CREATED_AT = 2,
}

@Component({
  selector: 'app-albaranes',
  templateUrl: './albaranes.component.html',
  styleUrls: ['./albaranes.component.css']
})
export class AlbaranesComponent implements AfterViewInit {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Albaran>;

  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  f = filter;
  v = ViewPath;

  filters: Filter[] = [
    new TagFilter("Estado", albaran_status, new FilterOption("Abiero"), new FilterOption("Cerrado"), new FilterOption("Facturado")).setId(AlbaranPageFiltesrEnum.STATUS),
    new TagFilter("Tipo", or_types, new FilterOption("Normal"), new FilterOption("Interno", undefined), new FilterOption("Garantia", undefined, !feature.garantia), new FilterOption("Siniestro", undefined, true), new FilterOption("Sin tipo", undefined, true)).setId(AlbaranPageFiltesrEnum.TYPE),
    new DayFilter("Fecha realización").setId(AlbaranPageFiltesrEnum.CREATED_AT),
  ]

  constructor(public subS: SubscriptionService, public params: ParamsService, private apiS: ApiService, private previewS: PreviewService) { }


  ngAfterViewInit(): void {
    this.apiS.albaranes().then(res => {
      const noDeletedAlbaran = res.filter(item => !item.deleted);
      this.ps.initTable(noDeletedAlbaran);
    })
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == AlbaranPageFiltesrEnum.STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  getClient(albaran: M_Albaran) {
    return albaran.client?.getName();
  }

  preicon(albaran: M_Albaran) {
    return isSomethingMissing(albaran.faults)
  }

  previsualize(pdfTemplate: M_PDFTemplate) {
    this.previewS.showPreview(pdfTemplate.type, pdfTemplate.token);
  }
}
