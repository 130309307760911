<app-speech-bubble *ngIf="currentSection" [section]="currentSection"></app-speech-bubble>
<mat-drawer-container [hasBackdrop]="true" [ngClass]="{'closed' : !menu.opened, 'open' : menu.opened}">
    <mat-drawer (openedStart)="onOpen()" (closedStart)="onClose()" style="max-width: 500px; height: 100vh;"
        [opened]="false" #menu [position]="'end'" mode="over">
        <app-card [noStyle]="true">
            <div class="title-square">
                <div class="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            class="shape-fill"></path>
                    </svg>
                </div>
                <button mat-icon-button color="white" class="close-drawer-icon" (click)="close()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
            <app-card-title class="white">Inicio rápido</app-card-title>
            <div class="section-container">
                
                <!-- TO DO-->
                <mat-progress-bar *ngIf="showProgress" class="onboarding-bar"
                    [value]="userOnboarding.getPercentageCompleated()"></mat-progress-bar>

                <ng-container *ngFor="let section of filteredSections">
                    <mat-accordion [class]="'example-headers-align ' + section._class" multi *ngIf="!section.hidden">
                        <mat-expansion-panel [expanded]="section.startOpened">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="mr5 ov" [filled]="true" [ngClass]="section.completed ? 'c_p' : 'c_t2'">check_circle
                                    </mat-icon>
                                    <app-card-subtitle class="onboarding-subtitle" [first]="true"
                                        [noMarginBottom]="true">
                                        {{section.title}}
                                    </app-card-subtitle>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="c_t2">{{section.description}}</p>
                            <button
                                [class]="section._class ? section._class + '-button' : ''"
                                [color]="section.closed && !section.completed ? 'blue' : !section.closed && !section.completed ? 'orange' : 'primary'"
                                mat-flat-button (click)="startSection(section)">{{section.completed ? 'Completada' :
                                section.closed ?
                                'Empezar' : 'En curso'}}</button>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
            </div>

            <div *ngIf="faqs.length" style="margin-top: 30px;">
                <app-card-title [rightIcon]="'contact_support'">FAQS</app-card-title>
                <lib-faqs *ngFor="let f of faqs" [faq]="f"></lib-faqs>
            </div>

        </app-card>
    </mat-drawer>
    <mat-drawer-content>
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>