<app-card [contentLoaded]="grid.parent.loaded_" cdkDropList
    (cdkDropListDropped)="grid.drop($event, grid.parent.currentDay!.v)"
    [cdkDropListData]="grid.parent.currentDay?.data!" [cdkDropListSortingDisabled]="!grid.parent.workloadData.isOrView"
    [cdkDropListDisabled]="respoisiveS.w <= 1003">
    <div class="df aic">
        <div [ngClass]="{'today' : grid.parent.refDay.isToday(), 'calendar df fdc' : true}">
            <div class="dayname pl10">{{grid.parent.refDay.getDayName()}}</div>
            <div class="daynumber fsxl mb20"
                [ngClass]="{'mini-companyDay' : grid.isCompanyHolidays(grid.parent.refDay)}">
                {{grid.parent.refDay.getDate()}}
                <ng-container *ngIf="grid.parent.workloadData.AppoPermisison">
                    <div *ngIf="grid.parent.getItemOfDay(grid.parent.refDay, true).length as total"
                        class="other-item-number" [ngClass]="!grid.parent.workloadData.isOrView ? 'or' : 'appointment'"
                        (click)="grid.parent.changeView(!grid.parent.workloadData.isOrView)">
                        <mat-icon>{{!grid.parent.workloadData.isOrView ? "build" : "event"}}</mat-icon>
                        +{{total}}
                    </div>
                </ng-container>
            </div>

        </div>
    </div>

    <div class="addNewOr ml5" *ngIf="grid.parent.currentDay" style="justify-content: flex-start;">
        <div
            *ngIf="grid.parent.currentDay.v.getDay() != 0 && grid.parent.currentDay?.v?.todayOrBigger() && grid.parent.workloadData.createView">
            <button mat-button (click)="grid.goCreateOnDay(grid.parent.currentDay.v)">
                {{grid.parent.workloadData.isOrView ? "Nueva OR" : "Nueva cita"}}
            </button>
        </div>
    </div>

    <div *ngIf="grid.parent.loaded_" class="df fdc fww jcc grid-container-parent">
        <div *ngFor="let item of grid.parent.currentDay?.dataAsAny">
            <app-grid-card class="no-blue" [workloadData]="grid.parent.workloadData"
                [filters]="grid.parent.currentFilters" [draggable]="respoisiveS.w >= 1003"
                [searchFilter]="grid.parent.searchFilter" [period]="grid.parent.selectedPeriod"
                [allUsers]="grid.parent.users" [day]="grid.parent.refDay" [item]="item"
                [otherCardIsDragging]="grid.draggingCard != undefined" (onStartDragging)="grid.draggingCard = $event"
                (deliveryWarn)="grid.deliveryWarn.emit($event)"
                (onModifyAppointment)="grid.onModifyAppointment.emit($event)"></app-grid-card>
        </div>
        <div *ngIf="grid.parent.currentDay?.data?.length == 0">
            <p class="c_t2 tac">
                {{grid.parent.workloadData.isOrView ? "Sin ordenes de reparación para el día solicitado." : "Sin citas
                para el día solicitado."}}
            </p>
        </div>
    </div>
</app-card>