/**
 * App endpoints
 * @baseUrl : Domain of current environment
 * @suffix : Endpoint route
 * @appendHeader : Append auth header / token ? 
 * @showLoading : Show the loading panel ?
 * @customError : When set to true, the httpListener don't show any error dialog
 */
export class Endpoint {
    constructor(private baseUrl : string, private suffix : string, public appendHeader: boolean = true, public showLoading : boolean = true, public customError : boolean = false){}
    get url(){
        return this.baseUrl + this.suffix;
    }
}