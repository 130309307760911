import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { EnrtryTypeEnum, entranceTypeIcon, entranceTypeName } from 'src/app/enums/EnrtryTypeEnum';
import { M_StoreHouseEntry } from 'src/app/models/M_StoreHouseEntry';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { PreviewService } from 'src/app/services/preview.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { SliderFilter, Filter, ClassSearcherFilter, TagFilter, FilterOption, DayFilter } from 'src/app/custom-classes/Filter';
import { getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';

export enum StorehouseEntryFiltesrEnum {
  PROVIDER = 0,
  ENTRY_TYPE = 1,
  DATE = 2,
  TOTAL = 3,
}

@Component({
  selector: 'app-storehouse-entry',
  templateUrl: './storehouse-entry.component.html',
  styleUrls: ['./storehouse-entry.component.css']
})
export class StorehouseEntryComponent implements OnInit {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_StoreHouseEntry>;

  f = filter;
  primary = getPrimaryColor;
  warn = getWarnColor;

  v = ViewPath;
  sliderFilter = new SliderFilter("Total", 999);
  filters: Filter[] = [
    new ClassSearcherFilter("Proveedor", MASTER_PROVIDER, "Buscar proveedor").setId(StorehouseEntryFiltesrEnum.PROVIDER),
    new TagFilter("Tipo de entrada", undefined,
      new FilterOption(entranceTypeName(EnrtryTypeEnum.PMP), entranceTypeIcon(EnrtryTypeEnum.PMP)),
      new FilterOption(entranceTypeName(EnrtryTypeEnum.IMPORT), entranceTypeIcon(EnrtryTypeEnum.IMPORT)),
      new FilterOption(entranceTypeName(EnrtryTypeEnum.COST), entranceTypeIcon(EnrtryTypeEnum.COST))).setId(StorehouseEntryFiltesrEnum.ENTRY_TYPE),
    new DayFilter("Fecha documento").setId(StorehouseEntryFiltesrEnum.DATE),
    this.sliderFilter.setId(StorehouseEntryFiltesrEnum.TOTAL)
  ]

  constructor(public subS: SubscriptionService, private apiS: ApiService, public paramsS: ParamsService, private previewS: PreviewService) { }

  ngOnInit(): void {
    this.apiS.getAllStoreHouseEntries().then(res => {
      this.ps.initTable(res);
      this.sliderFilter.changeMax(Math.max(...res.map(entry => entry.total)));
    })
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == StorehouseEntryFiltesrEnum.ENTRY_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  getProvName(she: M_StoreHouseEntry) {
    return she.provider?.getName();
  }

  entranceTypeIcon(she: M_StoreHouseEntry) {
    return she.entranceTypeIcon;
  }

  showPreview(she: M_StoreHouseEntry) {
    if (!she.id) { return; }
    this.previewS.showPreview("EA", she.id.toString());
  }

}
