<app-page-structure #comercialBudget [masterClass]="'budget'"
    [pageTitle]="'Presupuestos comercial'" [autocompleteID]="'budget_comercial'"
    listTitleText="Listado de presupuestos"
    searchLabel="Buscar presupuesto" createButtonClass="onboarding-create-budget-comercial"
    [addNewView]="v.createComercialBudget" noDataCreateNew="Crear nuevo presupuesto" [data]="[]"
    [displayedHeader]=" ['Número',  'Marca',    'Modelo',   'Precio',   'Estado'] "
    [displayedColumns]="['id',      'brand',    'model',    'price',    'status']"
    [specialText]="     [undefined, brand,      model,      'money',    undefined]"
    [preIcon]="         [preIcon, undefined,  undefined,  undefined,  undefined]" [filter]="f" [filters]="filters"
    [specialClass]="    [undefined, undefined,  undefined,  undefined]" [card]="card"
    [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear presupuesto' , icon : 'calculate'}"
    [quickFilter]="quickFilter">
    
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir al presupuesto'" mat-flat-button color="warn"
                (click)="goComercialB(ps.selected!)">
                <mat-icon>garage</mat-icon>
                Presupuesto Nº {{ps.selected!.id}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <button class="mt10" mat-stroked-button (click)="previsualize(ps.selected!)"
                *ngIf="ps.selected!.token">
                <mat-icon>find_in_page</mat-icon>
                Presupuesto
            </button>

            <button class="mt10" mat-stroked-button (click)="previsualizeI(ps.selected!.invoice)"
                *ngIf="ps.selected!.invoice">
                <mat-icon>find_in_page</mat-icon>
                Factura
            </button>

            <ng-container *ngIf="ps.selected!.vehicle; else brandModel">
                <app-card-subtitle [first]="false">Vehículo</app-card-subtitle>
                <div class="df fww">
                    <app-vn-vo-label *ngIf="ps.selected!.vehicle.isVnVo" [type]="ps.selected!.vehicle.type"></app-vn-vo-label>
                    <app-vehicle-row [item]="ps.selected!.vehicle"></app-vehicle-row>
                </div>

                <app-card-subtitle [first]="false">Chassis</app-card-subtitle>
                {{ps.selected!.vehicle.chassis}}

            </ng-container>

            <ng-template #brandModel>
                <app-card-subtitle [first]="false">Marca y modelo</app-card-subtitle>
                {{ps.selected!.getBrandModel()}}
            </ng-template>

        </app-card>
    </ng-template>
</app-page-structure>