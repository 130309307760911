import { Component, Input, OnInit } from '@angular/core';
import { or_types } from '../../../custom-classes/or_types';
import { MatChip } from '@angular/material/chips';

@Component({
  selector: 'app-or-type-label',
  templateUrl: './or-type-label.component.html',
  styleUrls: ['./or-type-label.component.css', '../labels.css']
})
export class OrTypeLabelComponent implements OnInit {

  @Input() small = false;
  @Input() pointer = false;
  @Input() tipo!: or_types[] | undefined;
  @Input() showText: boolean = true;
  @Input() verticalLines: boolean = false;
  @Input() wrapOn: number | undefined;
  /** For "mantenimiento" type */
  @Input() shortM: boolean | undefined;
  @Input() selectable: boolean = false;
  @Input() menuIconOpen: boolean = false;

  selecteds: or_types[] = [];
  constructor() { }

  ngOnInit(): void {
    if (this.tipo == undefined) {
      this.tipo = [];
      this.tipo.push(new or_types("Sin tipo"));
    }
    else if (!Array.isArray(this.tipo)) {
      this.tipo = [this.tipo];
    }
  }

  get needWrap() {
    return this.wrapOn != undefined && this.tipo != undefined && this.tipo.length >= this.wrapOn;
  }

  /** Short name for "mantenimiento" type */
  shortMantenimiento(i: number) {
    var t = this.tipo ? this.tipo[i] : undefined;
    if (!t) { return false }
    return t.mantenimiento && this.shortM;
  }

  get isToDefine() {
    return this.tipo?.length == 1 && this.tipo[0].num == -1;
  }

  onSelect(mc: MatChip) {
    if (this.selectable) {
      mc.highlighted = !mc.highlighted
    }
  }

}
