import { Component, Input, OnInit } from '@angular/core';
import { endpointResponse } from '../../types/endpoint-response';

@Component({
  selector: 'lib-endpoint-line',
  templateUrl: './endpoint-line.component.html'
})
export class EndpointLineComponent implements OnInit {
  
  @Input() res! : endpointResponse;
  open = false;
  constructor() { }
  ngOnInit(): void {}

}
