import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { M_Purchase } from '../models/M_Purchase';
import { ParameterStateComponent } from '../components/parameter-state/parameter-state.component';
import { ViewPath } from '../app-routing.module';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog } from '@angular/material/dialog';
import { PreviewService } from '../services/preview.service';
import { M_Vehicle } from '../models/M_Vehicle';
import { PayAllInvoiceDialogComponent } from '../views/invoices/pay-check-control/pay-all-invoice-dialog.component';
import { TypePaymentService } from '../services/type-payment-service';
import { M_LastMovements } from '../models/M_LastMovement';


export interface CV_PDF {
  client_id:    number | undefined,
  vehicle_id:   number | undefined,
  price:        number |  undefined,
  tax:          number |   undefined,
  comment :     string | undefined,
  type :        number | undefined,
  docum_prov_num :     string | undefined,
  mode : string | undefined,
  buy_transac_id: number | undefined
  token: "CV" | "FC" | undefined
}

@Component({
  selector: 'app-purchase-details',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.css']
})

export class PurchaseDetailsComponent  extends ParameterStateComponent {
loaded = false;
v = ViewPath;
isOpen = false;
menuVisible = false;
pdfData: CV_PDF | undefined;
pdfDataFC: CV_PDF | undefined;
purchase: M_Purchase | undefined;
vehicles: M_Vehicle[] =[];
constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
public previewS: PreviewService,private chdRef: ChangeDetectorRef,public d: MatDialog,public typePaymentS: TypePaymentService){
  super(routerS, route, ["purchase"])
}
override onParam(_k: string, v: any) {
  //cambiar endpoint 
  this.apiS.getPurchaseById(v).then(res => {
    console.log(res);
    if (res == undefined) {
      this.purchase = undefined;
    }
    if (res instanceof M_Purchase) {
      this.purchase = res;
      if(res.vehicle){
        this.vehicles.push(res.vehicle);
      }
      this.constructCV(res);
      this.constructFC(res);
      console.log(this.pdfData);
    }
    this.loaded = true;
    this.chdRef.detectChanges();
  })
}
constructCV(purchase:M_Purchase){
  if(purchase && purchase.contact && purchase.vehicle && purchase.pdf_cv && purchase.pdf_fc != undefined){
   this.pdfData = {
      client_id: purchase.client_id, // Ejemplo de valores
      vehicle_id: purchase.vehicle_id,
      price: purchase.total,
      tax: purchase.tax,
      comment: "",
      type: purchase.type,
      docum_prov_num: purchase.docum_prov_num,
      mode: 'save',
      buy_transac_id: purchase.id,
      token : "CV"
    };

  }
  
}
constructFC(purchase:M_Purchase){
  if(purchase && purchase.contact && purchase.vehicle && purchase.pdf_cv && purchase.pdf_fc != undefined){
   this.pdfDataFC = {
      client_id: purchase.client_id, // Ejemplo de valores
      vehicle_id: purchase.vehicle_id,
      price: purchase.total,
      tax: purchase.tax,
      comment: "",
      type: purchase.type,
      docum_prov_num: purchase.docum_prov_num,
      mode: 'save',
      buy_transac_id: purchase.id,
      token : "FC"
    };

  }
  
}
showPdfcv(){
  this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, this.pdfData);
}
showPdffc(){
  this.previewS.showPreview("CV", "FC", undefined, undefined, false, undefined, undefined, undefined, this.pdfDataFC);
}
gotoPay(){
  this.d.open(PayAllInvoiceDialogComponent, { panelClass: 'customDialog', data: { invoice: undefined, last_movements: undefined, pending: true, typePayment: this.typePaymentS.typePaymentArray,purchase: this.purchase } }).afterClosed().subscribe(res => {
    /** Some advance has done */
    // if (res instanceof M_LastMovements) {
    //   this.ps.data.forEach(d => {
    //     if (d instanceof M_Invoice && d.client && d.client.client_id == res.client_id) {
    //       let movement = d.client.last_movement.find(lm => lm.id == res.id);
    //       if (movement) { movement.deleted = true; }
    //     }
    //   })
    // }
    // this.newFilterPayment();
  });
}
deleteLastPayment(last_movement: M_LastMovements) {
  // this.confirmDialog.show({
  //   title: "¿Seguro que quieres eliminar el cobro?",
  //   body: "Se eliminara el cobro de la factura",
  //   confirmTxt: "Eliminar",
  //   showCancel: true,
  //   type: "danger"
  // }).afterClosed().subscribe(res => {
  //   if (res) {
  //     this.apiS.deleteLastPayment(last_movement).then(res => {
  //       this.purchase!.last_movements.removeElement(last_movement);
  //       // this.ps.data.forEach(d => {
  //       //   if (d instanceof M_Invoice && d.client && d.client.client_id == res.client_id) {
  //       //     let movement = d.client.last_movement.find(lm => lm.id == res.id);
  //       //     if (movement) { movement.deleted = false; }
  //       //   }
  //       // })
  //     })
  //     if (this.ps.selected?.last_movement.length == 1) {
  //       this.pendingChip(invoice_states_pending);
  //     }
  //     this.partialChip(invoice_states_partial);
  //     this.formChildren?.forEach(element => {
  //       element.resetForm();
  //     });
  //   }
  // });

}
get subTotal(){
  let  subtotal = 0;
  if(this.purchase && this.purchase.total && this.purchase.tax){

    subtotal = this.purchase.total - this.purchase.taxSum;
  }
  
  return subtotal;
}
toggleSection() {
  this.isOpen = !this.isOpen;
}
verDocumento(puchase: M_Purchase) {
  // Lógica para visualizar el documento
  console.log('Ver documento:', this.purchase?.pdf_cv);
}
toggleMenu(){
  this.menuVisible = !this.menuVisible;
}
downloadFile(){}
show(){}
descargarDocumento(puchase: M_Purchase) {
  // Lógica para descargar el documento
  console.log('Descargar documento:', this.purchase?.pdf_cv);
}
}
