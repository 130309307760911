import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IExitSaveChanges } from '../interfaces/IExitSaveChanges';
import { ConfirmExitDialogComponent } from '../components/confirm-exit-dialog/confirm-exit-dialog.component';
import { ViewPath } from 'src/app/app-routing.module';

/**
 * How to implement this guard ? 
 * 
 * 1 - Add this guard on come compoenent in the {@link ViewPath} :
 * 
 * { path: ViewPath.createInvoice.path, title:"Crear factura", ... canDeactivate: [{@link DeactivateGuard}]},
 * 
 * The component need to implement the {@link IExitSaveChanges} interface :
 * 
 * export class CreateInvoiceComponent extends ParameterStateComponent implements {@link IExitSaveChanges}
 * 
 * */
export class DeactivateGuard  {
    canDeactivate(component: IExitSaveChanges, _currentRoute: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (nextState) {
            if (component.showSaveExitDialog() && !component.forceExit) {
                let url = nextState.url;
                component.d.open(ConfirmExitDialogComponent, { data: url, disableClose: true, autoFocus: false }).afterClosed().subscribe(res => {
                    if (res == "exit-save") {
                        component.saveChangesBeforeExit();
                        component.forceExit = true;
                        component.router.navigateByUrl(url);
                    }
                    else if (res == "exit") {
                        component.forceExit = true;
                        component.router.navigateByUrl(url);
                    }
                });
                return false;
            }
        }
        return true;
    }
}