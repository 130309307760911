import { Component } from '@angular/core';

/** 
 * <app-advanced-details-footer>
 * 
 *    <!-- Title-->
 *    <app-footer-title>Ver más datos</app-footer-title>
 * 
 *    <!-- Content on title right -->
 *    <app-footer-right>
 *      <p>Example</p>
 *    </app-footer-right>
 * 
 *    <!-- Footer content !-->
 *    <app-footer-content>
 * 
 *       <!-- Example : Sections -->
 *       <app-section>
 *           <app-section-title>Example title</app-section-title>
 *           <app-section-content>Example content</app-section-content>
 *       </app-section>
 * 
 *    </app-footer-content>
 * 
 * </app-advanced-details-footer> 
*/
@Component({
    selector: 'app-advanced-details-footer',
    template: `
  <div class="df jcsb aic mt20">
      <p class="fw600 nmb" #title>
          <mat-icon dropDown [content]="content" [trigger]="title" [starClosed]="true"
              class="vam">arrow_drop_up</mat-icon>
          <ng-content select="app-footer-title"></ng-content>
      </p>
      <ng-content select="app-footer-right"></ng-content>
  </div>
  <div #content>
      <ng-content select="app-footer-content"></ng-content>
  </div>`
})

export class AdvancedDetailsFooterComponent { }
