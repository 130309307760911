import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ComercialVehicleType } from 'src/app/enums/ComercialVehicleType';
import { MatDialog } from '@angular/material/dialog';
import { BrandModelEditComponent } from 'src/app/components/brand-model-edit/brand-model-edit.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { ItemRowComponent } from 'src/app/components/vehicle-row/item-row.component';
import { AssignClientVnVoComponent } from 'src/app/components/assign-client-vn-vo/assign-client-vn-vo.component';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { getWarnColor } from 'src/app/utils/FunctionUtils';
import { Filter, FilterOption, SliderFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { RouterService } from 'src/app/services/router.service';

export enum GaragePageFilterEnum {
  VEHICLE_TYPE = 0,
  VEHICLE_PRICE = 1
}

@Component({
  selector: 'app-garage',
  templateUrl: './garage.component.html',
  styleUrls: ['./garage.component.css']
})
export class GarageComponent implements OnInit {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Vehicle>;
  @ViewChild(ItemRowComponent) cl!: ClassSearcherComponent<M_Contact>;

  v = ViewPath;
  vehicles: M_Vehicle[] = [];
  vehicle: M_Vehicle | undefined;
  warn = getWarnColor

  sf = new SliderFilter("Precio", 9999).setId(GaragePageFilterEnum.VEHICLE_PRICE);

  filters: Filter[] = [
    new TagFilter("Tipo", undefined, new FilterOption("VN"), new FilterOption("VO")).setId(GaragePageFilterEnum.VEHICLE_TYPE),
    this.sf
  ];

  constructor(public apiS: ApiService, public routerS: RouterService, public params: ParamsService, public subS: SubscriptionService,
    private d: MatDialog) {
    apiS.vnvo.vehicles().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        this.sf.changeMax(Math.max(...resp.map(o => o.price || 0)));
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == GaragePageFilterEnum.VEHICLE_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  filter(object: M_Vehicle, ...filters: Filter[]): boolean {
    var fitleredFilters = filters.filter(f => f.activated);
    let isTypeOk = true;
    let isPriceOk = true;
    fitleredFilters.forEach(f => {
      if (f instanceof TagFilter && f.id == GaragePageFilterEnum.VEHICLE_TYPE) {
        isTypeOk = f.checkFilter([object.comercialType == ComercialVehicleType.VN ? 0 : 1])
      }
      if (f instanceof SliderFilter && f.id == GaragePageFilterEnum.VEHICLE_PRICE) {
        isPriceOk = f.checkFilter(object.price);
      }
    })
    return isTypeOk && isPriceOk;
  }

  ngOnInit(): void { }

  goCreateOr(v: M_Vehicle) {
    this.params.go(ViewPath.createOr, v.vehicle_id!);
  }

  openBrandModel() {
    this.d.open(BrandModelEditComponent);
  }

  preIcon(v: M_Vehicle) {
    return v.icon;
  }

  goAssignClient(v: M_Vehicle) {
    this.d.open(AssignClientVnVoComponent, {
      data: {
        client: v.client,
        vehicle: v
      },
    }).afterClosed().subscribe((res: M_Contact) => {
      if (res instanceof M_Contact) {
        this.apiS.assignClient(v, res).then(cont => {
          v.client = res;
        })
      }
    });
  }

  getVnVo(v: M_Vehicle) {
    return v.comercialType == ComercialVehicleType.VN ? "VN" : "VO";
  }

  getVnVoClass(vehicle: M_Vehicle) {
    return 'vn-label';
  }

  getClient(): M_Contact | undefined {
    return this.ps?.selected?.client ? this.ps?.selected?.client : undefined;
  }
}

