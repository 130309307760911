import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'lib-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent implements OnInit {
    maintainAspectRatio = true;
    dni = false;
    cropImgPreview: any;
    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data : any){
      this.maintainAspectRatio = this.data.maintainAspectRatio;
      this.dni = this.data.dni;
    }

    ngOnInit(): void {}

    cropImg(e: ImageCroppedEvent) {
        this.cropImgPreview = e.base64;
    }
  
    getCroped(){
      return this.cropImgPreview;
    }
}