import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { Views } from 'src/app/custom-classes/View';
import { ActivityEnum, M_LastActivity } from 'src/app/models/M_Dashboard';

@Component({
  selector: 'app-last-activity',
  templateUrl: './last-activity.component.html',
  styleUrls: ['./last-activity.component.css']
})
export class LastActivityComponent {

  @Input() allActivity: M_LastActivity[] = [];
  @Input() isProfile: boolean = false;
  constructor() { }

  viewOf(a: M_LastActivity) : Views |undefined {
    if (a.action && a.object_id) {
      switch (a.activity_type) {
        case ActivityEnum.OR:
          return ViewPath.editOr;
        case ActivityEnum.BUDGET:
          return ViewPath.createEditBudget;
        case ActivityEnum.INVOICE:
          break;
        case ActivityEnum.CLIENT:
        case ActivityEnum.VEHICLE:
          return ViewPath.contactDetails;
        case ActivityEnum.ARTICLE:
          return ViewPath.productDetails;
        case ActivityEnum.CLOUDTICKET:
          break;
        case ActivityEnum.DELETE:
          break;
      }
    }
    return undefined;
  }
}
