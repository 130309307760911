<!-- WEEK OR -->
<div (click)="onGoAppointment.emit()" class="cp week-or-card">
    <div class="header appointment">
        <div class="header-contents">
            <mat-icon class="mr5">event</mat-icon>
            <p class="week-text workload-overflow-text fsm mr10">{{item.title_appointment}}</p>
            <button prevent mat-icon-button [matMenuTriggerFor]="menu" class="mla more-vert">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
    <div class="contents">
        <div class="body">
            <!-- Vehicle-->
            <p class="fsm fw500 c_p ttu overflow-text">{{item.vehicleName()}}</p>
            <p class="fsm fw500 ttu overflow-text">{{item.clientName()}}</p>
            <p *ngIf="item.reparations.length" prevent [matMenuTriggerFor]="groupMenu"
                [matMenuTriggerData]="{groups : item.reparations}" class="cp fss nmb blue_text_action"
                style="margin-left: auto; margin-right: 12px;">
                Ver tareas ({{item.reparations.length}})</p>
            <p *ngIf="!item.reparations.length" class="c_t2 fss nmb" style="margin-left: auto; margin-right: 12px;">Sin
                tareas establecidas</p>
        </div>
        <div class="bottom-section">
            <div class="separator"></div>
            <div [ngTemplateOutlet]="hourActionsAppointment"></div>
        </div>
    </div>
</div>

<!-- OR group menu -->
<mat-menu #groupMenu="matMenu">
    <ng-template matMenuContent let-groups="groups">
        <div style="padding : 5px 10px 5px 10px;">
            <p *ngFor="let g of item.reparations" class="df aic nmb">
                <mat-icon class="c_a" style="transform: scale(0.4);">circle</mat-icon>
                {{g}}
            </p>
        </div>

    </ng-template>
</mat-menu>


<ng-template #hourActionsAppointment>
    <div class="bottom-section-content">
        <lib-appointment-status-label [circularStatus]="true" style="transform: scale(0.8); margin-left: -5px;"
            [status]="item.status">
        </lib-appointment-status-label>
        <span class="c_t2 mr5"> | </span>
        <!-- (pointerdown)="preventCardClick($event)"  (touchend)="preventCardClick($event)" !-->
        <p class="nmb fw500" style="font-size: 18px;" prevent>
            {{item.appointment_date.hourFormat()}} <span *ngIf="item?.status == 0" class="cp fss nmb blue_text_action"
                (click)="onModifyAppointment.emit([item,'hour'])">Modificar</span></p>


    </div>
</ng-template>

<!--Appointments-->
<!-- Sinicloud deprecated (menu) !-->
<mat-menu #menu="matMenu">
    <div matTooltipPosition="above" [matTooltip]="!item.canRA ? 'La cita esta cancelada' :''">
        <button [disabled]="!item.canRA" mat-menu-item (click)="onModifyAppointment.emit([item,'ra'])">
            <mat-icon>build</mat-icon>
            Pasar a OR
        </button>
    </div>
    <button *ngIf="item?.status != app_status.canceled" mat-menu-item
        (click)="onModifyAppointment.emit([item,'eliminar'])">
        <lib-appointment-status-label [pointer]="true" [status]="app_status.canceled"></lib-appointment-status-label>
    </button>
    <button *ngIf="item?.status != app_status.pending" mat-menu-item
        (click)="onModifyAppointment.emit([item,'pending'])">
        <lib-appointment-status-label [pointer]="true" [status]="app_status.pending"></lib-appointment-status-label>
    </button>
    <button *ngIf="item?.status != app_status.done" mat-menu-item (click)="onModifyAppointment.emit([item,'done'])">
        <lib-appointment-status-label [pointer]="true" [status]="app_status.done"></lib-appointment-status-label>
    </button>
    <div class="contact-section df jcsa aic" [ngClass]="{'unique-option' : item.status != 0}">
        <app-whatsapp [phone]="item.clientphone"></app-whatsapp>
        <app-phone-button [phone]="item.clientphone"></app-phone-button>
        <app-email-button [email]="item.clientemail"></app-email-button>
    </div>
</mat-menu>