<div class="df aic" *ngIf="!circularStatus; else circular">
    <app-card-subtitle *ngIf="showText" class="subtitleNoBottom" [first]="true">Estado</app-card-subtitle>
    <div>
      <mat-chip-set>
          <mat-chip [class]="'i_states'+status.num"> <!-- CSS of or-status-label.component.css-->
            <span>{{taglabel}}</span>
          </mat-chip>
      </mat-chip-set>
    </div>
</div>

<ng-template #circular>
  <div [class]=" 'df jcfs aic fss' + ' ' + status">
    <mat-icon matChipAvatar>circle</mat-icon> <span *ngIf="showText">{{taglabel}}</span>
  </div>
</ng-template>