import { Component, Inject, ViewChild } from '@angular/core';
import { MASTER_CLIENT, MASTER_PRODUCT } from '../../constants/masters';
import { M_Product } from '../../models/Products/M_Product';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';
import { AddRemoveComponent } from 'src/app/views/products/add-remove/add-remove.component';
import { MovTypeEnum } from '../../enums/MovTypeEnum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_Contact } from '../../models/M_Contact';
import { M_Reservation } from '../../models/M_Reservation';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { SnackService } from 'src/app/services/snack.service';

export type manualReservationData = { product?: M_Product, client?: M_Contact }

@Component({
  selector: 'app-manual-reservation',
  templateUrl: './manual-reservation.component.html',
  styleUrls: ['./manual-reservation.component.css']
})
export class ManualReservationComponent {

  @ViewChild('productSearcher') productSearcherComponent?: ClassSearcherComponent<M_Product>
  @ViewChild('clientSearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  @ViewChild(AddRemoveComponent) addRemoveComponent?: AddRemoveComponent;


  product = MASTER_PRODUCT;
  client = MASTER_CLIENT;
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<ManualReservationComponent>, private snackS : SnackService,
    private formBuilder: UntypedFormBuilder, private apiS: ApiService, @Inject(MAT_DIALOG_DATA) public data?: manualReservationData,) {
    this.form = this.formBuilder.group({
      product_id: ['', Validators.required],
      location_id: ['', Validators.required],
      client_id: ['', [Validators.required]],
      quantity: ['', [Validators.required, this.quantityValidator()]],
      mov_id: [0],
      mov_type: [MovTypeEnum.RM],
      item_id: [0],
    });

    this.form.get('location_id')?.disable();
  }

  get selectedLocation() {
    if (!this.productSearcherComponent?.selected) { return undefined }
    return this.productSearcherComponent?.selected.locations.find(l => l.id == this.form.get('location_id')?.value);
  }

  disabledIf(product: M_Product) {
    return !product.locations.length;
  }
  haveProduct(data:manualReservationData){
    if(data.product){
      return true;
    }
    return false;
  }
  onSelectProduct(product: M_Product) {
    if (product.locations.length) {
      this.form.patchValue({ 'location_id': product.locations[0].id })
    }
    else {
      this.form.patchValue({ 'location_id': null })
    }
    this.form.get('location_id')?.enable();
  }

  onRemoveProduct(product: M_Product) {
    this.form.patchValue({ 'location_id': null })
    this.form.get('location_id')?.disable();
  }

  makeReservation() {
    let reservation = new M_Reservation(this.form.value)
    this.apiS.manualReservation(reservation).then(res => {
      this.snackS.show("Se a reservado el producto");
      this.dialogRef.close(res);
    })
  }

  quantityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let selectedProduct = this.productSearcherComponent?.selected;

      if (!selectedProduct) { return null }

      const valor = control.value;
      if (valor < this.addRemoveComponent?.minValue! || valor > this.addRemoveComponent?.maxValue!) {
        return { 'minMax': true };
      }
      return null;
    };
  }

  get searchersLoaded(): boolean {
    if (!this.data || !this.productSearcherComponent || !this.clientSearcherComponent) { return false }

    let productOk = false;
    let clientOk = false;

    if (!this.data.product) { productOk = true }
    else { productOk = this.productSearcherComponent.loaded }

    if (!this.data.client) { clientOk = true }
    else { clientOk = this.clientSearcherComponent.loaded }

    return productOk && clientOk;
  }

}
