import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ApiService } from 'src/app/services/Api/api.service';
import { endpoints } from '../../constants/Enpoints';
import { M_Contact } from '../../models/M_Contact';
import { ClientformComponent } from '../clientform/clientform.component';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { SearchService } from 'src/app/services/search.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-vehicle-owner-search-create',
  templateUrl: './vehicle-owner-search-create.component.html',
  styleUrls: ['./vehicle-owner-search-create.component.css']
})
export class VehicleOwnerSearchCreateComponent implements OnInit {

  client = MASTER_CLIENT_MINIFIY;
  e = endpoints;

  cSelected: M_Contact | undefined;
  cAll: M_Contact[] | undefined;
  cSearched: M_Contact[] | undefined;
  @ViewChild("ch1") ch1?: MatCheckbox;
  @ViewChild("ch2") ch2?: MatCheckbox;
  @ViewChild(ClientformComponent) clientForm!: ClientformComponent;
  @ViewChild(ClassSearcherComponent) searchForm!: ClassSearcherComponent<M_Contact>;
  @Input() title?: string;
  /**En el caso de estar en la pantalla de linkar un vehiculo con un usuario nuevo */
  @Input() isLinkVehicle?: boolean;
  @Input() currentOwner_id?: number;
  @Input() contentLoaded?: boolean = true;
  @Input() isOnDialog: boolean = false;

  constructor(private searchS: SearchService, private apiS: ApiService, public responsiveS: ResponsiveService) { }

  ngOnInit(): void {
    this.setUpClients();
  }

  setUpClients() {
    this.apiS.clientsForVehicles().then(resp => {
      let aux: M_Contact[] = []
      for (let i = 0; i < resp.length; i++) {
        aux.push(new M_Contact(resp[i]));
      }
      this.cAll = aux;
    })
  }


  searchCheckBox(e: MatCheckboxChange) {
    if (e.checked) {
      this.ch2!.checked = false;
    }
    else {
      if (!this.ch2?.checked) {
        this.ch2!.checked = true;
      }
    }
  }

  createCheckBox(e: MatCheckboxChange) {
    if (e.checked) {
      this.ch1!.checked = false;
    }
    else {
      if (!this.ch1?.checked) {
        this.ch1!.checked = true;
      }
    }
  }

  searchClient(searched: string) {
    let aux: M_Contact[] = []
    for (let i = 0; i < this.cAll!.length; i++) {
      let c = this.cAll![i];
      if (this.searchS.match(searched, c.name)) {
        if (this.isLinkVehicle && this.currentOwner_id != c.client_id) {
          aux.push(c);
        }
        else if (!this.isLinkVehicle) {
          aux.push(c);
        }
      }
    }
    this.cSearched = aux;
  }

  selectClient(c: M_Contact) {
    if (this.cSelected == undefined) {
      this.cSelected = c;
    }
    else if (this.cSelected == c) {
      this.cSelected = undefined;
    }
    else {
      this.cSelected = c;
    }
  }

  disableCurrentOwner(v: M_Contact) {
    return v.client_id == this.currentOwner_id;
  }

  onSelect(val: any) {
    if (val) {
      this.cSelected = new M_Contact(val);
    }
    else {
      this.cSelected = undefined;
    }
  }

  get isValid() {
    return (this.ch1?.checked && this.searchForm.selected) || (this.ch2?.checked && this.clientForm.form.valid)
  }

}

