import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { M_Vehicle } from "../models/M_Vehicle";
import { AlreadyExistsService } from "../services/already-exists.service";
import { ViewPath } from "src/app/app-routing.module";
import { ApiService } from "../services/Api/api.service";

export function chassisAsyncValidator(getCurrentVehicle: () => M_Vehicle | undefined, apiS: ApiService, existsS: AlreadyExistsService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {

        let currentVehicle = getCurrentVehicle();
        let vehicleChassis: string | undefined = currentVehicle?.chassis;

        // console.log("Checking chassis");
        // console.log("Current vehicle", currentVehicle);
        // console.log("Vehicle chasis", vehicleChassis)

        if (!control.value || control.value == vehicleChassis) { return Promise.resolve(null) }

        return apiS.checkChasis(control.value).then(res => {
            if (res instanceof M_Vehicle) {
                existsS.show({
                    title: "¡Atención!",
                    message: "Ya existe un vehículo con el chassis",
                    message2: "Recordamos que no se pueden crear dos vehículos con el mismo chassis",
                    value: res.chassis,
                    view: ViewPath.vehicleDetails,
                    param_id: res.vehicle_id,
                    accept_text: "Ver vehículo"
                })

                return { "chassis-exists": true }
            }
            return null;
        })

    };
}