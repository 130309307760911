import { Component, EventEmitter, Input, Output } from '@angular/core';
import { M_BaseUser } from '../../models/M_BaseUser';
import { IOr } from 'src/app/interfaces/IOr';
import { projectConfiguration } from 'src/app/app.module';

@Component({
  selector: 'lib-or-change-worker',
  templateUrl: './or-change-worker.component.html',
  styleUrls: ['./or-change-worker.component.css']
})
export class OrChangeWorkerComponent {

  @Input() day!: Date;
  @Input() item!: IOr;
  @Input() canAssign!: boolean;
  @Input() allUsers: M_BaseUser[] = [];
  @Output() onAssignUser: EventEmitter<M_BaseUser> = new EventEmitter();

  core = projectConfiguration;
  
  constructor() { }

  userHasHolidays(u: M_BaseUser) {
    var currentUser = this.allUsers.find(user => user.id == u.id);
    if (!currentUser) { return false }
    return currentUser.holidays.some(d => d.isEquals(this.day))
  }

  isClickable(u : M_BaseUser){
    return !this.userHasHolidays(u) && !this.isUserAlreadyAssigned(u);
  }

  onClickUser(u: M_BaseUser) {
    if (this.isClickable(u)) {
      this.onAssignUser.emit(u)
    }
  }

  isUserAlreadyAssigned(user : M_BaseUser){
    return this.item.assigned?.id == user.id;
  }

}
