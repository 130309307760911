import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Reservation } from 'src/app/models/M_Reservation';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-reserve-dialog',
  templateUrl: './reserve-dialog.component.html',
  styleUrls: ['./reserve-dialog.component.css']
})
export class ReserveDialogComponent {
  constructor(public responsiveS: ResponsiveService, @Inject(MAT_DIALOG_DATA) public data: { reserves: M_Product[], isClosing: boolean }, public apiS: ApiService) {
  }

  get displayedColumns() {
    return this.responsiveS.w > 514
      ? ['Ref', 'Cantidad', 'Faltas', 'Reservar']
      : ['Ref', 'Cantidad', 'Faltas', 'Reservar'];
  }

  extractReservationsFromProducts(products: M_Product[]): M_Reservation[] {
    const reservations: M_Reservation[] = [];

    products.forEach(product => {
      if (product.reservations) { // Verifica si el producto tiene una reserva
        product.reservations.forEach(reserve => {
          reservations.push(reserve);
        });
      }
    });

    return reservations;
  }
  
  saveReserve() {
    this.extractReservationsFromProducts(this.data.reserves);
  }
}
