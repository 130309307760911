import { Component, Input } from "@angular/core";
import type { ClassSearcherFilter } from "../../../custom-classes/Filter";

@Component({
    selector: 'app-class-searcher-filter',
    templateUrl: './class-searcher-filter.component.html',
    styleUrls: ['./class-searcher-filter.component.css']
})
export class ClassSearcherFilterComponent {
    @Input() filter!: ClassSearcherFilter;
}
