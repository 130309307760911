import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { feature } from 'src/app/utils/FeaturesController';
import { M_Contact } from 'src/app/models/M_Contact';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { ManualReservationComponent, manualReservationData } from 'src/app/components/manual-reservation/manual-reservation.component';
import { MatDialog } from '@angular/material/dialog';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { ButtonToggleFilter, Filter, FilterOption, SliderFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { RouterService } from 'src/app/services/router.service';

export enum ClientsPageFiltersEnum {
  EXENT_IVA = 1,
  SELLS = 2,
  E_INVOICE = 3,
  CONTACT_TYPE = 4,
  VEHICLE_TYPE = 5,
}

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  me = ModulesEnum;
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  f = filter;
  @Input() newTab: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Contact>;
  v = ViewPath;
  features = feature;
  filters: Filter[] = [
    new TagFilter("Tipo", undefined, new FilterOption("Persona", "person"), new FilterOption("Compañía", "business"), new FilterOption("Proveedor", "local_shipping")).setId(ClientsPageFiltersEnum.CONTACT_TYPE),
    new TagFilter("Vehículo", undefined, new FilterOption("Moto", "two_wheeler "), new FilterOption("Coche", "directions_car_filled")).setId(ClientsPageFiltersEnum.VEHICLE_TYPE),
    new ButtonToggleFilter("Ventas", "Ventas").setId(ClientsPageFiltersEnum.SELLS),
    new ButtonToggleFilter("Exento de IVA", undefined, false).setId(ClientsPageFiltersEnum.EXENT_IVA),
    new ButtonToggleFilter("Factura electrónica", undefined, false).setId(ClientsPageFiltersEnum.E_INVOICE),
    new SliderFilter("Descuento cliente", 100),
  ]

  constructor(public apiS: ApiService, public routerS: RouterService, public params: ParamsService, public subS: SubscriptionService, private d: MatDialog) {
    this.apiS.client.clients().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  ngOnInit(): void {
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == ClientsPageFiltersEnum.CONTACT_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  preIconName(c: M_Contact) {
    return c.icon;
  }

  sellsClass() {
    return "ml5"
  }

  preIconSells(c: M_Contact) {
    return c.isClient ? 'sell' : 'highlight_offsell';
  }

  completeName(u: M_Contact) {
    return u.getName();
  }

  emptySells(u: M_Contact) {
    return u.isClient ? 'Si' : 'No';
  }

}
