<form class="form" style="max-width: unset;" *ngIf="!actionFailedToLoad; else notfound">

  <div class="container">
    <div class="title">
      <app-go-back [text]="'Listado OR'" [v]="v.or" [show]="actionLoaded && allInvoiced"></app-go-back>
    </div>
    <div class="or_abstract">
      <app-card [contentLoaded]="actionLoaded" class="bc_b0 full-height-or">
        <app-card-title class="tas nmb d550">{{action?.title_id}}</app-card-title>
        <div class="df fww aic mb20">
          <app-card-title class="nmb dn550">{{action?.title_id}}</app-card-title>
          <app-or-status-label class="ml5-or-status" [showText]="false" [action]="action"></app-or-status-label>
          <app-or-type-label [wrapOn]="responsiveS.w < 600 ? 1 :  responsiveS.w > 1200 ? 3 :2" class="ml5"
            [showText]="false" [tipo]="action? action.type : undefined"></app-or-type-label>

          <!-- DOCUMENTATION !-->
          <app-documentation-pdf-menu class="mla" [action]="action"></app-documentation-pdf-menu>

        </div>

        <!-- OR FORM -->
        <app-or-form #orForm [isEdit]="true" [loaded]="actionLoaded"></app-or-form>

        <div class="cv-details">
          <div class="df jcc">
            <app-client-vehicle-in-dialog [t]="'Información cliente'" [c]="action?.client"
              [v]="action?.vehicle"></app-client-vehicle-in-dialog>
          </div>
        </div>

        <!-- NOTES AND IMAGES -->
        <app-or-notes-and-images [orFormComponent]="orForm"></app-or-notes-and-images>
      </app-card>
    </div>

    <div class="or_client">
      <app-card [contentLoaded]="actionLoaded">
        <div class="ribbon" *ngIf="action?.client?.discount">
          <span>{{action?.client?.discount}}% DTO</span>
        </div>
        <app-client-info [small]="true" [client]="action?.client" [somethingSelected]="true"></app-client-info>
      </app-card>
    </div>

    <div class="or_vehicle">
      <app-card [contentLoaded]="actionLoaded">
        <app-vehicle-info [vehicle]="action?.vehicle"></app-vehicle-info>
      </app-card>
    </div>
  </div>
</form>

<!------------------------------------------------------------------------------------------------------------>
<!------------------------------------------------------------------------------------------------------------>

<div class="or_actions floating-action-bar" *ngIf="action && !action?.isDone()">
  <app-card [contentLoaded]="actionLoaded" [cornerMatMenu]="deleteButton" class="normal-padding-top">
    <div class="floating-action-buttons" style="position: sticky;top: 0px;">
      <button #saveButton appTrialbutton mat-flat-button [disabled]="!drag.hasChanges() && !unsavedChanges"
        (click)="saveScreen(drag)" color="warn">
        Guardar
      </button>
      <div [matTooltip]="!action.hasClosedGroups()? 'Debes cerrar alguna intervención para poder facturarla' : ''">
        <button appTrialbutton mat-flat-button [roles]="[R.ADMIN, R.ADVISER]" [disabled]="!action.hasClosedGroups()"
          (click)="openInvoiceDialog()" class="mr25on368">
          Facturar
        </button>
      </div>
    </div>
  </app-card>
</div>

<!-- OR invoiced-->
<div class="or_actions" *ngIf="action && action?.isDone()">
  <app-card class="normal-padding-top bc_r0">
    <ng-template [ngTemplateOutlet]="transfer"></ng-template>
  </app-card>
</div>

<ng-template #deleteButton>
  <button *ngIf="subS.hasProPermissions" mat-icon-button [roles]="[R.ADMIN, R.ADVISER]"
    [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
  <mat-menu #deleteMenu="matMenu">
    <ng-template [ngTemplateOutlet]="transfer"></ng-template>
    <button mat-menu-item [disabled]="action == undefined || action.someProductRequested" (click)="deleteAction()">
      <mat-icon>delete</mat-icon> Eliminar
    </button>
  </mat-menu>
</ng-template>


<ng-template #transfer>
  <div [ngClass]="{'trasnferAllInvoiced' : action?.isDone()}"
    *ngIf="userS.userIsAdminOrAdviser; else alreadyInvoicedMechanic">

    <!-- Future development -->
    <!--<button mat-menu-item matTooltipPosition="left" matTooltip="¿De dónde procede la OR?">
      <mat-icon>timeline</mat-icon>
      Historial
    </button>-->

    <button mat-menu-item matTooltipPosition="left" matTooltip="Se creará una OR a partir de esta"
      *ngIf="!this.dragComponent?.action?.isVnVoOr" (click)="trasnferTo(drag, 'or')">
      <mat-icon>build</mat-icon>
      Nueva OR
    </button>
    <div [matTooltipPosition]="'left'" [matTooltip]="toBudgetButton.disabled ? 'La OR tiene grupos internos' : ''"
      *ngIf="!this.dragComponent?.action?.isVnVoOr">
      <button #toBudgetButton mat-menu-item (click)="trasnferTo(drag, 'budget')" [disabled]="hasSomeInterno()">
        <mat-icon>calculate</mat-icon>
        Nuevo presupuesto
      </button>
    </div>
  </div>
  <ng-template #alreadyInvoicedMechanic>
    <p class="tac nmb">Parece que esta OR ya se ha facturado... ¡A por otra!</p>
  </ng-template>
</ng-template>

<div class="or" [ngClass]="{'dn' : actionFailedToLoad}">
  <app-card class="bc_b0" [contentLoaded]="actionLoaded" [locked]="subS.isBasic"
    [ngClass]="{'locked_card' : subS.isBasic}" [lockedText]="'Se requieere el plan PRO'">

    <div class="interventions-add-time-title">
      <app-card-title [noMarginBottom]="true">Intervenciones</app-card-title>
      <div class="or_time_actions">
        <div class="df jcc fww aic" style="gap: 10px;">

          <!--Work button -->
          <button *ngIf="action?.canAddTime()" color="primary" (click)="startStopTime()" mat-flat-button
            class="work-or custom-button">
            <mat-icon>{{isOperatorWorking() ? 'stop_circle' : 'play_circle'}}</mat-icon>
            {{isOperatorWorking() ? isOperatorWorking()!.elapsed : 'Trabajar'}}
          </button>

          <!--Add manual time button -->
          <div *ngIf="action?.canAddTime()" (click)="addTime()">
            <button *ngIf="responsiveS.w >= 733" mat-button>
              <mat-icon>add_circle</mat-icon>
              Manual
            </button>
            <button *ngIf="responsiveS.w < 733" mat-icon-button [matTooltip]="'Añadir fichaje manualmente'"
              [matTooltipPosition]="'above'">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>

          <!-- Time history -->
          <div (click)="timeHistory()">
            <button *ngIf="responsiveS.w >= 733" mat-button>
              <mat-icon>history</mat-icon>
              <span>Historial</span>
            </button>
            <button *ngIf="responsiveS.w < 733" mat-icon-button [matTooltip]="'Ver historial de fichajes'"
              [matTooltipPosition]="'above'">
              <mat-icon>history</mat-icon>
            </button>
          </div>

        </div>
      </div>
    </div>

    <app-drag #drag [action]="action" [isBudgetPage]="false" [locked]="subS.isBasic"
      (onRemoveChildGroupTask)="checkOthersInvoiced($event)" (onInvoiceChildTask)="openDialog($event)">
    </app-drag>
  </app-card>
</div>

<ng-template #notfound>
  <app-model-not-found [title]="'No se ha encontrado la orden de reparación'"
    [goText]="'Ir al listado de órdenes de reparación'" [view]="v.or">
  </app-model-not-found>
</ng-template>