import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint } from '../custom-classes/Enpoint';

@Injectable({
  providedIn: 'root'
})
export class WorkloadDataGetterService {

  constructor(private http: HttpClient) { }

  updateSchedule(e: Endpoint, action_id: number, date: Date) {
    return new Promise<any>(resolve => {
      this.http.post<any>(e.url,
        { action_id: action_id, date: date.dataBaseFormat() }).subscribe(data => {
          resolve(data);
        });
    })
  }

  updateAppointment(e: Endpoint, id: number, date: Date) {
    return new Promise<any>(resolve => {
      this.http.post<any>(e.url, { id: id, date: date.dataBaseFormat() }).subscribe(data => {
          resolve(data);
        });
    })
  }

  reorderActions(e: Endpoint, action_ids: number[], showLoading: boolean = true) {
    e.showLoading = showLoading;
    return new Promise<any>(resolve => {
      this.http.post<any>(e.url, { action_ids: action_ids }).subscribe(data => {
        resolve(data);
      },
        error => {
          resolve(-1);
        });
    })
  }

  assignAction(e: Endpoint, action_id: number, user_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(e.url, { action_id: action_id, user_id: user_id }).subscribe(data => {
          resolve(data);
        });
    })
  }

}
