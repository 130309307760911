import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-drag-tutorial',
  templateUrl: './drag-tutorial.component.html',
  styleUrls: ['../../../components/welcome/welcome.component.css']
})
export class DragTutorialComponent implements OnInit {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;

  @ViewChild(MatStepper) stepper?: MatStepper;
  constructor() { }

  ngOnInit(): void { }
  
  go(i: number) {
    if (this.stepper) {
      this.stepper.selectedIndex = i;
    }
  }

}
