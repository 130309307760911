export enum DateFormats {
    DATABSE_FORMATT = "yyyy-MM-dd HH:mm:ss",
    SHORT_FORMAT = "dd/MM/yyyy - HH:mm",
    NO_HOUR_FORMATT = "yyyy/MM/dd",
    INPUT_FORMATT = "yyyy-MM-ddTHH:mm", //Input dateTime
    DATEPCIKER_FORMAT = "yyyy-MM-dd", //Datepicker
    HOUR_FORMAT = "HH:mm",
    HOUR_MINUTE_SECOND = "HH:mm:ss",
    DAY_MONTH_YEAR = "dd/MM/yyyy",
    SHORT_WITH_SECONDS = "dd/MM/yyyy - HH:mm:ss",
}
