import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-missing-company-info',
  templateUrl: './missing-company-info.component.html',
  styleUrls: ['./missing-company-info.component.css']
})
export class MissingCompanyInfoComponent {

  warn = getWarnColor;
  primary = getPrimaryColor;

  @Input({ required: true }) model!: string;
  @Input({ required: true }) pageLoaded: boolean = false;

  constructor(private companyS: CompanyService, private routerS: RouterService) { }

  goCompany() { this.routerS.goTo(ViewPath.settings) }

  get showComponent() {
    return this.companyS.companyMissingInfo && this.pageLoaded
  }

}
