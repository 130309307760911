import { nameByTemplateType } from "src/app/utils/templateType";
import { templateTypedoc } from "./M_TemplateField";

export class M_PDFTemplate {

    type: templateTypedoc;
    token: string | undefined;

    constructor(d: any) {
        this.type = d.type;
        this.token = d.token;
    }
    
    get docName() {
        return nameByTemplateType(this.type, false)
    }
}