import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { EinaDataService } from "../services/EinaMainData/eina-data.service";
import { RolesEnum } from "../enums/RolesEnum";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(private einaDataS: EinaDataService) { }
  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      /** Get Eina main user data */
      this.einaDataS.getEinaData().then(res => {
        if (!res) { resolve(false); }
        else {
          let roles: RolesEnum[] | undefined;
          if (next.data && next.data['roles']) {
            roles = next.data['roles'];
          }
          if (roles == undefined || !roles.length) { resolve(true); }
          else {
            resolve(roles.includes(res.user.role));
          }
        }
      })
    }
    )
  }
}