<div *ngIf="group != undefined && group.products != undefined" class="drag-box">
    <div class="group">
        <div class="header">
            <!-- NO EDIT -->
            <div *ngIf="!isEdit && group.title != ''" class="df aic">
                <div class="df fdc">
                    <app-card-title [noMarginBottom]="true" (click)="toggleEdit(group.title)" class="fw500 c_w"
                        [ngClass]="{'m10': !group.state.open, 'nmb' : group.state.open}">
                        <p style="word-break: break-word;" class="title-p">
                            <mat-icon *ngIf="canModifyGroup()" class="vam ml5">edit_note</mat-icon>
                            {{group.title}}
                        </p>
                    </app-card-title>

                    <p class="total-and-items phone" style="color:var(--t)">
                        <app-action-totals [responsive]="true" [groups]="group"
                            [invoiceClient]="dg.action?.defaultInvoiceClient"></app-action-totals>
                        <span class="fsss ml5 mr5 c_t2"> • {{group.products.length}} {{group.products.length > 1 ||
                            group.products.length == 0?
                            'items' : 'item'}}</span>
                    </p>

                </div>
            </div>
            <button mat-button *ngIf="group.token" color="white" style="min-width: 125px;" (click)="billPreview()">
                <span>{{!group.type.cargo ? 'Ver factura' : 'Ver trámite'}}</span>
                <span class="pdf-label"></span>
            </button>
            <!-- EDITAR -->
            <div [ngClass]="isEdit || group.title == '' ? 'df aic' : 'dn'">
                <form [formGroup]="groupName" (keydown.enter)="editGroupName(nameInput.value)">
                    <mat-form-field appearance="outline" class="small-form-field no-wrapper">
                        <input cdkFocusInitial #nameInput="matInput" matInput placeholder="Nombre de la intervención"
                            [value]="group.title" formControlName="name" (focusout)="editGroupName(nameInput.value)">
                    </mat-form-field>
                </form>
                <mat-icon class="cp c_p" style="margin-left: 5px;"
                    (click)="editGroupName(nameInput.value)">done</mat-icon>
            </div>


            <ng-template #statusMenuOptions>

                <button mat-menu-item (click)="openGroup()" *ngIf="isOr() && group.state.num == 1" class="chip-option">
                    <app-or-status-label [pointer]="true" [status]="[s_o, 'Abrir']"></app-or-status-label>
                </button>

                <button mat-menu-item (click)="closeGroup()" *ngIf="isOr() && group.state.num == 0"
                    [disabled]="!hasFacturableTaskas()" class="chip-option">
                    <app-or-status-label [pointer]="true" [status]="[s_c, 'Cerrar']"></app-or-status-label>
                </button>

                <ng-container *ngIf="isOr()">
                    <div *ngIf="canInvoiceS.data([group], this) as invocieData" [roles]="[R.ADMIN, R.ADVISER]"
                        [matTooltip]="!isGroupClosed() ? 'Debes cerrar la intervención antes de poder facturarla' : invocieData.tooltip"
                        [matTooltipPosition]="'right'">
                        <button mat-menu-item *ngIf="hasFacturableTaskas()" (click)="invoiceGroupTask()"
                            style="overflow: visible;" [disabled]="!isGroupClosed() || invocieData.disable"
                            class="chip-option">
                            <app-or-status-label [matBadge]="invocieData.badge" [matBadgeColor]="invocieData.badgeColor"
                                [pointer]="true"
                                [status]="[s_i, !group.type.cargo ? 'Facturar' : 'Tramitar']"></app-or-status-label>
                        </button>
                    </div>
                </ng-container>

            </ng-template>
            <mat-menu #actions="matMenu">
                <button mat-menu-item [mat-menu-trigger-for]="status" *ngIf="isOr()">
                    <span>Estado</span>
                </button>
                <button mat-menu-item [mat-menu-trigger-for]="groupType" *ngIf="isVnVoOr">
                    <span>Tipo</span>
                </button>
                <button mat-menu-item [disabled]="canDeleteGroup" (click)="onRemoveGroup.emit(this.group);">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                </button>
            </mat-menu>

            <!-- OR-TYPE -->
            <mat-menu #groupType="matMenu">
                <app-or-type-selector [showInterno]="!dg.isBudgetPage"
                    (onClickOption)="onTypeChange($event)"></app-or-type-selector>
            </mat-menu>

            <mat-menu #status="matMenu">
                <div [ngTemplateOutlet]="statusMenuOptions"></div>
            </mat-menu>
            <button mat-icon-button [mat-menu-trigger-for]="actions" *ngIf="canModifyGroup()">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
    <div class="tasks">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="status-type">

                            <!-- Show the matMenu if the action is OR and is not 'invoiced'-->
                            <app-or-status-label [showIcon]="responsiveS.w > 434" [menuIconOpen]="true"
                                *ngIf="!group.isInvoiced() && isOr()" [mat-menu-trigger-for]="status"
                                (click)="preventDropDownOpening($event)" [status]="getStatus()">
                            </app-or-status-label>
                            <!-- Don't show the matMenu if the action is a budget or the action is a OR && is already invoiced-->
                            <app-or-status-label [showIcon]="responsiveS.w > 434" *ngIf="group.isInvoiced() && isOr()"
                                (click)="preventDropDownOpening($event)" [status]="getStatus()">
                            </app-or-status-label>


                            <!-- Show the matMenu if the action is OR and the gorup is not invoiced-->
                            <app-or-type-label [mat-menu-trigger-for]="canModifyGroup() && !isVnVoOr ? groupType : null"
                                (click)="preventDropDownOpening($event)" [menuIconOpen]="canModifyGroup() && !isVnVoOr"
                                [showText]="false" [tipo]="[group.type]">
                            </app-or-type-label>

                        </div>
                    </mat-panel-title>
                    <mat-panel-description class="d465">
                        <div class="df fdc mla">
                            <p class="total-and-items pc">
                                <app-action-totals [responsive]="true" [groups]="group"
                                    [invoiceClient]="dg.action?.defaultInvoiceClient"></app-action-totals>
                                <span class="fss c_t2 ml5 mr5"> • {{group.products.length}} {{group.products.length > 1
                                    ||
                                    group.products.length == 0?
                                    'items' : 'item'}}</span>
                            </p>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <app-product-line-table [canModifyTable]="canModifyGroup()" [showReserves]="isBudgetPage"
                        [products]="group.getAllProducts()" [lineTypes]="['product', 'custom', 'time', 'comment']"
                        [showDrag]="true" [showPVP]="!group.type.cargo" [showCost]="group.type.cargo"
                        [extendedInfoConfig]="
                            {
                                showColumn: true, 
                                actions: isBudgetPage ? ['manual-fault'] : ['manual-fault','remove-reservation']
                            }">
                    </app-product-line-table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>