<!-- WEEK OR -->
<div (click)="onGoOR.emit()" class="cp week-or-card">
  <div class="header or">
    <div class="header-contents">
      <mat-icon class="mr5">build</mat-icon>
      <p class="week-text workload-overflow-text fsm mr10 fw500">{{item.title_to_show}}</p>
      <!--<p class="nmb ds fw500" style="margin-left: auto; margin-right: auto;">{{item.vehicleLicense()}}</p>!-->
      <button prevent mat-icon-button [matMenuTriggerFor]="actions" class="mla more-vert">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
  <div class="or-week-contents">
    <div class="body">

      <p class="fsm fw500 c_p ttu overflow-text">{{item.vehicleName()}}</p>
      <p class="fsm fw500 ttu overflow-text">{{item.clientName()}}</p>
      <p *ngIf="item.delivery && item.schedule && item.delivery.isEquals(item.schedule) && item.status.open" class="c_o fw500">
        <mat-icon class="vam c_o">running_with_errors</mat-icon> ¡Se entrega hoy!
      </p>
      <p *ngIf="item.delivery && item.schedule && !item.delivery.isEquals(item.schedule) && item.delivery < item.schedule && item.status.open" class="c_r fw500">
        <mat-icon class="vam c_r">error</mat-icon> Fuera de plazo
      </p>
    </div>
    <div class="bottom-section">
      <div class="separator"></div>
      <div class="bottom-section-content">
        <div class="df aic">
          <app-or-status-label  [circularStatus]="true" style="transform: scale(0.8); margin-left: -5px;"
            [status]="item.status">
          </app-or-status-label>
          <span class="c_t2 mr5"> | </span> 
          <div class="df aic">
            <app-or-type-label [wrapOn]="1" [small]="true" [tipo]="item.type" [showText]="false">
            </app-or-type-label>
            <p prevent [matMenuTriggerFor]="groupMenu" [matMenuTriggerData]="{groups : item.groups}"
              class="cp fss nmb blue_text_action" style="margin-left: auto;; margin-right: 12px;">
              ({{item.groups.length}})</p>
          </div>
        </div>
        <!--(pointerdown)="preventCardClick($event)"  (touchend)="preventCardClick($event)"-->
        <div class="df aic" prevent [matMenuTriggerFor]="selsectuser" style="margin-top: auto; margin-left: auto; ">
          <app-circular-letter class="mr5" [small]="true" [showFullName]="false" *ngIf="item.assigned != undefined"
            [grayOut]="!onFilters" [user]="item.assigned" [showTooltip]="false">
          </app-circular-letter>
          <mat-icon class="mr5" *ngIf="item.assigned == undefined">account_circle</mat-icon>
          <!--<p class="text-key workload-overflow-text">{{item.assigned? item.assigned.name : "Sin operario"}}</p>!-->
          <!--<p *ngIf="canAssign" class="text-value workload-overflow-text blue_text_action">
            {{item.assigned ? "Cambiar" : invoiced ? "" : "Asignar"}} !</p>-->
        </div>
      </div>
    </div>
  </div>
</div>




<!-- OR group menu -->
<mat-menu #groupMenu="matMenu" class="groups-menu">
  <ng-template matMenuContent let-groups="groups">
    <div style="padding : 5px 10px 5px 10px;">
      <app-group-status-type-text [preferWidth]="false" [groups]="groups"></app-group-status-type-text>
    </div>
  </ng-template>
</mat-menu>



<mat-menu #actions="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="selsectuser">Cambiar operario</button>
  <button mat-menu-item (click)="onGoOR.emit()">Ver OR</button>
  <div class="contact-section df jcsa aic" *ngIf="canContact">
    <app-whatsapp [phone]="item.clientPhone()"></app-whatsapp>
    <app-phone-button [phone]="item.clientPhone()"></app-phone-button>
    <app-email-button [email]="item.clientEmail()"></app-email-button>
  </div>
</mat-menu>


<mat-menu #selsectuser>
  <lib-or-change-worker  [day]="day" [item]="item" [canAssign]="canAssign" [allUsers]="allUsers"
  (onAssignUser)="onAssignUser.emit($event)"></lib-or-change-worker>
</mat-menu>