
declare global {
    interface Number {
        castDecimals(decimals: number): number;
    }
}

/** Cast a number to specific decimals */
Number.prototype.castDecimals = function (decimals: number) : number {
    return Number(this.toFixed(decimals));
}

export { }