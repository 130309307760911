import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { initialHolidayData } from 'src/app/components/holidays-calendar/holidays-calendar.component';
import { calendarEnabled } from 'src/app/constants/constants';
import { M_User } from 'src/app/models/M_User';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { DeleteService } from 'src/app/services/delete.service';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { ProfilePictureService } from 'src/app/services/profile-picture.service';
import { M_BaseUser } from 'src/app/models/M_BaseUser';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent extends ParameterStateComponent implements OnInit {
  loaded: boolean = false;
  isProfile: boolean = false;
  user: M_User | undefined;
  enabledCalender = calendarEnabled;
  v = ViewPath;
  accent = getAccentColor;
  warn = getWarnColor;
  primary = getPrimaryColor;
  year: number = new Date().getFullYear();
  months = [
    { number: 0, name: 'Enero' },
    { number: 1, name: 'Febrero' },
    { number: 2, name: 'Marzo' },
    { number: 3, name: 'Abril' },
    { number: 4, name: 'Mayo' },
    { number: 5, name: 'Junio' },
    { number: 6, name: 'Julio' },
    { number: 7, name: 'Agosto' },
    { number: 8, name: 'Septiembre' },
    { number: 9, name: 'Octubre' },
    { number: 10, name: 'Noviembre' },
    { number: 11, name: 'Diciembre' },
  ];
  startDate: Date;
  selectedDates: Date[] = [];

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    public params: ParamsService, private chdRef: ChangeDetectorRef,
    public subS: SubscriptionService, public einaDataS: EinaDataService, private d: MatDialog,
    public deleteS: DeleteService, private profilePicrureS: ProfilePictureService) {
    super(routerS, route, ["user"])
    this.startDate = new Date(this.year, 0, 1); // Iniciar en enero del año actual
    let routerData = route.snapshot.data['profile'];
    this.isProfile = routerData != undefined && routerData == true;
  }

  ngOnInit() {
    if (this.isProfile) {
      this.loadUser(this.einaDataS.user.id)
    }
  }

  goEditUser() {
    if (this.isProfile) {
      this.params.go(ViewPath.editProfile, this.user!.id);
    }
    else {
      this.params.go(ViewPath.createEditUser, this.user!.id);
    }
  }


  override onParam(_k: string, v: any) {
    this.loadUser(v);
  }

  loadUser(id: number) {
    this.apiS.getUserById(id).then(res => {
      if (res == undefined) {
        this.user = undefined;
      }
      if (res instanceof M_User) {
        this.user = res;
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })
  }

  uploadImage(data: [cf: CustomFile, u: M_BaseUser]) {
    if (this.userIsMe) {
      this.apiS.uploadImage(data[0], "profile").then(_res => { })
      this.profilePicrureS.onChangeProfilePicture(data);
    }
  }

  async resendConfirm() {
    this.user!.confirm_sent = true;
    return this.apiS.reSendConfirmation(this.user!.id);
  }

  range(limit: number): number[] {
    return Array.from({ length: limit }, (_, index) => index);
  }

  onSaveUserHolidays(onSaveData: initialHolidayData, user: M_User) {
    user.holidays = onSaveData.userH;
    if (user.company) {
      user.company.holidays = onSaveData.companyH;
    }
  }

  onDateSelected(selectedDate: Date): void {
    this.selectedDates.push(selectedDate);
  }

  changePasswordDialog() {
    this.d.open(ChangePasswordComponent, { data: this.user, autoFocus: false });
  }

  get showResend() { return this.user && !this.user.confirm_sent && !this.user.admin; }
  get userIsMe() { return this.user != undefined && this.user.id == this.einaDataS.user.id }

}
