import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { nifValidator, softNifValidator } from '../../validators/nif-validator';
import { BaseInput } from './base-input';


/**
 * [Component]
 * Input para introducir un DNI.
 * Si se introduce un valor que no es un DNI, se muestra un error automáticamente.
 * Se debe usar conjuntamente con {@link  nifValidator()}
 */

@Component({
  selector: 'app-dni-input',
  styleUrls: [],
  template: `<div class="{{class_}}" [formGroup]="form">
              <mat-form-field appearance="outline">
                  <mat-label>{{label ? label : 'DNI'}}</mat-label>
                  <input matInput formControlName="{{formCName}}" 
                        [required]="required" 
                        [disabled]="disabled" 
                        [attr.autocomplete]="disableAutocomplete ? 'off' : null"
                        [placeholder]="placeHolder? placeHolder : 'Introduce el DNI'"
                        (focusout)="onFocusOut.emit()">
                  <mat-error *ngIf="isDniBadFormatted()!">{{error ? error : 'Introduce un DNI válido'}}</mat-error>
              </mat-form-field>
            </div>`

})
export class DniInputComponent extends BaseInput implements OnInit {
  @HostBinding('class') classes = 'dniInput ' + this.classSelector;
  /** If the input allow more o less combinations */
  @Input() strict: boolean = true;
  @Input() error: string |undefined;

  constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  override ngOnInit(): void {
    if (this.required){
      this.control?.addValidators(Validators.required);
    }

    if(this.strict){
      this.control?.addValidators(nifValidator());
    }
    else{
      this.control?.addValidators(softNifValidator())
    }
  }

  /** Mira si en el formulario hay el error de DNI */
  isDniBadFormatted() {
    return (this.control?.errors != undefined && this.control?.errors['dni'] != undefined && this.touched);
  }
}
