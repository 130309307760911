import { environment } from "src/environments/environment";

/** Returns if user is on phone device */
export function isMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}

/** Generates the image URL if the backend don't pass the full path */
export function generateImageURL(parameter: string | undefined): string | undefined {
    
    let env = environment;
    
    if (typeof parameter != "string") {
        return undefined;
    }
    else {
        
        if (parameter.length == 0) { return undefined }

        var missingStart = !parameter.includes("http") && !parameter.includes("https");
        var missingEnd = cleanValue(env.apiUrl) == cleanValue(parameter);
        
        /** If nothing is missing, the url is ok, return the parameter*/
        if (!missingStart && !missingEnd) { return parameter; }
        
        /** If  the end of url is missing, return undefined*/
        if (missingEnd) { return undefined; }
        
        /** If the start of url is missing, add the start of the url and return the path */
        if (missingStart) { return env.apiUrl + (parameter[0] != '/' ? "/" : "") + parameter; }
        
        return undefined;
    }
}


function cleanValue(v: string) {
    var value = replaceAll(v, ":8000", "")
    value = replaceAll(value, ":8080", "")
    value = replaceAll(value, ":4200", "")
    value = replaceAll(value, "/", "")
    return value;
}

function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}