import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[prod-move-number]'
})
export class ProdMoveNumberDirective {

  @Input() val: number | undefined = undefined;
  constructor(private el: ElementRef<HTMLElement>) { }

  ngOnChanges() {
    this.changeNativeElement();
  }

  ngAfterContentInit() {
    this.changeNativeElement();
  }

  changeNativeElement() {
    var finalVal: string | number;
    if (this.val == undefined) {
      this.el.nativeElement.classList.add("c_t2");
      finalVal = "Sin cambios"
    }
    else if (this.val < 0) {
      this.el.nativeElement.classList.add("c_r");
      finalVal = Math.abs(this.val) + " (falta)";
    }
    else {
      finalVal = this.val;
    }
    this.el.nativeElement.innerHTML = finalVal.toString();
  }

}
