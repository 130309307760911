<app-close-dialog-button></app-close-dialog-button>
<div class="coupon-container" style="margin-top: 3rem; padding: 20px !important;" *ngIf="data.client && data.client.discount !=0">
    <div class="coupon-card" matTooltip="Descuento general">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <p class="text-coupon"> {{data.client.discount}}%</p>
        <p class="mt10 c_w dto">DTO</p>
    </div>
</div>
<div class="df jcc aic fww">
    <app-client-info [client]="data.client" [somethingSelected]="true"></app-client-info>
    <app-vehicle-info *ngIf="data.vehicle" [vehicle]="data.vehicle" [small]="false"></app-vehicle-info>
</div>