import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * [Component]
  * @param uploadtype : "single" | "multiple" | "drag" = "single";
  * @param FileType : FileType core enum
  * @param multiple Si la subida es un solo archivo o más de uno
  * @param reorder Si se quiere reordenar las imágenes
  * @param fav Si se quiere poder establecer una imagen favorita
  * @param maxSize Tamaño máximo de las imágenes que se suben
  * @param maxImages Número máximo de imagenes que se pueden subir
  * @param crop Si se quiere recortar la imágen antes de subirla
 */
@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements IUploadComponent {

  @ViewChild('input') input!: ElementRef<any>;
  @Input() uploadtype: "single" | "multiple" | "drag" = "single";
  @Input() acceptedTypes: FileType[] = [FileType.image];
  @Input() multiple: boolean = false;
  @Input() reorder: boolean = false;
  @Input() fav: boolean = false;
  @Input() crop: boolean = false;
  @Input() maintainAspectRatio = true;
  @Input() dni = false;
  @Input() canDelete: boolean = true;
  @Input() maxSize: number | undefined = undefined;
  @Input() maxImagesLength: number | undefined;
  @Input() showPreviewOnClick: boolean = true;
  @Input() showMax: boolean = true;
  @Input() dragPlaceHolder: string = isMobile() ? "Aquí puede subir sus imágenes" : "Arrastra aquí tus imágenes para subirlas"
  /** If the user can add images */
  @Input() canAddImages: boolean = true;
  @Output() stateChanged = new EventEmitter<CustomFile>();
  @Output() onFav = new EventEmitter<CustomFile>();
  @Output() onRemoveImage = new EventEmitter<CustomFile>();

  /** Form attributes */
  @Input() form?: UntypedFormGroup
  @Input() required: boolean = false;
  @Input() formCName?: string;

  fg: UntypedFormGroup;
  ft = FileType;


  principal: number = -1;
  selected: number = -1;
  uploadedFiles: CustomFile | CustomFile[] | undefined;
  idManager?: number | undefined;
  maxImgSize?: number | undefined;
  canReorder: boolean = false;
  a = Array;
  acceptedExtensions = "";


  /** Phone upload exlusive */
  @Input() phoneMultipleUpload?: boolean = false;
  @Output() onPhoneUpload = new EventEmitter<CustomFile>();
  phoneMultiplUploadFeature = false;

  constructor(public uploadS: UploadService, private dialog: MatDialog, private chedRef: ChangeDetectorRef,
    fb: UntypedFormBuilder,
    private previewS: ImagePreviewService,
    private phonemultipleUpload: MultiplePhoneImageService) {
    this.fg = fb.group({ none_field: '' })
  }

  ngOnInit(): void {
    if (this.isMultiple || this.isDrag) {
      this.multiple = true;
    }
    if (this.multiple) {
      this.uploadedFiles = []
    }

    this.acceptedTypes.forEach((t: FileType, i: number) => {
      if (t == FileType.image) {
        this.acceptedExtensions += "image/*"
      }
      if (t == FileType.video) {
        this.acceptedExtensions += "video/*"
      }
      if (t == FileType.excel) {
        this.acceptedExtensions += "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
      }
      if (t == FileType.txt) {
        this.acceptedExtensions += ".txt"
      }
      if (i != this.acceptedTypes.length - 1) {
        this.acceptedExtensions += ", "
      }
    })
  }

  ngAfterViewInit() {
    this.canReorder = this.reorder;
    this.maxImgSize = this.maxSize;
    if (this.multiple && this.idManager == undefined) { /** Lo inicializamos si es multiple */
      this.idManager = 0;
    }
    this.chedRef.detectChanges();
  }

  triggerFileUploaded(cf: CustomFile) {
    this.stateChanged.emit(cf);
  }

  triggerFileUploadedPhone(cf: CustomFile) {
    this.onPhoneUpload.emit(cf);
  }

  triggerRemoveImage(cf: CustomFile) {
    if (this.fav && this.principal == cf.id) {
      this.principal = -1;
    }
    this.onRemoveImage.emit(cf);
  }

  triggerOnFav(cf: CustomFile) {
    this.onFav.emit(cf);
  }

  getClassRequiredForm() {
    if (this.form && this.formCName && this.required) {
      if (!this.hasFiles()) {
        return "invalid-on-submit"
      }
    }
    return ""
  }

  getText(): string {
    if (this.acceptedTypes.includes(FileType.image)) {
      return this.multiple ? "Añadir imágenes" : "Añadir nueva imágen"
    }
    else if (this.acceptedTypes.includes(FileType.image) && this.acceptedTypes.includes(FileType.video)) {
      return this.multiple ? "Añadir vídeos" : "Añadir nuevo vídeo"
    }
    else if (this.acceptedTypes.includes(FileType.excel) && this.acceptedTypes.includes(FileType.txt)) {
      return this.multiple ? "Añadir documentos excel o .txt" : "Añadir excel o .txt"
    }
    else if (this.acceptedTypes.includes(FileType.excel)) {
      return this.multiple ? "Añadir documentos excel" : "Añadir excel"
    }
    else {
      return this.multiple ? "Añadir documentos" : "Añadir nuevos documentos"
    }
  }

  isOnMaximum() {
    return Array.isArray(this.uploadedFiles) ? this.uploadedFiles.length == this.maxImagesLength : this.uploadedFiles != undefined;
  }

  getMax() {
    if (this.maxImgSize != undefined) {
      return "Máx. " + this.maxImgSize + "MB por archivo"
    }
    return ""
  }

  getMaxLength() {
    if (this.maxImagesLength != undefined) {
      if (Array.isArray(this.uploadedFiles)) {
        return this.uploadedFiles.length + "/" + this.maxImagesLength;
      }
    }
    return ""
  }

  setPrincipal(customFileId: number) {
    this.principal = customFileId;
  }

  setSelected(customFileId: number) {
    this.selected = customFileId;
  }

  get isSimple() {
    return this.uploadtype == "single";
  }

  get isMultiple() {
    return this.uploadtype == "multiple";
  }

  get isDrag() {
    return this.uploadtype == "drag";
  }

  hasFiles() {
    let hasLength = true;
    if (this.a.isArray(this.uploadedFiles)) {
      hasLength = this.uploadedFiles.length > 0;
    }
    return this.uploadedFiles != undefined && hasLength;
  }

  /**
   * @SINGLE 
   * Set the database image, only used on not multiple file upload compoenent */
  setImageFromDatabase(cf: CustomFile | string) {
    if (cf instanceof CustomFile) {
      this.uploadedFiles = cf;
    }
    else {
      this.uploadedFiles = new CustomFile(cf, undefined);
    }
  }

  /** 
   * @MULTIPLE
   * Set the database images, only used on multiple file upload compoenent */
  setImagesFromDatabase(cf: CustomFile[] | string[]) {
    if (this.uploadedFiles != undefined && Array.isArray(this.uploadedFiles)) {

      /** 
       * Why starting index ?
       * If there is already images, we need to count from the legth of the array.
       * This way, we avoid duplicated files ID
       */
      let startingIndex = this.uploadedFiles.length;

      for (let i = 0; i < cf.length; i++) {
        let f = cf[i];
        if (f instanceof CustomFile) {
          f.id = i + startingIndex;
          this.uploadedFiles.push(f);
        }
        else {
          this.uploadedFiles.push(new CustomFile(f, undefined, i + startingIndex));
        }
      }

      this.idManager = this.uploadedFiles.length;
    }
    else {
      throw Error("Cannot set image array on a single file component")
    }
  }

  /** 
   * @SINGLE
   * Get the uploaded file */
  getUploadedFile() {
    if (!this.a.isArray(this.uploadedFiles)) {
      return this.uploadedFiles?.file;
    }
    return undefined;
  }

  /** 
   * @MULTIPLE
   * Get array of files on multiple uplpoad component */
  getUploadedFiles() {
    if (this.a.isArray(this.uploadedFiles)) {
      let files: File[] = [];
      if (this.uploadedFiles) {
        for (let i = 0; i < this.uploadedFiles?.length; i++) {
          files.push(this.uploadedFiles[i].file!)
        }
      }
      return files;
    }
    return undefined;
  }

  /** 
   * @MULTIPLE
   * Return all the CustomFiles */
  getCustomFiles() {
    if (this.a.isArray(this.uploadedFiles)) {
      return this.uploadedFiles;
    }
    return undefined;
  }

  /** 
   * @SINGLE
   * Return a CustomFile */
  getCustomFile() {
    if (!this.a.isArray(this.uploadedFiles)) {
      return this.uploadedFiles;
    }
    return undefined;
  }



  openCropDialog(e: HTMLInputElement) {
    if (e.files && e.files[0] != undefined) {
      let f = e.files[0];
      if (this.uploadS.checkSize([f], this)) { //Comprobamos el tamaño del archivo
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.addEventListener('load', (event: any) => {
          /**Abrimos el dialogo de recortar la imagen */
          let f = event.target.result;
          const dialogRef = this.dialog.open(CropImageComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {
              f,
              maintainAspectRatio: this.maintainAspectRatio,
              dni: this.dni
            }
          })
          /**Esperamos la respuesta del dialogo de recortar imagen */
          dialogRef.afterClosed().subscribe(res => {
            if (res == false) { }
            //Si el resultado del dialogo es una 'string' (imagen en base64), emitimos el evento de cambio
            else if (typeof res == "string") {
              this.uploadedFiles = new CustomFile(res, undefined);
              this.uploadedFiles.file = this.base64ToFile(this.uploadedFiles.src);
              this.stateChanged.emit(this.uploadedFiles);
            }
          })
        });
      }
    }
  }

  base64ToFile(dataurl: string) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'file', { type: mime });
  }

  clickInputTrigger() {
    if (this.phoneMultipleUpload) {
      this.phonemultipleUpload.open(this);
    }
    else {
      this.input.nativeElement.click();
    }
  }

  showPreview(cf?: CustomFile) {

    if (!cf) { return; }
    //Only can preview images or videos
    if (cf.getType() == this.ft.image || cf.getType() == this.ft.video) {  
      if (this.uploadedFiles && this.showPreviewOnClick && cf) {
        this.previewS.openPreview(this.uploadedFiles, cf);
      }
    }
    else {
      if (cf.file){
          return;
      }
      else {
        window.open(cf.src, '_blank')
      }
    }
  }

}



/** UPLOADED FILE */
/** Se ubica este componente en el mismo archivo, ya que así se evita un problema de dependencias circulares */
import { ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FileType } from '../../enums/FileType';
import { MatDialog } from '@angular/material/dialog';
import { CropImageComponent } from './crop-image/crop-image.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ImagePreviewService } from '../../services/image-preview.service';
import { MultiplePhoneImageService } from '../multiple-phone-image/multiple-phone-image.component';
import { IUploadComponent } from 'src/app/interfaces/IUploadComponent';
import { isMobile } from 'src/app/utils/Browserutils';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  selector: 'lib-uploaded-file',
  templateUrl: './uploaded-file/uploaded-file.component.html',
  styleUrls: ['./uploaded-file/uploaded-file.component.css']
})
export class UploadedFileComponent implements OnInit {
  mouseOver = false;
  @Input() cf!: CustomFile;
  @Input() principal?: boolean = false;
  @Input() selected: boolean = false;
  @Input() canDelete: boolean = true;
  @ViewChild('parentDiv') htmlDIV?: ElementRef<HTMLDivElement>;
  ft = FileType;
  constructor(@Inject(FileUploadComponent) public parent: FileUploadComponent) { }
  ngOnInit(): void { }

  /**Al hacer click en el icono de papelera de la imagen, se llama a ésta función 
   * Se llama a la función 'removeFile()' del componente padre (PictureUploadComponent).
  */
  remove() {
    this.parent.uploadS.removeFile(Number(this.cf.id), this.parent);
  }

  markAsPrincipal(event: Event) {
    this.parent.setPrincipal(this.cf.id!)
    this.parent.triggerOnFav(this.cf);
    event.stopPropagation();
    event.preventDefault();
  }

  markAsSelected() {
    this.parent.setSelected(this.cf.id!)
  }

  move(dir: "up" | "down") {
    this.markAsSelected();
    this.playAnimation();
    if (this.parent) {
      return this.parent.uploadS.reorderFile(this.cf, dir, this.parent)
    }
  }

  playAnimation() {
    if (this.htmlDIV) {
      this.htmlDIV.nativeElement.classList.add("triggerAnimation");
      this.removeAnim();
    }
  }

  removeAnim() {
    setTimeout(() => {
      if (this.htmlDIV) {
        this.htmlDIV.nativeElement.classList.remove("triggerAnimation");
      }
    }, 300)
  }

  showPreview() {
    this.parent.showPreview(this.cf)
    this.markAsSelected()
  }

  get phoneUser() {
    return isMobile();
  }
}





