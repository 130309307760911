import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewService } from 'src/app/services/preview.service';

@Component({
  selector: 'app-invoiced-siniestro-dialog',
  templateUrl: './invoiced-siniestro-dialog.component.html',
  styleUrls: ['./invoiced-siniestro-dialog.component.css']
})
export class InvoicedSiniestroDialogComponent {
  constructor(public dialogRef: MatDialogRef<InvoicedSiniestroDialogComponent, { "invoice_id": number, "token": string, "invoice_name" : string }[]>,
    @Inject(MAT_DIALOG_DATA) public invoices: { "invoice_id": number, "token": string, "invoice_name" : string }[],
    private previewS: PreviewService) {
  }
  showPreview(token : string) {
    this.previewS.showPreview("I", token)
  }

}
