import { ViewPath } from "src/app/app-routing.module";
import { environment } from "src/environments/environment";
import { VehicleType } from "../enums/VehicleType";
import { DEFAULT_IVA } from "../constants/constants";

export class M_AppointmentConfig {
    id: number | undefined;
    company_id: number;
    morning_work_schedule_start: number;
    morning_work_schedule_finish: number;
    afternoon_work_schedule_start: number;
    afternoon_work_schedule_finish: number
    granularity : number;
    range_of_days : number;
    total_appointment : number;
    company_logo : string | undefined;
    company_name : string
    primary_color: string
    secondary_color: string
    url : string;
    token: string;

    places: number;
    iva: number;
    default_vehicle: VehicleType = 1;
    price_hour: number;
    
    constructor(data : any){

        //Workload
        this.places = data.places ? data.places : 0;
        this.default_vehicle = data.default_vehicle ? data.default_vehicle : VehicleType.car;
        this.price_hour = data.price_hour ? data.price_hour : 0;
        this.iva = data.iva != undefined ? data.iva : DEFAULT_IVA;
        
        //Appoitments
        this.id = data.id ? data.id : undefined;
        this.company_id =  data.company_id;
        this.token =  data.token;
        this.morning_work_schedule_start =  data.morning_work_schedule_start;
        this.morning_work_schedule_finish = data.morning_work_schedule_finish;
        this.afternoon_work_schedule_start = data.afternoon_work_schedule_start;
        this.afternoon_work_schedule_finish = data.afternoon_work_schedule_finish;
        this.granularity = data.granularity;
        this.range_of_days = data.range_of_days;
        this.total_appointment = data.total_appointment;
        this.company_logo = data.company_logo;
        this.company_name = data.company_name;
        this.primary_color = data.primary_color;
        this.secondary_color = data.secondary_color;
        this.url = document.location.protocol + "//" + window.location.hostname + (environment.local ? ':4200' : '') + "/" + ViewPath.appointmentclientside.path + '?company=' + this.token;

    }
}