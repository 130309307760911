import { Component, Inject, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { IOr } from 'src/app/interfaces/IOr';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-change-delivery-or-dialog',
  templateUrl: './change-delivery-or-dialog.component.html',
  styleUrls: ['./change-delivery-or-dialog.component.css']
})
export class ChangeDeliveryOrDialogComponent {

  selected: Date | null

  @ViewChild(MatCalendar, { static: false }) calendar!: MatCalendar<Date>;


  constructor(public dialogRef: MatDialogRef<ChangeDeliveryOrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOr, private apiS: ApiService, private snackS: SnackService) {
    /** If not delivery date, close the dialog */
    if (!this.data.delivery) { this.dialogRef.close(); }
    this.selected = this.data.delivery!;
  }

  dateClass = (d: Date) => {
    if (!this.data.delivery || !this.data.schedule) { return ''; }
    const date = new Date(d);
    return date.isEquals(this.data.schedule) ? 'calendar_schedule' : '';
  }

  maxScheduleAndSunday: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) {
        return false;
      }
      const day = date.getDay();
      const param = new Date(date);
      return (param >= this.data.schedule! || param.isEquals(this.data.schedule!)) && day != 0;
    };

  changeOrDelivaery() {
    let newDate = new Date(this.selected!);
    this.apiS.changeORDelivery(this.data.id, newDate).then(res => {
      this.snackS.show("Fecha de entrega modificada")
      this.data.delivery = newDate;
      this.dialogRef.close();
    })
  }

  get datesStatus(): "equal" | "less" | "bigger" {
    return this.equalDates ? "equal" : this.deliveryLess ? "less" : 'bigger'
  }

  get equalDates() {
    if (!this.selected || !this.data.schedule) { return false; }
    return new Date(this.selected).isEquals(this.data.schedule)
  }
  get deliveryLess() {
    if (!this.selected || !this.data.schedule || this.equalDates) { return false; }
    return new Date(this.selected) < this.data.schedule
  }
}
