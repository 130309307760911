import { CustomFile } from "../custom-classes/CustomFile";
import { TICKET_STATUS } from "../enums/TicketStatus";
import { M_BaseUser } from "./M_BaseUser";

export class M_CloudTicketEvent {
    id: number;
    user_id: number;
    event: "comment" | "status" | "new" | "assign" = "comment";
    /** String if comment, number if status change */
    value: string | number = "";
    user!: M_BaseUser;
    uploading: boolean = false;
    isUserComment: boolean = false;
    created_at: Date;
    updated_at: Date;
    readed: boolean = false;
    file: CustomFile | undefined;

    /** Action of comment */
    action_id: number | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.event = d.event ? d.event : "comment";

        if (d.file) {
            this.file = new CustomFile(d.file, undefined);
        }

        /** Gets number from string if is status event */
        if (this.event == "status") {
            this.value = TICKET_STATUS.pending;
            let val = d.value;
            if (val) {
                if (typeof val == "string") {
                    let numberFromString = val.getNumber();
                    if (numberFromString != undefined) {
                        this.value = numberFromString;
                    }
                }
            }
        }
        else {
            this.value = d.value;
        }

        this.readed = d.readed;
        if (d.user instanceof M_BaseUser) {
            this.user = d.user;
        }
        else {
            this.user = new M_BaseUser(d.user);
        }
        this.user_id = d.author ? d.author : this.user.id;
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
        this.checkInternalAndAction();
    }

    /** Example of action comment -> comment = action_1 */
    checkInternalAndAction() {
        /*var isAction = this.comment.includes(actionCommentKey);
        if (isAction) {
            var num = this.comment.replace(/^\D+/g, '');
            if (num.isNumber()) {
                this.action_id = num.getNumber();
            }
        }*/
    }

    get isActionComment() {
        return this.action_id != undefined;
    }
}