import { Component, Inject } from '@angular/core';
import { M_Product } from '../../models/Products/M_Product';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-manual-fault',
  templateUrl: './manual-fault.component.html',
  styleUrls: ['./manual-fault.component.css']
})
export class ManualFaultComponent {
  form: UntypedFormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public prod: M_Product, public dialogRef: MatDialogRef<ManualFaultComponent>,
    formBuilder: FormBuilder, private apiS: ApiService, private snackS: SnackService) {
    this.form = formBuilder.group({
      product_id: [prod.product_id, Validators.required],
      location_id: ['', Validators.required],
      quantity: ['', [Validators.required]],
    });
  }


  manualReservation() {
    let product_id = this.form.get('product_id')!.value;
    let location_id = this.form.get('location_id')!.value
    let quantity = this.form.get('quantity')!.value

    this.apiS.manualFault(product_id, location_id, quantity).then(res => {
      this.snackS.show("Falta manual añadida correctamente");
      this.dialogRef.close(res);
    });
  }
}
