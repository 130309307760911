import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  private snackConfig: MatSnackBarConfig = new MatSnackBarConfig();
  constructor(private snackBar: MatSnackBar) {
    this.snackConfig.duration = 2000;
    this.snackConfig.panelClass = "snack-dialog"
  }

  show(text: string, close?: string, seconds?: number) {
    if (seconds) {
      this.snackConfig.duration = seconds * 1000;
    }
    return this.snackBar.open(text, close !== undefined ? close : 'Cerrar', this.snackConfig);
  }

}
