<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Crear dirección de envío</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <form [formGroup]="fg">
        <div class="df mt10 aic">
            <mat-form-field class="w100" appearance="fill">
                <mat-icon matPrefix>location_on</mat-icon>
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre de la dirección" formControlName="name">
            </mat-form-field>
        </div>
        <div class="df">
            <mat-select-country class="w50" [label]="'Seleccionar país'" [placeHolder]="'Seleccionar país'"
                [required]="true" [formControlName]="'country'">
            </mat-select-country>
            <app-phone-input class="w50" [form]="fg" [formCName]="'phone'"></app-phone-input>
        </div>
        <div class="df">
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="Nombre y número de la calle" formControlName="address">
            </mat-form-field>
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Complemento dirección</mat-label>
                <input matInput placeholder="Apartamento, suite, unidad, edificio o piso" formControlName="address2">
            </mat-form-field>
        </div>
        <div class="w33p">
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Código Postal</mat-label>
                <input matInput placeholder="Código Postal" formControlName="zip">
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Población</mat-label>
                <mat-select #selectedValue formControlName="city" (selectionChange)="stateChange($event)" [disabled]="!this.fg.get('zip')?.value?.length">
                    <!-- <mat-select-trigger>
                        {{ selectedPayment ? selectedPayment.poblacio : form.get('city')?.value}}
                      </mat-select-trigger> -->
                    <mat-option *ngFor="let zip of this.arrayZips" [value]="zip.id">
                        <p class="nmb df aic">{{zip.poblacio}}
                        </p>
                    </mat-option>
                  </mat-select>
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Provincia</mat-label>
                <input matInput placeholder="Provincia" formControlName="province">
            </mat-form-field>
        </div>
    </form>

</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" mat-flat-button [disabled]="!fg.valid" (click)="createAndClose()" cdkFocusInitial>Crear</button>
</div>