import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
  selector: 'app-create-revocer-password',
  templateUrl: './create-recover-password.component.html',
  styleUrls: ['.././login/login.component.css']
})
export class CreateRecoverPasswordComponent extends ParameterStateComponent implements OnInit {
  isCreate = true;
  loaded_ = false;
  pageError = false;

  hash?: string;
  email?: string;

  v = ViewPath;
  public form!: UntypedFormGroup;
  constructor(r: ActivatedRoute, rs: RouterService, private formBuilder: UntypedFormBuilder, private apiS: ApiService, private confirmDialgS: ConfirmDialogService) {
    super(rs, r, ['hash', 'email']);
    this.form = this.formBuilder.group({
      hash: [''],
      email: [''],
      password: [''],
      password_confirmation: ['',]
    });
  }
  
  ngOnInit(): void {}

  override onParams(params: { param: string; value: string; }[]) {

    this.hash = params.find(p => p.param == "hash")?.value;
    this.email = params.find(p => p.param == "email")?.value;

    if (this.hash && this.email) {

      this.form.patchValue({ 'hash': this.hash });
      this.form.patchValue({ 'email': this.email });

      this.apiS.noauth.validateEmail(this.hash, this.email).then(res => {
        switch (res) {

          case 'success_new':
            this.isCreate = true;
            break;

          case 'success_change':
            this.isCreate = false;
            break;

          case 'error':
          default:
            this.pageError = true;
        }

        this.loaded_ = true;
      })
    }
    else {
      this.pageError = true;
      this.loaded_ = true;
    }
  }

  override noStateNoParams() {
    this.loaded_ = true;
    this.pageError = true;
  }

  updatePassword() {
    this.apiS.noauth.updatePassword(this.form.value).then(_res => {
      this.showDialog();
    })
  }

  showDialog() {
    this.confirmDialgS.show({
      title: this.isCreate ?  "Contaseña creada con exito" : "Contaseña modificada con exito",
      body: this.isCreate ?  "Ya puede iniciar sesión" : "Ya puede iniciar sesión de nuevo",
      confirmTxt: "Iniciar sesión",
      disableClose: true,
      showCancel : false
    }).afterClosed().subscribe(res => {
      this.routerS.goTo(this.v.login)
    })
  }

  goLogin() {
    this.routerS.goTo(ViewPath.login);
  }

}
