import { TypeOrder } from "../enums/TypeOrder";
import { M_Contact } from "./M_Contact";
import { M_Product } from "./Products/M_Product";

export class M_PreOrderBreakdown {
    provider: M_Contact;
    type: TypeOrder;
    products: M_Product[] = []
    constructor(provider: M_Contact, products: M_Product[], type: TypeOrder) {
        this.provider = provider;
        this.products = products;
        this.type = type;
    }

    get total() {
        return this.products.reduce((sum, p) => sum + p.quantity * p.buy_price, 0);
    }
}