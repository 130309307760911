<app-page-structure [masterClass]="undefined" pageTitle="Pedidos" [autocompleteID]="'orders'"
    searchLabel="Buscar pedido" [addNewView]="v.createOrder" listTitleText="Listado de pedidos"
    createButtonClass="onboarding-create-order" noDataCreateNew="Crear un nuevo pedido" [data]="[]" [card]="card"
    [filters]="filters" [filter]="f" [basicUserSubscription]="subS.isBasic" [rightMenu]="rightMenu"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [displayedHeader]=" ['Número',   'Proveedor',        'Envío',        'Entrado',          'Estado',           'Creación']"
    [displayedColumns]="['order',    'provider.name',    'sendOrder',    'enteredDivision',  'order_status',     'created_at']"
    [specialText]="     [undefined,   client_name,        send,           undefined,          undefined ,         undefined]"
    [specialClass]="    [undefined,   undefined,          undefined,      undefined,          undefined,          undefined]"
    [preIcon]="         [preIcon,     undefined,          undefined,      undefined,          undefined,          undefined]"
    [cellZize]="        [undefined,   undefined,          undefined,      'small',            undefined,          undefined]"
    [createButton]="{text : 'Crear pedido' , icon : 'local_shipping'}"
    [quickFilter]="quickFilter">

    <ng-template #rightMenu>
        <button mat-menu-item (click)="openFaultsDialog()">
            <mat-icon>production_quantity_limits</mat-icon>
            Faltas
        </button>
    </ng-template>

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button mat-flat-button color="warn" (click)="params.go(v.editOrder, ps.selected!.id)" class="mb10">
                <mat-icon>{{ps.selected!.canEdit ? 'edit' : 'visibility'}}</mat-icon>
                {{ps.selected!.canEdit ? 'Editar pedido' : 'Ver pedido'}}
            </button>

            <app-card-subtitle [first]="true">General</app-card-subtitle>
            <p>
                {{ps.selected!.products.length}}
                {{ps.selected!.products.length == 1 ? 'producto' : 'productos'}}
                <span class="c_t2">(<span money [val]="ps.selected!.totalPrice"></span>)</span>
            </p>

            <app-card-subtitle>Envío</app-card-subtitle>
            <mat-checkbox (change)="changeSend(ps.selected!)" [(ngModel)]="ps.selected!.send">Pedido
                enviado</mat-checkbox>

            <app-card-subtitle>Proveedor</app-card-subtitle>
            <app-client-row [item]="ps.selected!.provider!"></app-client-row>
            <app-contact-buttons>
                <app-whatsapp [phone]="ps.selected!.provider.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.provider.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.provider.phone"></app-phone-button>
            </app-contact-buttons>
        </app-card>
    </ng-template>
</app-page-structure>