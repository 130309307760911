import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { AlreadyExistsService } from "../services/already-exists.service";
import { ViewPath } from "src/app/app-routing.module";
import { M_Product } from "../models/Products/M_Product";
import { ApiService } from "../services/Api/api.service";

export function referenceAsyncValidator(apiS: ApiService, existsS: AlreadyExistsService, getProduct: () => M_Product | undefined): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {

        let currentProduct = getProduct();
        let productReference: string | undefined = currentProduct?.reference.toLocaleLowerCase();

        console.log("Checking reference");
        console.log("Current product", currentProduct);
        console.log("Product reference", productReference)

        let val = control.value;
        if (typeof val == "string") { val = val.toLocaleLowerCase() }

        if (!val || val == productReference) { return Promise.resolve(null) }

        return apiS.checkReference(control.value).then(res => {
            if (res instanceof M_Product) {
                existsS.show({
                    title: "¡Atención!",
                    message: "Ya existe un producto con la misma referencia",
                    message2: "Recordamos que no se pueden crear dos productos con la misma referencia",
                    value: res.reference,
                    view: ViewPath.productDetails,
                    param_id: res.product_id!,
                    accept_text: "Ver producto"
                })
                return { "ref-exists": true }
            }
            return null;
        })
    }
};