<div class="container">
    <div class="title">
        <app-go-back [text]="'Listado de entradas'" [v]="v.storeHouseEntry"></app-go-back>
        <app-view-title>Entrada de almacén</app-view-title>
    </div>

    <div class="search">
        <app-card [contentLoaded]="loaded">
            <div class="df fww jcsa">
                <!-- General info form -->
                <form class="general-info-form form" [formGroup]="form">
                    <app-card-subtitle [first]="true">Información general</app-card-subtitle>
                    <!-- Provider searcher -->
                    <app-class-searcher #providerSearch [showLeftNumber]="false" class="w100" [masterClass]="provider"
                        [width100]="true" searchPlaceHolder="Buscar proveedor" [extendedInfo]="true"
                        (onSelect)="entry.provider = $event" [canRemove]="!entry.canEnter" [form_]="form"
                        [formCname]="'provider_id'">
                    </app-class-searcher>
                    <mat-form-field>
                        <mat-label>Tipo de entrada</mat-label>
                        <mat-select #ms [value]="entrancesType.PMP" [(ngModel)]="entry.entranceType"
                            formControlName="entranceType">
                            <mat-select-trigger>
                                <mat-icon class="vab">{{entryIcon(ms.value)}}</mat-icon>
                                {{entryName(ms.value)}}
                            </mat-select-trigger>
                            <mat-option [value]="entrancesType.PMP">
                                <mat-icon>{{entryIcon(entrancesType.PMP)}}</mat-icon> {{entryName(entrancesType.PMP)}}
                            </mat-option>
                            <mat-option [value]="entrancesType.COST">
                                <mat-icon>{{entryIcon(entrancesType.COST)}}</mat-icon> {{entryName(entrancesType.COST)}}
                            </mat-option>
                            <mat-option [value]="entrancesType.IMPORT">
                                <mat-icon>{{entryIcon(entrancesType.IMPORT)}}</mat-icon>
                                {{entryName(entrancesType.IMPORT)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Nº Documento</mat-label>
                        <app-async-validator-spinner matSuffix [control]="form.get('dnote_prov_num')"></app-async-validator-spinner>
                        <input [(ngModel)]="entry.dnote_prov_num" matInput formControlName="dnote_prov_num"
                            [required]="true" type="string" placeholder="Nº Documento" />
                        <mat-hint *ngIf="duplicatedDocu">
                            <mat-icon class="vam aic fs10 c_r"
                                style="font-size: 20px; margin-bottom: 5px;">warning</mat-icon>
                            <span class="c_r">Ya existe una entrada con este número de documento</span>
                        </mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Fecha documento</mat-label>
                        <input #pickerInput matInput formControlName="dnote_prov_date" [matDatepicker]="picker"
                            [(ngModel)]="entry.dnote_prov_date" (click)="picker.open()" placeholder="Fecha documento">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </form>

                <!-- Add product searcher-->
                <div class="enter-order-add-product-div">
                    <app-card-subtitle [first]="true">Añadir productos</app-card-subtitle>
                    <app-storehouse-entry-add-product [entry]="entry" (onLoad)="onProductSearcherLoaded($event)"
                        [provider]="providerSearch.selected"></app-storehouse-entry-add-product>
                </div>

            </div>

            <div class="mt20"></div>
            <mat-divider></mat-divider>
            <div class="mb20"></div>

            <p class="mla_i">
                Total :
                <span class="mla right-total" money [val]="entry.screenTotal"></span>
            </p>



            <!-- Single product section -->
            <div class="entry-section">
                <p class="entry-section-title" #trigger>
                    <mat-icon smartIcon class="c_w">barcode</mat-icon>
                    Individuales
                    <span class="right-total" money [val]="entry.totalProds"></span>
                    <mat-icon class="c_w" dropDown [content]="ddContent" [trigger]="trigger">arrow_drop_up</mat-icon>
                </p>
                <div class="section-content content-index-0" #ddContent>
                    <p class="c_t2" *ngIf="!entry.products.length">Puedes añadir productos con el buscador.</p>
                    <app-storehouse-entry-section [products]="entry.products" [entry]="entry" [showProviders]="false"
                        [entranceType]="entry.entranceType" [array]="'products'" [showDest]="false">
                    </app-storehouse-entry-section>
                </div>
            </div>

            <!-- Orders section -->
            <div class="entry-section">
                <p class="entry-section-title" #trigger2> <mat-icon class="c_w">local_shipping</mat-icon>
                    Pedidos
                    <span class="right-total" money [val]="entry.totalOrders"></span>
                    <mat-icon class="c_w" dropDown [content]="ddContent2"
                        [trigger]="trigger2">arrow_drop_down</mat-icon>
                </p>
                <div #ddContent2>
                    <p class="section-content content-index-0 c_t2" *ngIf="!entry.orders.length">Puedes añadir pedidos
                        con el buscador.</p>
                    <div class="section-content content-index-0" *ngFor="let order of entry.orders">
                        <p class="entry-section-title">
                            <mat-icon class="c_w">local_shipping</mat-icon>
                            {{order.order}}
                            <span class="right-total" money [val]="order.geTotal(entry)"></span>
                        </p>
                        <div class="section-content content-index-1">
                            <app-storehouse-entry-section [products]="order.products" [entry]="entry" [order]="order"
                                [showProviders]="false" [entranceType]="entry.entranceType" [showEntryTo]="true"
                                [array]="undefined" [locationOnlyInformative]="true"></app-storehouse-entry-section>
                        </div>
                    </div>
                </div>

            </div>

            <!-- File import section -->
            <div class="entry-section">
                <p class="entry-section-title" #trigger3>
                    <mat-icon class="c_w">description</mat-icon>
                    Fichero
                    <span class="right-total" money [val]="entry.totalProdsFile"></span>
                    <mat-icon class="c_w" dropDown [content]="ddContent3" [trigger]="trigger3">arrow_drop_up</mat-icon>
                </p>

                <div class="section-content content-index-0" #ddContent3>
                    <app-excel-column-matcher #ecm [expectedColumns]="expectedColums" (onRowEnter)="onRowEnter($event)"
                        (onRemoveExcel)="entry.products_file = []" (onClickNext)="entry.products_file = []">
                        <app-storehouse-entry-section [products]="entry.products_file" [entry]="entry"
                            [showProviders]="false" [showDest]="false" [showNewOrPriceChange]="true" [showPVP]="true"
                            [entranceType]="entry.entranceType" [array]="'products_file'">
                        </app-storehouse-entry-section>
                    </app-excel-column-matcher>
                </div>
            </div>

            <div class="df fdc aic jcc">
                <p *ngIf="duplicatedDocu" class="c_r">El número de documento introducido ya existe</p>
                <button style="width: fit-content;" mat-flat-button [disabled]="!entry.canEnter || !form.valid || ecm.inProcess"
                    (click)="enter()" [color]="'warn'">Entrar</button>
            </div>

        </app-card>
    </div>
</div>