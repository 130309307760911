<div [ngClass]="{'dn' : missingComponent.showComponent}">
    <div class="container" [ngClass]="{'dn' : albaranFialedToLoad}">
        <!-- ACTION GENERAL INFO -->
        <div class="title" *ngIf="loaded">
            <app-go-back class="title" *ngIf="albaran != undefined" [text]="'Listado albaranes'" [v]="v.albaranes"
                [show]="albaran != undefined"></app-go-back>
            <app-view-title *ngIf="albaran == undefined">Crear albarán</app-view-title>
            <app-view-title *ngIf="albaran != undefined">Modificar albarán</app-view-title>
        </div>


        <div class="general_info">
            <app-card [contentLoaded]="loaded">
                <div class="df jcsb w100">
                    <app-card-title>Información general</app-card-title>
                    <!-- DOCUMENTATION !-->
                    <app-documentation-pdf-menu [albaran]="albaran"></app-documentation-pdf-menu>
                </div>

                <div *ngIf="albaran" class="df">
                    <div class="w100 df jcsb vam">
                        <div>
                            <app-card-subtitle [first]="true">
                                <div class="df aic fww" style="line-height: 40px;">
                                    {{albaran.title_id}}
                                    <app-albaran-status-label class="ml5"
                                        [status]="albaran.status"></app-albaran-status-label>
                                </div>
                            </app-card-subtitle>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="!albaran">
                        <p class="c_t2">Selecciona un cliente para crear un nuevo albarán 🆕📝 </p>
                    </div>
                    <form [formGroup]="form" class="form">

                        <div>
                            <!-- <div class="df jcfe mt0" style="align-items: baseline;" *ngIf="albaran">
                                <mat-checkbox [formGroup]="form" formControlName="block"
                                    [(ngModel)]="albaran.block">Bloquear</mat-checkbox>
                            </div> -->
                            <div class="df">
                                <app-class-searcher class="w50 onboarding-albaran-select-client" #clientSearcher
                                    [masterClass]="client" searchPlaceHolder="Buscar cliente"
                                    (onSelect)="createAlbaran()"
                                    (onInit)="createAlbaran()"
                                    [noSelectionPlaceHolder]="'Ningún cliente seleccionado'"
                                    [specialRow]="'Nuevo cliente'" (onSpecialRow)="ccDialog.create(clientSearcher)"
                                    [required]="true" [canRemove]="!albaran">
                                </app-class-searcher>
                                <mat-form-field appearance="outline" class="w50">
                                    <mat-label>Realización</mat-label>
                                    <input matInput placeholder="dd/mm/aaaa" [matDatepicker]="picker_1"
                                        (click)="picker_1.open()" formControlName="date_user">
                                    <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker_1></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="dg w100" *ngIf="albaran && albaran.client">
                            <app-contact-sending-address-selector [contact]="albaran.client"
                                [fc]="form.get('extra_addres')!" [canChange]="opened"
                                (onChangedAddress)="albaran.extra_addres = $event;">
                            </app-contact-sending-address-selector>
                            <div class="df">
                                <mat-form-field class="w100" appearance="outline">
                                    <mat-label>Comentarios</mat-label>
                                    <textarea [formControlName]="'comment'" matInput cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- MOBILE -->
                <div class="client-details-phone">
                    <div class="df jcc">
                        <app-client-vehicle-in-dialog [t]="'Ver más información'"
                            [c]="clientSearcher.selected"></app-client-vehicle-in-dialog>
                    </div>
                </div>

            </app-card>
        </div>

        <div class="albaran_client">
            <app-card [contentLoaded]="loaded">
                <app-client-info [small]="false" [client]="clientSearcher.selected"></app-client-info>
            </app-card>
        </div>

    </div>

    <div class="albaran-products-container" [ngClass]="{'dn' : albaranFialedToLoad}">

        <div class="floating-action-bar no-border" [ngClass]="{'dn' : (albaran && albaran.invoiced)}">
            <app-card class="normal-padding-top" [contentLoaded]="loaded" [locked]="albaran == undefined"
                [cornerMatMenu]="!albaran?.status?.closed ? sideActions: undefined"
                [lockedText]="'Crea un albarán para poder realizar acciones'">

                <div class="df jcc" style="gap: 10px;">
                    <!-- ACTIONS ON OPEN ALBARAN -->

                    <button mat-flat-button color="warn" [disabled]="!screenChanges" (click)="saveAlbaran()"
                        *ngIf="opened ||albaran == undefined">
                        Guardar
                    </button>

                    <button *ngIf="opened ||albaran == undefined" mat-raised-button color="orange"
                        [disabled]="closeDisabled" (click)="changeAlbaranStatus(al_c)">
                        <mat-icon>assignment_turned_in</mat-icon>
                        Cerrar
                    </button>

                    <button *ngIf="closed" mat-flat-button color="green" (click)="changeAlbaranStatus(al_o)">
                        <mat-icon>content_paste_go</mat-icon>
                        Abrir
                    </button>

                    <div *ngIf="canInvoiceS.data(albaran, this) as invocieData" [matTooltip]="invocieData.tooltip">
                        <button *ngIf="albaran && closed" mat-flat-button color="white" (click)="invoiceAlbaran()"
                            [disabled]="invocieData.disable" [matBadge]="invocieData.badge"
                            [matBadgeColor]="invocieData.badgeColor">
                            <mat-icon>receipt_long</mat-icon>
                            {{!albaran.type.cargo ? 'Facturar' : 'Tramitar'}}
                        </button>
                    </div>

                </div>
            </app-card>
        </div>

        <!-- ALBARÁN PRODUCTS  class="bc_b0"-->
        <div class="albaran-products" [ngClass]="{'dn' : albaranFialedToLoad}">
            <app-card [contentLoaded]="loaded" [locked]="albaran == undefined"
                [lockedText]="'Crea un albarán para poder realizar acciones'">
                <div class="dg">
                    <app-card-title>
                        <div class="df fww">
                            Albarán
                            <button mat-button *ngIf="albaran && albaran.invoicePdf" color="warn" class="mla"
                                (click)="billPreview()">
                                <span>{{!albaran.type.cargo ? 'Ver factura' : 'Ver trámite'}}</span>
                                <span class="pdf-label"></span>
                            </button>
                        </div>
                    </app-card-title>

                    <div class="df" style="gap: 10px;">
                        <app-or-type-label *ngIf="albaran" [menuIconOpen]="albaran.canModify"
                            (click)="preventDropDownOpening($event)" [showText]="false" [tipo]="[albaran.type]"
                            [mat-menu-trigger-for]="albaran.canModify ? albaranType : null" [pointer]="true">
                        </app-or-type-label>
                    </div>
                </div>
                <app-product-line-table #plt [products]="albaran? albaran.products : []"
                    [lineTypes]="['product', 'custom']" [canModifyTable]="albaran ? albaran.canModify : false"
                    [extendedInfoConfig]="{showColumn:true, actions:['manual-fault', 'remove-reservation']}"></app-product-line-table>
            </app-card>
        </div>

        <mat-menu #albaranType="matMenu">
            <app-or-type-selector [isAlbaran]="true" (onClickOption)="onTypeChange($event)"></app-or-type-selector>
        </mat-menu>

    </div>
</div>

<ng-template #sideActions>
    <button mat-icon-button class="mt10" [disabled]="albaran == undefined"
        [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #deleteMenu="matMenu">
        <ng-template></ng-template>
        <div [matTooltip]="albaran?.someProductRequested ? 'El albarán tiene productos en camino' : ''">
            <button mat-menu-item (click)="deleteAlbaran()" [disabled]="albaran?.someProductRequested">
                <mat-icon>delete</mat-icon> Eliminar
            </button>
        </div>

    </mat-menu>
</ng-template>

<ng-template #notfound>
    <app-model-not-found [ngClass]="{'dn' : !albaranFialedToLoad}" [title]="'No se ha encontrado el albarán'" [goText]="'Ir al listado de albaranes'"
        [view]="v.albaranes">
    </app-model-not-found>
</ng-template>

<!-- MISSING COMPANY INFO COMPONENT-->
<app-missing-company-info #missingComponent [pageLoaded]="loaded" [model]="'albaran'">
</app-missing-company-info>