import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { projectConfiguration } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'lib-dobi-dialog',
  templateUrl: './dobi-dialog.component.html',
  styleUrls: ['./dobi-dialog.component.css']
})
export class DobiDialogComponent implements OnInit {
  env = environment;
  fc: UntypedFormControl;
  magicdobi = false;
  showDoor = false;
  key2 = ["calcetin", "calcetín", "calcetines"]
  dobitext = "¿Alguien ha llamado a Dobi?"
  appear: boolean = true;
  core = projectConfiguration;
  
  constructor() {
    this.fc = new FormControl("");
  }

  ngOnInit(): void { }

  sendText(v: string) {
    this.fc.setValue('');
    this.appear = false;
    v = v.toLowerCase();
    this.appearAgain().then(res => {
      this.appear = true;
      if (!this.magicdobi) {
        this.checkLiberation(v);
      }
      else {
        this.checkSpell(v);
      }
    })
  }

  checkLiberation(v: string) {
    if (this.is(v, "calcetin", "calcetín", "calcetines")) {
      this.dobitext = " ¿He escuchado calcetín? ¡Has liberado a Dobi! Dobi ahora seguira tus órdenes";
      this.magicdobi = true;
    }
    else {
      this.dobitext = "Dobi tiene amo, no puede seguir tus órdenes ahora."
    }
  }

  checkSpell(v: string) {

    /** Reset style */
    document.documentElement.setAttribute("style", "")
    document.documentElement.removeAttribute("class");
    this.showDoor = false;

    if (this.is(v, "lumos", "lumos", "lumus", "lumus maxima", "lumos maxima")) {
      document.documentElement.setAttribute("style", "filter: brightness(1.5);")
      this.dobitext = "Luz luz luz!!"
    }
    else if (this.is(v, "bombarda")) {
      this.dobitext = "¡Allá vamos...!"
      this.waitFor(1).then(res => document.documentElement.classList.add("bombarda"));
    }
    else if (this.is(v, "crucio", "krucio", "imperio", "abada", "avada", "cedabra", "kedabra", "avada kedabra", "abada kedabra", "avabda kevdara")) {
      this.dobitext = "¡NOOOOOOOO! Dobi jamás haría eso. Dobi debe castigarte. Asi que...."
      this.waitFor(3).then(res =>
        document.documentElement.setAttribute("style", "transform: rotate(180deg);")
      )
    }
    else if (this.is(v, "confundus")) {
      this.dobitext = "'Marchando confundus!"
      document.documentElement.setAttribute("style", "filter: invert(1);")
    }
    else if (this.is(v, "expecto", "patronum", "patronus", "expecto patronum", "expecto patronus")) {
      this.dobitext = "Dobi no ve dementores. Dobi no hará un patronum"
    }
    else if (this.is(v, "alohomora")) {
      this.dobitext = "Parece ser que Dobi ha abierto una puerta... Solo hay que hacer click para revelar los secretos que esconde"
      this.showDoor = true;
    }
    else {
      this.dobitext = "Dobi no reconoce tal cosa..."
    }
  }

  //EXPELLIARMUS
  //PETRIFICUS TOTALUS
  //RIDDIKULUS
  //SECTUMSEMPRA
  //WINGARDIUM LEVIOSA
  //CRUCIO
  //IMPERIO

  is(v: string, ...values: string[]) {
    var match = false;
    values.forEach(value => {
      if (v.includes(value)) {
        match = true;
      }
    })

    return match;
  }

  appearAgain(): Promise<boolean> {
    return new Promise<boolean>(resolve =>
      setTimeout(() => {
        resolve(true);
      }, 1000))
  }

  waitFor(seconds: number): Promise<boolean> {
    return new Promise<boolean>(resolve =>
      setTimeout(() => {
        resolve(true);
      }, 1000 * seconds))
  }

}
