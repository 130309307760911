import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientComponent } from 'src/app/views/create-client/create-client.component';
import { M_Contact } from '../models/M_Contact';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';
import { ClientTypeEnum } from '../enums/ClientTypeEnum';
import { ContactEnum } from '../enums/ContactEnum';

@Injectable({
  providedIn: 'root'
})
export class CreateClientInDialogService {

  constructor(private d: MatDialog) { }

  create(searcher: ClassSearcherComponent<M_Contact> | undefined, forceType?: ContactEnum) {
    if (!searcher) { return; }
    this.d.open(CreateClientComponent, { data: forceType, maxWidth: 800, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof M_Contact) {
        searcher?.addAndSelectNewOption(res);
      }
    })
  }

}
