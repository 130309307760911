import { Injectable } from '@angular/core';
import { M_ShippingAddress } from '../models/M_Address';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_ESP } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ExtraAddressService {
  generateAddresFormFroup(address?: M_ShippingAddress) {
    let fg = new FormGroup({
      'id': new FormControl(address?.id),
      'name': new FormControl(address?.name, Validators.required),
      'country': new FormControl(address? address.country : COUNTRY_ESP, Validators.required),
      'phone': new FormControl(address?.phone, Validators.required),
      'address': new FormControl(address?.address, Validators.required),
      'address2': new FormControl(address?.address2),
      'zip': new FormControl(address?.zip, Validators.required),
      'city': new FormControl(address?.city, Validators.required),
      'province': new FormControl(address?.province, Validators.required),
    });
    return fg;
  }
}
