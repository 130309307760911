<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title> {{data ? 'Editar almacén' : 'Crear almacén'}}</p>
<p class="storehouse-delete underline_hover" (click)="deleteStoreHouse()" *ngIf="data && !data.default">Eliminar</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <app-sotre-house-form [sh]="data" #sotreHouseComponent></app-sotre-house-form>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" [disabled]="!sotreHouseComponent.form.valid" mat-flat-button
        (click)="createSotreHouse(sotreHouseComponent)">
        {{data ? 'Editar' : 'Crear'}}
    </button>
</div>