<form [formGroup]="form">
    <mat-form-field class="w50" appearance="outline" >
        <mat-label>Desde</mat-label>
        <input placeholder="dd/mm/aaaa" autocomplete="off" matInput [matDatepicker]="picker_1" (click)="picker_1.open()" formControlName="c1">
        <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
        <mat-datepicker #picker_1></mat-datepicker>
    </mat-form-field>   
    <mat-form-field class="w50" appearance="outline" #d2>
        <mat-label>Hasta</mat-label>
        <input placeholder="dd/mm/aaaa" [min]="minD2" autocomplete="off" matInput [matDatepicker]="picker_2" (click)="picker_2.open()" formControlName="c2">
        <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
        <mat-datepicker #picker_2></mat-datepicker>
    </mat-form-field>   
</form>