<div [go]="data.view" [afterGo]="mc.afterGoTo.bind(mc)" #item *ngIf="isShowing"
    [class]="'menuItem ' + dataCalssPhoneDesktop() + ' ' + (class_ ? class_ : '')"
    [ngClass]="{'selected' : bS.isSelected(data), 'closed' : !mc.opened}">

    <mat-icon *ngIf="!svgIcon" [filled]="bS.isSelected(data)" class="c_w">{{data.icon}}</mat-icon>


    <mat-icon *ngIf="svgIcon"
        [svgIcon]="bS.isSelected(data) ? data.icon :  data.icon == 'wheel' ? (data.icon + '_outline') : data.icon"
        [ngClass]="{'filled' :bS.isSelected(data), 'c_w' : !bS.isSelected(data)}"></mat-icon>


    <span *ngIf="mc.opened" [ngClass]="{'mr20' : matmenu, 'c_w' : true}">
        {{data.name}}
    </span>

    <!-- Right icon-->
    <span class="span-icon" style="margin-right: 0px;" *ngIf="righticon" [ngClass]="mc.opened ? 'right' : 'absolute'">
        <mat-icon [filled]="true" [style]="'color : ' + righticon.color + ' !important;'">{{righticon.icon}}</mat-icon>
    </span>
    
    <div class="expansionPanel" [ngClass]="{'closed' : !mc.opened}"></div>
    <div *ngIf="badge" [ngClass]="!mc.opened ? 'badge-opened' : 'badge end-badge'" [matBadge]="badge ? badge : null">
    </div>
    
</div>