import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Action } from 'src/app/models/M_Action';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { ApiService } from 'src/app/services/Api/api.service';
import { PreviewService } from 'src/app/services/preview.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { DayFilter, Filter, FilterOption, SliderFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { or_status } from 'src/app/custom-classes/or_states';
import { action_type_budget } from 'src/app/custom-classes/action_types';

export enum BudgetPageFiltesrEnum {
  BUDGET_DELIVEY = 0,
  BUDGET_TOTAL = 1,
  BUDGET_STATUS = 2,
}

@Component({
  selector: 'app-normal-budget',
  templateUrl: './normal-budget.component.html',
  styleUrls: ['./normal-budget.component.css']
})
export class NormalBudgetComponent implements OnInit {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;

  v = ViewPath;
  f = filter;
  sf = new SliderFilter("Total", 500).setId(BudgetPageFiltesrEnum.BUDGET_TOTAL);
  filters: Filter[] = [
    new DayFilter("Fecha creación").setId(BudgetPageFiltesrEnum.BUDGET_DELIVEY),
    this.sf,
    new TagFilter("Estado", or_status, new FilterOption("Abierto"), new FilterOption("Cerrado")).setId(BudgetPageFiltesrEnum.BUDGET_STATUS),
  ]

  constructor(public subS: SubscriptionService, public companyS: CompanyService, private apiS: ApiService, private previewS: PreviewService) { }

  ngOnInit(): void {
    this.load();
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == BudgetPageFiltesrEnum.BUDGET_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  load() {
    this.apiS.actions(action_type_budget).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        this.sf.changeMax(Math.max(...resp.map(o => o.total || 0)))
      }
    });
  }

  client(action: M_Action) { return action.client?.getName() }
  preicon(action: M_Action) {
    return isSomethingMissing(action.faults);
  }
  reSendBudget() {
    return this.apiS.closeBudget(this.ps.selected!.id)
  }
  previsualize(bill: M_Action) {
    this.previewS.showPreview("B", bill.token, undefined, bill.title_id);
  }
}
