import { Injectable } from '@angular/core';
import { M_Fault } from '../models/M_Fault';
import { ParamsService } from './params.service';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class GoFaultService {

  constructor(private paramsS : ParamsService, private routerS : RouterService) { }

  go(f : M_Fault, newTab : boolean = true){
    let dest = f.getDestView;
    let master_id = f.getMasterId;
    if (dest && master_id) {
      let paramName = this.paramsS.getStateNameByView(dest); // get the param name ("or", "budget"...)
      this.routerS.goWithQueryParams(dest, {[paramName] : master_id, highlight_prod_id : f.product_id}, newTab)
    }
  }
}
