import { Component, Input} from '@angular/core';
import { UserOpinion } from 'src/app/custom-classes/UserOpinion';

@Component({
  selector: 'app-trusted-user',
  templateUrl: './trusted-user.component.html',
  styleUrls: ['./trusted-user.component.css']
})
export class TrustedUserComponent {

  @Input() userOpinion! : UserOpinion;
  constructor() { }
}
