import { Component, Input } from '@angular/core';
/**
 * Shows a mat-mini-fab button with a whatsapp icon.
 * On click the <a> link, the component opens a link to send a message with whatsapp.
 * Also show the phone number on a tooltip 
 */
@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css']
})
export class WhatsappComponent {

  @Input() phone: string | undefined;
  @Input() colorButton: boolean = false;
  constructor() { }

  isPhone(val: string | number | undefined) {
    if (val != undefined && val != "") {
      val = val.toString()
      if (val.length >= 1) {
        return val.charAt(0) == '6' || val.charAt(0) == '7';
      }
    }
    return false;
  }

  get data() {
    if (this.phone != undefined && this.phone != "") {
      return this.isPhone(this.phone)
    }
    return false;
  }
}
