<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Añadir falta manual</p>

<div mat-dialog-content>
    <p mat-dialog-subtitle>{{prod.name}}</p>
    <form class="form">
        <div class="df w100">
            <app-select-location class="w100" [product]="prod" [control]="form.get('location_id')!"></app-select-location>
        </div>
        <div class="df">
            <app-add-remove class="manual-fault" [initValue]="1" [label]="'Cantidad'" [minValue]="1"
                [addRemoveOutside]="false" [forceMinMaxValue]="true" [canModify]="true" [showSaveIcon]="false"
                [form]="form" [formCName]="'quantity'"></app-add-remove>
        </div>
    </form>

</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" mat-flat-button [disabled]="!form.valid" (click)="manualReservation()">Añadir</button>
</div>