<div class="df fdc jcfs">
    <div class="df aic jcc">
        <div class="df aic">
            <div>
                <div class="ribbon" *ngIf="classSearcher.selected?.discount && displayDiscount">
                    <span>{{classSearcher.selected?.discount}}% DTO</span>
                </div>
                <app-client-info [client]="classSearcher.selected"></app-client-info>
            </div>
        </div>
    </div>
</div>