import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { M_Concept } from '../../models/M_Concept';
import { MatDialog } from '@angular/material/dialog';
import { MASTER_CONCEPT } from '../../constants/masters';
import { ConceptsLineTableComponent } from '../concepts-line-table/concepts-line-table.component';
import { CreateConceptComponent } from '../create-concept/create-concept.component';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';

@Component({
  selector: 'app-concept-searcher',
  templateUrl: './concept-searcher.component.html',
  styleUrls: ['./concept-searcher.component.css']
})
export class ConceptSearcherComponent {

  masterConcept = MASTER_CONCEPT;

  @ViewChild(ClassSearcherComponent, { static: true }) classSearcher!: ClassSearcherComponent<M_Concept>;
  @Input({ required: true }) ref!: ComponentRef<ConceptSearcherComponent>;
  @Output() onSelectConcept: EventEmitter<M_Concept> = new EventEmitter();
  @Output() onDestroy: EventEmitter<ConceptSearcherComponent> = new EventEmitter();

  constructor(@Inject(ConceptsLineTableComponent) public conceptsLineTable: ConceptsLineTableComponent, private d: MatDialog, private chdRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.focus();
  }

  focus() {
    if (this.classSearcher) {
      this.classSearcher.focus();
      this.chdRef.detectChanges();
    }
  }

  disabledIf(concept: M_Concept) {
    return this.conceptsLineTable.concepts.some(c => c.id == concept.id);
  }

  createConcept() {
    this.d.open(CreateConceptComponent).afterClosed().subscribe(res => {
      if (res instanceof M_Concept) {
        this.classSearcher.allData.push(res);
        this.onSelectConcept.emit(res);
      }
    })
  }

  destroy(){
    this.ref.destroy();
  }

}
