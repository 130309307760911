import { Injectable } from '@angular/core';
import { CompanyService } from './EinaMainData/company.service';
import { M_Action } from '../models/M_Action';
import { M_Invoice } from '../models/M_Invoice';
import { M_Albaran } from '../models/M_Albaran';
import { M_GroupTask } from '../models/M_GroupTask';
import { getStock } from './stock-calculator.service';
import { M_Product } from '../models/Products/M_Product';
import { M_CustomProduct } from '../models/Products/M_CustomProduct';
import { ThemePalette } from '@angular/material/core';
import { IProductLineTableComponent } from '../interfaces/IProductLineTableComponent';

type canInvocieData = {
  disable: boolean, // Is the invoice button disabled ? 
  tooltip: string, // What's the tooltip to show ?
  badge: "!" | '', // Show the alert badge ?
  badgeColor: ThemePalette // Color of the badge
}

@Injectable({
  providedIn: 'root'
})
export class CanInvoiceService {

  verbose = false;

  constructor(private companyS: CompanyService) { }

  data(groups: M_GroupTask[] | undefined, comp: IProductLineTableComponent): canInvocieData;
  data(invoice: M_Invoice | undefined, comp: IProductLineTableComponent): canInvocieData;
  data(invoice: M_Albaran | undefined, comp: IProductLineTableComponent): canInvocieData;

  /**
   * Get all the invoice data
   * 
   * This function retrieves all the necessary data to determine the invoicing status 
   * based on the provided parameters and component. It checks whether the invoicing 
   * is allowed depending on the company's configuration and the product stock status.
   * 
   * @param param - The parameter which can be of type M_Action, M_GroupTask[], M_Invoice, M_Albaran, or undefined.
   * @param comp - The component of type I_ProductLineTableComponent.
   * @returns An object of type canInvocieData which contains the status of whether invoicing is enabled,
   *          the tooltip message to be displayed, and the badge information.
   */
  data(param: M_Action | M_GroupTask[] | M_Invoice | M_Albaran | undefined, comp: IProductLineTableComponent): canInvocieData {

    let hasProducts = this.hasProducts(param);

    /** If the user has the recambios module activated, they CANNOT invoice without stock */
    if (this.companyS.recambiosModule) {
      let canInvoice = this.canInvoice(param, comp);
      let canInvoiceD: canInvocieData = {
        disable: !canInvoice || !hasProducts,
        tooltip: !hasProducts ? 'Sin nada que facturar' : !canInvoice ? this.canInvoiceTooltip : '',
        badge: !canInvoice && hasProducts ? "!" : '',
        badgeColor: !canInvoice ? "warn" : 'primary',
      }
      return canInvoiceD;
    }
    else {
      /**
       * No recambios module
       * In this case, the user can always invoice. 
       * An alert is shown if it is out of stock
       */
      let missingStock = this.missingStock(param, comp);
      let canInvoiceD: canInvocieData = {
        disable: !hasProducts,
        tooltip: !hasProducts ? 'Sin nada que facturar' : missingStock ? this.missingStockTooltip : '',
        badge: missingStock && hasProducts ? "!" : '',
        badgeColor: missingStock ? 'orange' as ThemePalette : 'primary',
      }
      return canInvoiceD;
    }
  }

  /** ==================================================== */
  /** ===============  CAN INVOICE STUFFF  =============== */
  /** ==================================================== */

  /** Only used on recambios module */
  canInvoice(param: M_Action | M_GroupTask[] | M_Invoice | M_Albaran | undefined, comp: IProductLineTableComponent): boolean {
    if (!this.companyS.recambiosModule) {
      return true;
    }
    else if (param == undefined){
      return false;
    }
    else if (Array.isArray(param) && param.length && param[0] instanceof M_GroupTask) {
      return this.hasProducts(param) && param.every(g => g.products.every(p => this.recambiosCanInvoice(p, comp)));
    }
    else if (param instanceof M_Invoice) {
      return this.hasProducts(param) && param.breakdown.products.every(p => this.recambiosCanInvoice(p, comp));
    }
    else if (param instanceof M_Albaran) {
      return this.hasProducts(param) && param.products.every(p => this.recambiosCanInvoice(p, comp));
    }
    return false;
  }

  private recambiosCanInvoice(p: M_Product | M_CustomProduct, comp: IProductLineTableComponent) {
    if (p.instanceofCustom()) { return true; }
    if (this.verbose) {
      console.log("==============================");
      console.log("📦 Can invoice", p.name + "?");
      console.log("➡️ Control stock : ", p.control_stock, "✅");
      if (p.control_stock) {
        console.log("🏠 Selected location : ", (p.selectedLocation ? p.selectedLocation.name + "✅" : "??❌"));
        console.log("🏠 Selected location SCREEN disp. : ", getStock(p, comp, comp.getClient(), this.companyS.recambiosModule) + (this.isStockOk(p, comp) ? '✅' : '❌'))
        console.log("❗🛒 Pending quantity : ", (p.productsQuants ? p.productsQuants.quant_pend + (this.isQuantPendOk(p) ? "✅" : '❌') : "??❌"));
      }
      console.log("==============================");
    }
    if (!p.control_stock) { return true; }
    return p.control_stock && this.isStockOk(p, comp) && this.isQuantPendOk(p);
  }

  /** ==================================================== */
  /** ==============  MISSING STOCK STUFFF =============== */
  /** ==================================================== */

  /** Only used without the recambios module  */
  missingStock(param: M_Action | M_GroupTask[] | M_Invoice | M_Albaran | undefined, comp: IProductLineTableComponent): boolean {
    if (this.companyS.recambiosModule) {
      return false;
    }
    else if (param == undefined) {
      return true;
    }
    else if (Array.isArray(param) && param.length && param[0] instanceof M_GroupTask) {
      return this.hasProducts(param) && param.some(g => g.products.some(p => this.isMissingStock(p, comp)));
    }
    else if (param instanceof M_Invoice) {
      return this.hasProducts(param) && param.breakdown.products.some(p => this.isMissingStock(p, comp));
    }
    else if (param instanceof M_Albaran) {
      return this.hasProducts(param) && param.products.some(p => this.isMissingStock(p, comp));
    }
    return true;
  }

  private isMissingStock(p: M_Product | M_CustomProduct, comp: IProductLineTableComponent) {
    if (p.instanceofCustom()) { return false; }
    if (this.verbose) {
      console.log("==============================");
      console.log("📦 Is stock OK on ", p.name + "?");
      console.log("🛒 Stock OK : ", this.isStockOk(p, comp) ? "✅" : '❌');
      console.log("==============================");
    }
    return !this.isStockOk(p, comp)
  }

  /** ==================================================== */
  /** =================== HAS PRODUCT ==================== */
  /** ==================================================== */

  hasProducts(param: M_Action | M_GroupTask[] | M_Invoice | M_Albaran | undefined): boolean {
    if (Array.isArray(param) && param.length && param[0] instanceof M_GroupTask) {
      return param.length != 0 && param.every(g => g.products.length != 0)
    }
    else if (param instanceof M_Invoice) {
      return param.breakdown.products.length != 0 || param.breakdown.customs.length != 0;
    }
    else if (param instanceof M_Albaran) {
      return param.products.length != 0;
    }
    return false;
  }

  /** ==================================================== */

  private isStockOk(p: M_Product, comp: IProductLineTableComponent) {
    return getStock(p, comp, comp.getClient(), this.companyS.recambiosModule) >= 0;
  }

  private isQuantPendOk(p: M_Product) {
    return p.productsQuants == undefined || (p.productsQuants && p.productsQuants.quant_pend <= 0);
  }

  get canInvoiceTooltip() {
    return "⛔ No puedes facturar sin productos disponibles o con productos pendientes."
  }

  get missingStockTooltip() {
    return "¡Cuidado! Estas a punto de facturar sin stock"
  }

}