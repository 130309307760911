<app-page-structure [pageTitle]="'Entrada de almacén'" [autocompleteID]="'storehouse-entry'"
    searchLabel="Buscar entrada" createButtonClass="onboarding-company-storehouse-create"
    listTitleText="Listado de entradas"
    [addNewView]="v.createStoreHouseEntry" [data]="[]"
    [displayedHeader]=" ['Número',      'Tipo',             'Proveedor',        'Nº Documento',     'Productos',    'Total',    'Fecha Documento']"
    [displayedColumns]="['number',      'entranceTypeName', 'provider.name',    'dnote_prov_num',   'prodLen',      'total',    'dnote_prov_date']"
    [specialText]="     [undefined,     undefined,          getProvName,        undefined,           undefined,     'money',    undefined]"
    [specialClass]="    [undefined,     undefined,          undefined,          undefined,           undefined,     undefined,  undefined]"
    [preIcon]="         [entranceTypeIcon,undefined,        undefined,          undefined,           undefined,     undefined,  undefined]"
    [card]="card" [filter]="f" [filters]="filters" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Hacer entrada' , icon : 'archive'}"
    [quickFilter]="quickFilter">

    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/zxgyuwqy.json" trigger="in"
            colors="primary:#121131,secondary:#faddd1,tertiary:{{primary()}},quaternary:{{warn()}}" class="lord-small">
        </lord-icon>
    </ng-template>

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <app-card-title> {{ps.selected!.number}}</app-card-title>

            <button mat-stroked-button (click)="showPreview(ps.selected!)">
                <mat-icon>find_in_page</mat-icon>
                Entrada de almacén
            </button>


            <app-card-subtitle>Tipo de entrada</app-card-subtitle>
            <p>
                <mat-icon class="vam">{{ps.selected!.entranceTypeIcon}}</mat-icon>
                {{ps.selected!.entranceTypeName}}
            </p>

            <ng-container *ngIf="ps!.selected!.provider">
                <app-card-subtitle>Proveedor</app-card-subtitle>
                <app-client-row [item]="ps!.selected!.provider"></app-client-row>
            </ng-container>

            <app-card-subtitle>Creación</app-card-subtitle>
            <p class="nmb">
                <mat-icon class="vam">calendar_month</mat-icon>
                {{ps.selected!.created_at!.shortFormat()}}
            </p>

        </app-card>
    </ng-template>

</app-page-structure>