<app-page-structure [pageTitle]="'Citas'" [masterClass]="'appointment'" [autocompleteID]="'appointments'"
    searchLabel="Buscar cita" listTitleText="Listado de citas" createButtonClass="onboarding-appointments-create"
    [addNewView]="v.createappointment" [data]="[]"
    [displayedHeader]=" ['Número','Fecha','Nombre','Matrícula','Estado', 'Origen']"
    [displayedColumns]="['id_appointment','date_appointment','name_client','license','state', 'origin']"
    [dateFormat]="[undefined, 'show-hour', undefined, undefined,undefined,undefined]" [filters]="filters" [filter]="f"
    [showPagination]="true" [cellZize]="[undefined, 'big', undefined, undefined, 'big', 'small']"
    [specialText]="[undefined]"
    [preIcon]="[appointmentIcon, undefined, undefined, undefined, undefined, appointmentOrigin]"
    [specialClass]="[undefined]" [card]="card" [rightMenu]="rightMenu" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()" [createButton]="{text : 'Crear cita' , icon : 'event'}"
    (onclickRow)="paramsS.go(v.editAppointment, $event.id)"
    [quickFilter]="quickFilter">

    <ng-template #rightMenu>
        <app-skeleton class="mla" *ngIf="!comp?.token" [width]="176" [height]="36"></app-skeleton>
        <button mat-menu-item class="mla" *ngIf="comp?.token" (click)="showAppointments()">
            <mat-icon>desktop_windows</mat-icon>
            Proyectar citas
        </button>
    </ng-template>

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <div>
                <div class="df fdc">
                    <div class="first-appo-details df jcsb aic jcfsonphone">
                        <lib-appointment-status-label class="top-card-status" [menuIconOpen]="true"
                            [status]="ps.selected!.status" [pointer]="true"
                            [mat-menu-trigger-for]="status"></lib-appointment-status-label>
                        <div class="df" style="gap: 10px;">
                            <mat-icon [matTooltipPosition]="'above'"
                                [matTooltip]="ps.selected!.origin == 'Web' ? 'Creada por el cliente' : 'Creada en Eina'">{{appointmentOrigin(ps.selected!)}}</mat-icon>
                            <mat-icon [matTooltipPosition]="'above'"
                                [matTooltip]="ps.selected!.isClient ? 'Cliente registrado' : 'Cliente no registrado'">{{appointmentIcon(ps.selected!)}}</mat-icon>
                            <mat-icon (click)="goOr(ps.selected!.or.id)" class="or_icon_red"
                                [matTooltipPosition]="'above'" *ngIf="ps.selected!.or"
                                [matTooltip]="'Ver OR'">build</mat-icon>
                        </div>
                        <mat-menu #status="matMenu">
                            <button *ngIf="!ps.selected!.isCanceled" mat-menu-item class="chip-option"
                                (click)="descartarDialogo(ps.selected!)">
                                <lib-appointment-status-label [pointer]="true"
                                    [status]="appo_status.canceled"></lib-appointment-status-label>
                            </button>
                            <button *ngIf="!ps.selected!.isPending" mat-menu-item class="chip-option"
                                (click)="pendingDialog(ps.selected!)">
                                <lib-appointment-status-label [pointer]="true"
                                    [status]="appo_status.pending"></lib-appointment-status-label>
                            </button>
                            <button *ngIf="!ps.selected!.isDone" mat-menu-item class="chip-option"
                                (click)="marcarHechaDialogo(ps.selected!)">
                                <lib-appointment-status-label [pointer]="true"
                                    [status]="appo_status.done"></lib-appointment-status-label>
                            </button>
                        </mat-menu>
                    </div>

                    <button mat-flat-button color="warn" class="w100" style="margin-bottom: 10px;"
                        [go]="v.editAppointment" [param]="{appointment : ps.selected!.id}">
                        <mat-icon>event</mat-icon>
                        {{ps.selected!.id_appointment}}
                        <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                    </button>
                    <div [matTooltipPosition]="'left'"
                        [matTooltip]="!ps.selected!.isEditable ? 'Solo puedes modificar la fecha de las citas pendientes' : ''">
                        <button [disabled]="!ps.selected!.isEditable" mat-stroked-button class="w100"
                            style="margin-bottom: 10px;" (click)="onChangeHour(ps.selected!)">
                            <mat-icon>event</mat-icon>
                            Modificar fecha
                        </button>
                    </div>
                    <div [matTooltipPosition]="'left'"
                        [matTooltip]="!ps.selected!.canRA ? 'La cita esta cancelada' : ''">
                        <button class="w100" mat-stroked-button (click)="toOr(ps.selected!)" color="primary"
                            [disabled]="!ps.selected!.canRA">
                            <mat-icon>build</mat-icon>
                            {{ps.selected!.or ? 'Generar otra OR' : 'Pasar a OR'}}
                        </button>
                    </div>
                    <app-card-subtitle>Fecha</app-card-subtitle>
                    <app-appointment-row [item]="ps.selected!"></app-appointment-row>
                    <app-client-side-details-appointment [appointment]="ps.selected!"
                        [first]="false"></app-client-side-details-appointment>
                    <app-contact-buttons>
                        <app-whatsapp
                            [phone]="ps.selected!.client?.phone ? ps.selected!.client?.phone : ps.selected!.clientphone"></app-whatsapp>
                        <app-email-button
                            [email]="ps.selected!.client?.email ? ps.selected!.client?.email : ps.selected!.email"></app-email-button>
                        <app-phone-button
                            [phone]="ps.selected!.client?.phone ? ps.selected!.client?.phone : ps.selected!.clientphone"></app-phone-button>
                    </app-contact-buttons>
                </div>
            </div>
            <ng-template #no_data></ng-template>
        </app-card>
    </ng-template>
</app-page-structure>