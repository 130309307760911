import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-async-validator-spinner',
  template: `
    <div [ngClass]="{'vh': !loading}">
      <mat-spinner [strokeWidth]="6" [diameter]="25" mode="indeterminate" style="margin-right: 5px;"></mat-spinner>
    </div>
  `,
  styles: [`
    .vh {
      visibility: hidden;
    }
  `]
})
export class AsyncValidatorSpinnerComponent {
  @Input({ required: true }) control!: AbstractControl | null;
  loading = false;
  private statusChangesSub?: Subscription;

  constructor() { }

  ngOnInit() {
    if (this.control) {
      this.statusChangesSub = this.control.statusChanges.subscribe(status => {
        console.log('Control status:', status);
        this.loading = (status === 'PENDING');
      });
    }
  }

  ngOnDestroy() {
    if (this.statusChangesSub) {
      this.statusChangesSub.unsubscribe();
    }
  }
}
