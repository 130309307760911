<app-card [contentLoaded]="contentLoaded" [noStyle]="isOnDialog">
    <app-card-title>{{title ? title : 'Propietario del vehículo'}}</app-card-title>

    <div class="df jcse onboarding-vehicle-owner" style="justify-content: space-evenly;">
        <app-card-subtitle>
            <div class="df aic">
                <p>
                    <mat-icon class="vam">person_search</mat-icon>
                    <mat-checkbox class="bold-checkbox ml5 mr5" [checked]="true" #ch1 (change)="searchCheckBox($event)">
                        <span>Buscar {{responsiveS.w > 447 ? 'cliente' : ''}}</span>
                    </mat-checkbox>

                </p>

            </div>
        </app-card-subtitle>

        <app-card-subtitle>
            <div class="df aic">
                <p>
                    <mat-icon class="vam">person_add</mat-icon>
                    <mat-checkbox class="bold-checkbox ml5 mr5" #ch2 (change)="createCheckBox($event)">
                        <span>Crear {{responsiveS.w > 447 ? 'cliente' : ''}}</span>
                    </mat-checkbox>
                </p>

            </div>
        </app-card-subtitle>
    </div>


    <div [ngClass]="{'dn' : !ch1.checked}">
        <app-client-searcher-header [classSearcher]="aacs"></app-client-searcher-header>
        <app-class-searcher #aacs (onSelect)="onSelect($event)" [masterClass]="client"
            [selectedText]="isLinkVehicle ? '' : 'Cliente seleccionado para asociar el nuevo vehículo:'"
            [disableIf]="disableCurrentOwner.bind(this)" [required]="true" [searchPlaceHolder]="'Buscar cliente'">
        </app-class-searcher>
        <div *ngIf="cSearched != undefined" class="df fww">
            <div *ngFor="let c of cSearched">
                <div #res class="searchResult" [ngClass]="{'sel': c == cSelected}" (click)="selectClient(c)" mat-ripple>
                    <lib-icon-text [icon]="'person'">{{c.getName()}}</lib-icon-text>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'dn' : !ch2.checked}">
        <app-clientform
            (onClientExists)="aacs.setMasterObject($event); ch2.checked = false; ch1.checked = true;" [canSelectClient]="true"></app-clientform>
    </div>

    <ng-content></ng-content>

</app-card>