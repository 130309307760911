<form [formGroup]="form">
    <div class="form">
        <div class="df w33p fww">
            <!-- VEHICLE OR -->
            <app-class-searcher *ngIf="(!isEdit && !appointment) || (!isEdit && appointment && appointment.isClient) " class="onboarding-or-select-vehicle w33" #vehicleSearcher
                [masterClass]="vehicle_minify" searchPlaceHolder="Buscar vehículo o propietario"
                [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'" [specialRow]="'Nuevo vehículo'"
                (onSpecialRow)="createVehicleInDialog()"
                (onRemove)="onVehicle.emit(undefined)"
                (onLoad)="onLoad.emit()"
                (onSelect)="clientInvoice.removeSelectedValue(); clientInvoice.setMasterById($event.clientInvoice?.client_id); form.get('km')?.markAsTouched(); onVehicle.emit($event)">
            </app-class-searcher>
            <!--KM-->
            <mat-form-field *ngIf="!isEdit" [ngClass]="isEdit ? 'dn_i' : appointment? appointment.isClient? 'w33': 'w50' : 'w33'" appearance="outline">
                <mat-label>{{this.vehicleSearcher?.selected?.km ? 'Última revisión '+ this.vehicleSearcher?.selected?.km
                    +'km' : 'Kilómetros del vehículo'}}</mat-label>
                <input type="number" matInput placeholder="Kilómetros del vehículo" formControlName="km">
                <mat-error>{{kmError}}</mat-error>
            </mat-form-field>
            <!-- WORKER-->
            <app-class-searcher [ngClass]="isEdit ? 'w100' : appointment? appointment.isClient? 'w33': 'w50' : 'w33'" #workerSearcher [masterClass]="user"
                searchPlaceHolder="Asignar operario" [noSelectionPlaceHolder]="'Ningún operario seleccionado'"
                [required]="false" (onSelect)="assignUser($event)" (onRemove)="assignUser($event)"
                (onLoad)="assignMyself()"
                [canRemove]="userS.userIsAdminOrAdviser">
            </app-class-searcher>

        </div>

        <div class="df fww w33p" [ngClass]="isEdit ? '' : 'w33f'">

            <!-- INVOICE TO -->
            <app-class-searcher [ngStyle]="{'display': 'none'}" #clientInvoice
                class="onboarding-or-select-invoice-to w50" [masterClass]="client"
                [searchPlaceHolder]="clientInvoicePlaceholder" [required]="false" [specialRow]="'Nuevo cliente'"
                (onSpecialRow)="routerS.goTo(v.createContact)" [hint]="vehicleSearcher?.selected && clientInvoice.selected ?
                 vehicleSearcher?.selected?.clientInvoice?.client_id == clientInvoice.selected?.client_id ? 
                {hint :'Cliente a facturar por defecto', class : 'c_g'} :
                {hint :'No es el cliente a facturar por defecto', class : 'c_r'} : 
                'Sin selección, se usará el cliente de la OR.'">
            </app-class-searcher>


        </div>

        <div class="df w33p fww">

            <!-- SCHEDULE -->
            <mat-form-field [ngClass]="isEdit ? 'w50' : 'w33'" appearance="outline">
                <mat-label>Realización</mat-label>
                <input matInput placeholder="dd/mm/aaaa" [matDatepicker]="picker_1"
                    [matDatepickerFilter]="cs.maxTodayAndSunday" (click)="picker_1.open()" formControlName="schedule">
                <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                <mat-datepicker #picker_1 [dateClass]="getDaysClass.bind(this)"></mat-datepicker>
            </mat-form-field>

            <!-- DELIVERY -->
            <mat-form-field [ngClass]="isEdit ? 'w50' : 'w33'" appearance="outline">
                <mat-label>Entrega</mat-label>
                <input placeholder="dd/mm/aaaa" [min]="minValueDelivery" matInput [matDatepicker]="picker_2"
                    [matDatepickerFilter]="cs.sundayFilter" (click)="picker_2.open()" formControlName="delivery">
                <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                <mat-datepicker #picker_2></mat-datepicker>
            </mat-form-field>

            <!-- COMBUSTIBLE -->
            <mat-form-field *ngIf="!isEdit" class="w33" appearance="outline">
                <mat-label>Combustible</mat-label>
                <mat-select [value]="1" formControlName="fuel" (selectionChange)="fuelChange($event)">
                    <mat-option [value]="0">Vacío</mat-option>
                    <mat-option [value]="1">1/4</mat-option>
                    <mat-option [value]="2">2/4</mat-option>
                    <mat-option [value]="3">3/4</mat-option>
                    <mat-option [value]="4">Lleno</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>