import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { endpointResponse } from '../components/types/endpoint-response';
import { CustomLog } from '../custom-classes/CustomLog';

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {
  
  /** Endpoints */
  onEndpointResponse: Subject<endpointResponse> = new Subject();
  onClearEndpoints: Subject<any> = new Subject();
  
  /** Errors */
  onError: Subject<CustomLog> = new Subject();
  onClearError: Subject<any> = new Subject();

  constructor() { }
  
}
