<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>{{isEdit? 'Editar producto personalizado' : 'Añadir producto personalizado'}}</p>
<div mat-dialog-content>
    <form [formGroup]="form">
        <app-card [noStyle]="true">
            <app-card-subtitle [first]="true">Datos obligatorios</app-card-subtitle>
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre" formControlName="name">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>PVP</mat-label>
                <input type="number" matInput placeholder="PVP" formControlName="price">
                <mat-error
                    *ngIf="this.form.get('price')?.hasError('required') || this.form.get('price')?.hasError('min')">
                    El valor introducido no es correcto
                </mat-error>
                <mat-error *ngIf="this.form.get('price')?.hasError('max')">Número demasiado grande</mat-error>
            </mat-form-field>
            <div *ngIf="data.interno" [ngTemplateOutlet]="buy_price_template"></div>
            <mat-form-field  appearance="outline">
                <mat-label>IVA</mat-label>
                <mat-select formControlName="tax" [value]="21">
                    <mat-option [value]="0">Sin IVA</mat-option>
                    <mat-option [value]="4">4%</mat-option>
                    <mat-option [value]="10">10%</mat-option>
                    <mat-option [value]="21">21%</mat-option>
                </mat-select>
            </mat-form-field>
            <app-card-subtitle [first]="true">Datos opcionales</app-card-subtitle>
            <mat-form-field appearance="outline">
                <mat-label>Referencia</mat-label>
                <input matInput placeholder="Referencia" formControlName="reference">
            </mat-form-field>
            <ng-container *ngIf="!data.interno" [ngTemplateOutlet]="buy_price_template"></ng-container>
            <mat-form-field appearance="outline">
                <mat-label>Cantidad</mat-label>
                <input type="number" matInput placeholder="Cantidad" formControlName="quantity">
                <mat-error *ngIf="this.form.get('quantity')?.hasError('min')">El valor debe ser mayor o igual que
                    0</mat-error>
                <mat-error *ngIf="this.form.get('buy_price')?.hasError('max')">Número demasiado grande</mat-error>
            </mat-form-field>
        </app-card>
    </form>
</div>
<div mat-dialog-actions align="center">
    <button [mat-dialog-close]="false" mat-button>Cancelar</button>
    <button color="warn" mat-flat-button (click)="create()" [disabled]="!form.valid">{{isEdit ? 'Editar' : 'Añadir'}}</button>
</div>



<ng-template #buy_price_template>
    <mat-form-field appearance="outline" [formGroup]="form">
        <mat-label>Coste</mat-label>
        <input type="number" matInput placeholder="Coste" formControlName="buy_price">
        <mat-error *ngIf="this.form.get('buy_price')?.hasError('min')">El valor debe ser mayor o igual que
            0</mat-error>
        <mat-error *ngIf="this.form.get('buy_price')?.hasError('max')">Número demasiado grande</mat-error>
    </mat-form-field>
</ng-template>