<div class="linkParent">

    <app-go-back [show]="ve != undefined" [text]="'Volver atrás'" (click)="returnClient()"></app-go-back>

    <app-view-title>Compra venta del vehículo</app-view-title>
    <div class="mt20">
        <app-card [contentLoaded]="ve != undefined">
            <app-card-title>Propietario actual</app-card-title>
            <div *ngIf="ve != undefined">
                <div class="df fww jcsa">
                    <app-client-info [client]="ve.client" [small]="true"></app-client-info>
                    <app-vehicle-info [vehicle]="ve"></app-vehicle-info>
                </div>
            </div>
            <app-card-title>Precio compra</app-card-title>
            <form class="form" style="max-width: unset;" [formGroup]="form">
                <mat-form-field class="w50" appearance="outline">
                    <mat-label>Precio</mat-label>
                    <input type="number" min="0" matInput formControlName="price">
                </mat-form-field> 
                <mat-form-field appearance="outline" class="w50">
                    <mat-label>Fecha de Compra</mat-label>
                    <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate"
                        (click)="picker_3.open()" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
                    <mat-datepicker #picker_3></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w50" appearance="outline">
                    <mat-label>IVA</mat-label>
                    <input #input type="number" matInput placeholder="IVA." formControlName="tax" type="number"
                        DecimalInput>
                    <mat-icon matSuffix>percent</mat-icon>
                    <mat-error *ngIf="form.get('tax')?.touched && form.get('tax')?.errors">Valor máx.
                        :
                        21</mat-error>
                </mat-form-field>
                <div *ngIf="ve?.client?.type == 2 || ve?.client?.type == 3" class="pdf-div">
                    <!-- <lib-file-upload [phoneMultipleUpload]="true" #documentation class="hidde_add"
                        [uploadtype]="'drag'" [maxImagesLength]="5" [maxSize]="6" [acceptedTypes]="[ft.document]"
                        (stateChanged)="onFileUpload($event)">
                    </lib-file-upload> -->
                    <app-card-title>Adjuntar Documentación de Compra</app-card-title>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Número de Factura</mat-label>
                        <input type="text" min="0" matInput formControlName="docum_prov_num">
                    </mat-form-field>
                    <app-pdf-documents-upload></app-pdf-documents-upload>
                </div>
            </form>
        </app-card>
    </div>
    <div class="df jcc mb20 mt20">
        <lord-icon class="rotate90 lord-small" src="https://cdn.lordicon.com/pvcozflw.json" trigger="loop" delay="3000"
            colors="primary:#121331,secondary:{{warn()}},tertiary:{{primary()}}">
        </lord-icon>
    </div>
    <div class="mb20">
        <app-card>
            <app-card-title>Tu empresa</app-card-title>
            <div class="df jcc aic">
                <lord-icon src="https://cdn.lordicon.com/yphfrmut.json" trigger="in" trigger="loop" delay="2000"
                    colors="primary:#121331,secondary:{{primary()}},tertiary:#ebe6ef,quaternary:#646e78,quinary:{{warn()}},senary:#3a3347"
                    class="lord-large ">
                </lord-icon>
            </div>
            <div class=" df jcc aic" [matTooltip]="haveLocation ? '' : 'El cliente no tiene ubicación'"
                matTooltipPosition="above">
                <button [disabled]="!haveLocation" mat-flat-button color="warn" style="margin-top: 10px"
                    (click)="showTemplateFields()">
                    <span>Comprar vehículo</span>
                </button>
            </div>
        </app-card>

    </div>

</div>