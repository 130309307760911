export class CustomEnum {
    num : number = -1;
    name : string = "Sin tipo";
    constructor(all : any[], type : string | number) {
        all.map(t => {
            try {
                if (typeof type == "number") {
                    if (t[0] == type){
                        this.num = t[0];
                        this.name = t[1];
                    }
                }
                else{
                    if (t[1] == type){
                        this.num = t[0];
                        this.name = t[1];
                    }
                }
            }
            catch(e : any){}
        })
    }
}