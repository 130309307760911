import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ctrl-space-shortcut',
  templateUrl: './ctrl-space-shortcut.component.html',
})
export class CtrlSpaceShortcutComponent {

  @Input({ required: true }) text!: string;
  @Input() combination: string = "Ctrl+Espacio";

  isMobileOrTablet(): boolean {
    const width = window.innerWidth;
    return width <= 768;
  }
}
