<video playsinline loop autoplay [muted]="true" id="loginVideo">
  <source src="./assets/video/login_back.mp4" type="video/mp4">
</video>

<div class="login-gradient"></div>

<div class="df jcc parent">
  <div class="container mt20">
    <app-card class="login-card">
      <div class="df jcc mb20"><img class="mtb-20 eina-logo" src="../assets/img/logos/eina_logo_black.png"></div>
      <mat-stepper (selectionChange)="stepChanged(stepper)" class="hidden-stepper-header">
        <!--Login-->
        <mat-step>
          <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="makeCall()" class="single-rows">
              <app-card-title class="tal">Iniciar sesión</app-card-title>
              <app-email-input class_='input-full-width' [form]="loginForm" formCName="email"></app-email-input>
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input #passwRef type="password" matInput placeholder="Contraseña" formControlName="password">
                <button type="button" (click)="passwRef.type=='text'? passwRef.type='password' : passwRef.type='text'"
                  mat-icon-button matSuffix>
                  <mat-icon *ngIf="passwRef.type == 'password'">visibility</mat-icon>
                  <mat-icon *ngIf="passwRef.type == 'text'">visibility_off</mat-icon>
                </button>
              </mat-form-field>

              <p (click)="goForgetPassword()" class="mt20 link_u_h">¿Has olvidado la contraseña?</p>

              <div class="mb20 mt20">
                <button mat-flat-button class="w100" color="primary">Iniciar sesión</button>
              </div>

              <p class="mt20" style="margin-bottom: 0px;">
                <span class="c_t2">¿Aún no tienes cuenta?</span>
                <span (click)="goRegister()" class="link_u_h ml5">Registrarse</span>
              </p>

            </form>
          </mat-card-content>
        </mat-step>

        <!--Recuperacion de password-->
        <mat-step>

          <div style="margin-bottom:20px">
            <button color="primary" style="display: flex;" mat-button (click)="goLogin()">
              <mat-icon>arrow_back</mat-icon>
              Iniciar sesión
            </button>
          </div>

          <p class="large fw600 tas">Recuperar contraseña</p>
          <p class="medium">A continuación, introduce tu correo electrónico</p>

          <mat-card-content>
            <form [formGroup]="forgotPassword" (ngSubmit)="sendRecoverPassword()" class="single-rows">
              <app-email-input #ei class_='input-full-width mb20' [form]="forgotPassword"
                formCName="email"></app-email-input>
            </form>

            <div class="df jcc">
              <app-layout-send-email [disabled]="!forgotPassword.valid" [endpoint]="sendRecoverPassword.bind(this)"
                [temaplte]="'forgot'"></app-layout-send-email>
            </div>


          </mat-card-content>
        </mat-step>
      </mat-stepper>
    </app-card>
  </div>
</div>