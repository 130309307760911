<app-page-structure #normalBudget [masterClass]="'budget'" 
    [pageTitle]="'Presupuestos'" 
    [autocompleteID]="'budget'"
    listTitleText="Listado de presupuestos"
    searchLabel="Buscar presupuesto" createButtonClass="onboarding-create-budget"
    [addNewView]="v.createEditBudget" noDataCreateNew="Crear nuevo presupuesto" [data]="[]"
    [displayedHeader]="['Número',          'Cliente',      'Fecha creación',     'Total',          'Estado', 'Tipo'] "
    [displayedColumns]="['title_id',          'client',       'created_at',         'total',          'status','type']"
    [specialText]="[undefined,     client,         undefined,            'money',           undefined,      undefined]"
    [specialClass]="[undefined,     undefined,      undefined,            undefined,       undefined,    undefined]"
    [preIcon]="!this.companyS.recambiosModule ?   
    [undefined,     undefined,      undefined,            undefined,       undefined,    undefined]:
    [preicon,     undefined,      undefined,            undefined,       undefined ,     undefined,      undefined]"
    [card]="card" [filters]="filters" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear presupuesto' , icon : 'calculate'}"
    [quickFilter]="quickFilter">
    
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <button [matTooltip]="'Ir al presupuesto'" mat-flat-button color="warn" [go]="v.createEditBudget" [param]="ps.selected!.id">
                <mat-icon>calculate</mat-icon>
                Presupuesto Nº {{ps.selected!.title_id}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>
            <button *ngIf="ps.selected!.groups.length" mat-stroked-button style="margin-top: 10px;"
                (click)="previsualize(ps.selected!)">
                <mat-icon>find_in_page</mat-icon>
                Vista previa
            </button>

            <app-card-subtitle>Cliente y vehículo</app-card-subtitle>
            <app-vehicle-row [item]="ps.selected!.vehicle!"></app-vehicle-row>
            <app-client-row [item]="ps.selected!.client!"></app-client-row>

            <app-contact-buttons>
                <app-whatsapp [phone]="ps.selected!.client?.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.client?.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.client?.phone"></app-phone-button>
            </app-contact-buttons>

            <div *ngIf="ps.selected?.reservations?.length && companyS.recambiosModule" class="card-reserves">
                <app-card-subtitle>Reservas</app-card-subtitle>
                <div class="card-reserve">
                    <app-info-reserve-orders [action]="ps.selected!"
                        [reservations]="ps.selected?.reservations ?? []"
                        [showLastCircle]="false"></app-info-reserve-orders>
                </div>
            </div>

            <div>
                <app-layout-send-email
                    *ngIf="ps.selected && ps.selected.client && !ps.selected.client.deleted"
                    [temaplte]="'budget'" [endpoint]="reSendBudget.bind(this)"
                    [disabled]="ps.selected.client.email == undefined"></app-layout-send-email>
            </div>

        </app-card>
    </ng-template>
</app-page-structure>