<app-card [noStyle]="true">
    <div class="df aic" *ngIf="filter.showLabel">
        <app-card-subtitle>{{filter.label}}</app-card-subtitle>
    </div>
    <div class="df aic">
        <mat-checkbox [(ngModel)]="filter.checked" [indeterminate]="filter.checked == null">
            {{filter.checkBoxName ? filter.checkBoxName : filter.label }}
        </mat-checkbox>
        <span (click)="clear()" class="clear-text"
            [ngClass]="filter.checked == null ? 'indeterminate' : 'some-value underline_hover'">Quitar valor</span>
    </div>
</app-card>