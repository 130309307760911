import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentStatus } from '../../enums/AppointmentStatus';
import { IAppointment } from 'src/app/interfaces/IAppointment';

/**
 * Workload DAY card for appointemnts
 */
@Component({
  selector: 'lib-appointment-day-card',
  templateUrl: './appointment-day-card.component.html',
  styleUrls: ['./appointment-day-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-day.css']
})
export class AppointmentDayCardComponent implements OnInit {

  app_status = AppointmentStatus;

  @Input() item!: IAppointment;
  @Output() onModifyAppointment: EventEmitter<[IAppointment, ("ra" | "eliminar" | "done" | "pending" | "hour")]> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
