import { Sort } from '@angular/material/sort';
import { or_status } from '../custom-classes/or_states';
import { invoice_type } from '../custom-classes/invoice_types';

/**
 * Generate a random number bewteen min and max (min and max included). 
 * @param min Min value
 * @param max Max value
 * @returns Random number
 */
export function randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Returns the first not undefined value of passed `data`
 * @param data any
 * @returns undefined || the firs value that's not undefined
 */
export function oneOf(...data: any): any {
    var value = undefined;
    data.forEach((v: any) => {
        if (v != undefined) {
            value = v;
        }
    })
    return value;
}

/** When a mat table sort change
 *  Automatically sort the passed data
 */
export function sortTable(tableData: any[], sort: Sort) {

    const CHECKATTRIBUTES = ['name', 'brand'];

    if (sort.direction != undefined) {
        tableData = tableData.sort((a, b) => {

            let element: any = a[sort.active];
            let element2: any = b[sort.active];

            let dir = sort.direction == "asc" ? 1 : -1;

            /** Get the specific element attribute*/
            if (sort.active.includes('.')) {
                element = a[sort.active.split('.')[0]][sort.active.split('.')[1]]
                element2 = b[sort.active.split('.')[0]][sort.active.split('.')[1]]
            }

            if (element == undefined) {
                return 1 * dir;
            }

            if (element2 == undefined) {
                return 1 * dir;
            }

            /** Comparing or status */
            if (element instanceof or_status && element2 instanceof or_status) {
                return element.name.localeCompare(element2.name) * dir;
            }

            if (element[0] && element[0] instanceof invoice_type && element2[0] && element2[0] instanceof invoice_type) {
                return element[0].name.localeCompare(element2[0].name) * dir;
            }

            /** If the element is a string */
            if (typeof element == "string") {
                return element.localeCompare(element2) * dir;
            }

            /** If the element is a number */
            if (typeof element == "number") {
                return element > element2 ? -1 * dir : 1 * dir;
            }

            /** Comparing dates */
            if (element instanceof Date && element2 instanceof Date) {
                return element > element2 ? -1 * dir : 1 * dir;
            }


            /***/
            /** Checks if the element has some of the 'CHECKATTRIBUTES' attributes */
            /***/
            for (let i = 0; i < CHECKATTRIBUTES.length; i++) {

                var attribute = CHECKATTRIBUTES[i];
                var element_1 = element[attribute];
                var element_2 = element2[attribute];

                if (element_1 == undefined && element_2 == undefined) { return 0; }
                else if (element_1 == undefined) { return 1 * dir; }
                else if (element_2 == undefined) { return -1 * dir; }
                else {
                    try {
                        return element_1.toString().localeCompare(element_2.toString()) * dir;
                    }
                    catch (e: any) {
                        return 0;
                    }
                }
            }

            return 0;
        }
        );
    }
}

/** Foreach object on `data`, creates a new object of `class_`.
 * @returns Array of `class_`
 */
export function getArrayOf(class_: any, data: any): typeof class_[] {
    try {
        if (data && Array.isArray(data)) {
            var array: typeof class_[] = []
            for (let i = 0; i < data.length; i++) {
                array.push(new class_(data[i]));
            }
            return array;
        }
    }
    catch (e: any) {
        console.log("Error creating the array")
        console.log(e)
    }
    return [];
}

/** Downloads a blob file.
 * @param downloadName The file name
*/
export function downloadBlob(blob: Blob, downloadName: string) {
    var url = URL.createObjectURL(blob)
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    /** Download name */
    link.download = downloadName;
    document.body.append(link);
    link.click();
    link.remove();
}


export function getPrimaryColor(): string {
    const computedStyle = window.getComputedStyle(document.documentElement);
    var pValue = computedStyle.getPropertyValue('--p').trim();
    return pValue;
}

export function getWarnColor(): string {
    const computedStyle = window.getComputedStyle(document.documentElement);
    var pValue = computedStyle.getPropertyValue('--r').trim();
    return pValue;
}

export function getAccentColor(): string {
    const computedStyle = window.getComputedStyle(document.documentElement);
    var pValue = computedStyle.getPropertyValue('--a').trim();
    return pValue;
}

/**
 * Constructs the "data" object, which is passed in the views of the "app-routing.module.ts"
 * @param roles Role or roles that can access the view
 * @param modules Modules that need to be activated to access the view
 * @returns { data: { roles: number[], modules: number[] }
 */
export function permissionsViewData(roles?: number | number[], modules?: number | number[]): { roles: number[], modules: number[] } {
    let finalRoleArray: number[] = [];
    let finalModuleArray: number[] = [];
    if (roles != undefined) {
        if (typeof roles == "number") { finalRoleArray.push(roles); }
        else { finalRoleArray = roles; }
    }
    if (modules != undefined) {
        if (typeof modules == "number") { finalModuleArray.push(modules); }
        else { finalModuleArray = modules; }
    }
    return { roles: finalRoleArray, modules: finalModuleArray }
}

export function toMoney(val: number | undefined) {
    return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(val || 0);
}
