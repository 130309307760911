import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StandardDialogComponent } from 'src/app/components/standard-dialog/standard-dialog.component';
import { ConfirmData, ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
  selector: 'app-frontend-documentation',
  templateUrl: './frontend-documentation.component.html',
  styleUrls: ['./frontend-documentation.component.css']
})
export class FrontendDocumentation {

  constructor(public d: MatDialog, public confirmD: ConfirmDialogService) { }

  getColor(color: string, index: number) {
    return "background-color :" + "var(--" + color + (index == 4 ? '' : index) + ");"
  }

  getVar(color: string, index: number) {
    return "var(--" + color + (index == 4 ? '' : index) + ")"
  }

  standarDialog() {
    this.d.open(StandardDialogComponent)
  }

  noCardDialog() {
    this.d.open(StandardDialogComponent, { panelClass: "no-card-style" })
  }

  confirmDialog(type: "confirm" | "warn" | "info" | "danger") {
    let cdd: ConfirmData = {
      title: "Título",
      body: "Cuerpo del diálogo",
      type: type
    }
    this.confirmD.show(cdd);
  }
}
