import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ConceptsLineTableComponent } from 'src/app/components/concepts-line-table/concepts-line-table.component';
import { IConceptLineTableComponent } from 'src/app/interfaces/IConceptLineTableComponent';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { RouterService } from 'src/app/services/router.service';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-vnvo-details',
  templateUrl: './vnvo-details.component.html',
  styleUrls: ['./vnvo-details.component.css']
})
export class VnvoDetailsComponent extends ParameterStateComponent implements IConceptLineTableComponent {
  
  v = ViewPath;
  loaded = false;
  ve: M_Vehicle | undefined;
  public form: UntypedFormGroup;
  @ViewChild('documentation') documentation!: FileUploadComponent;
  @ViewChild('vehicleImages') vehicleImages!: FileUploadComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;

  constructor(private chdRef: ChangeDetectorRef,routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public params: ParamsService, private fb: UntypedFormBuilder) {
    super(routerS, route, ["vnvo"]);
    this.form = this.fb.group({
      concepts: this.fb.array([])
    })
  }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
      this.chdRef.detectChanges();
      this.loaded = true;
    }
  }
  override onParam(_k: string, v: any) {
    this.apiS.vnvo.getVehicleById(v).then(res => {
      
      if (res instanceof M_Vehicle) {
        this.ve = res;
        /** Ugly redirect. --> Contemplar hacer un ÚNICO componente de vehicle details. */
        if (!res.isVnVo) {
          this.params.go(ViewPath.vnvoDetails, res.vehicle_id!, undefined, false, true);
          
        }
        this.fillImages(this.ve);
        this.formConcepts.push(...this.ve!.concepts)
      }
      this.loaded = true;
    })
  }

  get formConcepts(): M_Concept[] {
    return this.form.get('concepts')?.value;
  }
  onImgError(e: any) {
    e.srcElement.style.display = 'none'
  }

  fillImages(v: M_Vehicle) {
    this.documentation.setImagesFromDatabase(v.documentation);
    this.vehicleImages.setImagesFromDatabase(v.vehicleImages);
  }

  removeImage(c: CustomFile) {
    if (c.database_id) {
      this.apiS.deleteImage(c.database_id);
    }
  }

  uploadImages(c: CustomFile, key: "vehicle_img" | "docu_img") {
    this.apiS.uploadImage(c, key, ["client_id", this.ve!.client!.client_id!], ["vehicle_id", this.ve!.vehicle_id]).then(res => {
      if (res.image_id) {
        c.database_id = res.image_id;
      }
    })
  }
  hasDocuImages() {
    return this.documentation?.hasFiles();
  }

  hasVehicleImages() {
    console.log(this.vehicleImages?.hasFiles());
    return this.vehicleImages?.hasFiles();
  }

  addConcept(_c: M_Concept): void {
    throw new Error('Method not implemented.');
  }
  
}
