<ng-container *ngIf="show">
    <ng-container *ngIf="style == 'flat'">
        <div *ngIf="singleVehicle">
            <button mat-flat-button [color]="'primary'" [go]="v.createOr" [param]="singleVehicle.vehicle_id">
                <mat-icon>build</mat-icon>
                Abrir OR
            </button>
        </div>
        <div  *ngIf="multipleVehicles">
            <button mat-flat-button [color]="'primary'" [mat-menu-trigger-for]="openOrMenu">
                <mat-icon>build</mat-icon>
                Abrir OR
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="style == 'menu-option'">
        <div *ngIf="singleVehicle">
            <button mat-menu-item [go]="v.createOr" [param]="singleVehicle.vehicle_id">
                Abrir OR
            </button>
        </div>
        <div *ngIf="multipleVehicles">
            <button mat-menu-item [mat-menu-trigger-for]="openOrMenu">
                Abrir OR
            </button>
        </div>
    </ng-container>
</ng-container>

<!-- OPEN OR MENU-->
<mat-menu #openOrMenu="matMenu">
    <button mat-menu-item *ngFor="let veh of multipleVehicles" (click)="paramsS.go(v.createOr, veh.vehicle_id, 'vehicle')">
        {{veh.getName()}}
    </button>
</mat-menu>