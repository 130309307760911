import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { M_Contact } from 'src/app/models/M_Contact';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { ParamsService } from 'src/app/services/params.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent {

  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;

  v = ViewPath;

  @Input() client: M_Contact | undefined;
  @Input() small: boolean = false;
  @Input() somethingSelected: boolean = false;

  constructor(public paramsS: ParamsService, public userS: UserService) { }

  get clickable(){
    return this.client && !this.client.deleted && this.userS.userIsAdminOrAdviser;
  }

  goClient() {
    if (!this.client?.deleted && this.userS.userIsAdminOrAdviser) {
      this.paramsS.go(ViewPath.contactDetails, this.client!.client_id, undefined, true)
    }
  }
}
