import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { M_Contact } from '../../models/M_Contact';
import { AbstractControl, Validators } from '@angular/forms';
import { M_ShippingAddress } from '../../models/M_Address';
import { MatDialog } from '@angular/material/dialog';
import { ContanctSendingAdressSelectorDialogComponent, contactAddressSelectorData } from './contanct-sending-adress-selector-dialog/contanct-sending-adress-selector-dialog.component';
import { CreateEditSendingAdressComponent } from './create-edit-sending-adress/create-edit-sending-adress.component';

@Component({
  selector: 'app-contact-sending-address-selector',
  templateUrl: './contact-sending-address-selector.component.html',
  styleUrls: ['./contact-sending-address-selector.component.css']
})
export class ContactSendingAddressSelectorComponent implements OnInit {

  @Input({ required: true }) contact!: M_Contact | undefined;
  @Input({ required: true }) fc!: AbstractControl<number | null>;
  @Input({ required: true }) canChange: boolean = true;
  @Output() onChangedAddress: EventEmitter<number | null> = new EventEmitter();

  constructor(private d: MatDialog) { }

  get required() {
    return this.fc.hasValidator(Validators.required);
  }

  ngOnInit(): void {
    this.fc.valueChanges.subscribe(val => {
      this.onChangedAddress.emit(val);
    })
  }

  get selectedName(): string | undefined {
    let selected = this.selectedAdress;
    if (selected) { return selected.name; }
    return undefined
  }

  get fullAddress() {
    let selected = this.selectedAdress;
    if (selected) { return selected.toText(); }
    return "Sin datos"
  }

  get selectedAdress(): M_ShippingAddress | undefined {
    if (!this.contact) { return undefined; }
    return this.contact.extra_adress.find(extra => extra.id == this.fc.value);
  }

  get error() {
    return this.fc.getError('required');
  }

  createChange() {
    if (this.fc.disabled || !this.contact) { return; }
    if (this.contact.extra_adress.length == 0) {
      this.d.open<CreateEditSendingAdressComponent, M_Contact>(CreateEditSendingAdressComponent, { data: this.contact }).afterClosed().subscribe(res => {
        this.processDialogResponse(res, true);
      })
    }
    else {
      this.d.open<ContanctSendingAdressSelectorDialogComponent, contactAddressSelectorData>
        (ContanctSendingAdressSelectorDialogComponent, {
          data: {
            contact: this.contact,
            address: this.selectedAdress
          }
        }).afterClosed().subscribe(res => {
          this.processDialogResponse(res);
        })
    }
  }

  processDialogResponse(res: any, push: boolean = false) {
    if (!this.contact) { return }
    if (res instanceof M_ShippingAddress) {
      if (res.id) {
        if (push) {
          this.contact.extra_adress.push(res);
        }
        this.fc.setValue(res.id!);
      }
      else {
        console.error("Address without id")
      }
    }
  }

}
