import { Component, Input, OnInit } from '@angular/core';
import { or_status } from '../../../custom-classes/or_states';
import { IOr } from 'src/app/interfaces/IOr';


@Component({
  selector: 'app-or-status-label',
  templateUrl: './or-status-label.component.html',
  styleUrls: ['./or-status-label.component.css', '../labels.css']
})
export class OrStatusLabelComponent implements OnInit {

  @Input() showText: boolean = false;
  @Input() pointer = false;
  @Input() circularStatus: boolean = false;
  @Input() action?: IOr;
  @Input() status!: or_status | [or_status, string] | undefined;
  @Input() menuIconOpen: boolean = false;
  @Input() showIcon: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (this.status == undefined) {
      this.status = new or_status("Abierta");
    }
  }

  get status_() {
    return this.action ? this.action.status : this.status ? this.status instanceof or_status ? this.status : this.status[0] : undefined;
  }

  /** Change this please */
  get text_() {
    if (this.action) {
      if (this.action.isBudget()) {
        return this.action.status.getBudgetName();
      } else {
        if (this.action.groups.every(g => g.type?.cargo) && this.action.status.invoiced) {
          return "TRAMITADA"
        }
        else {
          return this.action.status.name;
        }
      }
    } else if (this.status) {
      if (this.status instanceof or_status) {
        return this.status.name;
      } else {
        return this.status[1];
      }
    } else {
      return undefined;
    }
  }

  get iconByStatus() {
    return this.status_?.num == 0 ? "shcedule" : this.status_?.num == 1 ? "schedule" : "done"
  }

}
