<app-page-structure [masterClass]="'vehicle'" pageTitle="Stock de vehículos" [autocompleteID]="'vehicle_garage'"
    searchLabel="Buscar vehículo" createButtonClass="onboarding-create-garage"
    [addNewView]="v.createVnVo" noDataCreateNew="Crear VN/VO" [data]="vehicles"
    listTitleText="Listado de stock de vehículos"
    [filter]="filter"
    [filters]="filters"
    [displayedHeader]=" ['Chassis', 'Marca',    'Modelo',  'Precio',    'Tipo',        ]"
    [displayedColumns]="['chassis', 'brand',    'model',   'price',     'comercialType']"
    [specialText]="     [undefined,  undefined,  undefined, 'money',    undefined,      ]"
    [specialClass]="    [undefined,  undefined,  undefined, undefined,  undefined  ]"
    [cellZize]="        ['big',      undefined,  undefined, undefined,  'small']" [preIcon]="         [preIcon]" [card]="card"
    [filter]="filter" [basicUserSubscription]="subS.isBasic" [rightMenu]="rightMenu"
    (onSubscriptionAddFail)="subS.openTryProDialog()"
    [createButton]="{text : 'Crear vehículo' , icon : 'garage'}"
    [quickFilter]="quickFilter">

    <ng-template #rightMenu>
        <button mat-menu-item (click)="openBrandModel()">
            <mat-icon>style</mat-icon>
            Marcas y modelos
        </button>
        <button mat-menu-item (click)="routerS.goTo(v.concepts)">
            <mat-icon>playlist_add_check_circle</mat-icon>
            Conceptos
        </button>
    </ng-template>

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir al vehículo'" mat-flat-button color="warn"
                (click)="params.go(v.vnvoDetails, ps.selected!.vehicle_id)">
                <mat-icon>{{ps.selected!.icon}}</mat-icon>
                {{ps.selected!.getName()}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>
            <div class="row-client" *ngIf="ps.selected!.client; else nodata">
                <app-client-row [item]="getClient()!"></app-client-row>
            </div>
            <ng-template #nodata>
                <p class="c_t2 row-client" [matTooltip]="'No tiene cliente asignado'"><mat-icon class="vam">no_accounts</mat-icon>Sin propietario</p>
            </ng-template>
            <!-- Si existe una OR en curso, cambiar este botón para ir al a OR -->
            <button [disabled]="ps.selected?.client == undefined" mat-stroked-button class="mt10" [go]="v.createOr"
            [param]="ps.selected!.vehicle_id">
                <mat-icon>build</mat-icon>
                Abrir OR
            </button>
            <button  mat-stroked-button class="mt10" (click)="goAssignClient(ps.selected!)">
                <mat-icon *ngIf="this.ps.selected?.client == undefined">contacts</mat-icon>
                <mat-icon *ngIf="this.ps.selected?.client != undefined">published_with_changes</mat-icon>
                {{this.ps.selected?.client != undefined ? "Reasignar cliente/agente": "Asignar agente"}}
            </button>

            <button class="mt10" mat-stroked-button
                (click)="params.go(v.sellVehicle, ps.selected!.vehicle_id, 'vehicle')">
                <mat-icon>sell</mat-icon>
                Vender
            </button>
        </app-card>
    </ng-template>
</app-page-structure>
