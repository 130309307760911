import { endpoints } from "../constants/Enpoints";
import { IClassSearcher } from "../interfaces/IClassSearcher";
import { match } from "../services/search.service";

export class M_Brand implements IClassSearcher<M_Brand>{
    id: number
    name: string;
    created_at?: Date;
    updated_at?: Date;
    type: number;

    constructor(d: any) {
        this.id = d.id;
        this.name = d.name;
        this.type = d.type;
        this.updated_at = d.udpated_at ? new Date(d.udpated_at) : undefined;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
    }

    createNew(d: any): M_Brand {
        return new M_Brand(d);
    }
    getInputText(): string {
        return this.name;
    }
    getOptionText(): [string, undefined] {
        return [this.name, undefined]
    }
    defaultSearchFilter(v: string): boolean {
        return match(v, this.name);
    }

    get endpoint() { return endpoints.brands }
    get cs_id() { return this.id; }
    get icon() { return "copyright"; }
    get typeof() { return M_Brand }

    /*
    minify?: boolean | undefined;
    onlyCopies?: boolean | undefined;
    extraOptionText?: string | undefined;*/
}